export default {
  "infoBubble_location": "Ubicación",
  "infoBubble_dtc": "DTC",
  "infoBubble_address": "Dirección",
  "infoBubble_currentTime": "Hora Actual",
  "infoBubble_unitTime": "Hora de Unidad",
  "infoBubble_distance": "Distancia",
  "infoBubble_status": "Estado",
  "infoBubble_type": "Tipo",
  "infoBubble_speed": "Velocidad",
  "infoBubble_event": "Evento",
  "infoBubble_latLng": "Lat/Long",
  "infoBubble_driver": "Conductor",
  "infoBubble_speedMessage": "* La velocidad puede no ser precisa debido a señal débil.",
  "infoBubble_vehicleInfo": "Información de Vehículo",
  "infoBubble_vehicleName": "Nombre de Vehículo",
  "infoBubble_odometer": "Odómetro",
  "infoBubble_engineHours": "Horas Motor",
  "infoBubble_yearMakeModel": "Año/Marca/Modelo",
  "infoBubble_vin": "VIN",
  "infoBubble_tempReading": "Temperatura",
  "infoBubble_streetView": "Vista de Calle",
  "infoBubble_videocam": "Video",
  "infoBubble_snapshot": "Instántaneo",
  "infoBubble_settings": "Opciones",
  "infoBubble_live": "En Vivo",
  "infoBubble_requestVideo": "Solicitar Video",
  "infoBubble_trail": "Trayecto",
  "infoBubble_map": "Mapa",
  "infoBubble_compare": "Comparar",
  "infoBubble_getTrail": "Obtener Trayecto",
  "infoBubble_video": "Video",
  "infoBubble_streetViewNotAvailable": "La vista de calle no está disponible para esta ubicación.",
  "infoBubble_getMarkerDetailsError": "Ha ocurrido un error, por favor intente de nuevo.",
  "infoBubble_landmarks": "Punto de referencia",
  "infoBubble_waypointNumbers": "Números de Waypoint",
  "infoBubble_otherVehicles": "Otros Vehículos",
  "infoBubble_connectingLine": "Línea de Ruta",
  "infoBubble_getSnapshotError": "Ha ocurrido un error, por favor intente de nuevo.",
  "infoBubble_noSnapshotAvailable": "No hay instantánea disponible.",
  "infoBubble_snapshotDateTime": "Hora de instantánea: ",
  "infoBubble_Datetime": "Fecha/Hora",
  "infoBubble_Date": "Fecha",
  "infoBubble_Time": "Hora",
  "infoBubble_clipRange": "Rango del Video",
  "infoBubble_seconds20": "20 seg video (10 seg antes, 10 seg después)",
  "infoBubble_seconds40": "40 seg video (20 seg antes, 20 seg después)",
  "infoBubble_seconds60": "60 seg video (30 seg antes, 30 seg después)",
  "infoBubble_requestClip": "Solicitar",
  "infoBubble_openRequestClip": "Abrir Línea de Tiempo",
  "infoBubble_chooseClip": "Elegir Clip",
  "infoBubble_requestSuccess": "Tu video ha sido solicitado. Cuando termine de descargarse, estará disponible en la pestaña de video.",
  "infoBubble_requestError": "Ha ocurrido un error, por favor intente de nuevo",
  "infoBubble_done": "Aceptar",
  "infoBubble_goToVideoTab": "Ir a pestaña de video",
  "infoBubble_createNewVideoClip": "Crear nuevo videoclip",
  "infoBubble_geofenceInfo": "Información de Geocerca",
  "infoBubble_routePointInfo": "Información del punto de Ruta",
  "infoBubble_routePointInfo_history": "Registro de cambios de estado",
  "infoBubble_requestRangeTimeError": "No hay datos disponibles en el rango de tiempo seleccionado",
  "infoBubble_defaultGroup": "Default Group",
  "infoBubble_media": "Video",
  "infoBubble_cameraNotFound": "Cámara no encontrada",
  "infoBubble_crewMembers": "Miembros de Tripulación",
  "infoBubble_clip": "Clip de Evento",
  "infoBubble_pointInfo": "Información del Punto",
  "infoBubble_radius": "Radio",
  "infoBubble_tags": "Etiquetas",
  "infoBubble_videoClip_from": "Desde",
  "infoBubble_videoClip_to": "Hasta",
  "infoBubble_notes": "Notas",
  "infoBubble_validDate": "Se debe seleccionar una fecha valida",
  "infoBubble_noEventsClip": "No existen eventos para la unidad seleccionada",
  "infoBubble_clipRequestError": "Ocurrió un error intentelo mas tarde",
  "infoBubble_clipRequestSuccess" : "Tu video ha sido solicitado. Cuando termine de descargarse, estará disponible en la pestaña de video"



}