//View Keys
export default {
  realtimemaps_vehicletrails_unit: 'Unit',
  realtimemaps_vehicletrails_date: 'Date / Time',
  realtimemaps_vehicletrails_event: 'Event',
  realtimemaps_vehicletrails_filter: 'Filter',
  realtimemaps_vehicletrails_allevents: '- All Events -',
  realtimemaps_vehicletrails_gettrail: 'Get Trail',
  realtimemaps_vehicletrails_nodata: 'No data available for the requested period',
  realtimemaps_vehicletrails_closetable: 'Close Vehicle Trail Table',
  realtimemaps_vehicletrails_closesettings: 'Close Vehicle Trail Settings',
  realtimemaps_vehicletrails_vehicletrail_table: 'View Vehicle Trail Table',
  realtimemaps_vehicletrails_vehicletrail_view: 'Vehicle Trail Table',
  realtimemaps_vehicletrails_vehicletrail_err: 'loading issue has occurred. please try again.',
  realtimemaps_vehicletrails_vehicletrail_validatedate: 'The date range can not exceed 30 days',
  realtimemaps_vehicletrails_vehicletrail_validateunit: 'The Unit is a mandatory field',

  realtimemaps_vehicletrails_all: 'All',
  realtimemaps_vehicletrails_drive: 'Drive',
  realtimemaps_vehicletrails_ping: 'Ping',
  realtimemaps_vehicletrails_travelStartStop: 'Travel Start/Stop',
  realtimemaps_vehicletrails_travelStop: 'Travel Stop',
  realtimemaps_vehicletrails_travelStart: 'Travel Start',
  realtimemaps_vehicletrails_ignitionOnOff: 'Ignition On/Off',
  realtimemaps_vehicletrails_ignitionOff: 'Ignition Off',
  realtimemaps_vehicletrails_ignitionOn: 'Ignition On',
  realtimemaps_vehicletrails_driveEvent: 'Driving',
  realtimemaps_vehicletrails_totaltime: 'Total Time',
  realtimemaps_vehicletrails_overSpeed: 'Over Speed',
  realtimemaps_vehicletrails_maxSpeed: 'Max Speed',
  realtimemaps_vehicletrails_travelStart: 'Travel Start',
  realtimemaps_vehicletrails_motionStart: 'Motion Start',
};