export default {
    settingsKeyFob_add: "Crear nuevo llavero",
    settingsKeyFobs_createTitle:"Crear nuevo LLavero",
    settingsKeyFobs_editTitle:"Editar LLavero",
    settingsKeyFobs_columTitle:"LLavero ",
    settingsKeyFobs_columTitleDriver:"Conductor ",
    settingsKeyFobs_minLengthMessage:'El campo debe contener un mínimo de caracteres: ',
    settingsKeyFobs_maxLengthMessage:'El campo debe contener un maximo de caracteres: ',
    settingsKeyFobs_success:'LLavero Guardado',
    settingsKeyFobs_deleteWarning:'¿Está seguro de que desea eliminar el LLavero?',
    settingsKeyFobs_deleteTitle:'Eliminar LLavero',
    settingsKeyFobs_deleted:'LLavero eliminado',
    settingsKeyFob_search:'Buscar por LLavero y Conductor',
    settingsKeyFobs_driverTitle:'Conductor',
    settingsKeyFobs_infoBluetooth:'Este llavero es de tipo Bluetooth geométrico y no es posible editar el ID del llavero'
};