import {
    MAPS_ETA_SHARE,
    MAPS_ETA_SHARE_RESPONSE,
    CLEAR_REDUX
} from 'Redux/actionTypes';
const INIT_STATE = {
    response: null,
    error: false,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MAPS_ETA_SHARE:
            return { ...state, response: null, error: false, loading: true };

        case MAPS_ETA_SHARE_RESPONSE:
            return { ...state, response: action.payload.response, error: action.payload.error, loading: false };

        case CLEAR_REDUX:
            return ["", "MAPS_ETA"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state };

        default:
            return state;
    }
}