import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { buildUrlShare, getData } from "../utils";
import { useComputeData } from 'Core/hooks'
import { getIconAttributes, validateEventColor } from "../../historyOverView/utils";
import { Area, AreaMenu, IconButton, Menu, Radio } from "@gpstrackit/gtc-react-controls";
import { useIntl } from "react-intl";
import { orderTrailPolilyne } from "../../../utils";
import { getVidFleetEvent } from 'Core/data/VideoEvents';
import MarkerOverlay from "Components/Map/providers/google/MarkerOverlay";
import { getUnit } from "../../../../../.globals/utils";
import { sliceHistoryTrails } from "Redux/actions";

export const Map = ({
  activeIncident,
  viewTrails,
  setReadingSelected,
  setNumberSelected
}) => {
  const mapRef = useRef(null);
  const { messages } = useIntl()
  const dispatch = useDispatch()
  const areaMenuRef = useRef();
  const areaMenuLayerRef = useRef();
  const polylinesRef = useRef([]);
  const markersRef = useRef([]);
  const labelRef = useRef([]);

  const [map, setMap] = useState(null);
  const markerRef = useRef(null);
  const { 
    isOpenOverViewUnit,  
    loadingTrailsIncident,
    errorTrailsIncident,
    trailsIncidentTotal,
    trailsIncident,
    sliceTrails
  } = useSelector((state) => state.incidentsRedux);

  const [latLog, setLatLog] = useState({ lat: -34.397, lng: 150.644 })
  const [zoom, setZoom] = useState(12)
  const [id, setId] = useState(null)
  const [hasVideo, setHasVideo] = useState(false)
  const [shareUrl, setShareUrl] = useState('')
  const [mapType, setMapType] = useState('roadmap')

  const [data, changeIdsCallback] = useComputeData(
    getData,
    "id",
    ["label", "lat", "lng", "eventName", "changeLatLng"],
    true,
    (itemsChanged) => {}
  )

  useEffect(() => {
    if(isOpenOverViewUnit?.data?.unit){
      setId(isOpenOverViewUnit?.data?.unit?.id)
      setHasVideo(isOpenOverViewUnit?.data?.unit?.hasVideo)
      const urlShare = buildUrlShare(isOpenOverViewUnit?.data?.incident?.shortcode)
      setShareUrl(urlShare)
    }
  }, [isOpenOverViewUnit])

  useEffect(() => {
    const mapOptions = {
      zoom,
      center: latLog,
      mapTypeControl: false,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.TOP_LEFT
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_RIGTH
      },
      mapTypeId: mapType
      
    };

    const newMap = new google.maps.Map(mapRef.current, mapOptions);
    setMap(newMap);
  }, [mapType])
  
  useEffect(() => {
    if (!google) {
      return;
    }

    if (!map) {
      const mapOptions = {
        zoom,
        center: latLog,
        mapTypeControl: false,
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.TOP_LEFT
        },
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_RIGTH
        },
        mapTypeId: mapType
        
      };

      const newMap = new google.maps.Map(mapRef.current, mapOptions);
      setMap(newMap);
    } else {

      if (markerRef.current) {
        markerRef.current.setMap(null);
      }

      clearPolilyne()
      clearMarkers();
      clearLabels()

      if(viewTrails){
        if (!loadingTrailsIncident && sliceTrails?.length > 0) {

  
          const getOrderTrail = orderTrailPolilyne(sliceTrails)
  
          getOrderTrail?.length && getOrderTrail.forEach((markerData, index) => {
            const pathCoordinates = getOrderTrail.slice(index, index + 2).map(data => ({
              lat: data.lat,
              lng: data.lon
            }));
  
            if (pathCoordinates.length > 1) {
  
              var dashedLine = {
                //path: 'M 0,-1 0,1',
                path: 'M -2,0 2,0 0,-4 z',
                strokeOpacity: 0.8,
                scale: 3,
                //fillColor: mapObject.color,
                fillOpacity: 0.8,
              };
  
              const color = validateEventColor(markerData); 
              const polyline = new google.maps.Polyline({
                path: pathCoordinates,
                geodesic: false,
                strokeColor: color,
                strokeOpacity: 0.6,
                strokeWeight: 8,
                clickable: false,
                icons: [{
                  icon: dashedLine,
                  offset: '0',
                  repeat: '200px' // Define la distancia entre cada segmento punteado
                }],
              });
  
              
  
              polyline.setMap(map);
              polylinesRef.current.push(polyline);
            }
          });
  
          sliceTrails.forEach((markerData, index) => {
            const color = validateEventColor(markerData);
            const numberItem = sliceTrails?.length - index;
            // let iconMarker = getIconAttributes(color, markerData?.heading, null, null, {...markerData, hasVideo: true });
  
            let url = null;
            let imageStyle = {};
            if (markerData?.eventCode && markerData?.eventCode?.includes("CameraEvent")) {
              let event = getVidFleetEvent(markerData?.eventCode);
              url = event.icon;
              imageStyle = {
                anchor: new google.maps.Point(15, 10)  // eslint-disable-line no-undef
              };
            }
  
            const iconMarker = getIconAttributes(color, markerData.heading, url, imageStyle, markerData)
  
            const marker = new google.maps.Marker({
              position: { lat: markerData.latitude, lng: markerData.longitude },
              map: map,
              icon: iconMarker,
              info: markerData,
            });
  
  
            marker.addListener('click', () => {
              setReadingSelected(markerData);
              filterTrail(index)
              setNumberSelected(numberItem)
            });
  
            labelRef.current.push(addLabel(marker, numberItem))
  
            markersRef.current.push(marker);
          });
  
          const lastEvent = sliceTrails[0];
  
          map.setCenter({ lat: lastEvent.latitude, lng: lastEvent.longitude });
          setReadingSelected(lastEvent);
        }
      }else{
        const unit = getUnit(id, data) 
  
        if (unit) {
          const {lat, lng} = unit
          map.setCenter({lat, lng});
          const color = validateEventColor(unit)
          const iconMarker = getIconAttributes(color, unit?.heading, null, null, unit)
  
          if (markerRef.current) {
            markerRef.current.setMap(null);
          }
  
          const newMarker = new google.maps.Marker({
            position: {lat, lng},
            map: map,
            icon: iconMarker,
            zoom
          });
          markerRef.current = newMarker;
        }
      }
      
    }
  }, [viewTrails, sliceTrails, data, map]);

  const clearMarkers = () => {
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];
  }

  const clearLabels = () => {
    labelRef.current.forEach(labels => labels.setMap(null));
    labelRef.current = [];
  }

  const clearPolilyne = () => {
    polylinesRef.current.forEach(polyline => polyline.setMap(null));
    polylinesRef.current = [];
  }

  const filterTrail = (item = 0) => {
    const newSlice = sliceTrails?.slice(item)
    if(newSlice?.length > 0){
      dispatch(sliceHistoryTrails(newSlice))
    }
  }

  const addLabel = (mapObject, index) => {
    if (!map) return
  
    let googlePosition = null;

    googlePosition = new google.maps.LatLng(mapObject.position.lat(), mapObject.position.lng()) // eslint-disable-line no-undef

    const bubbleAnchor = document.createElement("div");
    bubbleAnchor.innerHTML = index;
    bubbleAnchor.setAttribute("id", `${index}`);


    const customOverlayLabels = new MarkerOverlay(googlePosition, bubbleAnchor);

    customOverlayLabels.setMap(map);
    return customOverlayLabels;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {}).catch(err => {
      console.error('Error al copiar el texto: ', err);
    });
  }

  return (
    <div style={{width: hasVideo ? '70%' : '100%', marginLeft: hasVideo ? '30px' : 0}}>
      {
        activeIncident &&
        <div 
          style={{
            position: 'absolute',
            zIndex: 1,
            right: '30px',
            top: '70px'
          }}
        >
          <AreaMenu
            id="areaUser"
            noHideOnBlur={true}
            ref={areaMenuRef}
            noCloseAway={true}
            menuPlacement="left"
          >
            <Area>
              <IconButton 
                icon="share" 
                style={{
                  color: 'black',
                  borderRadius: 0,
                  background: 'white'
                }} 
                tooltip={messages['incident_live_share']}
                placement="bottom"
              />
            </Area>
            <Menu >
              <div style={{
                display: 'flex',
                fontSize: '14px'
              }}>
                <p>{shareUrl}</p>
                <IconButton onClick={() => copyToClipboard(shareUrl)} style={{marginTop: '-5px'}} className='icon' icon='content_copy' size='xsmall'/>
              </div>
            </Menu>

          </AreaMenu>
        </div>
      }
      <div 
          style={{
            position: 'absolute',
            zIndex: 1,
            right: '30px',
            top: '115px'
          }}
        >
          <AreaMenu
            id="areaUser"
            noHideOnBlur={true}
            ref={areaMenuLayerRef}
            noCloseAway={true}
            menuPlacement="left"
          >
            <Area>
              <IconButton 
                icon="map" 
                style={{
                  color: 'black',
                  borderRadius: 0,
                  background: 'white'
                }} 
                tooltip={messages['incident_map_layer']}
                placement="bottom"
              />
            </Area>
            <Menu >
              <div style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
                <Radio 
                  value="hybrid" 
                  checked={mapType === 'hybrid'} 
                  label={messages['incident_map_hybrid']}  
                  onChange={(e) => setMapType(e)}
                />
                <Radio 
                  value="roadmap" 
                  checked={mapType === 'roadmap'} 
                  label={messages['incident_map_map']}  
                  onChange={(e) => setMapType(e)}
                />
                <Radio 
                  value="satellite" 
                  checked={mapType === 'satellite'} 
                  label={messages['incident_map_satelite']}  
                  onChange={(e) => setMapType(e)}
                />
                <Radio 
                  value="terrain" 
                  checked={mapType === 'terrain'} 
                  label={messages['incident_map_terrain']}  
                  onChange={(e) => setMapType(e)}
                />
              </div>
            </Menu>

          </AreaMenu>
      </div>
      <div className="incidents_map" style={{ width: "100%", height: activeIncident ? "250px" : "300px" }} ref={mapRef}></div>
    </div>
  );
};
