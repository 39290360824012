import {
    SETTINGS_CAMERA_MANAGE_SEARCH,
    SETTINGS_CAMERA_MANAGE_SEARCH_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loading: false,
    items: [],
    total: 0
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SETTINGS_CAMERA_MANAGE_SEARCH:
            return { ...state, loading: true };

        case SETTINGS_CAMERA_MANAGE_SEARCH_RESPONSE:
            return { ...state, loading: false, ...action.payload }

        case CLEAR_REDUX:
            return ["", "SETTINGS_CAMERA_MANAGE"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return state;
    }
};