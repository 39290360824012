import React, {
  useEffect,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { endpoints } from 'Core/defaultValues';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// Components
import { Loading, Pill } from 'Components';
import _ from 'lodash';
import { Grid, Icon, IconButton, Typography } from '@mui/material';
import { getUserFormattedDate, getFormattedDateByTimeZone } from 'Core/data/Helpers';
// Styles
import './smartwitness.scss';

// Actions
import {
  getSnapshotsUrls,
  refreshSnapshotsUrls,
  saveSnapshotsUrls,
  clearRedux
} from 'Redux/actions';

const getSnapshots = (state) => state.smartwitnessVideoRedux.snapshotsResponse;
const getSnapshotsLoading = (state) => state.smartwitnessVideoRedux.loadingSnapshots;

const snapshotsServer = (recorderId) => {
  return createSelector(
    [getSnapshots, getSnapshotsLoading],
    (snapshots, loadingSnapshots) => {
      return [loadingSnapshots[recorderId], snapshots[recorderId]]
    }
  )
}

const saveSnapshotsResponse = (state) => state.smartwitnessVideoRedux.saveSnapshotsUrlsResponse;
const saveSnapshotsLoading = (state) => state.smartwitnessVideoRedux.loadingSaveSnapshotsUrls;

const saveSnapshotsServer = (recorderId) => {
  return createSelector(
    [saveSnapshotsResponse, saveSnapshotsLoading],
    (saveResponse, loadingSaveResponse) => {
      return [loadingSaveResponse[recorderId], saveResponse[recorderId]]
    }
  )
}

const SmartWitnessVideoComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { messages } = props;

  useImperativeHandle(ref, () => ({
    isVideoAvailable: isVideoAvailable,
    save: save
  }));

  const { recorderId, deviceId, cameraChannel, setIsVideoAvailable, timeZone } = props;

  const [loadingSnapshots, snapshotsResponse] = useSelector(snapshotsServer(recorderId), (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps)
  });

  const [loadingSaveSnapshots, saveSnapshotsResponse] = useSelector(saveSnapshotsServer(recorderId), (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps)
  });

  const { user } = useSelector(state => state.securityRedux);

  const [message, setMessage] = useState();
  const [showPlayButton, setShowPlayButton] = useState(false);
  const [selectedSnapshot, setSelectedSnapshot] = useState();
  const [showBackButton, setShowBackButton] = useState();

  useEffect(() => {
    return () => {
      clearInterval(window.smartwitnessVideo);
      window.smartwitnessVideo = null;
      dispatch(clearRedux('SMARTWITNESS_VIDEO'));
    };
  }, []);

  useEffect(() => {
    if (recorderId && cameraChannel) {
      dispatch(getSnapshotsUrls({ recorderId, cameraChannel, user }));
    } else {
      if (!recorderId) {
        setMessage(messages['smartwitnessVideo_notEsn']);
      } else {
        setMessage(messages['errorMessage']);
      }
    }
  }, [recorderId, cameraChannel]);

  useEffect(() => {
    if (snapshotsResponse) {
      if (snapshotsResponse.error) {
        if (snapshotsResponse?.data?.Code === 202) {
          setMessage(messages['smartwitnessVideo_deviceOffline']);
          setShowPlayButton(true);
          if (setIsVideoAvailable) setIsVideoAvailable(false);
        }
      } else if (snapshotsResponse?.data?.length) {
        if (!selectedSnapshot || snapshotsResponse?.data?.length === 1) {
          setSelectedSnapshot(snapshotsResponse.data[0]);
        } else {
          setSelectedSnapshot(snapshotsResponse?.data?.find(s => s?.Camera === selectedSnapshot?.Camera));
        }

        if (!window.smartwitnessVideo) {
          if (setIsVideoAvailable) setIsVideoAvailable(true);
          fetchSnapshots();
        }
      }
    }
  }, [snapshotsResponse]);

  useEffect(() => {
    if (saveSnapshotsResponse) {
      if (saveSnapshotsResponse?.error) {
        if (setIsVideoAvailable) setIsVideoAvailable(false);
        setMessage(messages['errorMessage']);
        setShowPlayButton(true);
        clearInterval(window.smartwitnessVideo);
        window.smartwitnessVideo = null;
        if (setIsVideoAvailable) setIsVideoAvailable(false);
      } else {
        setShowBackButton(true);
        setMessage(messages['smartwitnessVideo_saved']);
      }
    }
  }, [saveSnapshotsResponse]);


  const fetchSnapshots = useCallback(() => {
    clearInterval(window.smartwitnessVideo);
    window.smartwitnessVideo = null;
    window.smartwitnessVideo = setInterval(() => {
      dispatch(refreshSnapshotsUrls({ recorderId, cameraChannel, user }));
    }, 5000);
  })

  const isVideoAvailable = () => {
    return (loadingSnapshots === false && !loadingSaveSnapshots && snapshotsResponse && !snapshotsResponse?.error && !message);
  };

  const save = () => {
    setMessage("");
    setShowPlayButton(false);
    if (setIsVideoAvailable) setIsVideoAvailable(false);

    if (snapshotsResponse?.data && snapshotsResponse?.data?.length && recorderId && deviceId) {
      dispatch(saveSnapshotsUrls({
        recorderId: recorderId,
        deviceId: `${deviceId}`,
        media: JSON.stringify(snapshotsResponse?.data)
      }));
    }
  };

  const getSpeedColor = useCallback(() => {
    if (selectedSnapshot) {
      if (!selectedSnapshot?.Speed || selectedSnapshot?.Speed <= 0) {
        return '#FFFFFF';
      } else if (selectedSnapshot?.Speed > 0 && selectedSnapshot?.Speed < 60) {
        return '#66BB6A';
      } else if (selectedSnapshot?.Speed >= 60 && selectedSnapshot?.Speed < 80) {
        return '#E77225';
      } else if (selectedSnapshot?.Speed >= 80) {
        return '#D0021B';
      }
    }
  });

  return (
    <div className="smartwitness-container">
      <div className="smartwitness-actions-container">
        {
          (loadingSnapshots || loadingSaveSnapshots) &&
          <Loading full />
        }

        <div
          style={{
            fontSize: 16,
            padding: '24px 24px 10px 24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {
            (snapshotsResponse?.error || saveSnapshotsResponse?.error) &&
            <Icon className="text-danger" style={{ marginRight: '5px' }}>error</Icon>
          }
          {message}
        </div>

        {
          showBackButton &&
          <div>
            <IconButton
              aria-label="back"
              style={{ color: 'black' }}
              onClick={(e) => {
                e.preventDefault();
                setMessage(null);
                setShowBackButton(false);
                if (setIsVideoAvailable) setIsVideoAvailable(true);
              }}
              size="large">
              <Icon fontSize="default" style={{ color: 'black' }}>
                keyboard_backspace
              </Icon>
            </IconButton>
          </div>
        }

        {
          (showPlayButton && !loadingSnapshots && !loadingSaveSnapshots) &&
          <div>
            <IconButton
              aria-label="play"
              style={{ color: 'black' }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(clearRedux('SMARTWITNESS_VIDEO'));
                setMessage(null);
                setShowPlayButton(false);
                dispatch(getSnapshotsUrls({ recorderId, cameraChannel, user }));
                if (setIsVideoAvailable) setIsVideoAvailable(false);
              }}
              size="large">
              <Icon fontSize="default" style={{ color: 'black' }}>
                replay
              </Icon>
            </IconButton>
          </div>
        }
      </div>

      {
        (!snapshotsResponse?.error && snapshotsResponse && !message && !loadingSnapshots && !loadingSaveSnapshots) &&
        <div>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              item
              xs={3}
              style={{ paddingRight: 10, height: 215 }}
            >
              <div
                style={{
                  marginBottom: 10,
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  height: 215
                }}
              >
                {
                  snapshotsResponse?.data?.map((snapshot, key) => {
                    return (
                      <div key={key}>
                        <img
                          src={`${endpoints.SMARTWITNESS_API_URL}${snapshot?.URL}`}
                          alt={snapshot?.Camera}
                          className={`snapshot-thumbnail ${(snapshot?.Camera === selectedSnapshot?.Camera ? 'selected-snapshot-thumbnail' : '')}`}
                          onClick={() => {
                            setSelectedSnapshot(snapshot);
                          }}
                        />
                      </div>
                    )
                  })
                }
              </div>
            </Grid>

            <Grid
              container
              item
              xs={9}
              style={{ height: 215 }}
            >
              {
                selectedSnapshot &&
                <img
                  src={`${endpoints.SMARTWITNESS_API_URL}${selectedSnapshot?.URL}`}
                  alt={selectedSnapshot?.Camera}
                  className={`snapshot`}
                />
              }
            </Grid>
          </Grid>

          {
            selectedSnapshot &&
            <Grid
              item
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{
                paddingTop: 10
              }}
            >
              <Grid item xs>
                <Typography style={{ fontWeight: 'bold', fontSize: 16 }}>
                  {
                    timeZone ?
                      getFormattedDateByTimeZone(selectedSnapshot?.StartDateTime, timeZone, messages) :
                      getUserFormattedDate(user, selectedSnapshot?.StartDateTime, messages)
                  }
                </Typography>
              </Grid>

              <Grid itemstyle={{ textAlign: 'center' }}>
                <Pill
                  item={{
                    name: `${selectedSnapshot?.Speed ? parseInt(selectedSnapshot?.Speed) : 0} ${user?.isKph ? 'KPH' : "MPH"}`
                  }}
                  style={{
                    color: getSpeedColor() === "#FFFFFF" ? 'black' : 'white',
                    backgroundColor: getSpeedColor()
                  }}
                  clickable={false}
                />
              </Grid>

              <Grid item xs style={{ textAlign: 'right' }}>
                <NavLink
                  className="btn-link"
                  to={`/app/legacy/mediaevents`}
                  style={{ fontSize: 16 }}
                >
                  {messages['smartwitnessVideo_viewCameraEvents']}
                </NavLink>
              </Grid>
            </Grid>
          }
        </div>
      }
    </div>
  );
});

export default SmartWitnessVideoComponent;

SmartWitnessVideoComponent.propTypes = {
  recorderId: PropTypes.string.isRequired, //The recorder id of the camera
  cameraChannel: PropTypes.string.isRequired, //The camera number or * for all of them
};