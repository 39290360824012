//View Keys
export default {
	forgotPassword_title: 'Recuperar contraseña',
	forgotPassword_email: 'Correo',
	forgotPassword_reset: 'Reiniciar contraseña',
	forgotPassword_subtitle: 'Por favor complete los datos',
	forgotPassword_verificationCode: 'Código de verificación',
	forgotPassword_newPassword: 'Nueva Contraseña',
	forgotPassword_ConfirmNewPassword: 'Confirmar nueva contraseña',
	forgotPassword_verify: 'Verificar',
	forgotPassword_success: 'Su contraseña ha sido actualizada, por favor inicie sesión',
	forgotPassword_passwordError: 'Las contraseñas no coinciden',
  	forgotPassword_back: '¿Volver a iniciar sesión?',
  	forgotPassword_userNoMigrated: 'Para utilizar la recuperación de contraseña, primero deberá registrar este nombre de usuario. Complete el proceso de registro o comuníquese con nuestro equipo de Servicio al cliente al 866-320-5810 o customerservice@gpstrackit.com para obtener más ayuda.',
  	forgotPassword_userNoMigratedReseller: 'Para utilizar la recuperación de contraseña, primero deberá registrar este nombre de usuario. Complete el proceso de registro o comuníquese con nuestro equipo de Servicio al cliente para obtener más ayuda.',
  	forgotPassword_errorMesaggeUserNoFound: 'No se encontró la combinación de nombre de usuario/ID de cliente.',

};
