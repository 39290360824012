import {
    //TYPES
    REALTIME_MAP_CENTER_UNIT,
    REALTIME_MAP_ADD_POINTERS,
    REALTIME_MAP_ADD_NOT_CENTER_POINTERS,
    REALTIME_MAP_ADD_POINTERS_MORE_ITEMS,
    REALTIME_MAP_ADD_POINTERS_RESPONSE,
    REALTIME_MAP_CLICK_LATLNG,
    REALTIME_MAP_CLICK_LATLNG_RESTART,
    REALTIME_MAP_GET_KMLS,
    REALTIME_MAP_GET_JSON_CONFIRM,
    REALTIME_MAP_UPDATE_KMLS,
    REALTIME_MAP_UPDATE_KMLS_COMFIRM,
    REALTIME_MAP_SAVE_KMLS,
    REALTIME_MAP_LISTEN_ON_CLICK_MAP,
    REALTIME_MAP_SET_ON_CLICK_MAP_POSITION,
    REALTIME_MAP_SAVE_LOADING,
    REALTIME_MAP_ZOOM_POINT,
    REALTIME_MAP_ADD_RADIUS,
    REALTIME_MAP_GET_DIRECTIONS,
    REALTIME_MAP_GET_DIRECTIONS_OPTIONS,
    REALTIME_MAP_GET_DIRECTIONS_OPTIONS_RESTART,
    REALTIME_MAP_GET_DIRECTIONS_ERROR,
    REALTIME_MAP_RESTART,
    REALTIME_MAP_GET_OFFLINE_DEVICES,
    REALTIME_MAP_GET_OFFLINE_DEVICES_RESPONSE,
    REALTIME_MAP_FOLLOW_UNIT,
    REALTIME_MAP_PRINT_VIEW,
    REALTIME_MAP_GET_LANDMARK_CONFIRM,
    REALTIME_MAP_GET_GEOFENCE_CONFIRM,
    REALTIME_MAP_LOAD_OBJECT_LANDMARK,
    REALTIME_MAP_LOAD_OBJECT_GEOFENCE,
    REALTIME_MAP_UPDATE_ADD_LANDMARK,
    REALTIME_MAP_UPDATE_ADD_GEOFENCE
} from 'Redux/actionTypes';

export const centerOnUnit = (unitId, followUnit = false, currentZoom = false) => {
    return {
        type: REALTIME_MAP_CENTER_UNIT,
        payload: { centerUnitId: unitId, followUnit: followUnit, currentZoom: currentZoom }
    };
}

export const setFollowUnit = (followUnit = false) => {
    return {
        type: REALTIME_MAP_FOLLOW_UNIT,
        payload: followUnit
    };
}

export const zoomPointOnMap = (point) => {
    return {
        type: REALTIME_MAP_ZOOM_POINT,
        payload: point
    };
}

export const addPointers = (pointers) => {
    return {
        type: REALTIME_MAP_ADD_POINTERS,
        payload: pointers
    };
}

export const noCenterPointers = (center) => {
    return {
        type: REALTIME_MAP_ADD_NOT_CENTER_POINTERS,
        payload: center
    };
}

export const setLatLng = (latLng) => {
    return {
        type: REALTIME_MAP_CLICK_LATLNG,
        payload: latLng
    };
}

export const setLatLngRestart = () => {
    return {
        type: REALTIME_MAP_CLICK_LATLNG_RESTART
    };
}

export const enableMorePointers = (enable = false) => {
    return {
        type: REALTIME_MAP_ADD_POINTERS_MORE_ITEMS,
        payload: enable
    };
}

export const getKmls = () => {
    return {
        type: REALTIME_MAP_GET_KMLS,
        payload: true
    };
}

export const getKmlsConfirmResult = (result, error) => ({
    type: REALTIME_MAP_GET_JSON_CONFIRM,
    payload: { result, error },
});

export const getJsonLandmarkConfirmResult = (result, error) => ({
    type: REALTIME_MAP_GET_LANDMARK_CONFIRM,
    payload: { result, error }
})

export const getJsonGeofenceConfirmResult = (result, error) => ({
    type: REALTIME_MAP_GET_GEOFENCE_CONFIRM,
    payload: { result, error }
})

export const updateKmls = (entityId) => {
    return {
        type: REALTIME_MAP_UPDATE_KMLS,
        payload: entityId
    };
}

export const updateKmlsConfirmResult = (result, error) => ({
    type: REALTIME_MAP_UPDATE_KMLS_COMFIRM,
    payload: { result, error },
});

export const saveKmlToShow = (kmlDataToShow = []) => {
    return {
        type: REALTIME_MAP_SAVE_KMLS,
        payload: kmlDataToShow
    };
}

export const mapLoading = (loading) => {
    return {
        type: REALTIME_MAP_SAVE_LOADING,
        payload: loading
    };
}

export const setListenOnClickMap = (status) => {
    return {
        type: REALTIME_MAP_LISTEN_ON_CLICK_MAP,
        payload: status
    };
}

export const setOnClickMapPosition = (position) => {
    return {
        type: REALTIME_MAP_SET_ON_CLICK_MAP_POSITION,
        payload: position
    };
}

export const addRadius = (radius) => {
    return {
        type: REALTIME_MAP_ADD_RADIUS,
        payload: radius
    };
}

export const pointersCreated = (value) => {
    return {
        type: REALTIME_MAP_ADD_POINTERS_RESPONSE,
        payload: value
    };
}

export const drawGetDirections = (value) => {
    return {
        type: REALTIME_MAP_GET_DIRECTIONS,
        payload: value
    };
}

export const setMapRoutesOptions = (options) => {
    return {
        type: REALTIME_MAP_GET_DIRECTIONS_OPTIONS,
        payload: options
    };
}

export const setMapRoutesOptionsRestart = () => {
    return {
        type: REALTIME_MAP_GET_DIRECTIONS_OPTIONS_RESTART
    };
}

export const errorMapRoutesError = () => {
    return {
        type: REALTIME_MAP_GET_DIRECTIONS_ERROR
    };
}

export const setMapRestart = () => {
    return {
        type: REALTIME_MAP_RESTART
    };
}

export const getMapOfflineDevices = () => {
    return {
        type: REALTIME_MAP_GET_OFFLINE_DEVICES,
        payload: true
    };
}

export const getMapOfflineDevicesResult = (result, error) => ({
    type: REALTIME_MAP_GET_OFFLINE_DEVICES_RESPONSE,
    payload: { result, error },
});

export const printView = (value = false) => ({
    type: REALTIME_MAP_PRINT_VIEW,
    payload: value
})

export const loadObjectsLandmarksOnMap = (value) => ({
    type: REALTIME_MAP_LOAD_OBJECT_LANDMARK,
    payload: value
})

export const loadObjectsGeofenceOnMap = (value) => ({
    type: REALTIME_MAP_LOAD_OBJECT_GEOFENCE,
    payload: value
})
    
export const addUpdateLandmark = (action, landmark) => ({
    type: REALTIME_MAP_UPDATE_ADD_LANDMARK,
    payload: { action, landmark }
});

export const addUpdateGeofence = (action, geofence) => ({
    type: REALTIME_MAP_UPDATE_ADD_GEOFENCE,
    payload: { action, geofence }
});

