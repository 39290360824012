import {
  //TYPES
  MAP_UTILITIES_VEHICLE_LIST_SET,
  MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS,
  MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS_RESPONSE,
  MAP_UTILITIES_VEHICLE_LIST_REFRESH,
  MAP_UTILITIES_VEHICLE_LIST_GET_UNIT,
  MAP_UTILITIES_VEHICLE_LIST_GET_UNIT_RESPONSE,
  MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT,
  MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT_RESPONSE
} from "Redux/actionTypes";

const INIT_STATE = {
  hiddenVehicleList: [],
  loadUnitsVehicleList: false,
  unitsVehicleList: [],
  error: null,
  vehicleListRefresh: false,
  vehicleItem: null,
  loadTagsVehicleList: false,
  tagsVehicleList: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case MAP_UTILITIES_VEHICLE_LIST_SET:
        const list = state.hiddenVehicleList
        if(list.length > 0){
            let findElement = false
            list.map((item) => {
                if(item.hiddenGroupIds == action.payload.hiddenGroupIds){
                    item.visible = action.payload.visible
                    findElement = true
                    return
                } 
            })
            if(!findElement) list.push(action.payload);
        }else
            list.push(action.payload)
        
        return { ...state, hiddenVehicleList: [...list] };

    case MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS:
        return { ...state, loadUnitsVehicleList: true}

    case MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS_RESPONSE:
        if(!action.payload.error)
            return { ...state, unitsVehicleList: action.payload.data, loadUnitsVehicleList: false, vehicleListRefresh: false }

        return { ...state, error: action.payload.error, loadUnitsVehicleList: false}      

    case MAP_UTILITIES_VEHICLE_LIST_REFRESH:
        return { ...state, vehicleListRefresh: action.payload.refresh }

    case MAP_UTILITIES_VEHICLE_LIST_GET_UNIT:
        return { ...state, loadUnitsVehicleList: true, vehicleItem: null }
    
    case MAP_UTILITIES_VEHICLE_LIST_GET_UNIT_RESPONSE:
        if(!action.payload.error)
            return { ...state, loadUnitsVehicleList: false, vehicleItem: action.payload.data }

        return { ...state, error: action.payload.error, loadUnitsVehicleList: false}      

    case MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT:
        return { ...state, loadTagsVehicleList: true, tagsVehicleList: [] }
    
    case MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT_RESPONSE:
        if(!action.payload.error)
            return { ...state, loadTagsVehicleList: false, tagsVehicleList: action.payload.data }

        return { ...state, error: action.payload.error, loadTagsVehicleList: false}   

    default:
      return state;
  }
};