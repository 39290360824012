import {
    DASHBOARD_PANEL_DEVICE_OFFLINE,
    DASHBOARD_PANEL_DEVICE_OFFLINE_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    devices: {},
    loadingDevices: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
     
        case DASHBOARD_PANEL_DEVICE_OFFLINE:
            return {
                ...state,
                devices: {},
                loadingDevices: true
            };

        case DASHBOARD_PANEL_DEVICE_OFFLINE_RESPONSE:
            return {
                ...state,
                devices: action.payload,
                loadingDevices: false
            };

   
        case CLEAR_REDUX:
            return ["", "DASHBOARD_PANEL"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return { ...state };
    }
};