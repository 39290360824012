import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions 
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions'; 

import {
  getTimeZone
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getInputDetailsReport(report, event) {
  const { timeZone, name, reportTimeRanges } = report;
  const { startRange, endRange } = report.filters;
  const {
    filterType,
    filterArray,
    page,
    rowsPerPage,
    sortField,
    sortOrder,
    otherFilters
  } = event.filters;
  
  try {
      const newReport = JSON.parse(JSON.stringify(report))
      let data = yield call(
        clientQuery,
        `query getInputDetailsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
          getInputDetailsReport (conditions: $conditions, limit: $limit, offset: $offset) {
              items {
                id
                idLabel
                event
                onDatetime
                onAddress
                onLatitude
                onLongitude
                offDatetime
                offAddress
                offLatitude
                offLongitude
                duration
              }
              total
              limit
              offset
              category
          }
      }`,
        {
          "conditions": {
            reportName: name,
            "category": {
              field: filterType,
              value: filterArray?.id
            },
            timeZone: getTimeZone(timeZone),
            dateRange: {
              fields: [
                "unitTime"
              ],
              GTE: startRange,
              LTE: endRange,
              reportTimeRanges
            },
            stringValues: {
              values: [otherFilters?.event]
            },
            sortField: {
              field: sortField || "deviceId",
              order: sortOrder === 'desc' ? 'DESC' : 'ASC'
            }
          },
          limit: rowsPerPage,
          offset: page
        },
        endpoints.GRAPHQL_PX_REPORTING
      );
      if (data?.getInputDetailsReport) {
        newReport.summary = data.getInputDetailsReport
        newReport.loadingSummary = false
        yield put(reportsMainGenerateReportProgress(newReport))
      } else {
        newReport.loadingSummary = false
        yield put(reportsMainGenerateReportProgress(newReport))
      }
    } catch (exc) {
      report.loadingSummary = false
      ExceptionManager(exc, 'src/modules/reports/views/main/components/reportsTab/.components/reports/inputReport', 'getInputDetailsReport');
      yield put(reportsMainGenerateReportProgress(report))
    }
  }


export default getInputDetailsReport;