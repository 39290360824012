//imports
import React, {useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

//controls
import Modal, {ModalBody, ModalFooter} from 'Components/Modal';
//ownControls
//Hooks
// actions
//styles
import './reportListFilters.scss';
import {reportsFilterHideReportFilter} from "Modules/reports/views/main/components/reportsTab/.components/reportListFilters/redux/actions";
import {SearchInput, Table} from "Components/uiControls";
import {Button, Pill} from "Components";
import {Grid, Icon, Typography} from "@mui/material";

const ReportListFilters = (props) => {
    const {messages} = props;
    const dispatch = useDispatch();

    const showReportSetup = useSelector(state => state.reportsMainModalRTRedux.showReportSetup);
    const data = useSelector(state => state.reportsMainModalRTRedux.data);
    const tableRef = useRef();

    const [searchText, setSearchText] = useState([]);

    const handleCancel = () => {
        setSearchText(null)
        tableRef.current.setPage(1)
        tableRef.current.setSelectedItems([])
        dispatch(reportsFilterHideReportFilter('NAN'))
    }

    const onChangeSearchText = (newSearchText) => {
        setSearchText(newSearchText)
        tableRef.current.setPage(1)
        tableRef.current.setSelectedItems([])
    }

    const titleSort = data?.title ? data?.title.split(' ') : [];

    const columns = getColumns(messages, titleSort.length ? titleSort[titleSort.length - 1] : '');

    return (
        <Modal
            title={data?.title || ''}
            open={showReportSetup}
            handleClose={() => handleCancel()}
            size="extra-small"
            position="right"
            useCloseButtonTop
            hiddenCloseButton
            className="modal-report-list-filter"
        >
            <ModalBody className="modal-body-report-list-filter">
                {
                    (showReportSetup && data && data.list) && (
                        <>
                            <div className="modal-search-report-list-filter">
                                <SearchInput
                                    placeholder={messages['search']}
                                    searchText={searchText}
                                    onSearchText={onChangeSearchText}
                                />
                            </div>
                            <div className="modal-div-report-list-filter">
                                <Table
                                    ref={tableRef}
                                    data={data.list || []}
                                    order={'asc'}
                                    loading={!showReportSetup}
                                    keyColumn="id"
                                    topheight={300}
                                    columns={columns}
                                    searchText={searchText}
                                    valuesPerPage={[]}
                                    fixHeader
                                    className="modal-table-report-list-filter"
                                    hideDragable
                                    disableItemSelection
                                    hidePerPage
                                    hideTotal
                                    hideAllInPagination
                                    selectAllRows={data?.list.length}
                                    messageNoData={messages['reportsMainST_withoutSchedules']}
                                />
                            </div>
                        </>
                    )
                }
            </ModalBody>
            <ModalFooter>
                <Button
                    className='btn-blue'
                    onClick={() => handleCancel()}
                >
                    {messages['close']}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

const getColumns = (messages, title) => {
    return [
        {
            key: 'reportType',
            show: true,
            sort: true,
            title: title,
            sortValue: (item) => item?.label,
            export: false,
            render: (item) => {
                const defaultColor = (item?.color === 'ffffff') ? '000000' : item.color;
                return item.color ? (
                        <span style={{display: 'inline-flex'}}>
                            <Icon style={{fontSize: 20, marginRight: 5, color: '#' + defaultColor}}>local_offer</Icon>
                            <Typography noWrap>{item?.label?.length > 30 ? item?.label.substring(0, 30) + "..." : item?.label}</Typography>
                        </span>
                ) : (
                    <>
                        <Grid item xs zeroMinWidth style={{display: 'flex', justifyContent: 'space-between', width: '95%'}}>
                        <Typography noWrap>{item?.label}</Typography>
                            {
                                !item?.status && (
                                    <>
                                        <Pill
                                            textField="label"
                                            item={{label: messages['entityTagPicker_inactive']}}
                                            clickable={false}
                                            outlined={true}
                                            style={{border: '0', width: '110px', minWidth: '110px'}}
                                            colorField="default"
                                            colorSelection="66bb6a"
                                        /></>
                                )
                            }
                        </Grid>
                    </>
                )
            }
        }
    ];
}

export default ReportListFilters;
