import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
// Cloud React components
import { Grid } from '@mui/material';
import { Button } from 'Components';
import { TypeObjectMap, TypeLayer } from 'Components/Map/providers/enums';
import MapObject from 'Components/Map/providers/mapObject';
import Map from 'Components/Map';

import {
    copyToClipboard,
    GetFormatForMoment,
    getFormattedDateByTimeZone,
    conversionTimeZone,
    cutNotes
} from 'Core/data/Helpers'

import './styles.scss';

//actions
import {
    alertLogGetSystemAlertlog,
    clearRedux
} from 'Redux/actions';

const AlertLog = (props) => {
    const dispatch = useDispatch();
    const navigate= useNavigate();
    const { messages } = useIntl();
    const mapRef = useRef();
    const lang = useSelector(state => state.commomRedux.language);

    const { id } = useParams();

    let rowId;
    let unitId;

    if (id) {
        let data = id.split("-");
        if (data && data?.length > 1) {
            rowId = data[0];
            unitId = data[1];
        } else {
            if (navigate) navigate(`/error`);
        }
    } else {
        if (navigate) navigate(`/error`);
    }

    const response = useSelector(state => state.alertLogRedux.response);
    const loading = useSelector(state => state.alertLogRedux.loading);

    useEffect(() => {
        return () => {
            dispatch(clearRedux("ALERT_LOG"));
        }
    }, []);

    useEffect(() => {
        if (rowId && unitId) {
            dispatch(alertLogGetSystemAlertlog({ rowId, unitId }));
        }
    }, [rowId, unitId]);

    useEffect(() => {
        if (response) {
            if (response?.error) {
                if (navigate) navigate(`/error`);
            } else {
                const position = { lat: response.latitude, lng: response.longitude };
                let marker = new MapObject().buildMarker({
                    id: response?.id,
                    position: position,
                    layerName: TypeLayer.Default,
                    type: TypeObjectMap.Marker,
                    label: response?.systemAlertName,
                    draggable: false,
                    onClick: (newMarker) => {
                        mapRef?.current?.showMarkerModal(newMarker, null, getInfoBubble(), position, 10);
                    }
                });

                mapRef?.current?.addMapObject(marker, true)
                mapRef?.current?.centerByFitBounds([position], 0);
                mapRef?.current?.showMarkerModal(marker, null, getInfoBubble(), position, 10);
            }
        }
    }, [response]);

    const formatDateUser = () => {
        const momentFormat = GetFormatForMoment(null);
        return `h:mm A, ${momentFormat}`;
    }

    const currentTime = useCallback((paramTimeZone) => {
        let timezone = conversionTimeZone(paramTimeZone);
        let _currentTime = new Date().toLocaleString("en-US", { timeZone: timezone });
        let responseDate = moment(_currentTime).format(formatDateUser());
        return responseDate;
    });

    const getInfoBubble = useCallback(() => {
        return (
            <div style={{ minWidth: 535, maxWidth: 535, minHeight: 225 }}>
                <table className="info-bubble-table">
                    <tbody>
                        <tr>
                            <td>
                                {messages['alertLog_alertName']}
                            </td>
                            <td>
                                <div className="hover-container">
                                    <span style={{ paddingRight: 8 }}>
                                        {(response?.systemAlertName || "N/A")}
                                    </span>
                                    <Button
                                        type="icon"
                                        icon="content_copy"
                                        onClick={() => copyToClipboard(response?.systemAlertName || "N/A")}
                                        fontSize="inherit"
                                        className="btn-no-padding hover-item"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {messages['alertLog_vehicleName']}
                            </td>
                            <td>
                                <div className="hover-container">
                                    <span style={{ paddingRight: 8 }}>
                                        {(response?.unit?.label ? cutNotes(response?.unit?.label, 44) : "N/A")}
                                    </span>
                                    <Button
                                        type="icon"
                                        icon="content_copy"
                                        onClick={() => copyToClipboard(response?.unit?.label || "N/A")}
                                        fontSize="inherit"
                                        className="btn-no-padding hover-item"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {messages['alertLog_address']}
                            </td>
                            <td>
                                <div className="hover-container">
                                    <span style={{ paddingRight: 8 }}>
                                        {(response?.address || "N/A")}
                                    </span>
                                    <Button
                                        type="icon"
                                        icon="content_copy"
                                        onClick={() => copyToClipboard(response?.address || "N/A")}
                                        fontSize="inherit"
                                        className="btn-no-padding hover-item"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {messages['alertLog_driver']}
                            </td>
                            <td>
                                <div className="hover-container">
                                    <span style={{ paddingRight: 8 }}>
                                        {(response?.driverName || "N/A")}
                                    </span>
                                    <Button
                                        type="icon"
                                        icon="content_copy"
                                        onClick={() => copyToClipboard(response?.driverName || "N/A")}
                                        fontSize="inherit"
                                        className="btn-no-padding hover-item"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {messages['alertLog_landmark']}
                            </td>
                            <td>
                                <div className="hover-container">
                                    <span style={{ paddingRight: 8 }}>
                                        {(response?.landmarkName || "N/A")}
                                    </span>
                                    <Button
                                        type="icon"
                                        icon="content_copy"
                                        onClick={() => copyToClipboard(response?.landmarkName || "N/A")}
                                        fontSize="inherit"
                                        className="btn-no-padding hover-item"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {messages['alertLog_unitTime']}
                            </td>
                            <td>
                                <div className="hover-container">
                                    <span style={{ paddingRight: 8 }}>
                                        {(response?.unitTime ? getFormattedDateByTimeZone(response?.unitTime, response?.unit?.timeZone?.name, messages, formatDateUser(), true, lang) :  "N/A")}
                                    </span>
                                    <Button
                                        type="icon"
                                        icon="content_copy"
                                        onClick={() => copyToClipboard((response?.unitTime ? getFormattedDateByTimeZone(response?.unitTime, response?.unit?.timeZone?.name, messages, `h:mm A, ${GetFormatForMoment(null)}`, true, lang) :  "N/A"))}
                                        fontSize="inherit"
                                        className="btn-no-padding hover-item"
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {messages['alertLog_currentTime']}
                            </td>
                            <td>
                                <div className="hover-container">
                                    <span style={{ paddingRight: 8 }}>
                                        {currentTime(response?.timeZone?.name)}
                                    </span>
                                    <Button
                                        type="icon"
                                        icon="content_copy"
                                        onClick={() => copyToClipboard(currentTime(response?.timeZone?.name))}
                                        fontSize="inherit"
                                        className="btn-no-padding hover-item"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    });

    return (
        <div>

            <Map
                ref={mapRef}
                noRealtime={true}
                hiddenButton
                hiddenLayerToggle={true}
                hiddenLegends={true}
                hiddenTraffic={true}
                hiddenSearchMap
                height="100vh"
            />
        </div>
    );
}

export default AlertLog;