// View Keys
export default {
  // shared
  reports_searchSourcePlaceholder: 'Empezar a escribir...',
  // main page
  reportsView_comingSoon: 'Próximamente',
  reportsView_filterBy: 'Filtrar por',
  reportsView_reports: 'Reportes',
  reportsView_scheduled: 'Programado',
  reportsView_schedule: 'Programado',
  // no generated report
  noGeneratedReport_clickModal: '(Haga clic para ver el modal "Reportes demasiado grandes". Esto es temporal)',
  noGeneratedReport_defaultMessage: 'No hay reportes generados previamente. Haga clic en el icono "+" para generar un nuevo reporte',
  noGeneratedReport_defaultNewMessage: 'No hay informes generados previamente. Utilice Configuración de informe para generar un nuevo informe.',
  noGeneratedReport_reportsTooLarge: 'Modal - Reporte demasiado grandes',
  thereAreNoPinned: 'Aún no hay informes anclados.',
  reportSetup_noDataFound: 'No se han encontrado datos.',
  // generated reports
  generatedReport_daysCurrentAddress: '# de días en la dirección actual',
  generatedReport_address: 'Dirección',
  generatedReport_eventDistance: 'Distancia del evento',
  generatedReport_activity: 'Actividad',
  generatedReport_currentAddress: 'Dirección Actual',
  generatedReport_previousAddress: 'Dirección Anterior',
  generatedReport_antennaDisconnect: 'Desconexión de antena',
  generatedReport_antennaConnect: 'Conexión de antena',
  generatedReport_inputOn: 'Entrada encendida',
  generatedReport_inputOff: 'Entrada desactivada',
  generatedReport_advanceSummary: 'Resumen avanzado',
  generatedReport_avgSpeed: 'Velocidad promedio',
  generatedReport_alertLog: 'Registro de alerta',
  generatedReport_alertType: 'Tipo de alerta',
  generatedReport_alertDetail: 'Detalle de la alerta',
  generatedReport_contacts: 'Contactos',
  generatedReport_cost: 'Costo',
  generatedReport_date: 'Fecha',
  generatedReport_detailedReportLabel: 'Detallado',
  generatedReport_dir: 'Dir',
  generatedReport_edit: 'Editar',
  generatedReport_download: 'Descargar',
  generatedReport_category: 'Categoría',
  generatedReport_copy: 'Copiar al Setup',
  generatedReport_view: 'Ver',
  generatedReport_direction: 'Dirección',
  generatedReport_distance: 'Distancia',
  generatedReport_AverageSpeed: 'Velocidad media',
  generatedReport_driveTime: 'Tiempo de conducción',
  generatedReport_driver: 'Conductor',
  generatedReport_drivers: 'Conductores',
  generatedReport_drive: 'Conducción',
  generatedReport_allEvents: 'Todos los Eventos',
  generatedReport_vehicle: 'Vehículo',
  generatedReport_hourMeter: 'Contador horario',
  generatedReport_driverSafetyLabel: 'Seguridad del conductor',
  generatedReport_driverStatus: 'Estado del conductor',
  generatedReport_endOdometer: 'Fin del odómetro',
  generatedReport_engineOn: 'Motor encendido',
  generatedReport_engineOff: 'Motor apagado',
  generatedReport_started: 'Encendido',
  generatedReport_enterLandmark: 'Ingresar',
  generatedReport_event: 'Evento',
  generatedReport_location: 'Ubicación',
  generatedReport_eventandDuration: 'Evento y duración',
  generatedReport_events: 'Eventos',
  generatedReport_leaveLandmark: 'Leave',
  generatedReport_from: 'Desde',
  generatedReport_formName: 'Nombre formulario',
  generatedReport_to: 'Hasta',
  generatedReport_toAndFrom: 'Enviado/Recibido',
  generatedReport_exit: 'Salida',
  generatedReport_gps: 'GPS',
  generatedReport_GPSCalculated: 'gps calculado',
  generatedReport_group: 'Grupo ',
  generatedReport_odometerGroup: 'Informe de odómetro para grupo',
  generatedReport_home: 'Inicio',
  generatedReport_idleLabel: 'Inactivo',
  generatedReport_ignitionOn: 'Encendido - conectado',
  generatedReport_ignitionOff: 'Encendido - Desconectado',
  generatedReport_firstIgnitionOff: 'Primera Encendido a tiempo',
  generatedReport_lastIgnitionOff: 'Último  Encendido a tiempo',
  generatedReport_ignitionlabel: 'Encendido',
  generatedReport_in: 'Entrada',
  generatedReport_input1: 'Entrada 1 Tiempo de encendido',
  generatedReport_input2: 'Entrada 2 Tiempo de encendido',
  generatedReport_input3: 'Entrada 3 Tiempo de encendido',
  generatedReport_input4: 'Entrada 4 Tiempo de encendido',
  generatedReport_input5: 'Entrada 5 Tiempo de encendido',
  generatedReport_input6: 'Entrada 6 Tiempo de encendido',
  generatedReport_input7: 'Entrada 7 Tiempo de encendido',
  generatedEngineOnUtilization: 'Aprovechamiento del motor encendido',
  generatedEngineOffUtilization: 'Aprovechamiento del motor apagado',
  generatedReport_internalBatteryDisconnect: 'Desconexión de batería interna',
  generatedReport_internalBatteryConnect: 'Conexión de batería interna',
  generatedReport_landmark: 'Crear Punto de interés',
  generatedReport_landmarkLabel: 'Punto de interés',
  generatedReport_duration: 'Duración',
  generatedReport_mainPowerOff: 'Apagado principal',
  generatedReport_mainPowerOn: 'Encendido principal',
  generatedReport_runTime: 'Tiempo de ejecución',
  generatedReport_maxSpeed: 'Velocidad máxima',
  generatedReport_message: 'Mensaje',
  generatedReport_maintenance: 'Mantenimiento',
  generatedReport_milesLower: 'millas',
  generatedReport_miles: 'Millas',
  generatedReport_miAcronym: 'mi',
  generatedReport_kmLower: 'kilómetros',
  generatedReport_km: 'Kilómetros',
  generatedReport_kmAcronym: 'km',
  generatedReport_mileagelabel: 'Kilometraje',
  generatedReport_mileageSummaryLabel: 'Resumen de millas',
  generatedReport_na: 'NA',
  generatedReport_notes: 'Notas',
  generatedReport_newReport: 'Nuevo reporte',
  generatedReport_noAddress: '(No se ha dado la dirección)',
  generatedReport_noEvents: 'No hay eventos',
  generatedReport_noPreviouslyGeneratedReports: "No hay reportes generados previamente. Haga clic en el icono " + " para generar un nuevo informe.",
  generatedReport_notAvailable: 'No disponible',
  generatedReport_unknownDriver: 'Conductor Desconocido',
  generatedReport_unassigned: 'No asignado',
  generatedReport_notAssigned: 'No asignado',
  generatedReport_out: 'Out',
  generatedReport_odom: 'Odom',
  generatedReport_odometer: 'Odómetro',
  generatedReport_range: 'Rango',
  generatedReport_selectAReportOption: 'Seleccione una opción de reporte',
  generatedReport_infoReports: 'Tipos de informes y resúmenes',
  generatedReport_selectAContactOption: 'Seleccione uno o mas contactos',
  generatedReport_speed: 'Velocidad',
  generatedReport_postedSpeed: 'Velocidad publicada',
  generatedReport_state: 'Estado',
  generatedReport_grandTotals: 'Totales generales',
  generatedReport_grandTotal: 'Total general',
  generatedReport_startOdometer: 'Iniciar odómetro',
  generatedReport_startStopIdleLabel: 'Iniciar, Parada, Inactivo',
  generatedReport_stop: 'Parada',
  generatedReport_stopTime: 'Tiempo de Parada',
  generatedReport_simple: 'Simple',
  generatedReport_summary: 'Resumen',
  generatedReport_totalsFor: 'Totales para',
  generatedReport_time: 'Hora/fecha',
  generatedReport_updated: 'Actualizado',
  generatedReport_answer: 'Respuesta',
  generatedReport_question: 'Pregunta',
  generatedReport_timeInLandmark: 'Tiempo',
  generatedReport_longitude: 'Longitud',
  generatedReport_latitude: 'Latitud',
  /**Pendiente */
  generatedReport_total: 'Total',
  generatedReport_totals: 'Totales',
  generatedReport_totalsTitle: 'TOTALES',
  generatedReport_totalDistance: 'Distancia total',
  generatedReport_totalTime: 'Tiempo total',
  generatedReport_combinatedTotalTime: 'Tiempo total combinado',
  generatedReport_totalHours: 'Total (horas)',
  generatedReport_totalEngineOn: 'Total Motor Encendido',
  generatedReport_totalEngineOff: 'Total Motor Apagado',
  generatedReport_totalIgnitionOff: 'Total tiempo apagado',
  generatedReport_totalIdles: 'Número de Inactivos',
  generatedReport_totalIdleTime: 'Total tiempo inactivo',
  generatedReport_totalProductiveIdleTime: 'Total tiempo de inactividad productivo',
  generatedReport_totalProductiveIdle: 'Total tiempo de inactividad productivo',
  generatedReport_totalProductiveIdling: 'Total tiempo de inactividad productivo',
  generatedReport_totalVisits: 'Total Visitas',
  generatedReport_totalVisitDuration: 'Duración total de la visita',
  generatedReport_engineHours: 'Horas de motor',
  generatedReport_engineRunning: 'Tiempo de funcionamiento del motor (hrs)',
  generatedReport_totalStops: 'Número de Paradas',
  generatedReport_travelTime: 'Tiempo de viaje',
  generatedReport_travelStart: 'Inicio de viaje',
  generatedReport_travelStop: 'Parada de viaje',
  generatedReport_totalTravelTime: 'Total Tiempo de viaje',
  generatedReport_type: 'Tipo:',
  generatedReport_unit: 'Unidad',
  generatedReport_units: 'Unidades',
  generatedReport_unitLabel: 'Etiqueta de unidad',
  generatedReport_utilization: 'Utilización',
  generatedReport_wip: '(Placeholder)',
  generatedReport_totalMileage: 'Kilometraje total',
  generatedReport_stateCrossings: 'Cruces del Estado',
  generatedReport_noExport: 'No es posible exportar',
  generatedReport_tripSummary: 'Resumen del viaje',
  generatedReport_tripDetails: 'Detalles del viaje',
  generatedReport_unavailable: 'no disponible',
  generatedReport_stateTotals: 'Totales del Estado',
  generatedReport_stopDrive: 'Parada de conducir',
  generatedReport_stops: 'Paradas',
  generatedReport_lastEvent: 'Último evento',
  generatedReport_lastEventTime: 'Hora del último evento',
  generatedReport_averageStopTime: 'Tiempo medio de parada',
  generatedReport_idles: 'Inactivos',
  generatedReport_averageIdleTime: 'Tiempo medio de inactividad',
  generatedReport_stateCrossingDetails: 'Detalles del cruce estatal',
  generatedReport_unitsPerPage: 'unidades',
  generatedReport_driversPerPage: 'conductores',
  generatedReport_perPage: 'por página',
  generatedReport_reportDetails: 'Detalles del reporte',
  generatedReport_selectUnitsDrivers: 'Seleccionar unidades o conductores',
  generatedReport_totalMiles: 'Total de Millas',
  generatedReport_noTripDetails: 'No hay detalles del viaje',
  generatedReport_avgStopTime: 'Tiempo medio de parada',
  generatedReport_avgIdleTime: 'Tiempo medio de inactividad',
  generatedReport_distanceInside: 'Distancia interior',
  generatedReport_timeInside: 'Tiempo adentro',
  generatedReport_entered: 'Ingresó',
  generatedReport_exited: 'Salida',
  generatedReport_betweenLandMark: 'Entre Landmarks',
  generatedReport_landmarkTotals: 'Totales de referencia',
  generatedReport_totalIgnitionOn: 'Encendido total',
  generatedReport_totalIdle: 'Total de inactividad',
  generatedReport_serviceItem: 'Artículo de servicio:',
  generatedReport_serviceNotes: 'Notas de servicio',
  generatedReport_unit_group: 'Grupo de unidades',
  generatedReport_driver_group: 'Grupo de conductores',
  generatedReport_vehicle_group: 'Grupo de Vehículos',
  generatedReport_on: 'On',
  generatedReport_off: 'Off',
  // report setup modal
  reportSetup_cancel: 'Cancelar',
  reportSetup_clearAll: 'Borrar todo',
  reportSetup_allUnit: 'Todas las',
  reportSetup_allDriver: 'Todos los',
  reportSetup_finallyPlural: 'es',
  reportSetup_exclude: 'Excluir',
  reportSetup_excludeTablesWithoutData: 'Excluir tablas sin datos',
  reportSetup_getReport: 'Obtenga el reporte',
  reportSetup_reportType: 'Tipo de Reporte',
  reportSetup_selected: 'SELECCIONADO',
  reportSetup_productiveIdleCheckbox: 'Mostrar tiempo de inactividad productivo',
  reportSetup_excludeIdleCheckbox: 'Excluir tiempo inactivo',
  reportSetup_timeframe: 'Periodo',
  reportSetup_title: 'Configuración del reporte',
  // report too large
  reportTooLarge_message: 'El reporte que está intentando generar es demasiado grande para cargarlo en el navegador y tendrá que ser enviado por correo electrónico.',
  reportTooLarge_send: 'Enviar',
  reportTooLarge_sendMessage: 'Enviar reporte a',
  reportTooLarge_title: 'Reporte demasiado grande',
  reportsReady_title: 'Reportes Listos',
  reportsReady_ready: 'Listo',
  reportsPinReports: "Favorito",
  reportsPinnedReports: "Favoritos",
  reportsPinnedReports_title: "Reportes Favoritos",
  reportsRecentlyGenerated_title: "Recientemente Generados",
  reportsRecentlyGenerated: "Recientes",
  generatedReport_stopped: 'Detenido',
  generatedReport_moving: 'Movimiento',
  generatedReport_daysWorkedTotalPercentPart1: 'Días',
  generatedReport_daysWorkedTotalPercentPart2: '(trabajados,totales,porcentaje)',
  generatedReport_timeAndDate: 'Tiempo & Día',
  generatedReport_timezone: 'Zona horaria',
  generatedReport_ins: 'instantes',
  generatedReport_timeInGeofence: 'Tiempo en Geocerca',
  generatedReport_timeInLandmarkAll: 'Tiempo en el punto',
  generatedReport_geofence: 'Geocerca',
  generatedReport_geofenceTotal: 'Geocerca Total',
  generatedReport_stopSummaryTotal: 'Total Stop Duration',
  generatedReport_distanceInGeofence: 'Distancia en Geocerca',
  generatedReport_distanceInLandmark: 'Distancia en el punto',
  generatedReport_serial: 'Unidad Serial',
  generatedReport_serialNumber: 'Unidad Serial Númbero',
  generatedReport_vin: 'Vin',
  generatedReport_equipment: 'Equipo',
  generatedReport_garmin: 'Garmin Numero',
  generatedReport_garminSerial: 'Garmin Serial Número',
  generatedReport_tag: 'Etiqueta',
  generatedReport_crewMember: 'Miembro de la tripulación',
  generatedReport_crewMemberDuration: 'Tiempo total del miembro de la tripulación en el punto',
  generatedReport_vehicleTotalTimeInLandmark: 'Tiempo total del vehículo en el punto',
  generatedReport_LastReportedDateTime: 'Fecha/hora del último registrada',
  generatedReport_LastReportedAddress: 'Última dirección registrada',
  generatedReport_acceleratorPedalPosition: 'Pos del pedal de aceleración',
  generatedReport_batteryVoltage: 'Voltaje de la batería',
  generatedReport_brakePedalPosition: 'Pos del pedal de freno',
  generatedReport_engineCoolantLevel: 'Nivel de refrigerante del motor',
  generatedReport_engineCoolantTemp: 'Temperatura refrigerante del motor',
  generatedReport_engineFuelRate: 'Combustible de motor',
  generatedReport_engineOilLevel: 'Nivel de aceite del motor',
  generatedReport_engineOilPressure: 'Presión de aceite del motor',
  generatedReport_engineOilTemp: 'Temperatura aceite del motor',
  generatedReport_fuelLevelOne: 'Nivel de combustible 1',
  generatedReport_fuelLevelTwo: 'Nivel de combustible 2',
  generatedReport_rpm: 'RPM',
  generatedReport_seatbeltswitch: 'Interruptor cinturón de seguridad',
  generatedReport_engineCoolantPressure: 'Presión refrigerante del motor',
  generatedReport_spn: 'DTC SPN',
  generatedReport_pid: 'DTC PID',
  generatedReport_fmi: 'DTC FMI',
  generatedReport_occurrence: 'DTC Ocurrencia',
  generatedReport_dtcStatus: 'DTC Estado',
  generatedReport_title: 'Titulo',
  generatedReport_status: 'Estado',
  generatedReport_tripDuration: 'Duración del viaje',
  generatedReport_totalCost: 'Costo Total',
  generatedReport_signal: 'Señal',
  generatedReportFtpError: 'Seleccione un servidor FTP',
  generatedReport_TIMEFRAME: 'RANGO DE TIEMPO',
  generatedReport_jump_to_unit: 'SALTAR A LA UNIDAD',
  generatedReport_unitSummary: 'RESUMEN UNIDAD',
  generatedReport_driverSummary: 'RESUMEN CONDUCTOR',
  generatedReport_total_trip: 'Total de la duracion del viaje',
  generatedReport_reportRec: 'Informes recibidos',
  generatedReport_firstOn: 'Primera parada',
  generatedReport_lastOff: 'Última parada',
  generatedReport_idleTime: 'Tiempo de inactividad',
  generatedReport_machineHours: 'Horas de\n máquina',
  generatedReport_workHours: 'Horas de\n trabajo',
  generatedReport_saturdayHours: 'Horario del\n sábado',
  generatedReport_sundayHours: 'Horario del\n domingo',
  generatedReport_billingHours: 'Horas de\n facturación',
  generatedReport_overtimeHours: 'Horas\n extraordinarias',
  generatedReport_hour: 'horas',
  generatedReport_driverApp: 'Aplicación del conductor'
};
