export default {
  accountInformation_title: "Información de la cuenta",
  accountInformation_preferences: "Preferencias",
  accountInformation_defaultTab: "Tab por Defecto",
  accountInformation_unitOfMeasure: "Unidad de Medida",
  accountInformation_timezone: "Zona Horaria",
  accountInformation_dateFormat: "Formato de Fecha",
  accountInformation_durationFormat: "Formato de la duración",
  accountInformation_durationFormatHH: "Horas con decimal",
  accountInformation_durationFormatDaysHoursMinSec: "Días, Horas, Minutos, Segundos, (1 Día 2 h 16 min 47 seg)",
  accountInformation_select: "Seleccione",
  accountInformation_cancel: "Cancelar",
  accountInformation_save: "Guardar",
  accountInformation_success: "Cuenta editada, inicie sesión nuevamente para ver los cambios.",
  accountInformation_vehicleStatus: "Estado",
  accountInformation_maps: "Mapas",
  accountInformation_reports: "Reportes",
  accountInformation_dashboard: "Panel",
  accountInformation_mph: "MPH/Millas",
  accountInformation_kph: "KPH/Kilometros",
  accountInformation_language: "Lenguaje",
  accountInformation_apollo_eld_account: "Cuenta Apollo ELD",
  accountInformation_username: "Nombre de usuario",
  accountInformation_password: "Contraseña",
  accountInformation_change_password: "Cambiar la contraseña",
  accountInformation_confirm_password: "Confirmar contraseña",
  accountInformation_minMessageUsername: "El nombre de usuario debe contener un mínimo de caracteres:",
  accountInformation_maxMessageUsername: "El nombre de usuario debe contener el máximo de caracteres:",
  accountInformation_minMessagePassword: "La contraseña debe contener un mínimo de caracteres:",
  accountInformation_maxMessagePassword: "La contraseña debe contener el máximo de caracteres:",
  accountInformation_valueToCompareMessage: "La contraseña confirmada no coincide con la nueva contraseña",
};