//View Keys
export default {
  realtimemaps_title: 'Mapas',
  realtimemaps_route_error: 'La solicitud de direcciones no ha dado resultados.',
  realtimemaps_polygon_maxpoints: 'El polígono debe tener de 3-{maxPoints} puntos.',
  realtimemaps_route_limit: 'La ruta excede el valor de 50 millas establecido como límite, por favor configure nuevamente el punto inicial y final.',
  realtimemaps_drive: 'Conducir',
  realtimemaps_motion: 'Movimiento',
  realtimemaps_ignitionOff: 'Encendido apagado',
  realtimemaps_ignitionOn: 'Encendido conectado',
  realtimemaps_travelStart: 'Inicio del viaje',
  realtimemaps_travelStop: 'Fin de viaje',
  realtimemaps_geofenceIn: 'Geocerca adentro',
  realtimemaps_geofenceOut: 'Geocerca afuera',
  realtimemaps_idle: 'Inactivo',
  realtimemaps_stop: 'Detener',
  realtimemaps_parked: 'Parqueado',
  realtimemaps_update: 'Actualizar',
  realtimemaps_rapidAcceleration: 'Aceleración rápida',
  realtimemaps_hardBrake: 'Freno brusco',
  realtimemaps_hardTurn: 'Giro brusco',
  realtimemaps_suddenStop: 'Parada repentina',
  realtimemaps_other: 'Otro',
  realtimemaps_geofence: 'Geocerca',
  realtimemaps_offline: 'Fuera de línea',
  realtimemaps_address: 'Dirección',
  realtimemaps_useMapClick: 'Click en el mapa',
  realtimemaps_unit: 'Unidad',
  realtimemaps_landmark: 'Punto de referencia',
  realtimemaps_latLng: 'Lat/Long',
  realtimemaps_latitude: 'Latitud',
  realtimemaps_longitude: 'Longitud',
};