import { Box } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

const RangeLabelTime = ({ dateFrom, dateTo }) => {
  const { messages } = useIntl();

  const [startDate, setStartDate] = useState(dateFrom);

  const [endDate, setendDate] = useState(dateTo);

  useEffect(() => {
    setStartDate(moment(dateFrom).format("hh:mm:ss a"));

    setendDate(moment(dateTo).format("hh:mm:ss a"));
  }, [dateFrom, dateTo]);

  return (
    <Box>
      <label style={{marginRight:'13px', fontWeight:'bold', fontSize:'14px', color: '#000000'}}>
        {messages["videoClipRequest_selectedClip"]}
      </label>
      <label style={{fontSize:'14px', color: '#000000'}}>
        {`${startDate ? startDate : "----"} - ${endDate ? endDate : "----"} `}
      </label>

    </Box>
  );
};

export default RangeLabelTime;
