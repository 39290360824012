// Action Types
export const VIDFLEET_VIDEO_START_STREAMING = "VIDFLEET_VIDEO_API_START_STREAMING";
export const VIDFLEET_VIDEO_START_STREAMING_RESPONSE = "VIDFLEET_VIDEO_START_STREAMING_RESPONSE";
export const VIDFLEET_VIDEO_START_LIVE_PHOTO = "VIDFLEET_VIDEO_START_LIVE_PHOTO";
export const VIDFLEET_VIDEO_START_LIVE_PHOTO_RESPONSE = "VIDFLEET_VIDEO_START_LIVE_PHOTO_RESPONSE";
export const VIDFLEET_VIDEO_STOP_STREAMING = "VIDFLEET_VIDEO_API_STOP_STREAMING";
export const VIDFLEET_VIDEO_STOP_STREAMING_RESPONSE = "VIDFLEET_VIDEO_STOP_STREAMING_RESPONSE";
export const VIDFLEET_VIDEO_GET_STREAMING_STATUS = "VIDFLEET_VIDEO_GET_STREAMING_STATUS";
export const VIDFLEET_VIDEO_GET_STREAMING_STATUS_RESPONSE = "VIDFLEET_VIDEO_GET_STREAMING_STATUS_RESPONSE";
export const VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS = "VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS";
export const VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS_RESPONSE = "VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS_RESPONSE";
export const VIDFLEET_VIDEO_GET_CLIP_BY_ID = "VIDFLEET_VIDEO_GET_CLIP_BY_ID";
export const VIDFLEET_VIDEO_GET_CLIP_BY_ID_RESPONSE = "VIDFLEET_VIDEO_GET_CLIP_BY_ID_RESPONSE";
export const VIDFLEET_VIDEO_GET_PICTURE_BY_ID = "VIDFLEET_VIDEO_GET_PICTURE_BY_ID";
export const VIDFLEET_VIDEO_GET_PICTURE_BY_ID_RESPONSE = "VIDFLEET_VIDEO_GET_PICTURE_BY_ID_RESPONSE";
export const VIDFLEET_VIDEO_GET_ONLINE_STATUS = "VIDFLEET_VIDEO_GET_ONLINE_STATUS";
export const VIDFLEET_VIDEO_GET_ONLINE_STATUS_RESPONSE = "VIDFLEET_VIDEO_GET_ONLINE_STATUS_RESPONSE";
export const VIDFLEET_VIDEO_LIVE_PHOTO_RESPONSE = "VIDFLEET_VIDEO_LIVE_PHOTO_RESPONSE";