//View Keys
export default {
	fleetView_vehicle: 'Unidad',
	fleetView_vehicles: 'Vehiculos',
	fleetView_search: 'Buscar',
	fleetView_vehicleStatus: 'Estado del vehiculo',
	fleetView_advancedFilters: 'Filtrar por',
	fleetView_clearFilters: 'Limpiar filtros',
	fleetView_clearAll: 'Limpiar todo',
	fleetView_vehicleGroups: 'Grupos',
	fleetView_select: 'Seleccionar',
	fleetView_starter: 'Estado del arranque',
	fleetView_drivers: 'Conductor',
	fleetView_groups: 'Groups',
	fleetView_tags: 'Etiquetas',
	fleetView_address: 'Dirección',
	fleetView_dataTime: 'Hora / Fecha',
	fleetView_event: 'Evento',
	fleetView_vin: 'VIN',
	fleetView_year: 'Año',
	fleetView_make: 'Marca',
	fleetView_model: 'Modelo',
	fleetView_engineOdometer:'Odometro',
	fleetView_engineHours:'Horas de Motor',
	fleetView_usn:'ESN',
	fleetView_imei:'IMEI',
	fleetView_events: 'Eventos',
	fleetView_times: 'Último Reporte',
	fleetView_icon:'Icono',
	fleetView_searchGroups: 'Buscar Grupos',
	fleetView_notSupport: 'No Disponible',
	fleetView_starterDisabled: 'Arranque Deshabilitado',
	fleetView_starterEnablePending: 'Pendiente De Iniciar Arranque',
	fleetView_starterDisablePending: 'Pendiente De Deshabilitar Arranque',
	fleetView_starter_enabled: 'Arranque Habilitado',
	fleetView_ScheduledStarter: 'Cronograma De Arranque',
	fleetView_ScheduledGroupStarter: 'Cronograma De Grupo De Arranque',
	vehicleStatusModal_titleModalEnableDisable: 'Habilitar/Deshabilitar Arranque',
	vehicleStatusModal_confirmationModalEnableDisable:
		'¿Estas seguro de cambiar el estado del arranqué?',
	vehicleStatusModal_currentStatus: 'Estado Actual',
	fleetView_disable: 'DESHABILITAR',
	fleetView_enable: 'HABILITAR',
	fleetView_outputs: 'Dispositivos De salida Unidad',
	fleetView_ping_toolTip: 'Enviar Ping',

	fleetView_starter_no_permission: 'No Tienes Permiso',
	fleetView_starter_no_supported: 'No Disponible',
	fleetView_create_landmark: 'Crear punto de referencia',
	All: 'Todos',
	None: 'Ninguno',
	fleetView_group: "Etiqueta",
	fleetView_unit: "Unidad",
	fleetView_vehicleTags: "Etiquetas",

	fleetView_locate: "Localizar",
	fleetView_enableDisableStarter: "Habilitar/deshabilitar arrancador",
	fleetView_outputs: "Salidas",

	fleetView_linkedDevice: "Dispositivo Enlazado",
	fleetView_unitOverViewPanel: "Descripción general del vehículo",

};
