import {
    MAPS_ETA_SHARE,
    MAPS_ETA_SHARE_RESPONSE
} from 'Redux/actionTypes';

export const mapsEtaLinkShare = (data) => ({
    type: MAPS_ETA_SHARE,
    payload: { data },
});

export const mapsEtaLinkShareResponse = (response, error) => ({
    type: MAPS_ETA_SHARE_RESPONSE,
    payload: { response, error },
});