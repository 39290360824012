
import { VehicleInformation } from './VehicleInformation'
import { clearStringValue } from 'Core/data/Helpers'

export class Unit {

  executeArray = (items, itemsExists) => {
    let units = [...itemsExists.units]
    for (let itemIdex in units) {
      let item = units[itemIdex];

      let index = items.findIndex(x => x.CUnits_id == item.id || x.id == item.id)
      if (index > -1) {
        item = this.getProcessItem(item, items[index]);
        units[itemIdex] = item;
      }
    }

    return [true, { units: [...units] }]
  }

  execute = (item, itemsExists) => {
    let units = itemsExists.units
    let index = units.findIndex(x => x.id == item.CUnits_id || x.id == item.id)
    if (index != -1)
      units[index] = this.getProcessItem(units[index], item)

    return [true, { units: [...units] }]
  }

  getProcessItem = (item, newItem) => {
    let objVehicle = new VehicleInformation()
    let tags = item?.tags || [];
    if (newItem?.tags && Array.isArray(newItem?.tags)) {
      tags = newItem?.tags;
    }

    let objItem = {
      ...item,
      label: newItem.Label || newItem.label || item.label,
      esn: newItem.USN || item.esn,
      imei: newItem.IMEI || item.imei,
      useEcuOdometer: newItem.useEcuOdometer != undefined
        ? Boolean(newItem.useEcuOdometer) : newItem.ecuodometer
          ? newItem.ecuodometer != undefined && Boolean(newItem.ecuodometer) : item.useEcuOdometer
            ? item.useEcuOdometer != undefined && Boolean(item.useEcuOdometer) : false,
      offsetEcuEngineOdometer: newItem.offsetEcuEngineOdometer && Number(newItem.offsetEcuEngineOdometer).toFixed(2) || item.offsetEcuEngineOdometer && Number(item.offsetEcuEngineOdometer).toFixed(2) || 0,
      offsetEngineOdometer: newItem.offsetEngineOdometer && Number(newItem.offsetEngineOdometer).toFixed(2) || item.offsetEngineOdometer && Number(item.offsetEngineOdometer).toFixed(2) || 0,
      useEcuEngineHours: newItem.useEcuEngineHours || item.useEcuEngineHours || false,
      offsetEcuEngineHours: newItem.offsetEcuEngineHours && Number(newItem.offsetEcuEngineHours).toFixed(2) || item.offsetEcuEngineHours && Number(item.offsetEcuEngineHours).toFixed(2) || 0,
      offsetEngineHours: newItem.offsetEngineHours && Number(newItem.offsetEngineHours).toFixed(2) || item.offsetEngineHours && Number(item.offsetEngineHours).toFixed(2) || 0,
      useEcuVin: newItem.useEcuVin || item.useEcuVin || false,
      jbus: Boolean(Number(newItem.jbus)) || Boolean(Number(item.jbus)),
      vbus: Boolean(Number(newItem.vbus)) || Boolean(Number(item.vbus)),
      driverId: (newItem?.DID || newItem?.driverId) != undefined ? (newItem?.DID || newItem?.driverId) : item.driverId,
      driverName: newItem.driverName != undefined || ((newItem?.DID || newItem?.driverId) != undefined && (newItem?.DID || newItem?.driverId) == 0) ? clearStringValue(newItem?.driverName) : item.driverName,
      vehiclesInformation: newItem?.vehiclesInformation ? objVehicle.getProcessItem(item.vehiclesInformation || {}, newItem?.vehiclesInformation) : item.vehiclesInformation,
      vehicleStatusId: newItem?.vehicleStatusId == 0 || newItem?.vehiclestatus_id == 0 ? 0 : newItem?.vehiclestatus_id || newItem?.vehicleStatusId || item.vehicleStatusId,
      hasVideo: newItem?.linkedUnitId > 0 || item?.linkedUnitId > 0 || false,
      tags: tags,
      starter: newItem.starter ? true : false,
      desiredStarterState: newItem?.desiredStarterState=="Enable" ? newItem?.desiredStarterState : "Disable",
      useEcuSpeed: newItem.useEcuSpeed || item.useEcuSpeed || false,
    }

    return objItem
  }

}
