import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@mui/material';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'
import {
  formatDatetime,
  formatTimeDelta,
  getNameEntity,
  getCategoryFilterType,
  mapAddress,
  getPlaceholderSelector,
  getNameUnitDriverForSubtables,
  getFilterNameByEntity,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

import { useSidePanelMap, useTableReport } from 'Core/hooks';

import './latitudeLongitude.scss'

import {
  reportsMainGenerateReportUpdate,
  reportsMainGenerateEvents,
  reportsMainGenerateSubEvents,
  openFleetLandmarkModal,
  getLandmarkById,
  clearRedux
} from 'Redux/actions'

// import { getCordinatesAndRadius } from './../../../helper';
import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark'
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";
const LatitudeLongitudeReport = (props) => {
  const { messages, report } = props;
  const { loading, data, filters, events, summary, loadingSummary, subEvents, loadingSubEvents, timeZone } = report;
  const { filterEntity, filterArray, otherFilters, filterType, startRange, endRange } = filters;

  const [unitDriverSelected, setUnitDriverSelected] = useState();
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
  const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");


  const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
  const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

  const reportRef = useRef();



  const dispatch = useDispatch()
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const exportHandler = useHandleExportReport(report);





  useEffect(() =>{
    if(report){
      const newReport = JSON.parse(JSON.stringify(report));
      // to keep the reference to the units selected
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if (!loadingCreateLandMark && createLandMarkResult) {
      dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
      dispatch(clearRedux("LANDMARK", { "createLandMarkResult": null }));
    }

  }, [loadingCreateLandMark, createLandMarkResult])

  const onChangeExpandable = (unit) => {
    if(!unit.noData){
      let events = {
        data: [],
        filters: {
          filterType: getCategoryFilterType(filterEntity),
          filterArray: unit,
          page: 1,
          rowsPerPage: 10,
          sortField: 'eventDate',
          otherFilters
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }
  }

  let parametersSummary = useTableReport({data, report, messages, hideHeader: false});



  const renderExpandableRow = (row) => {
    let parametersSummaryTable = {
      ...parametersSummary,
      data: summary?.items || [],
      totalItems: summary?.total || 0,
      hideHeader: false,
      serverSideTable: true,
      onChangedPage: (page, rowsPerPage, sortField, sortOrder) => {
        let events = {
          data: [],
          filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
        }
        dispatch(reportsMainGenerateEvents(report, events))
      },
      onChangeSelector: null,
      itemsSelector: null,
      generateExport: false,
      hideSelectorUnitsDriver: true,
      groups:report.filters.units || [],
      hideNumberOfRows:true,
      centerPagination: true,
      noData: row.noData
    };
    return (
      <Table
        {...parametersSummaryTable}
        loading={loadingSummary}
      >
        <Column label={messages['generatedReport_timeAndDate']} field="date" noSorting>
          {(element) => {
            return (
              <div>
                <span className="speed-report-stacked">{element.unitTime && formatDatetime(element.unitTime, "h:mm A", timeZone)}</span>
                <span className="speed-reportstacked speed-report-event-date">{element.unitTime && formatDatetime(element.unitTime, "MMM D", timeZone)}</span>
              </div>
            )
          }}
        </Column>
        <Column label={messages['generatedReport_address']} field="totalIdleTime" noSorting>
          {(element) => {
            return (
              <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={element}/>
            );
          }}
        </Column>
        <Column label={messages['reportsMainRT_latitude']} field="latitude" noSorting>
          {(element) => {
            return (
              element.latitude
            );
          }}
        </Column>
        <Column label={messages['reportsMainRT_longitude']} field="latitude" noSorting>
          {(element) => {
            return (
              element.longitude
            );
          }}
        </Column>
        <Column label={messages['reportsMainRT_event']} field="latitude" noSorting>
          {(element) => {
            return (
                <EventReports element={{event: element.eventName || ''}}/>
            );
          }}
        </Column>
      </Table>
    )
  }

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: 'id'
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column label="Day" field="label" noSorting>
          {(element) => {
            return (
              <div>
                <span>
                  <span><strong>{getNameEntity(filterEntity, messages)}:</strong> {element.idLabel} </span>
                </span>
              </div>
            );
          }}
        </Column>
        <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
          {(element) => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (
              <div>
                <span>
                  <span>
                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                  </span>
                </span>
              </div>
            );
          }}
        </Column>
      </Table>
      <SideMapComponent hiddenButton hiddenSearchMap />
    </Fragment>
  );
}

export default LatitudeLongitudeReport
