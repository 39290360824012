import {
    SETTINGS_CAMERA_MANAGE_SEARCH,
    SETTINGS_CAMERA_MANAGE_SEARCH_RESPONSE
} from "Redux/actionTypes";

export const searchSettingsCameraManage = (payload) => {
    return {
        type: SETTINGS_CAMERA_MANAGE_SEARCH,
        payload: payload
    };
};

export const searchSettingsCameraManageResponse = (data) => {
    return {
        type: SETTINGS_CAMERA_MANAGE_SEARCH_RESPONSE,
        payload: data
    };
};
