import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  SHARED_MEDIA_SHARE_MEDIA,
  SHARED_MEDIA_GET_SHARED_MEDIA
} from 'Redux/actionTypes';

import {
  sharedMediaResponse,
  getSharedMediaResponse
} from 'Redux/actions';

function* sharedMediaRequest({ payload }) {
  try {
    const { data: body } = payload;
    const data = yield call(
      clientMutation,
      `
      mutation createSharedMedia (
        $sharedEntities: [SharedEntityInput]!,
        $sharedTo: [SharedToInput]!
      ) {
        SharedMedia: createSharedMedia(sharedEntities: $sharedEntities, sharedTo: $sharedTo) {
          id
          sharedTo {
            via
            to
          }
        }
      } `,
      body,
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.SharedMedia) {
      yield put(sharedMediaResponse(data.SharedMedia));
    } else {
      throw 'Error while creating shared media';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/.common/components/SharedMedia/redux/saga', 'sharedMediaRequest');
    yield put(sharedMediaResponse({ error: true }));
  }
}

function* getSharedMediaRequest({ payload }) {
  try {
    const { data: body } = payload;
    const { uuid, type } = body;

    let media = `
      ... on ClipRequest {
        id
        clipID
        deviceModelName
      }
    `;

    if (type === "DeviceReading") {
      media = `
      ... on DeviceReading  {
        id
        deviceData
      }
    `;
    }

    let data = yield call(
      clientQuery,
      `query ($id: String!) {
        SharedMedia: getSharedMedia(id: $id) {
          id
          entity {
            id 
            name
          }
          mediaDetail {
            ${media}
          }
          deviceModelName
        }
      }`,
      {
        id: uuid
      },
      endpoints.GRAPHQL_GENERAL,
      {
        'x-api-key': endpoints.PUBLIC_API_KEY
      }
    );

    if (data && data.SharedMedia) {
      if (type === "DeviceReading" && !data?.SharedMedia?.mediaDetail?.deviceData) {
        throw 'Not shared media data';
      }
      yield put(getSharedMediaResponse(data.SharedMedia));
    } else {
      throw 'Not shared media data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/.common/components/SharedMedia/redux/saga', 'getSharedMediaRequest');
    yield put(getSharedMediaResponse({ error: true }));
  }
}

function* watchSharedMediaRequest() {
  yield takeEvery(SHARED_MEDIA_SHARE_MEDIA, sharedMediaRequest);
}

function* watchGetSharedMediaRequest() {
  yield takeEvery(SHARED_MEDIA_GET_SHARED_MEDIA, getSharedMediaRequest);
}

export default function* rootSaga() {
  yield all([
    fork(watchSharedMediaRequest),
    fork(watchGetSharedMediaRequest)
  ]);
}