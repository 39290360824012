import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// controls
import Table, { Column } from '../../table'
//helpers
import {
    formatDatetime,
    getCategoryFilterType, getMethodToFormatDuration,
    getNameEntity,
    getNameVehicle,
    useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import { useSidePanelMap, useTableReport } from 'Core/hooks';
//actions
import { clearRedux, reportsMainGenerateEvents, reportsMainGenerateReportUpdate } from 'Redux/actions'
import moment from 'moment';

// styles
import './driverTimesheetReport.scss';
import { Accordion } from "Components";

const DriverTimesheetReport = (props) => {
    const { messages, report, user } = props;
    const { durationFormat } = user;
    const { data, filters, summary, loadingSummary, timeZone } = report;
    const { filterEntity, otherFilters, startRange, endRange } = filters;

    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
    const [unitDriverSelected, setUnitDriverSelected] = useState();
    const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
    const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
    const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");

    const exportHandler = useHandleExportReport(report)

    const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
    const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

    const reportRef = useRef();
    const dispatch = useDispatch()
    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    useEffect(() => {
        if (!loadingCreateLandMark && createLandMarkResult) {
            dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
            dispatch(clearRedux("LANDMARK", { "createLandMarkResult": null }));
        }

    }, [loadingCreateLandMark, createLandMarkResult])

    const durationFormatTime = getMethodToFormatDuration(durationFormat);
    const onChangeExpandable = (unit) => {
        if(!unit.noData){
            let events = {
                data: [],
                filters: {
                    filterType: getCategoryFilterType(filterEntity),
                    filterArray: unit,
                    page: 1,
                    rowsPerPage: 10,
                    sortField: 'eventDate',
                    otherFilters
                }
            }
            dispatch(reportsMainGenerateEvents(report, events))
        }
    }

    function summaryDetails(data) {
        const mapCount = data.reduce((acc, item) => {
            let group = filterEntity === 'Driver' ? item.idLabel : item.driverLabel
            const duration = item.duration;
            acc.set(group, (acc.get(group) || 0) + duration)
            return acc
        }, new Map()
        )
        return [...mapCount.entries()];
    }

    const summaryTable = (item, type) => {
        const summaryList = summaryDetails(item);
        return (
            <Fragment>
                <Table
                    data={summaryList || []}
                    noDataMessage={messages["reportsMainRT_noTripsAvailable"]}
                    propertyToShowExpandable="day"
                    hiddenPagination
                    classNameColumns="start-stop-idle-sub-table"
                >
                    <Column label={type}>
                        {(element) => {
                            return element[0] ? element[0] : messages["generatedReport_unknownDriver"]
                        }}
                    </Column>
                    <Column label={messages['generatedReport_totalTime']}>
                        {(element) => {
                            return element[1] ? durationFormatTime(element[1], messages) : '0 min, 0 sec'
                        }}
                    </Column>
                </Table>
            </Fragment>
        )
    }

    const renderExpandableRow = (row) => {
        const type = getNameVehicle(filterEntity === 'Driver' ? 'Unit' : 'Driver', messages)
        return (
            <div>
                {
                    !row.noData && (
                        <Accordion
                            customTitle={() => { return (<b>{`${messages['generatedReport_totalsFor']}: ${row.idLabel}`}</b>) }}
                            className="AccordionContainer-root start-stop-idle-sub-tablen"
                            defaultExpanded
                        >
                            <div style={{ width: '100%' }}>
                                {summaryTable(summary?.items || [], type)}
                            </div>
                        </Accordion>
                    )
                }
                <Table
                    loading={loadingSummary}
                    data={summary?.items || []}
                    totalItems={summary?.total || 0}
                    onChangedPage={(page, rowsPerPage, sortField, sortOrder) => {
                        let events = {
                            data: [],
                            filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
                        }
                        dispatch(reportsMainGenerateEvents(report, events))
                    }}
                    serverSideTable={true}
                    hideNumberOfRows={true}
                    hiddenPagination={false}
                    classNameColumns={'report-column-events-expandible'}
                    noDataMessage={messages["generatedReport_noEvents"]}
                    numberofRows={10}
                    centerPagination
                    noData={row.noData}
                >
                    <Column label={getNameVehicle(filterEntity === 'Driver' ? 'Unit' : 'Driver', messages)} field="driverLabel" noSorting>
                        {(element) => {
                            return filterEntity === 'Driver' ? (
                                <div>
                                    <span>{element.idLabel} </span>
                                </div>
                            ) : (
                                <div>
                                    <span>{element.driverLabel ? element.driverLabel : messages["generatedReport_unknownDriver"]} </span>
                                </div>
                            )
                        }}
                    </Column>
                    {
                        filterEntity === 'Unit' ?
                        <Column label={messages["generatedReport_status"]} field="status" noSorting>
                            {(element) => {
                                return (
                                    <div>
                                        <span className="speed-report-stacked">{element.status}</span>
                                    </div>
                                )
                            }}
                        </Column>
                        :
                        <></>
                    }
                    <Column label={messages["generatedReport_in"]} field="dateIn" noSorting>
                        {(element) => {
                            return (
                                <div>
                                    <span className="speed-report-stacked">{element.dateIn && formatDatetime(element.dateIn, "h:mm A", timeZone)}</span>
                                    <span
                                        className="speed-reportstacked speed-report-event-date">{element.dateIn && formatDatetime(element.dateIn, "MMM D", timeZone)}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages["generatedReport_out"]} field="dateOut" noSorting>
                        {(element) => {
                            return (
                                <div>
                                    <span className="speed-report-stacked">{element.dateOut && formatDatetime(element.dateOut, "h:mm A", timeZone)}</span>
                                    <span
                                        className="speed-reportstacked speed-report-event-date">{element.dateOut && formatDatetime(element.dateOut, "MMM D", timeZone)}</span>
                                </div>
                            )
                        }}
                    </Column>
                    <Column label={messages['generatedReport_timeInLandmark']} field="duration" noSorting>
                        {(element) => {
                            return (
                                <div>
                                    <span>
                                        {messages['generatedReport_totalTime']}: {element.duration ? durationFormatTime(element.duration, messages) : '0 min, 0 sec'}
                                    </span>
                                </div>
                            )
                        }}
                    </Column>
                </Table>
            </div>
        )
    }

    let parametersTable = useTableReport({
        data: data.items,
        report,
        messages,
        hideHeader: true,
        renderExpandableRow,
        onChangeExpandable,
        propertyToShowExpandable: 'id',
        classNameColumns: 'start-stop-idle-main-table'
    });

    return (
        <Fragment>
            <Table
                {...parametersTable}
                {...exportHandler}
            >
                <Column label="Day" field="label" noSorting>
                    {(element) => {
                        return (
                            <div>
                                <span><strong>{getNameVehicle(filterEntity, messages)}:</strong> {element.idLabel} </span>
                            </div>
                        );
                    }}
                </Column>
                <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
                    {(element) => {
                        const localTimeStart = moment.utc(startRange).local().format();
                        const localTimeEnd = moment.utc(endRange).local().format();
                        return (
                            <div>
                                <span>
                                    <span>
                                        <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                                    </span>
                                </span>
                            </div>
                        );
                    }}
                </Column>
            </Table>
            <SideMapComponent hiddenButton hiddenSearchMap />
        </Fragment>
    );
}

export default DriverTimesheetReport;
