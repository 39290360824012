// View Keys
export default {
    // shared
    reports_searchSourcePlaceholder: 'Start Typing...',
    // main page
    reportsView_comingSoon: 'Coming Soon',
    reportsView_filterBy: 'Filter by',
    reportsView_reports: 'Reports',
    reportsView_scheduled: 'Scheduled',
    reportsView_schedule: 'Schedule',
    // no generated report
    noGeneratedReport_clickModal: '(Click to see "Reports Too Large" modal. This is temporary)',
    noGeneratedReport_defaultMessage: "No previously generated reports. Click the add '+' icon to generate a new report",
    noGeneratedReport_defaultNewMessage: "No previously generated reports. Please use Report Setup to generate a new report.",
    noGeneratedReport_reportsTooLarge: 'Reports Too Large Modal',
    thereAreNoPinned: 'There are no pinned reports yet.',
    // generated reports
    generatedReport_daysCurrentAddress: '# of days at current address',
    generatedReport_address: 'Address',
    generatedReport_eventDistance: 'Event Distance',
    generatedReport_activity: 'Activity',
    generatedReport_currentAddress: 'Current Address',
    generatedReport_previousAddress: 'Previous Address',
    generatedReport_antennaDisconnect: 'Antenna Disconnect',
    generatedReport_antennaConnect: 'Antenna Connect',
    generatedReport_inputOn: 'Input ON',
    generatedReport_inputOff: 'Input Off',
    generatedReport_advanceSummary: 'Advance Summary',
    generatedReport_avgSpeed: 'Avg Speed',
    generatedReport_alertLog: 'Alert Log',
    generatedReport_alertType: 'Alert Type',
    generatedReport_alertDetail: 'Alert Detail',
    generatedReport_contacts: 'Contacts',
    generatedReport_cost: 'Cost',
    generatedReport_date: 'Date',
    generatedReport_edit: 'Edit',
    generatedReport_download: 'Download',
    generatedReport_copy: 'Copy to Setup',
    generatedReport_category: 'Category',
    generatedReport_view: 'View',
    generatedReport_timeAndDate: 'Time & Date',
    generatedReport_timezone: 'Timezone',
    generatedReport_detailedReportLabel: 'Detailed',
    generatedReport_dir: 'Dir',
    generatedReport_direction: 'Direction',
    generatedReport_distance: 'Distance',
    generatedReport_AverageSpeed: 'Average Speed',
    generatedReport_driveTime: 'Drive Time',
    generatedReport_driver: 'Driver',
    generatedReport_drivers: 'Drivers',
    generatedReport_drive: 'Drive',
    generatedReport_allEvents: 'All Events',
    generatedReport_vehicle: 'Vehicle',
    generatedReport_driverSafetyLabel: 'Driver Safety',
    generatedReport_driverStatus: 'Driver Status',
    generatedReport_endOdometer: 'End Odometer',
    generatedReport_engineOn: 'Engine On',
    generatedReport_engineOff: 'Engine Off',
    generatedReport_enterLandmark: 'Enter',
    generatedReport_leaveLandmark: 'Leave',
    generatedReport_event: 'Event',
    generatedReport_eventandDuration: 'Event & Duration',
    generatedReport_from: 'From',
    generatedReport_formName: 'Form Name',
    generatedReport_to: 'To',
    generatedReport_toAndFrom: 'To/From',
    generatedReport_events: 'Events',
    generatedReport_exit: 'Exit',
    generatedReport_gps: 'GPS',
    generatedReport_GPSCalculated: 'gps calculated',
    generatedReport_group: 'Group ',
    generatedReport_location: 'Location',
    generatedReport_longitude: 'Longitude',
    generatedReport_latitude: 'Latitude',
    generatedReport_odometerGroup: 'Odometer Report for Tag',
    generatedReport_home: 'Home',
    generatedReport_idleLabel: 'Idle',
    generatedReport_ignitionOn: 'Ignition On',
    generatedReport_ignitionOff: 'Ignition Off',
    generatedReport_firstIgnitionOff: 'First Ignition On Time',
    generatedReport_lastIgnitionOff: 'Last Ignition Off Time',
    generatedReport_lastEvent: 'Last Event',
    generatedReport_lastEventTime: 'Last Event Time',
    generatedReport_ignitionlabel: 'Ignition',
    generatedReport_in: 'In',
    generatedReport_input1: 'Input 1 On Time',
    generatedReport_input2: 'Input 2 On Time',
    generatedReport_input3: 'Input 3 On Time',
    generatedReport_input4: 'Input 4 On Time',
    generatedReport_input5: 'Input 5 On Time',
    generatedReport_input6: 'Input 6 On Time',
    generatedReport_input7: 'Input 7 On Time',
    generatedEngineOnUtilization: 'Engine On Utilization',
    generatedEngineOffUtilization: 'Engine Off Utilization',
    generatedReport_internalBatteryDisconnect: 'Internal Battery Disconnect',
    generatedReport_internalBatteryConnect: 'Internal Battery Connect',
    generatedReport_landmark: 'Create Landmark',
    generatedReport_landmarkLabel: 'Landmark',
    generatedReport_duration: 'Duration',
    generatedReport_runTime: 'Run Time',
    generatedReport_maxSpeed: 'Max Speed',
    generatedReport_mainPowerOff: 'Main Power Off',
    generatedReport_mainPowerOn: 'Main Power On',
    generatedReport_message: 'Message',
    generatedReport_maintenance: 'Maintenance',
    generatedReport_milesLower: 'miles',
    generatedReport_miles: 'Miles',
    generatedReport_miAcronym: 'mi',
    generatedReport_kmLower: 'kilometers',
    generatedReport_km: 'Kilometers',
    generatedReport_kmAcronym: 'km',
    generatedReport_mileagelabel: 'Mileage',
    generatedReport_mileageSummaryLabel: 'Mileage Summary',
    generatedReport_notes: 'Notes',
    generatedReport_newReport: 'New Report',
    generatedReport_noAddress: '(No street address given)',
    generatedReport_noEvents: 'No events',
    generatedReport_noLandmarksTotals: 'No Landmarks Totals',
    generatedReport_noPreviouslyGeneratedReports: "No previously generated reports. Click the add '+' icon to generate a new report.",
    generatedReport_notAvailable: 'Not available',
    generatedReport_unassigned: 'Unassigned',
    generatedReport_notAssigned: 'Not assigned',
    generatedReport_unknownDriver: 'Unknown Driver',
    generatedReport_unknownDevice: 'Unknown Device',
    generatedReport_out: 'Out',
    generatedReport_odom: 'Odom',
    generatedReport_odometer: 'Odometer',
    generatedReport_range: 'Range',
    generatedReport_infoReports: 'Report Types and Summaries',
    generatedReport_selectAReportOption: 'Please select a report option',
    generatedReport_selectAContactOption: 'Please select one or more contacts',
    generatedReport_speed: 'Speed',
    generatedReport_postedSpeed: 'Posted Speed',
    generatedReport_state: 'State',
    generatedReport_grandTotals: 'Grand Totals',
    generatedReport_grandTotal: 'Grand Total',
    generatedReport_startOdometer: 'Start Odometer',
    generatedReport_startStopIdleLabel: 'Start, Stop, Idle',
    generatedReport_stop: 'Stop',
    generatedReport_stopTime: 'Stop Time',
    generatedReport_simple: 'Simple',
    generatedReport_summary: 'Summary',
    generatedReport_totalsFor: 'Totals for',
    generatedReport_updated: 'Updated',
    generatedReport_answer: 'Answer',
    generatedReport_question: 'Question',
    generatedReport_time: 'Time/Date',
    generatedReport_timeInLandmark: 'Time',
    generatedReport_total: 'Total',
    generatedReport_totals: 'Totals',
    generatedReport_totalsTitle: 'TOTALS',
    generatedReport_totalDistance: 'Total Distance',
    generatedReport_totalTime: 'Total Time',
    generatedReport_combinatedTotalTime: 'Combined Total Time: ',
    generatedReport_totalHours: 'Total (hours)',
    generatedReport_hourMeter: 'Hour Meter',
    generatedReport_totalEngineOn: 'Total Engine On',
    generatedReport_totalEngineOff: 'Total Engine Off',
    generatedReport_totalIgnitionOff: 'Total Ignition Off',
    generatedReport_landmarkTotals: 'Landmark Totals',
    generatedReport_landmarkDetails: 'Landmark Details',
    generatedReport_totalIdles: 'Number of Idles',
    generatedReport_totalIdleTime: 'Total Idle Time',
    generatedReport_totalProductiveIdleTime: 'Total Productive Idle Time',
    generatedReport_totalProductiveIdle: 'Total Productive Idle',
    generatedReport_totalProductiveIdling: 'Total Productive Idling',
    generatedReport_totalVisits: 'Total Visits',
    generatedReport_totalVisitDuration: 'Total Visit Duration',
    generatedReport_engineHours: 'Engine Hours',
    generatedReport_engineRunning: 'Engine Running Time (hrs)',
    generatedReport_totalStops: 'Number of Stops',
    generatedReport_travelTime: 'Travel Time',
    generatedReport_travelStart: 'Travel Start',
    generatedReport_started: 'Started',
    generatedReport_travelStop: 'Travel Stop',
    generatedReport_totalTravelTime: 'Total Travel Time',
    generatedReport_type: 'Type:',
    generatedReport_unit: 'Unit',
    generatedReport_units: 'Units',
    generatedReport_unitLabel: 'Unit Label',
    generatedReport_utilization: 'Utilization',
    generatedReport_wip: '(Placeholder)',
    generatedReport_totalMileage: 'Total Mileage',
    generatedReport_stateCrossings: 'State Crossings',
    generatedReport_noExport: 'It is not possible to export',
    generatedReport_tripSummary: 'Trip Summary',
    generatedReport_tripDetails: 'Trip Details',
    generatedReport_unavailable: 'unavailable',
    generatedReport_stateTotals: 'State Totals',
    generatedReport_stopDrive: 'Stop Drive',
    generatedReport_stops: 'Stops',
    generatedReport_averageStopTime: 'Average Stop Time',
    generatedReport_idles: 'Idles',
    generatedReport_averageIdleTime: 'Average Idle Time',
    generatedReport_stateCrossingDetails: 'State Crossing Details',
    generatedReport_unitsPerPage: 'units',
    generatedReport_driversPerPage: 'drivers',
    generatedReport_perPage: 'per page',
    generatedReport_distanceInside: 'Distance Inside',
    generatedReport_timeInside: 'Time Inside',
    generatedReport_entered: 'Entered',
    generatedReport_exited: 'Exited',
    generatedReport_betweenLandMark: 'Between Landmarks',
    generatedReport_reportDetails: 'Report Details',
    generatedReport_selectUnitsDrivers: 'Select Units or Drivers',
    generatedReport_totalIgnitionOn: 'Total Ignition On',
    generatedReport_totalMiles: 'Total Miles',
    generatedReport_noTripDetails: 'No trip details',
    generatedReport_avgStopTime: 'Avg Stop Time',
    generatedReport_avgIdleTime: 'Avg Idle Time',
    generatedReport_totalIdle: 'Total Idle',
    generatedReport_unit_group: 'Unit Tags',
    generatedReport_driver_group: 'Driver Tags',
    generatedReport_vehicle_group: 'Vehicle Tags',
    generatedReport_stopped: 'Stopped',
    generatedReport_moving: 'Moving',
    generatedReport_daysWorkedTotalPercentPart1: 'Days',
    generatedReport_daysWorkedTotalPercentPart2: '(worked, total, percent)',
    generatedReport_serial: 'Unit Serial',
    generatedReport_serialNumber: 'Unit Serial Number',
    generatedReport_serviceItem: 'Service Item:',
    generatedReport_serviceNotes: 'Service Notes',
    generatedReport_vin: 'Vin',
    generatedReport_equipment: 'Equipment',
    generatedReport_garmin: 'Garmin Number',
    generatedReport_garminSerial: 'Garmin Serial Number',
    generatedReport_on: 'On',
    generatedReport_off: 'Off',
    // report setup modal
    reportSetup_cancel: 'Cancel',
    reportSetup_clearAll: 'Clear All',
    reportSetup_allUnit: 'All',
    reportSetup_allDriver: 'All',
    reportSetup_finallyPlural: 's',
    reportSetup_exclude: 'Exclude',
    reportSetup_excludeTablesWithoutData: 'Exclude Tables Without Data',
    reportSetup_noDataFound: 'No data found.',
    reportSetup_getReport: 'Get Report',
    reportSetup_reportType: 'Report Type',
    reportSetup_selected: 'SELECTED',
    reportSetup_productiveIdleCheckbox: 'Show Productive Idling',
    reportSetup_excludeIdleCheckbox: 'Exclude Idle',
    reportSetup_timeframe: 'Timeframe',
    reportSetup_title: 'Report Setup',
    // report too large
    reportTooLarge_message: 'The report you are trying to generate is too large to load in the browser and will need to be emailed.',
    reportTooLarge_send: 'Send',
    reportTooLarge_sendMessage: 'Send Report To',
    reportTooLarge_title: 'Report Too Large',
    reportsReady_title: 'Reports Ready',
    reportsReady_ready: 'Ready',
    reportsPinReports: "Pin",
    reportsPinnedReports: "Pinned",
    reportsPinnedReports_title: "Pinned Reports",
    reportsRecentlyGenerated_title: "Recently Generated",
    reportsRecentlyGenerated: "Recent",
    generatedReport_ins: 'Ins',
    generatedReport_timeInGeofence: 'Time in Geofence',
    generatedReport_timeInLandmarkAll: 'Time in Landmark',
    generatedReport_geofence: 'Geofence',
    generatedReport_geofenceTotal: 'Geofence Totals',
    generatedReport_stopSummaryTotal: 'Total Stop Duration',
    generatedReport_totalCost: 'Total Cost',
    generatedReport_distanceInGeofence: 'Distance in Geofence',
    generatedReport_distanceInLandmark: 'Distance in Landmark',
    generatedReport_tag: 'Tag',
    generatedReport_LastReportedDateTime: 'Last Reported Date/Time',
    generatedReport_LastReportedAddress: 'Last Reported Address',
    generatedReport_crewMember: 'Crew Member',
    generatedReport_crewMemberDuration: 'Crew Member Total Time In Landmark',
    generatedReport_vehicleTotalTimeInLandmark: 'Vehicle Total Time In Landmark',
    generatedReport_acceleratorPedalPosition: 'Accel Pedal Position',
    generatedReport_batteryVoltage: 'Battery Voltage',
    generatedReport_brakePedalPosition: 'Brake Pedal Position',
    generatedReport_engineCoolantLevel: 'Eng Coolant Level',
    generatedReport_engineCoolantTemp: 'Eng Coolant Temp',
    generatedReport_engineFuelRate: 'Eng Fuel Rate',
    generatedReport_engineOilLevel: 'Eng Oil Level',
    generatedReport_engineOilPressure: 'Eng Oil Pressure',
    generatedReport_engineOilTemp: 'Eng Oil Temp',
    generatedReport_fuelLevelOne: 'Fuel Level 1',
    generatedReport_fuelLevelTwo: 'Fuel Level 2',
    generatedReport_rpm: 'RPM',
    generatedReport_seatbeltswitch: 'Seatbelt switch',
    generatedReport_engineCoolantPressure: 'Eng Coolant Pressure',
    generatedReport_spn: 'DTC SPN',
    generatedReport_pid: 'DTC PID',
    generatedReport_fmi: 'DTC FMI',
    generatedReport_occurrence: 'DTC Occurrence',
    generatedReport_dtcStatus: 'DTC Status',
    generatedReport_title: 'Title',
    generatedReport_status: 'Status',
    generatedReport_tripDuration: 'Trip Duration',
    generatedReport_signal: 'Signal',
    generatedReport_idleTime: 'Idle Time',
    generatedReport_TIMEFRAME: 'TIMEFRAME',
    generatedReport_jump_to: 'JUMP TO',
    generatedReportFtpError: 'Please select one FTP Server',
    generatedReport_unitSummary: 'UNIT SUMMARY',
    generatedReport_driverSummary: 'DRIVER SUMMARY',
    generatedReport_total_trip: 'Total Trip Duration',
    generatedReport_reportRec: 'Reports Rec´d',
    generatedReport_firstOn: 'First On',
    generatedReport_lastOff: 'Last Off',
    generatedReport_machineHours: 'Machine\n Hours',
    generatedReport_workHours: 'Work\n Hours',
    generatedReport_saturdayHours: 'Saturday\n Hours',
    generatedReport_sundayHours: 'Sunday\n Hours',
    generatedReport_billingHours: 'Billing\n Hours',
    generatedReport_overtimeHours: 'Overtime\n Hours',
    generatedReport_hour: 'hours',
    generatedReport_driverApp: 'Driver App'
};
