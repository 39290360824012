// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    SETTINGS_CAMERA_MANAGE_EDIT_OPEN,
    SETTINGS_CAMERA_MANAGE_EDIT_SAVE
} from "Redux/actionTypes";

// Actions
import {
    searchSettingsCameraManageEditOpenResponse,
    searchSettingsCameraManageEditSaveResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
import { result } from "lodash";

function* searchCameraManageOpenRequest() {
    yield takeEvery(SETTINGS_CAMERA_MANAGE_EDIT_OPEN, function* ({ payload }) {
        try {

            const { id } = payload
            let data = yield call(
                clientQuery,
                `
                query getCameraManage ($id: Int) {
                    cameraManage: getCameraManage (id: $id) {
                        id
                        unitId
                        unit {
                            id
                            label
                        }
                    }
                }
                `,
                {
                    id
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(searchSettingsCameraManageEditOpenResponse(data.cameraManage || []))

        } catch (exc) {
            ExceptionManager(exc, 'modules/settings/views/cameraManage/components/edit/redux/saga', 'searchCameraManageOpenRequest');
            yield put(searchSettingsCameraManageEditOpenResponse({ total: 0, items: [] }));
        }
    });
}


function* searchCameraManageSaveRequest() {
    yield takeEvery(SETTINGS_CAMERA_MANAGE_EDIT_SAVE, function* ({ payload }) {
        try {

            const { id, unitId } = payload
            let data = yield call(
                clientMutation,
                `
                    mutation updateCameraManage ($id: Int!, $unitId: Int) {
                        result: updateCameraManage (id: $id, unitId: $unitId) {
                            id
                        }
                    }
                `,
                {
                    id,
                    unitId
                },
                endpoints.GRAPHQL_GENERAL
            );

            if (data.result.id && data.result.id > 0) {
                yield put(searchSettingsCameraManageEditSaveResponse({ result: true }))
            }
            else {
                throw Error("errorMessage")
            }

        } catch (exc) {
            ExceptionManager(exc, 'modules/settings/views/cameraManage/components/edit/redux/saga', 'searchCameraManageSaveRequest');
            yield put(searchSettingsCameraManageEditSaveResponse({ result: false, error: exc }));
        }
    });
}





export default function* rootSaga() {
    yield all([
        fork(searchCameraManageOpenRequest),
        fork(searchCameraManageSaveRequest)
    ]);
}