export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const HIDE_TOGGLE_SIDEBAR = 'HIDE_TOGGLE_SIDEBAR';
export const CLEAR_REDUX = 'CLEAR_REDUX';
export const DOWNLOAD_ATTACHMENT_URL = 'DOWNLOAD_ATTACHMENT_URL';
export const DOWNLOAD_ATTACHMENT_URL_RESULT = 'DOWNLOAD_ATTACHMENT_URL_RESULT';
export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT';
export const UPLOAD_ATTACHMENT_RESULT = 'UPLOAD_ATTACHMENT_RESULT';
export const UPLOAD_ATTACHMENT_LOCAL = 'UPLOAD_ATTACHMENT_LOCAL';
export const UPLOAD_ATTACHMENT_LOCAL_RESULT = 'UPLOAD_ATTACHMENT_LOCAL_RESULT';
export const SET_DEVELOPER = 'SET_DEVELOPER';
export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN';
export const CHANGE_SELECT_LANGUAGE = 'CHANGE_SELECT_LANGUAGE';
export const GET_USER_LANGUAGE = 'GET_USER_LANGUAGE';
export const SET_CALENDAR_SCROLL_PARENT = 'SET_CALENDAR_SCROLL_PARENT';

export const GENERIC_EXPORT_SEND = 'GENERIC_EXPORT_SEND';
export const GENERIC_EXPORT_SEND_RESULT = 'GENERIC_EXPORT_SEND_RESULT'

