import {
    SETTINGS_CAMERA_MANAGE_EDIT_OPEN,
    SETTINGS_CAMERA_MANAGE_EDIT_OPEN_RESULT,
    SETTINGS_CAMERA_MANAGE_EDIT_CLOSE,
    SETTINGS_CAMERA_MANAGE_EDIT_SAVE,
    SETTINGS_CAMERA_MANAGE_EDIT_RESULT,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    loading: false,
    open: false,
    data: null,
    response: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case SETTINGS_CAMERA_MANAGE_EDIT_OPEN:
            return { state, loading: true, open: true };

        case SETTINGS_CAMERA_MANAGE_EDIT_OPEN_RESULT:
            return { ...state, loading: false, data: action.payload }

        case SETTINGS_CAMERA_MANAGE_EDIT_SAVE:
            return { ...state, loading: true, }

        case SETTINGS_CAMERA_MANAGE_EDIT_RESULT:
            return { ...state, loading: false, response: action.payload }

        case SETTINGS_CAMERA_MANAGE_EDIT_CLOSE:
            return { ...state, open: false };

        case CLEAR_REDUX:
            return ["", "SETTINGS_CAMERA_MANAGE_EDIT"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : INITIAL_STATE
                : { ...state };

        default:
            return state;
    }
};