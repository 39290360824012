// Action Types
import { 
  ACCOUNT_SETTINGS_SIDERBAR,
  ACCOUNT_SETTINGS_SAVE,
  ACCOUNT_SETTINGS_SAVE_RESPONSE,
  CLEAR_REDUX
} from "Redux/actionTypes";

/**
 * Initial State Contacts
 */
const INITIAL_STATE = {
  loading: false,
  showSliderAccountSettings: false,
  error: false,
  updatedAccount: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACCOUNT_SETTINGS_SAVE:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case ACCOUNT_SETTINGS_SAVE_RESPONSE: 
      return {
        ...state,
        showSliderAccountSettings: false,
        updatedAccount: action.payload?.updatedAccount,
        error: action.payload?.error,
        loading: false,
      };  
    case ACCOUNT_SETTINGS_SIDERBAR:
      return {
        ...state,
        error: false,
        showSliderAccountSettings: action.payload,
        loading: false,
        updatedAccount: false
      };
    case CLEAR_REDUX:
      return ["", "ACCOUNT_SETTINGS"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INITIAL_STATE }
        : { ...state };  
    default:
      return state;
  }
};
