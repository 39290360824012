import {
    //TYPES
    MAP_UTILITIES_VISIBLE_TAGS_SHOW_MODAL,
    MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP_CONFIRM,
    MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP
} from "Redux/actionTypes";
  
const INIT_STATE = {
    loadModule: false ,
    showModalVehicleGroups: false,
    loadUpdateUnitConfirm: false,
    unitGroupColors: []
};
  
export default (state = INIT_STATE, action) => {
    switch (action.type) {        
        case MAP_UTILITIES_VISIBLE_TAGS_SHOW_MODAL:
            return { ...state, showModalVehicleGroups: action.payload.display}

        case MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP:
          return { ...state, loadUpdateUnitConfirm: action.payload}
        
        case MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP_CONFIRM:
            return { ...state, loadUpdateUnitConfirm: false}

        default:
      return state;
    }
};