// Action types.
import { 
  ACCOUNT_SETTINGS_SIDERBAR,
  ACCOUNT_SETTINGS_SAVE,
  ACCOUNT_SETTINGS_SAVE_RESPONSE
} from "Redux/actionTypes";

export const showAccountSettings = (data) => {
  return {
    type: ACCOUNT_SETTINGS_SIDERBAR,
    payload: data,
  };
};


export const saveAccountSettings = (data) => {
  return {
    type: ACCOUNT_SETTINGS_SAVE,
    payload: data,
  };
};

export const saveAccountSettingsResponse = (data) => {
  return {
    type: ACCOUNT_SETTINGS_SAVE_RESPONSE,
    payload: data,
  };
};
