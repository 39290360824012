//Module Keys
let locales = {};

//Merge Views
import main from 'Modules/realtimeMaps/main/lan/en';
locales = { ...locales, ...main };

import mainView from 'Modules/realtimeMaps/main/components/MapUtilitiesMenu/lan/en';
locales = { ...locales, ...mainView };

import vehicleList from 'Modules/realtimeMaps/main/components/VehicleList/lan/en';
locales = { ...locales, ...vehicleList };

import ModalVisibleGroupsTags from 'Modules/realtimeMaps/main/components/modalVisibleGroupTags/lan/en';
locales = { ...locales, ...ModalVisibleGroupsTags };

import routes from 'Modules/realtimeMaps/main/components/routes/lan/en';
locales = { ...locales, ...routes };

import vehicleTrail from 'Modules/realtimeMaps/main/components/VehicleTrails/lan/en';
locales = { ...locales, ...vehicleTrail };

import landmarks from 'Modules/realtimeMaps/main/components/LandmarksListMain/lan/en';
locales = { ...locales, ...landmarks };

import landmarkMain from 'Modules/realtimeMaps/main/components/landmarks/lan/en';
locales = { ...locales, ...landmarkMain };

import landmarkList from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkList/lan/en';
locales = { ...locales, ...landmarkList };

import landmarkSettings from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkSettings/lan/en';
locales = { ...locales, ...landmarkSettings };

import landmarkVisibleGroups from 'Modules/realtimeMaps/main/components/landmarks/components/landmarkVisibleGroups/lan/en';
locales = { ...locales, ...landmarkVisibleGroups };

import oldGeofence from 'Modules/realtimeMaps/main/components/GeofenceListMain/lan/en';
locales = { ...locales, ...oldGeofence };

import geofence from 'Modules/realtimeMaps/main/components/geofences/lan/en';
locales = { ...locales, ...geofence };

import SendMessage from 'Modules/realtimeMaps/main/components/SendMessage/lan/en';
locales = { ...locales, ...SendMessage };

import vehicleDirections from 'Modules/realtimeMaps/main/components/VehicleDirections/lan/en';
locales = { ...locales, ...vehicleDirections };

import LayerMap from 'Modules/realtimeMaps/main/components/LayerMap/lan/en';
locales = { ...locales, ...LayerMap };

import findNearest from 'Modules/realtimeMaps/main/components/findNearest/lan/en';
locales = { ...locales, ...findNearest };

import unitSelection from 'Modules/realtimeMaps/main/components/UnitSelection/lan/en';
locales = { ...locales, ...unitSelection };

import mapSetting from 'Modules/realtimeMaps/main/components/MapSetting/lan/en';
locales = { ...locales, ...mapSetting };

import eta from 'Modules/realtimeMaps/main/components/eta/lan/en';
locales = { ...locales, ...eta };

import speedOverride from 'Modules/realtimeMaps/main/components/speedOverride/lan/en';
locales = { ...locales, ...speedOverride };

import vicinityTool from 'Modules/realtimeMaps/main/components/vicinityTool/lan/en';
locales = { ...locales, ...vicinityTool };

export default locales;
