import vidFleetComponent from 'Modules/.common/components/VidFleetVideo/lan/en';
import infoBubbleComponent from 'Modules/.common/components/InfoBubble/lan/en';
import dtcComponent from 'Modules/.common/components/Dtc/lan/en';
import sharedMediaComponent from 'Modules/.common/components/SharedMedia/lan/en';
import entityPickerComponent from 'Modules/.common/components/EntityPicker/lan/en';
import accountInformationComponent from 'Modules/.common/components/AccountInformation/lan/en';
import smartWitnessComponent from 'Modules/.common/components/SmartWitnessVideo/lan/en';
import mapIconPickerComponent from 'Modules/.common/components/IconPicker/lan/en';
import tagPickerComponent from 'Modules/.common/components/TagPicker/lan/en';
import entityTagPickerComponent from 'Modules/.common/components/EntityTagPicker/lan/en';
import entityTagPicker from 'Modules/.common/components/NewEntityTagPicker/lan/en';
import entityTagViewerComponent from 'Modules/.common/components/EntityTagViewer/lan/en';
import newTagPicker from 'Modules/.common/components/NewTagPicker/lan/en';
import troubleshootDevice from 'Modules/.common/components/TroubleshootDevice/lan/en';

let locales ={}

locales = {
  exportMessageSuccess: 'The export has been generated successfully.',
  exportMessageFailed: 'Sorry, a problem occurred during the export generation.',
  exportMessageFailedNoData: 'No information found.',
  ...vidFleetComponent,
  ...infoBubbleComponent,
  ...dtcComponent,
  ...sharedMediaComponent,
  ...entityPickerComponent,
  ...accountInformationComponent,
  ...smartWitnessComponent,
  ...mapIconPickerComponent,
  ...tagPickerComponent,
  ...entityTagPickerComponent,
  ...entityTagPicker,
  ...entityTagViewerComponent,
  ...newTagPicker,
  ...troubleshootDevice
};

export default locales;
