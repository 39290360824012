import React, { useEffect, useState } from "react";
import PanelLabel from "./PanelLabel";
import { useIntl } from "react-intl";
import SliderList from "./SliderList";
import { searchAvailableClips, setRequestClipData } from 'Redux/actions';
import { useDispatch, useSelector } from "react-redux";

const SliderUnit = ({ setCurrentClip, currentClip, units, unitsList }) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { requestClipData } = useSelector((state) => state.clipsRedux);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <>
      {currentClip?.deviceName ? (
        <>
          <PanelLabel
            message={messages["videoClipRequest_unit"]}
            value={currentClip.deviceName}
            row
            onClick={handleClick}
          />

          <SliderList
            anchorEl={anchorEl}
            setAnchorEl={setAnchorEl}
            list={units}
            onClick={(clip) => {
              const unitSelected = unitsList.find(item => {
                if (item.linkedMode === 0) {
                  if (item?.linkedUnit?.id === Number(clip?.id)) {
                    return item
                  } else if (item?.id === Number(clip?.id)) {
                    return item
                  }
                }
              })

              dispatch(searchAvailableClips({ esn: clip?.id }))
              setCurrentClip({
                ...currentClip,
                deviceId: unitSelected?.id,
                deviceName: unitSelected?.label,
                gpsId: clip?.id
              });
              dispatch(setRequestClipData({ ...requestClipData, deviceId: unitSelected?.id, label: unitSelected?.label, gpsId: clip?.id }))
              handleClose();
            }}
          />
        </>
      ) : (
        <PanelLabel
          message={messages["no_data"]}
          value='-----'
        />
      )}
    </>
  );
};

export default SliderUnit;
