import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientQuery } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
//import { resetAll } from "../../../../../../../../../../.globals/redux/actions";
import { NotificationManager } from 'Components/Notifications';
import { ExceptionManager } from 'Core/logManager';

const INIT_STATE = {
    open: false,
    loading: false,
    vehicles: [],
    error: null,
    result: null,
};

const route = "modules/maintenance/views/main/components/vehiclesTab/components/exportVehicles/redux/slice";

export const maintenanceEVSearchMaintenanceDueServiceUnits = createAsyncThunk("maintenance/exportVehicles/searchMaintenanceDueServiceUnits", async (payload, { rejectWithValue, dispatch }) => {
    try {
        const { dueDays, dueMiles, dueEngineHours } = payload;

        const response = await clientQuery(
            `query searchMaintenanceDueServiceUnits( $serviceId: Int, $unitId: Int, $dueDays: Int,  $dueMiles: Int, $dueEngineHours: Int){
                data: searchMaintenanceDueServiceUnits(
                serviceId: $serviceId,
                unitId: $unitId,
                dueDays: $dueDays,
                dueMiles: $dueMiles,
                dueEngineHours: $dueEngineHours
                ){
                    id
                    serviceId
                    service {
                        title
                    }
                    unitId
                    unitLabel
                    lastReadingId
                    lastMaintenanceDate
                    lastMaintenanceOdometer
                    lastMaintenanceEngineHours
                    nextServiceDate
                    nextServiceOdometer
                    nextServiceEngineHours
                    status
                    notes
                    completed
                    estimatedCost
                    subscribersCount
                    subscribers {
                        id
                        contactId
                        alert
                        alertBefore
                        alertAfter
                        createdOn
                        createdBy
                        updatedOn
                        updatedBy
                    }
                    createdOn
                    createdBy
                    updatedOn
                    updatedBy
                }   
            }`,
            {
                dueDays,
                dueMiles,
                dueEngineHours
            },
            endpoints.GRAPHQL_GENERAL
        );
        return response.data

    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceEVSearchMaintenanceDueServiceUnits');
        rejectWithValue(exc);
    }
});

export const maintenanceExportVehiclesRedux = createSlice({
    name: 'maintenance/exportVehicles',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload
                ? { ...state, ...action.payload }
                : { ...INIT_STATE };
        },
        openModalExportVehicles: (state, { payload }) => {
            state.open = payload.open
            state.vehicles = payload.data
        }
    },
    extraReducers: (builder) => {
        //maintenanceEVSearchMaintenanceDueServiceUnits
        builder.addCase(maintenanceEVSearchMaintenanceDueServiceUnits.pending, (state) => {
            state.loading = true
        });

        builder.addCase(maintenanceEVSearchMaintenanceDueServiceUnits.fulfilled, (state, { payload }) => {
            state.result = payload,
            state.loading = false
        });

        builder.addCase(maintenanceEVSearchMaintenanceDueServiceUnits.rejected, (state, action) => {
            state.loading = false,
            state.error = payload
        });
    },
});

const { actions, reducer } = maintenanceExportVehiclesRedux;

export const {
    reset,
    openModalExportVehicles,
} = actions;

export default reducer;