import {call, put} from 'redux-saga/effects';
import {clientQuery} from 'Core/data/GraphQLApi';
import {endpoints} from 'Core/defaultValues';
import {ExceptionManager} from 'Core/logManager';
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate} from 'Redux/actions';

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getDriverTimesheetReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
    const {timeZone, sendByEmail, emailsTo, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {filterType, filterArray, startRange, endRange, otherFilters, unitsSelected, filterEntity} = report.filters
    const baseConditionsQuery = baseReportConditions({
        report,
        name: 'DriverTimesheetReport',
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange
    })

    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        if (report.export) {
            const addColumnDriver = (
                report?.exportType === 'csv' ?
                    [
                        ... filterEntity === 'Unit' ?
                        [
                            {
                                "column": "idLabel",
                                "header": 'Vehicle'
                            },
                            {
                                "column": "driverLabel",
                                "header": "Driver"
                            },
                        ]:
                        [
                            {
                                "column": "driverLabel",
                                "header": "Driver"
                            },
                            {
                                "column": "idLabel",
                                "header": 'Vehicle'
                            },
                        ]
                    ]
                    :
                    [
                        {
                        "column": filterEntity === 'Unit' ? "driverLabel" : "idLabel",
                        "header": filterEntity === 'Unit' ? "Driver" : 'Vehicle'
                        }
                    ]
                    )
            payload = {
                ...payload,
                limit: (!dataResultPath || !isJsonFormat(report?.exportType, dataResultPath))
                ? 5000
                : rowsPerPage,
                offset: (!dataResultPath || !isJsonFormat(report?.exportType, dataResultPath))
                ? 1
                : page,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json",
                            formatOptions: "{}",
                            durationFormat: report?.durationFormat
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "driverTimesheetReport",
                        columns: [
                            ... addColumnDriver,
                            ... (filterEntity === 'Unit') ? [{
                                "column": "status",
                                "header": "Status"
                            }]:[],
                            {
                                "column": "dateIn",
                                "header": "In"
                            },
                            {
                                "column": "dateOut",
                                "header": "Out"
                            },
                            {
                                "column": "duration",
                                "header": "Time"
                            }
                        ]
                    }
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getDriverTimesheetReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getDriverTimesheetReport (conditions: $conditions, limit: $limit, offset: $offset) {
                  ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                        `reportInstanceId`
                        : `
                    items {
                        id
                        idLabel
                        noData
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data);

    } catch (exc) {
        ExceptionManager(exc, 'reports/driverTimesheetReport', 'getDriverTimesheetReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}

export default getDriverTimesheetReport;
