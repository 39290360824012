import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@mui/material';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'
import {
  formatDatetime,
  getCategoryFilterType,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

import { useSidePanelMap, useTableReport } from 'Core/hooks';


import {
  reportsMainGenerateEvents
} from 'Redux/actions'
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";

const UnitInfoReport = (props) => {
  const { messages, report } = props;
  const { loading, data, filters, events, summary, loadingSummary, subEvents, loadingSubEvents, timeZone } = report;
  const { filterEntity, filterArray, otherFilters, filterType, startRange, endRange } = filters;


  const reportRef = useRef();
  const dispatch = useDispatch()
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const exportHandler = useHandleExportReport(report);



  useEffect(() =>{
    if(report){
      const newReport = JSON.parse(JSON.stringify(report));
      // to keep the reference to the units selected
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);



  const onChangeExpandable = (unit) => {
    if(!unit.noData){
      let events = {
        data: [],
        filters: {
          filterType: getCategoryFilterType(filterEntity),
          filterArray: unit,
          page: 1,
          rowsPerPage: 10,
          sortField: 'eventDate',
          otherFilters
        }
      }
      dispatch(reportsMainGenerateEvents(report, events))
    }
  }

  let parametersSummary = useTableReport({data, report, messages, hideHeader: false});

  const renderExpandableRow = (row) => {
    let parametersSummaryTable = {
      ...parametersSummary,
      data: summary?.items || [],
      totalItems: summary?.total || 0,
      hideHeader: false,
      serverSideTable: true,
      onChangedPage: (page, rowsPerPage, sortField, sortOrder) => {
        let events = {
          data: [],
          filters: { filterType: getCategoryFilterType(filterEntity), filterArray: row, page, rowsPerPage, sortField, sortOrder, otherFilters }
        }
        dispatch(reportsMainGenerateEvents(report, events))
      },
      onChangeSelector: null,
      itemsSelector: null,
      generateExport: false,
      hideSelectorUnitsDriver: true,
      groups:report.filters.units || [],
      hideNumberOfRows:true,
      centerPagination: true,
      hiddenPagination: summary?.items?.length? false : true,
      noData: row.noData
    };
    return (
      <Table
        {...parametersSummaryTable}
        loading={loadingSummary}
      >
        <Column label={messages['generatedReport_type']} field="type" noSorting>
          {(element) => {
            return (
              element.type
            );
          }}
        </Column>
        <Column label={messages['generatedReport_serialNumber']} field="serial" noSorting>
          {(element) => {
            return (
              element.serial
            );
          }}
        </Column>
        <Column label={messages[`generatedReport_garminSerial`]} field="garminSerialNumber" noSorting>
          {(element) => {
            return (
                element.garminSerialNumber
            );
          }}
        </Column>
        <Column label={messages['generatedReport_unitLabel']} field="label" noSorting>
          {(element) => {
            return (
              element.label
            );
          }}
        </Column>
        <Column label={messages['generatedReport_timezone']} field="label" noSorting>
          {(element) => {
            return (
              element.timezone
            );
          }}
        </Column>
        <Column label={messages['generatedReport_driver']} field="driverName" noSorting>
          {(element) => {
            return (
              element.driverName
            );
          }}
        </Column>
        <Column label={messages[`generatedReport_odometer`]} field="odometer" noSorting>
          {(element) => {
            return (
              element.odometer !== 0.0 ? Number(element.odometer).toFixed(2) : ' '
            );
          }}
        </Column>
        <Column label={messages[`generatedReport_engineHours`]} field="engineHours" noSorting>
          {(element) => {
            return (
              element.engineHours !== 0.0 ? Number(element.engineHours).toFixed(2) : ' '
            );
          }}
        </Column>
        <Column label={messages[`generatedReport_equipment`]} field="equipment" noSorting>
          {(element) => {
            return (
              element.equipment
            );
          }}
        </Column>
        <Column label={messages[`generatedReport_vin`]}  field="vin" noSorting>
          {(element) => {
            return (
              element.vin
            );
          }}
        </Column>
        <Column label={messages['generatedReport_lastEvent']} field="eventName" noSorting> 
          {(element) => {
            return (
                <EventReports element={{event: element.eventName || ''}}/>
            );
          }}
        </Column>
        <Column label={messages['generatedReport_lastEventTime']} field="date" noSorting>
          {(element) => {
            return element.unitTime === ' ' ? (
                <div>
                  <span className="speed-report-stacked"> </span>
                </div>
            ) : (
                <div>
                  <span className="speed-report-stacked">{element.unitTime && formatDatetime(parseInt(element.unitTime), "h:mm A", timeZone)}</span>
                  <span className="speed-reportstacked speed-report-event-date">{element.unitTime && formatDatetime(parseInt(element.unitTime), "MM/DD/YY", timeZone)}</span>
                </div>
            )
          }}
        </Column>
      </Table>
    )
  }

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: 'groupId',
    classNameColumns: "table-report-main-expandible"
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column label="Day" field="label" noSorting>
          {(element) => {
            return (
              <div>
                <span>
                  <span><strong>{messages['generatedReport_tag']}:</strong> {element?.groupIdLabel?.replace(/\s/g,"\xa0")} </span>
                </span>
              </div>
            );
          }}
        </Column>
        <Column label={messages['generatedReport_totalIdleTime']} field="label" noSorting>
          {(element) => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (
              <div>
                <span>
                  <span>
                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                  </span>
                </span>
              </div>
            );
          }}
        </Column>
      </Table>
    </Fragment>
  );
}

export default UnitInfoReport
