import {
  INFO_BUBBLE_GET_MARKER_DETAILS,
  INFO_BUBBLE_GET_MARKER_DETAILS_RESPONSE,
  INFO_BUBBLE_GET_LAST_SNAPSHOT,
  INFO_BUBBLE_GET_LAST_SNAPSHOT_RESPONSE,
  INFO_BUBBLE_SHOW_TIMELINE,
  INFO_BUBBLE_CLIP_REQUEST_DATE
} from 'Redux/actionTypes';

export const getMarkerDetails = (data) => ({
  type: INFO_BUBBLE_GET_MARKER_DETAILS,
  payload: { data },
});

export const getMarkerDetailsResponse = (data) => ({
  type: INFO_BUBBLE_GET_MARKER_DETAILS_RESPONSE,
  payload: { data },
});

export const getLastSnapshot = (data) => ({
  type: INFO_BUBBLE_GET_LAST_SNAPSHOT,
  payload: { data },
});

export const getLastSnapshotResponse = (data) => ({
  type: INFO_BUBBLE_GET_LAST_SNAPSHOT_RESPONSE,
  payload: { data },
});

export const getShowTimeline = (data) => ({
  type: INFO_BUBBLE_SHOW_TIMELINE,
  payload: { data },
});

export const getClipRequestDate = (data) => ({
  type: INFO_BUBBLE_CLIP_REQUEST_DATE,
  payload: { data },
});