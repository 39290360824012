import React, { useEffect, useRef, useState } from "react";

import {
  DateRangePicker,
  IconButton,
  Icons,
  LinearProgress,
  Loading,
} from "@gpstrackit/gtc-react-controls";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useComputeData } from 'Core/hooks'

import { searchTrailsIncident, sliceHistoryTrails } from "Redux/actions";
import moment from "moment";
import { reset } from "../../../../redux/slice";
import { createDataToShow } from "../../../trailEvent/utils";
import TrailItem from "../../../trailEvent";
import { getData } from "../../utils";
import { getUnit } from "../../../../../../.globals/utils";

export const Trails = ({
  rangeDate,
  onChangeDateTime,
  activeIncident,
  startIncident,
  setViewTrails,
  viewTrails,
  setNumberSelected,
}) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const scrollTrailsRef = useRef();

  const {
    isOpenOverViewUnit,
    loadingTrailsIncident,
    errorTrailsIncident,
    trailsIncidentTotal,
    trailsIncident,
    sliceTrails
  } = useSelector((state) => state.incidentsRedux);
  const landmarkGroups = useSelector(
    (state) => state.entitySelectorRedux.landmarkGroups
  );
  const lang = useSelector((state) => state.commomRedux.language);

  const { user } = useSelector((state) => state.securityRedux);
  const { dateformat, timeZone, isKph } = user;
  const id = isOpenOverViewUnit?.data?.unit?.id;
  const hasVideo = isOpenOverViewUnit?.data?.unit?.hasVideo;
  
  const [activeFilterDate, setActiveFilterDate] = useState(1);
  const [page, setPage] = useState(0);
  const [paginationLoading, setPaginationLoading] = useState(true);
  const [filters, setFilters] = useState({
    limit: 100,
    offset: 0,
    deviceId: id,
    startDate: rangeDate?.start,
    endDate: rangeDate?.end,
  });
  const [currentTrails, setCurrentTrails] = useState([])


  const [data, changeIdsCallback] = useComputeData(
    getData,
    "id",
    ["label", "lat", "lng", "eventName", "changeLatLng"],
    true,
    (itemsChanged) => {}
  )

  useEffect(() => {
    dispatch(searchTrailsIncident(filters));
  }, [filters]);

  useEffect(() => {
    if (page === 0) return;

    setFilters({
      ...filters,
      offset: page * filters?.limit,
      research: false,
      currentData: trailsIncident,
    });
  }, [page]);

  useEffect(() => {
    if (!loadingTrailsIncident && trailsIncident?.length > 0) {
      dispatch(sliceHistoryTrails(trailsIncident));
      setPaginationLoading(false);
      setCurrentTrails(trailsIncident)
    }else{
      dispatch(sliceHistoryTrails([]));
    }
  }, [loadingTrailsIncident, trailsIncident]);

  useEffect(() => {
    if (
      moment(filters?.startDate?.start).isSame(moment(rangeDate?.start)) &&
      moment(filters?.endDate?.end).isSame(moment(rangeDate?.end))
    ) {
    } else {
      if (scrollTrailsRef.current) {
        scrollTrailsRef.current.scrollTop = 0;
      }

      setPage(0);
      dispatch(
        reset({
          trailsIncident: [],
          trailsIncidentTotal: 0,
        })
      );

      setFilters({
        ...filters,
        offset: 0,
        limit: 100,
        research: true,
        currentData: [],
        startDate: rangeDate?.start,
        endDate: rangeDate?.end,
      });
    }
  }, [rangeDate]);

  useEffect(() => {
    const unit = getUnit(id, data) 
    if(unit){
        const fixLatLong = {
          ...unit,
          latitude: unit?.lat,
          longitude: unit?.lng,
          formattedAddress: unit?.address?.formatted
        }
        const updateTrail = [fixLatLong, ...currentTrails]
        setCurrentTrails(updateTrail)

        if(currentTrails?.length !== sliceTrails?.length) return

        dispatch(sliceHistoryTrails(updateTrail));
    }
  }, [data])

  const handleScroll = () => {
    if (scrollTrailsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollTrailsRef.current;
      if (
        scrollTop + clientHeight + 100 >= scrollHeight &&
        trailsIncident?.length < trailsIncidentTotal &&
        !paginationLoading
      ) {
        setPaginationLoading(true);
        setPage(page + 1);
      }
    }
  };

  const items = createDataToShow(
    currentTrails,
    landmarkGroups,
    messages,
    {},
    isOpenOverViewUnit?.data?.unit,
    lang,
    timeZone
  );


  const filterTrail = (item = 0) => {
    if(!viewTrails) return
    const newSlice = currentTrails?.slice(item);
    if (newSlice?.length > 0) {
      // if (scrollTrailsRef.current) {
      //   scrollTrailsRef.current.scrollTop = 0;
      // }
      dispatch(sliceHistoryTrails(newSlice));
    }
  };

  return (
    <div
      className="incident_trails"
      style={{ width: hasVideo ? "33%" : "50%", height: "auto" }}
    >
      <div style={{ display: "flex", justifyContent: 'space-between' }} className="incident_trails_title">
        <p style={{ margin: 0, marginRight: "5px" }}>
          {messages["incident_event_trails"]}
        </p>
        {!hasVideo && (
          <div style={{ width: "100%" }}>
            {!activeIncident ? (
              <DateRangePicker
                calendarPosition="bottom-end"
                showButtons
                withTime={true}
                startDate={rangeDate?.start}
                endDate={rangeDate?.end}
                style={{ minWidth: "310px" }}
                maxDate={moment().endOf("day").toDate()}
                onChange={(start, end) => onChangeDateTime(start, end)}
                customRangeDate={8}
              />
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <div
                  className={
                    activeFilterDate === 0
                      ? "incident_selected_filter_date"
                      : "incident_filter_date"
                  }
                  onClick={() => {
                    if (activeFilterDate === 0) return;
                    onChangeDateTime(startIncident, moment().endOf("day"));
                    setActiveFilterDate(0);
                  }}
                >
                  {messages["event_from_start"]}
                </div>
                <div
                  className={
                    activeFilterDate === 1
                      ? "incident_selected_filter_date"
                      : "incident_filter_date"
                  }
                  onClick={() => {
                    if (activeFilterDate === 1) return;
                    onChangeDateTime(moment().subtract(24, "hours"), moment());
                    setActiveFilterDate(1);
                  }}
                >
                  {messages["event_last_24h"]}
                </div>
              </div>
            )}
          </div>
        )}
        <div style={{marginLeft: '5px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
          <IconButton 
            isDisabled={trailsIncidentTotal > 0 ? false : true}
            style={{color: 'black'}} 
            tooltip={viewTrails ? messages["incident_hide_trails"] : messages["incident_view_trails"]} 
            icon={viewTrails ? 'visibility_off' : "visibility"} 
            size="small" 
            onClick={() => setViewTrails(!viewTrails)}/>
        </div>
      </div>
      {loadingTrailsIncident && trailsIncident?.length === 0 ? (
        <div className="incident_trails_loading">
          <Loading />
        </div>
      ) : !loadingTrailsIncident && trailsIncident?.length === 0 ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            textAlign: "center",
          }}
        >
          <p>{messages["incidents_no_data_trails"]}</p>
        </div>
      ) : (
        <div
          className="incident_trails_list"
          onScroll={handleScroll}
          ref={scrollTrailsRef}
        >
          {items &&
            items?.map((item, index) => {
              return (
                <TrailItem
                  dateformat={dateformat}
                  timezone={timeZone.name}
                  numberItem={index}
                  count={items?.length - index}
                  element={item}
                  isKph={isKph}
                  setNumberSelected={setNumberSelected}
                  filterTrail={filterTrail}
                  sliceTrails={sliceTrails}

                />
              );
            })}
        </div>
      )}
      {paginationLoading && trailsIncident?.length > 0 && (
        <div style={{ width: "100%" }}>
          <LinearProgress />
        </div>
      )}
    </div>
  );
};
