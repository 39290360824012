import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
  INFO_BUBBLE_GET_MARKER_DETAILS,
  INFO_BUBBLE_GET_LAST_SNAPSHOT
} from 'Redux/actionTypes';

import {
  getMarkerDetailsResponse,
  getLastSnapshotResponse
} from 'Redux/actions';

function* getMarkerDetailsRequest({ payload }) {
  try {
    const { data: d } = payload;
    const { type, id, unitId, isPublic } = d;

    let detail;
    let body = {
      type: type,
      id: id
    };

    if (type === "Trail") {
      detail = `
        ... on MarkerDetailTrail {
          vehicleTrail {
            id
            deviceId
            latitude
            longitude
            heading
            eventCode
            eventName
            address
            formattedAddress
            driverId
            driverName
            ecuEngineOdometer
            ecuSpeed
            ecuEngineHours
            engineHours
            engineOdometer
            speed
            ignitionStatus
            satelliteCount
            signal
            speedLimit
            unitTime
            state
            unitType
            zip
            hDop
            vDop
            deviceData
          }
          unit {
            id
            deviceCategory
            useEcuOdometer
            useEcuEngineHours
            useEcuSpeed
            label
            jbus
            vbus            
            typeId
            offsetEcuEngineOdometer
            offsetEcuEngineHours
            offsetEngineOdometer
            offsetEngineHours
            notes
            lastReading {
              deviceLabel
              temperatures
              dtc
              unitType
              landmark_id
            }
            vehicleInformation {
              vin
              make
              model
              year
            }
            dtcCount {
              history
              active
            }
            timeZoneId
            timeZone {
              id
              name
            }
            crews {
              id
              firstName
              lastName
            }
            tags {
              id
              label
              color
              path
            }
          }
          linkedDevice {
            id
            esn
            deviceModelId
            deviceModelName
            manufacturerId
            manufacturerName
            simProviderName
            hasVideo
            liveMode
          }
        }
      `;
      body.unitId = `${unitId}`;
    } else if (type === "Vehicle") {
      detail = `
      ... on MarkerDetailVehicle {
        unit {
          id
          deviceCategory
          useEcuOdometer
          useEcuEngineHours
          useEcuSpeed
          label
          jbus
          vbus
          driverId
          driverName
          typeId
          offsetEcuEngineOdometer
          offsetEcuEngineHours
          offsetEngineOdometer
          offsetEngineHours
          notes
          vehicleInformation {
            vin
            make
            model
            year
          }
          lastReading {
            deviceId
            unitId
            ecuEngineOdometer
            ecuSpeed
            ecuEngineHours
            speed
            engineHours
            engineOdometer
            unitType
            deviceLabel
            unitTime
            eventCode
            eventName
            latitude
            longitude
            distance
            temperatures
            dtc
            heading
            landmark_id
            address {
              address
              city
              formatted
              state
              zip
            }
            deviceData
          }
          dtcCount {
            history
            active
          }
          timeZoneId
          timeZone {
            id
            name
          }
          crews {
            id
            firstName
            lastName
          }
          tags {
            id
            label
            color
            path
          }
        }
        linkedDevice {
            id
            esn
            deviceModelId
            deviceModelName
            manufacturerId
            manufacturerName
            simProviderName
            hasVideo
            liveMode
        }
      }
    `;
    } else if (type === "Landmark") {
      detail = `
      ... on MarkerDetailLandmark {
        landmark {
          id
          companyId
          name
          tags {
            id
            label
            color
            path
          }
          latitude
          longitude
          shape
          radius
          address
          city
          zip
          state
          country
          note
          coordinates {
            latitude
            longitude
          }
        }
      }
      `;
    } else if (type === "Geofence") {
      detail = `
      ... on MarkerDetailGeofence {
        geofence {
          id
          companyId
          name
          tags {
            id
            label
            color
            path
          }
          shape
          radius
          alertOnType
          center_latlong
          contacts {
              id
              title
              address
              type
          }
          coordinates {
              latitude
              longitude
          }
        }
      }
      `;
    } else if (type === "RoutePoint") {
      detail = `
      ... on MarkerDetailRoutePoint {
        routePoint {
          
          id
          name
          routeId
          address
          city
          state
          landmarkId
          latitude
          longitude
          radius
          sequence
          note       
          radius   
          status

        }
      }
      `;
    }


    let data = null;
    
    data = yield call(
      clientQuery,
      `query ($type: MarkerType!, $id: String! ${type === "Trail" ? ', $unitId: String' : ''}) {
        Marker: getMarkerDetail(type: $type, id: $id ${type === "Trail" ? ', unitId: $unitId' : ''}) {
          id,
          type,
          markerDetail {
            ${detail}
          }
        }
      }`,
      body,
      type === "RoutePoint" ? endpoints.GRAPHQL_GENERAL_V2 : endpoints.GRAPHQL_GENERAL
    );
    

    if (data && data.Marker) {
      yield put(getMarkerDetailsResponse(data.Marker));
    } else {
      throw 'Not marker data';
    }
  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/.common/components/InfoBubble/redux/saga', 'getMarkerDetailsRequest');
    yield put(getMarkerDetailsResponse({ error: true }));
  }
}

function* getSnapshotRequest({ payload }) {
  try {
    const { data: d } = payload;
    const { deviceId, unitTime } = d;

    let data = yield call(
      clientQuery,
      `query getVidFleetLastSnapshot($deviceId: String!, $unitTime: AWSDateTime!) {
        Snapshot: getVidFleetLastSnapshot (deviceId: $deviceId, unitTime: $unitTime)
      }`,
      {
        deviceId: deviceId,
        unitTime: unitTime
      },
      endpoints.GRAPHQL_GENERAL,
    );

    if (data && data.Snapshot) {
      yield put(getLastSnapshotResponse(data.Snapshot));
    } else {
      yield put(getLastSnapshotResponse({}));
    }

  } catch (exc) {
    // ALWAYS RETURN ACTION
    ExceptionManager(exc, 'modules/.common/components/InfoBubble/redux/saga', 'getSnapshotRequest');
    yield put(getLastSnapshotResponse({ error: true }));
  }
}

function* watchGetMarkerDetailsRequest() {
  yield takeEvery(INFO_BUBBLE_GET_MARKER_DETAILS, getMarkerDetailsRequest);
}

function* watchGetSnapshotRequest() {
  yield takeEvery(INFO_BUBBLE_GET_LAST_SNAPSHOT, getSnapshotRequest);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetMarkerDetailsRequest),
    fork(watchGetSnapshotRequest)
  ]);
}
