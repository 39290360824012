import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateReportUpdate
} from 'Redux/actions';

import {
  getTimeZone,
  handleAsyncReport,
  baseReportConditions,
  generateReportInstance,
  isJsonFormat
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getInputReport(report, page, rowsPerPage, sortField, sortOrder, requestFlag) {
  const { timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath } = report;
  const { filterType, filterArray, startRange, endRange, unitsSelected } = report.filters
  const baseConditionsQuery = baseReportConditions({ report, name, dataResultPath, timeZone, delayed, unitsSelected, timeframeName, filterType, filterArray, categoryName, startRange, endRange })

  try {
    let payload = {
      conditions: {
        ...baseConditionsQuery,
        sortField: {
          field: sortField || "deviceId",
          order: sortOrder === 'desc' ? 'DESC' : 'ASC'
        }
      },
      limit: rowsPerPage,
      offset: page
    };

    if (report.export) {
      payload = {
        ...payload,
        limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
        offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
        conditions: {
          ...payload.conditions,
          exportOptions: {
            sendByEmail,
            emailsTo,
            format: {
              fileFormat: report?.exportType || "json",
              formatOptions: "{}"
            },
            culture: report.lang,
            timeZone: getTimeZone(timeZone),
            folderName: "px-customer-reports",
            filePrefixName: "inputReport",
            columns: report?.exportType === "pdf" ?
              [
                {
                  "column": "onDatetime",
                  "header": "On",
                  "colSpan": 2
                },
                {
                  "column": "onAddress",
                  "header": ""
                },
                {
                  "column": "offDatetime",
                  "header": "Off",
                  "colSpan": 2
                },
                {
                  "column": "offAddress",
                  "header": ""
                },
                {
                  "column": "duration",
                  "header": "Total Time"
                }
              ] :
              [
                {
                  "column": "label",
                  "header": "Label"
                },
                {
                  "column": "event",
                  "header": "Event"
                },
                {
                  "column": "location",
                  "header": "Location"
                },
                {
                  "column": "dateTime",
                  "header": "DateTime"
                },
                {
                  "column": "total",
                  "header": "Total (minutes)"
                }
              ]
          }
        }
      }
    }
    let data = yield call(
      clientQuery,
      `query getInputReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
        Report: getInputReport (conditions: $conditions, limit: $limit, offset: $offset) {
          ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
        `reportInstanceId`
        : `
            items {
              id
              idLabel
              noData
              eventList {
                  event
                  count
              }
            }
            total
            limit
            offset
            category
        `}
        }
        
      }`,
      payload,
      endpoints.GRAPHQL_PX_REPORTING,
    );
    yield handleAsyncReport(report, data);

  } catch (exc) {
    ExceptionManager(exc, 'reports/detailedReport', 'getInputReport');
    report.loading = false
    yield put(reportsMainGenerateReportProgress(report))
    if (!requestFlag) {
      let flag = true
      yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
    } else {
      report.loading = false
      yield put(reportsMainGenerateReportProgress(report))
    }
  }
}


export default getInputReport;
