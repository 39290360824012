import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
/*********************************************************
 * Controls
 *********************************************************/
import styles from 'Assets/sass/core/vars.scss';
import Popover from '@mui/material/Popover';
import ButtonToggleMap from '../ButtonToggleMap'

/*********************************************************
 * Icons
 *********************************************************/
import NavigationIcon from '@mui/icons-material/Navigation';
import LandmarkMarkerSvg from '../IconsSvg/LandmarkMarkerSvg'
import GeofenceSvg from '../IconsSvg/GeofenceSvg';

/*********************************************************
 * Style
 *********************************************************/
import './style.scss';

/*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    28 Jan 2020
 * Module:  Realtime Maps
 * 
 * Class associated to the legends markers in realtime maps
 ********************************************************/
const LegendsToggle = (props) =>  {
    /********************************************************
     * Define const
     ********************************************************/
     const { messages } = props.intl;

     const LEGENDS = [
        { isControl: false, name: messages['realtimemaps_drive'], isControl: false, color: styles.color_evnt_drive },
        { isControl: false, name: messages['realtimemaps_motion'], isControl: false, color: styles.color_evnt_drive },
        { isControl: false, name: messages['realtimemaps_motion_start'], isControl: false, color: styles.color_evnt_travel_start },
        { isControl: false, name: messages['realtimemaps_motion_stop'], isControl: false, color: styles.color_evnt_travel_stop },
        { isControl: false, name: messages['realtimemaps_ignitionOff'], color: styles.color_evnt_ignition_off},
        { isControl: false, name: messages['realtimemaps_ignitionOn'], color: styles.color_evnt_ignition_on},
        { isControl: false, name: messages['realtimemaps_travelStart'], color: styles.color_evnt_travel_start},
        { isControl: false, name: messages['realtimemaps_travelStop'], color: styles.color_evnt_travel_stop},
        { isControl: false, name: messages['realtimemaps_geofenceIn'], color: styles.color_evnt_geofence_in},
        { isControl: false, name: messages['realtimemaps_geofenceOut'], color: styles.color_evnt_geofence_out},
        { isControl: false, name: messages['realtimemaps_idle'], color: styles.color_evnt_idle},
        { isControl: false, name: messages['realtimemaps_stop'], color: styles.color_evnt_stop},
        { isControl: false, name: messages['realtimemaps_parked'], color: styles.color_evnt_parked},
        { isControl: false, name: messages['realtimemaps_update'], color: styles.color_evnt_update},
        { isControl: false, name: messages['realtimemaps_rapidAcceleration'], color: styles.color_evnt_rapid_acceleration},
        { isControl: false, name: messages['realtimemaps_hardBrake'], color: styles.color_evnt_hard_brake},
        { isControl: false, name: messages['realtimemaps_hardTurn'], color: styles.color_evnt_hard_turn},
        { isControl: false, name: messages['realtimemaps_suddenStop'], color: styles.color_evnt_sudden_stop},
        { isControl: false, name: messages['realtimemaps_other'], color: styles.color_evnt_other},
        { isControl: true, name: messages['realtimemaps_landmark'], control: <LandmarkMarkerSvg color={styles.color_evnt_drive} /> },
        { isControl: true, name: messages['realtimemaps_geofence'], control: <GeofenceSvg /> },
        { isControl: true, name: messages['realtimemaps_offline'], control: <img src="/assets/icons/offlineimage.png" style={{ width: 20, height: 20 }} alt="offline" /> }
    ];
    

    const [isLegendsOpened, setIsLegendsOpened] = useState() 

    /********************************************************
     * Event to close legend component
     ********************************************************/
    const handleClose = () => {
        setIsLegendsOpened(null)
    };

    /********************************************************
     * Event to open legend component
     ********************************************************/
    const handleOpenLegend = (event) => {
        setIsLegendsOpened(event.currentTarget)
    }
    
    /********************************************************
     * Return the legend component
     ********************************************************/
    const open = Boolean(isLegendsOpened);
    const id = open ? 'simple-popover' : undefined;
    return (
        <Fragment>
            <ButtonToggleMap label={messages['map_legend']} open={open} onClick={handleOpenLegend} />
            <Popover
                id={id}
                open={open}
                anchorEl={isLegendsOpened}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <div className={'legend-menu'}>
                    {
                        LEGENDS.map((legend, key) => {
                          if(legend.isControl){
                            return (
                              <div key={key} style={{ display: '-webkit-box' }} className={'legend-item'}>
                                  <div className={'legend-iconFlag'}>
                                    {legend.control}
                                  </div>
                                  <span style={{ paddingLeft: 4, fontSize: 14 }}>{legend.name}</span>
                              </div>
                            )
                          }else{
                            return (
                              <div key={key} className={'legend-item'}>
                                  <NavigationIcon className={'navigation-icon'} style={{ color: `${legend.color}` }} />
                                  <span className={'legend-name'}>{legend.name}</span>
                              </div>
                            )
                          } 
                        })
                    }
                </div>
            </Popover>
        </Fragment>
    )
}

export default injectIntl(LegendsToggle);