import { Button, Icons } from "@gpstrackit/gtc-react-controls";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { 
  incidentPanelAction, 
  createIncident, 
  incidentGetLastReading,
  updateIncidentRedux,
  searchIncidents
} from "Redux/actions";
import Step1 from "./step1";
import Step2 from "./step2";
import moment from "moment";
import { reset } from "../../redux/slice";
import { NotificationManager } from "Components/Notifications";
import Modal, { ModalBody, ModalFooter } from "Components/Modal";


const INIT_STATE = {
  id: null,
  name: "",
  date: moment(),
  unitId: 0,
  alerts: [],
  subscribers: [],
};

export const CreateEdit = () => {
  const dispatch = useDispatch();
  const { messages } = useIntl();

  const { 
    openIncidentPanel, 
    createIncidentRes, 
    loadingCreateIncident, 
    errorCreateIncident,
    updateIncidentLoading,
    updateIncident,
    updateIncidentError } = 
  useSelector((state) => state.incidentsRedux);
  const { unitsList, contacts } = useSelector(state => state.catalogsRedux);

  const [step, setStep] = useState(1);
  const [phoneContacts, setPhoneContacts] = useState([]);
  const [emailContacts, setEmailContacts] = useState([]);
  const [formData, setFormData] = useState(INIT_STATE);
  const [updateNotification, setUpdateNotification] = useState(false)
  const [errors, setErrors] = useState({
    name: "",
    unitId: 0,
    date: "",
  });

  useEffect(() => {
    if(loadingCreateIncident) return
    if(errorCreateIncident && createIncidentRes?.error){
      NotificationManager.error(
        messages["incidents_error_create"],
        messages["error"],
        3000,
        null,
        null,
        "error"
      );
    }
    if(createIncidentRes && !errorCreateIncident){
      NotificationManager.success(
        messages["incidents_success_create"],
        messages["success"],
        3000,
        null,
        null,
        "success"
      );
      onClearFields();

    }
  }, [loadingCreateIncident, errorCreateIncident, createIncidentRes])

  useEffect(() => {
    if(!updateNotification) return
    if(updateIncidentLoading) return
    if(updateIncidentError && updateIncident?.error){
      NotificationManager.error(
        messages["incidents_error_update"],
        messages["error"],
        3000,
        null,
        null,
        "error"
      );
    }
    if(updateIncident?.id && !updateIncidentError){
      NotificationManager.success(
        messages["incidents_success_update"],
        messages["success"],
        3000,
        null,
        null,
        "success"
      );
      onClearFields();
      dispatch(
        searchIncidents({
          conditions: [
            {
              fields: "ended",
              terms: ["0"],
              exact: true,
            },
            {
              fields: "isDeleted",
              terms: ["0"],
              exact: false,
            }
          ],
          sort: [
            {
              field: "id",
              order: "DESC",
            },
          ],
          limit: 100,
          offset: 0,
        })
      );
      dispatch(
        reset({ 
          updateIncidentLoading: false,
          updateIncident: {},
          updateIncidentError: false
        })
      );

    }
  }, [updateIncidentLoading, updateIncident, updateIncidentError])

  useEffect(() => {
    if(openIncidentPanel?.update?.id){
      const {
        id, 
        name, 
        start, 
        unitId, 
        end,
        systemAlertId,
        assignments,
        config,
        subscribers
      } = openIncidentPanel?.update

      let buildData = {}

      const findUnit = unitsList?.find(unitItem => unitItem?.id === unitId)
      dispatch(incidentGetLastReading({ id: unitId }));

      buildData = {
        id,
        name,
        date: moment(start),
        unitId: findUnit,
        end,
        alerts: [],
        subscribers: []
      }
      
      if(systemAlertId){
        const parse = JSON.parse(config)
        const activeAlerts = Object.keys(parse).filter(key => parse[key]);

        const currentSubscribers = JSON.parse(subscribers)
        if(currentSubscribers?.length > 0){
          const buildSubs = []
          const buildPhoneContacts = []
          const buildEmailContacts = []
          currentSubscribers?.map(item => {
            if(item?.contactId === -1){
              return buildSubs.push({
                "alert": true,
                "alertAfter": false,
                "contactId": -1,
                "title": "All Contacts"
              })
            }
            if(item?.contactId === -2){
              return buildSubs.push({
                "alert": true,
                "alertAfter": false,
                "contactId": -2,
                "title": "Current Driver"
              })
            }
            if(!item?.contactId && item?.address){
              if(item?.type === 'email'){
                return buildEmailContacts.push({
                  "alert": true,
                  "alertAfter": false,
                  "address": item?.address,
                  "type": 'email'
                })
              }
              if(item?.type === 'sms'){
                return buildPhoneContacts.push({
                  "alert": true,
                  "alertAfter": false,
                  "address": item?.address,
                  "type": 'sms'
                })
              }
              
            }
            const find = contacts?.find(contact => contact?.id === item?.contactId)
            if(find){
              buildSubs.push({
                "alert": true,
                "alertAfter": false,
                "contactId": find?.id,
                "title": find?.title,
                "type": find?.type,
              })
            }
          })

          setPhoneContacts(buildPhoneContacts)
          setEmailContacts(buildEmailContacts)

          buildData = {
            ...buildData,
            subscribers: buildSubs
          }
        }

        buildData = {
          ...buildData,
          alerts: activeAlerts
        }
      }

      setFormData(buildData)
    }
  }, [openIncidentPanel])

  const saveOrUpdate = () => {
    const { unitId, name, date, subscribers, alerts } = formData;

    if(
      openIncidentPanel?.update?.systemAlertId ?.length > 0 &&
      alerts.length === 0 &&
      emailContacts?.length === 0 &&
      phoneContacts?.length === 0 &&
      subscribers?.length === 0 
    ){
      return NotificationManager.warning(
        messages["incident_alert_after_created"],
        messages["warning"],
        3000,
        null,
        null,
        "warning"
      );
    }

    if(
      (emailContacts?.length > 0 ||
      phoneContacts?.length > 0 ||
      subscribers?.length > 0) && 
      alerts?.length === 0
    ){
      return NotificationManager.warning(
        messages["incident_alert_no_selected"],
        messages["warning"],
        3000,
        null,
        null,
        "warning"
      );
    }

    if(
      alerts.length > 0 &&
      emailContacts?.length === 0 &&
      phoneContacts?.length === 0 &&
      subscribers?.length === 0 
    ){
      return NotificationManager.warning(
        messages["incident_contact_no_selected"],
        messages["warning"],
        3000,
        null,
        null,
        "warning"
      );
    }

    let newContacts = {} 
    if(emailContacts?.length){
      newContacts = {
        ...newContacts,
        emailContacts
      }
    }

    if(phoneContacts?.length){
      newContacts = {
        ...newContacts,
        phoneContacts
      }
    }

    const buildJsonAlert = JSON.stringify({
      newContacts,
      alerts,
      subscribers
    }) 

    if(formData?.id !== null){
      setUpdateNotification(true)
      dispatch(
        updateIncidentRedux({
          id: formData?.id,
          unitId: unitId?.id,
          name,
          start: date,
          shortCode: formData?.shortcode,
          note: formData?.note,
          end: moment(date).add(1, "h"),
          systemAlertId: openIncidentPanel?.update?.systemAlertId,
          jsonAlert: buildJsonAlert
        })
      );
    }else{
      dispatch(
        createIncident({
          unitId: unitId?.id,
          name,
          start: date,
          end: moment(date).add(1, "h"),
          jsonAlert: buildJsonAlert
        })
      );
    }

    dispatch(
      incidentPanelAction({
        update: {},
        open: false,
      })
    );
    
  };

  const nextStep = () => {
    const name = formData?.name;
    const unitId = formData?.unitId;
    const date = formData?.date;

    
    if (name?.length > 30 || name?.length < 3) {
      setErrors({
        ...errors,
        name: "incidents_name_rules_error",
      });
      
      return
    }

    if (moment(date).isAfter(moment())) {
      setErrors({
        ...errors,
        date: "incidents_date_rules_error",
      });

      return;
    }

    if (!unitId) {
      setErrors({
        ...errors,
        unitId: "incidents_unitId_rules_error",
      });

      return;
    }

    setErrors({ name: "", unitId: 0 });

    setStep(2);
  };

  const onClearFields = () => {
    setFormData(INIT_STATE);
    setPhoneContacts([])
    setEmailContacts([])
    setStep(1);
    setErrors({
      name: "",
      unitId: 0,
      date: "",
    });
    dispatch(reset({ lastReading: null }));
  };

  return (
    <Modal
      open={openIncidentPanel?.open}
      position="right"
      size="small"
      title={
        <div className="incident_modal_title">
          <div>
            <Icons
              color="#ff0000"
              style={{ fontSize: "20px", marginRight: "8px", color: "red" }}
              name="error"
            ></Icons>
            <h3 style={{ margin: "5px" }}>
              {formData?.id
                ? `${messages["edit_incident"]}`
                : `${messages["create_incident"]}`}
            </h3>
          </div>
        </div>
      }
      handleClose={() => {
        dispatch(incidentPanelAction({
          update: {},
          open: false,
        }));
        onClearFields();
      }}
    >
      <ModalBody>
        {step === 1 ? (
          <Step1
            formData={formData}
            setFormData={setFormData}
            errors={errors}
            setErrors={setErrors}
          />
        ) : (
          <Step2
            formData={formData}
            setFormData={setFormData}
            phoneContacts={phoneContacts}
            setPhoneContacts={setPhoneContacts}
            emailContacts={emailContacts}
            setEmailContacts={setEmailContacts}
          />
        )}
      </ModalBody>

      <ModalFooter>
        <div>
          <Button
            variant="secondary"
            style={{ marginRight: 8 }}
            onClick={() => {
              if (step === 1) {
                onClearFields();
                return dispatch(
                  incidentPanelAction({
                    update: {},
                    open: false,
                  })
                );
              }
              setStep(1);
            }}
          >
            {step === 1
              ? messages["incidents_close"]
              : `${messages["incidents_prev"]}`}
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (step === 1) return nextStep();
              saveOrUpdate();
            }}
          >
            {step === 1
              ? messages["incidents_next"]
              : formData?.id
              ? `${messages["edit_incident"]}`
              : `${messages["create_incident"]}`}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};
