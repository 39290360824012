import React, { useCallback, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import videojs from 'video.js';
import './panorama';

import { download } from 'Core/data/Helpers';
import { Loading } from 'Components';
import { Icon, IconButton } from '@mui/material';

import 'video.js/dist/video-js.css';
import './videoPlayer.scss';
import './panorama.scss';

const videoPlayer = [];
const upsidedown = [];
let retryTimer = null;

//This component uses Video.js library, for more information please visit the Video.js documentation 
//and search about the player options
const VideoPlayer = forwardRef((props, ref) => {
  const refContainer = React.useRef();
  const [showRetryButton, setShowRetryButton] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  useImperativeHandle(ref, () => ({
    downloadVideo: downloadVideo,
    clearVideo: clearVideo,
    playVideo: playVideo
  }));

  React.useEffect(() => {
    if (videoPlayer[props.id]) {
      clearVideo();
    }
  }, []);

  React.useEffect(() => {
    return () => {
      clearVideo()
    };
  }, []);

  React.useEffect(() => {
    if (props.isUpsidedown != undefined && props.isUpsidedown != null) {
      upsidedown[props.id] = props.isUpsidedown;
    }
  }, [props.isUpsidedown]);

  React.useEffect(() => {
    if (props.url) {
      playVideo();
    }
  }, [props.url]);

  const clearVideo = useCallback(() => {
    if (videoPlayer[props.id]) {
      videoPlayer[props.id].dispose();
      videoPlayer[props.id] = null;
      if (refContainer?.current?.innerHTML) {
        refContainer.current.innerHTML = "";
      }
    }
  });

  const playVideo = useCallback(() => {
    setShowRetryButton(false);
    if (videoPlayer[props.id]) {
      videoPlayer[props.id].src({
        type: props.type || '',
        src: props.url
      });
    } else {
      let video = document.createElement('video');
      video.id = props.id;
      video.style.width = '100%';
      //video.style.minHeight = '240px';
      video.style.height = '100%';
      video.muted = 'muted';
      video.muted = true;
      video.preload = "auto";
      video.crossOrigin = "anonymous";
      video.className = "video-js vjs-default-skin vjs-static-controls vjs-big-play-centered";

      refContainer?.current?.append(video);

      let options = {
        controls: true,
        errorDisplay: false,
        autoplay: true,
        donwloadButton: true,
        controlBar: {
          volumePanel: {
            inline: false,
          },
          pictureInPictureToggle: false
        },
        preload: 'auto',
        playsinline: true,
        //aspectRatio: '16:10',
        responsive: true,
        techCanOverridePoster: true,
        //html5: {
        //  hls: {
        //    overrideNative: true
        //  },
        //  nativeVideoTracks: false,
        //  nativeAudioTracks: false,
        //  nativeTextTracks: false
        //},
        ...props
      }

      videoPlayer[props.id] = videojs(video, options, () => {
        const _this = videoPlayer[props.id];

        _this.on('playing', () => {
          setShowRetryButton(false);
          setMessage(null);
          clearTimeout(retryTimer);
        });

        _this.on('play', () => {
          setShowRetryButton(false);
          setMessage(null);
        });

        _this.on('end', () => {
          setShowRetryButton(false);
        });

        _this.on('pause', () => {
          //setShowRetryButton(false);
        });

        _this.on('dispose', () => {
          setShowRetryButton(false);
          setMessage(null);
        });

        _this.on('error', () => {
          setShowRetryButton(true);
          setMessage(null);
          clearTimeout(retryTimer);
        });

        _this?.tech_?.on("retryplaylist", () => {
          clearTimeout(retryTimer);
          clearVideo();
          setMessage(props.timeoutMessage);
          setShowRetryButton(true);
        });

        _this.on('waiting', (e) => {
          clearTimeout(retryTimer);
          retryTimer = setTimeout(() => {
            if (props.retryCallback) {
              clearVideo();
            }
            setShowRetryButton(true);
          }, 25000);
        });
      });

      if (props.videoType === "streaming") {
        addStopButton();
      }

      // TODO: disable download action for 360 videos temporary, while the API dewrapping is ready
      if (props.downloadable && !props.is360) {
        addDownloadButton();
      }

      if (props.is360) {
        add360Plugin();
        addRotateButton();
      }

      videoPlayer[props.id].src({
        type: props.type || '',
        src: props.url
      });
    }
  });

  const replayVideo = useCallback(() => {
    clearVideo();
    playVideo();
  });

  const addStopButton = useCallback(() => {
    let button = videojs.getComponent('Button');
    let closeButton = videojs.extend(button, {
      constructor: function () {
        button.apply(this, arguments);
        this.controlText("Stop");
        this.addClass('vjs-icon-stop');
      },
      handleClick: function () {
        if (props.stopCallback) {
          props.stopCallback();
        }
      }
    });
    videojs.registerComponent('closeButton', closeButton);
    videoPlayer[props.id].getChild('controlBar').addChild('closeButton', {}, 1);
  });

  const addDownloadButton = useCallback(() => {
    let button = videojs.getComponent('Button');
    let downloadButton = videojs.extend(button, {
      constructor: function () {
        button.apply(this, arguments);
        this.controlText("Download");
        this.addClass('vjs-icon-download');
      },
      handleClick: function () {
        setLoading(true);
        //download(props.url);
        downloadVideo();
        setLoading(false);
      }
    });
    videojs.registerComponent('downloadButton', downloadButton);
    videoPlayer[props.id].getChild('controlBar').addChild('downloadButton', {}, 12);
  });

  const addRotateButton = useCallback(() => {
    let button = videojs.getComponent('Button');
    let rotateButton = videojs.extend(button, {
      constructor: function () {
        button.apply(this, arguments);
        this.controlText("Rotate");
        this.addClass('vjs-icon-rotate');
      },
      handleClick: function () {
        upsidedown[props.id] = !upsidedown[props.id];
        replayVideo();
      }
    });
    videojs.registerComponent('rotateButton', rotateButton);
    videoPlayer[props.id].getChild('controlBar').addChild('rotateButton', {}, 14);
  });

  const add360Plugin = useCallback(() => {
    if (videoPlayer[props.id]) {

      videoPlayer[props.id].panorama({
        videoType: 'fisheye',
        maxLat: 90,
        minLat: 16,
        initLat: 15.5,
        initLon: 90,
        initFov: 70,
        clickAndDrag: true,
        scrollable: false,
        isUpsidedown: upsidedown[props.id],
        upsidedownToggle: upsidedown[props.id],
        NoticeMessage: props.notice360Message || 'Please use your mouse drag and drop the video',
        helperCanvas: {
          width: 1200,
          height: 1200,
        },
        callback: () => {
          let userAgentString = navigator.userAgent;
          let chromeAgent = userAgentString.indexOf("Chrome") > -1;
          let safariAgent = userAgentString.indexOf("Safari") > -1;
          if ((chromeAgent) && (safariAgent)) safariAgent = false;

          if (videoPlayer[props.id] && safariAgent) {
            videoPlayer[props.id].play();
          }
        },
      })
    }
  });

  const downloadVideo = useCallback(() => {
    if (props?.url) {
      download(props?.url, `${props.name || props.id}.mp4`);
    }
  });

  let maxHeight = "100%";
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    maxHeight = "250px";
  }

  return (
    <div className="video-player">
      <div ref={refContainer} style={{ width: '100%', height: '100%', maxHeight }}></div>
      <div className="video-player-retry-container">
        {
          loading
          &&
          <Loading />
        }

        {
          showRetryButton
          &&
          <div>
            <div className="video-player-retry-message">
              {
                message || props.errorMessage || "The media could not be loaded, either because the server or network failed, please try again."
              }
            </div>

            <IconButton
              aria-label="play"
              style={{ color: 'white' }}
              onClick={(e) => {
                e.preventDefault();
                if (props.retryCallback) {
                  props.retryCallback();
                } else {
                  playVideo(props);
                }
              }}
              size="large">
              <Icon style={{ fontSize: 35, color: "white" }}>replay</Icon>
            </IconButton>
          </div>
        }
      </div>

    </div>
  );
});

export default VideoPlayer;

VideoPlayer.propTypes = {
  id: PropTypes.string.isRequired,
  downloadable: PropTypes.bool,
  errorMessage: PropTypes.string,
};