//View Keys
export default {
  permissions_title: "Permisos",
  permissions_dashboard: "Tablero",
  permissions_behavior: "Comportamiento",
  permissions_maintenance: "Mantenimiento",
  permissions_vehicleStatus: "Estado del vehículo",
  permissions_vehicleStatusGeneral: "General",
  permissions_vehicleStatusGeneral_unitSettings: "Configuración de la unidad",
  permissions_vehicleStatusGeneral_unitOutputs: "Salidas unitarias",
  permissions_vehicleStatusGeneral_ping: "Ping",
  permissions_vehicleStatusApuControl: "APU Control",
  permissions_vehicleStatusApuControl_starter: "Activar / Desactivar arrancador",
  permissions_vehicleStatusUnitSpecificAlerts: "Alertas específicas de la unidad",
  permissions_maps: "Mapas",
  permissions_mapsAllUserMaps: "Todos los usuarios tienen acceso a",
  permissions_mapsGeneral: "General",
  permissions_mapsSetting: "Configuración del mapa",
  permissions_mapsSendSMS: "Enviar SMS",
  permissions_mapsTrails: "Caminos",
  permissions_mapsVehicleDirections: "Direcciones del vehículo",
  permissions_mapsNearestVehicleLandmarkEquipment: "Vehículo / Punto de referencia / Equipo más cercano",
  permissions_mapsGeofence: "Geofence",
  permissions_mapsRoutes: "Rutas",
  permissions_mapsLandmarks: "Puntos de referencia",
  permissions_reports: "Reportes",
  permissions_reportsGroupReports: "Reportes de grupo",
  permissions_reportsDriverReports: "Reportes del conductor",
  permissions_reportsFSM: "Reportes FSM",
  permissions_reportsExport: "Exportar Reportes",
  permissions_reportsAdvancedSummary: "Resumen avanzado",
  permissions_reportsDetailed: "Detallado",
  permissions_reportsMileage: "Kilometraje",
  permissions_reportsMileageSummary: "Resumen de kilometraje",
  permissions_reportsSimple: "Sencillo",
  permissions_reportsSpeed: "Velocidad",
  permissions_reportsSummary: "Resumen",
  permissions_reportsDriverSafety: "Seguridad del conductor",
  permissions_reportsDriverScoring: "Puntuación del conductor",
  permissions_reportsEvent: "Evento",
  permissions_reportsIdle: "Inactivo",
  permissions_reportsIgnition: "Encendido",
  permissions_reportsInput: "Entrada",
  permissions_reportsMediaEvents: "Eventos de medios",
  permissions_reportsMoving: "En movimiento",
  permissions_reportsStartStopIdle: "Inicio Parada Inactivo",
  permissions_reportsStop: "Detenerse",
  permissions_reportsTimesheet: "Hoja de horas",
  permissions_reportsGeofence: "Geofence",
  permissions_reportsGeofenceBased: "Basado en Geofence",
  permissions_reportsLandmark: "Punto de referencia",
  permissions_reportsLandmarkBased: "Basado en puntos de referencia",
  permissions_reportsLandmarkSummary: "Resumen de hitos",
  permissions_reportslatlong: "lat/long",
  permissions_reportsStateMiles: "Millas estatales",
  permissions_reportsAlertLog: "Alert Log",
  permissions_reportsDriverTimesheet: "Registro de alertas",
  permissions_reportsLandmarkCrew: "Hito de tripulación",
  permissions_reportsLocation: "Localización",
  permissions_reportsNonReporting: "No Reportar",
  permissions_reportsOdometer: "Odometro",
  permissions_reportsPing: "Ping",
  permissions_reportsTemperature: "Temperatura",
  permissions_reportsUnitInfo: "Información de la unidad",
  permissions_reportsFleetSummary: "Resumen de flota",
  permissions_settings: "Ajustes",
  permissions_settingsLandmarkUpload: "Subida de puntos de referencia",
  permissions_settingsOffHour: "Fuera de hora",
  permissions_settingsScheduledReports: "Informes programados",
  permissions_settingsDataExports: "Exportaciones de datos",
  permissions_settingsXML: "XML",
  permissions_settingsKML: "KML",
  permissions_vehicleManagement: "Administrar",
  permissions_vehicleManagementGroupSetup: "Configuración de grupo",
  permissions_vehicleManagementDriverSetup: "Configuración del controlador",
  permissions_vehicleManagementVehicleStatusLabels: "Etiquetas de estado del vehículo",
  permissions_vehicleManagementVehicleLandmarks: "Puntos de referencia de vehículos",
  permissions_vehicleManagementCrewManagement: "Puntos de referencia de vehículos",
  permissions_vehicleManagementVehicleLocations: "Ubicaciones de vehículos",
  permissions_vehicleManagementGeofenceVsIconChange: "Geofence vs. Cambio de íconos",
  permissions_vehicleManagementUnitSettings: "Configuración de la unidad de gestión",
  permissions_vehicleManagementGeofence: "Geofence de gestión",
  permissions_vehicleManagementVehicleEquipment: "Equipamiento del vehículo",
  permissions_fsm: "FSM",
  permissions_fsmTwoWayCommunication: "Comunicación bidireccional",
  permissions_fsmDriverDriverCommunication: "Comunicación conductor-conductor",
  permissions_fsmRouteManager: "Gerente de ruta",
  permissions_fsmSettings: "Ajustes",
  permissions_account: "Cuenta",
  permissions_accountContacts: "Contactos",
  permissions_accountSubUsers: "Sub Usuarios",
  permissions_accountLoginHistory: "Historial de inicio de sesión",
  permissions_fsmformGroup: "Grupo de formularios FSM",
  permissions_help: "Centro de ayuda",
  permissions_mobile: "Móvil",
  permissions_mobileFleetManagerMobile: "Gestor de flota móvil",
  permissions_mediaEvents: "Eventos de medios",
  permissions_mediaEventsGeneralMedia: "Medios generales",
  permissions_mobileFetchLiveVideo: "Obtener video en vivo",
  permissions_mobileFetchImagesVideo: "Obtener imágenes y video",
  permissions_mobileMediaDownload: "Descarga de medios",
  permissions_mobileMediaShare: "Compartir medios",
  permissions_mobileReceivePanicButtonAlerts: "Recibir alertas de botón de pánico",
  permissions_mobileMediaMediaReview: "Revisión de medios",
  permissions_mobileMediaPerformReview: "Realizar revisión",
  permissions_mobileManagement: "Gestión",
  permissions_mobileManageCameras: "Administrar cámaras",
  permissions_mobileManageReviewChecklist: "Gestionar la lista de verificación de revisión",
  permissions_mobileManageMediaAlerts: "Administrar alertas de medios",
  permissions_marketplace: "Mercado",
  permissions_fleet: "Estado", //Rename temporaly
  permissions_feedback: "Retroalimentación",
  permissions_allowAccessTo: "Permitir el acceso a",
  permissions_allowAccessToLegacy: "Permitir el acceso a la version de legado",
  permissions_globalAccess: "Acceso global",
  permissions_localAccess: "Acceso local",
  permissions_alerts: "Alertas",
  permissions_general: "General",
  permissions_basic: "Basico",
  permissions_back: "Atrás",
  permissions_view: "Ver",
  permissions_add: "Agregar",
  permissions_event: "Evento",
  permissions_location: "Localización",
  permissions_other: "Otro",
  permissions_custom: "Personalizado",
  permissions_edit: "Editar",
  permissions_apiKeys: "API Keys",
  permissions_video: "Video",
  permissions_promiles: "ProMiles",
  permissions_eld: "ELD",
  permissions_utilization: "Utilización",
  permissions_allow: 'Permitir'
};