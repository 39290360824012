// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";
// Action Types
import {
    SETTINGS_CAMERA_MANAGE_SEARCH
} from "Redux/actionTypes";

// Actions
import {
    searchSettingsCameraManageResponse
} from "Redux/actions";

// Helpers
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* searchCameraManageRequest() {
    yield takeEvery(SETTINGS_CAMERA_MANAGE_SEARCH, function* ({ payload }) {
        try {
        
            let data = yield call(
                clientQuery,
                `
                    query searchCameraManage ($openSearch: String, $limit: Int!, $offset: Int) {
                        items: searchCameraManage (openSearch: $openSearch, limit: $limit, offset: $offset) {
                            items {
                                id
                                recorderId
                                swDeviceId
                                tspSerialNumber
                                unitId
                                unit {
                                    label
                                }
                            }
                            total
                        }
                    }
                `,
                {
                    ...payload
                },
                endpoints.GRAPHQL_GENERAL
            );

            yield put(searchSettingsCameraManageResponse(data.items || []))

        } catch (exc) {
            ExceptionManager(exc, 'modules/settings/views/cameraMnage/redux/saga', 'searchCameraManageRequest');
            yield put(searchSettingsCameraManageResponse({ total: 0, items: [] }));
        }
    });
}




export default function* rootSaga() {
    yield all([
        fork(searchCameraManageRequest)
    ]);
}