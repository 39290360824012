import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import _ from 'lodash';

//Utils
import { download, getFormattedDateByTimeZone } from 'Core/data/Helpers';
// Styles
import './vidFleet.scss';

// Components
import { VideoPlayer, Image360, LinearProgress } from 'Components';
import { Icon, IconButton } from '@mui/material';

// Actions
import {
  startStreaming,
  startLivePhoto,
  stopStreaming,
  getStreamingStatus,
  getLivePhotoStatus,
  getClipById,
  getPictureById,
  getStreamingStatusResponse,
  clearRedux
} from 'Redux/actions';
import { ModalImg } from './.components/modalImg';
import moment from 'moment';

let vidFleetVideoRetryTimer = null;

const getStatus = (state) => state.vidFleetVideoRedux.cameraStatusResponse
const getStatusLoading = (state) => state.vidFleetVideoRedux.loadingCameraStatus

const cameraOnlineStatusServer = (id) => {
  return createSelector(
    [getStatus, getStatusLoading],
    (status, loading) => {
      return [loading[id], status[id]]
    }
  )
}

const getPicture = (state) => state.vidFleetVideoRedux.pictureResponse
const getPictureLoading = (state) => state.vidFleetVideoRedux.loadingPicture

const pictureServer = (pictureId) => {
  return createSelector(
    [getPicture, getPictureLoading],
    (pictures, loading) => {
      return [loading[pictureId], pictures[pictureId]]
    }
  )
}

const getVideo = (state) => state.vidFleetVideoRedux.response
const getVideoLoading = (state) => state.vidFleetVideoRedux.loading

const videoServer = (id) => {
  return createSelector(
    [getVideo, getVideoLoading],
    (video, loading) => {
      return [loading[id], video[id]]
    }
  )
}

//This component uses Video.js library, for more information please visit the Video.js documentation 
//and search about the player options
const VidFleetVideoComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  const videoPlayerRef = useRef();

  const {
    id,
    type,
    unitId,
    mode,
    autoplay,
    messages,
    deviceModel,
    dewrapImage,
    thumbnailUrl,
    pictureId,
    disabled,
    simProviderName,
    vehicleEvent,
    timeZone,
    name
  } = props;

  const [url, setUrl] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [playingVideo, setPlayingVideo] = useState(null);
  const [showPlayButton, setShowPlayButton] = useState(true);
  const [showLoading, setShowLoading] = useState(null);
  const [message, setMessage] = useState("");
  const [openImg, setOpenImg] = useState(false)

  const [loadingCameraStatus, cameraStatus] = useSelector(cameraOnlineStatusServer(id), (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps)
  });

  const [loadingPicture, pictureResponse] = useSelector(pictureServer(pictureId), (prevProps, nextProps) => {
    return thumbnail || _.isEqual(prevProps, nextProps)
  });

  const loadings = useSelector(state => state.vidFleetVideoRedux.loading);
  //const user = useSelector(state => state.securityRedux.user);

  const [loading, response] = useSelector(videoServer(id), (prevProps, nextProps) => {
    return url || _.isEqual(prevProps, nextProps)
  });

  useImperativeHandle(ref, () => ({
    downloadVideo: downloadVideo,
    downloadPicture: downloadPicture
  }));

  React.useEffect(() => {
    return () => {
      dispatch(clearRedux('VIDFLEET_VIDEO'));
    };
  }, []);

  useEffect(() => {
    if (id && mode === "video") {
      if (autoplay && type === "streaming" || type === "photo") {
        if (vehicleEvent &&
          //(vehicleEvent === "Ignition Off" || vehicleEvent === "Parked" || vehicleEvent === "Park") && 
          (simProviderName === "USC" || simProviderName === "US Cellular") &&
          cameraStatus
        ) {
          if (cameraStatus?.lastConnectTime < cameraStatus?.lastDisconnectTime) {
            setMessage(messages ? messages['vidFleet_unableToLiveStream'] : "The vehicle is currently offline - unable to live stream");
            setShowPlayButton(false)
          } else {
            startStopLiveStreaming('start');
          }
        } else {
          startStopLiveStreaming('start');
        }
      } else if (type === "playback") {
        if(props?.url && props?.thumbnailUrl){
          setMessage('');
          setUrl(props.url);
          setThumbnail(props.thumbnailUrl);
  
          if (autoplay) {
            setPlayingVideo(true);
            setShowPlayButton(false);
          } else {
            setShowPlayButton(true);
          }
  
          setShowLoading(false); 
        } else {
          dispatch(getClipById(id));
        }
      }
    } else if (mode === "picture") {
      setShowPlayButton(false);
    }
  }, [id, autoplay, type, mode, simProviderName, cameraStatus, vehicleEvent]);

  useEffect(() => {
    if (!thumbnail && thumbnailUrl) {
      setThumbnail(thumbnailUrl);
    } else if (!thumbnail && pictureId) {
      //if (!pictureResponse[pictureId] || pictureResponse[pictureId].expiredTime < Date.now()) {
      dispatch(getPictureById(pictureId));
      //}
    }
  }, [thumbnailUrl, pictureId]);

  useEffect(() => {
    if (!thumbnail && pictureResponse) {
      if (pictureResponse.error) {
        setThumbnail(null);
      } else {
        setThumbnail(pictureResponse.picUrl);
      }
    }
  }, [pictureResponse]);

  useEffect(() => {
    if (response) {
      setShowPlayButton(false);
      setPlayingVideo(false);
      setShowLoading(true);
      clearTimeout(vidFleetVideoRetryTimer);

      //Handle an error with the request to VidFleet API
      if (response?.error) {
        setMessage(messages ? messages[response?.message || 'vidFleet_errorApi'] : "Error");
        setShowPlayButton(true);
        setPlayingVideo(false);
        setShowLoading(false);
      } else {
        //The video is a streaming|live son has multiples states
        if (type === "streaming" || type === "photo") {
          //The camera is in live streaming
          if (response?.status === "live") {
            setMessage('');
            setUrl(type === "photo" ? response?.url: response?.playUrl);
            setPlayingVideo(true);
            setShowPlayButton(false);
            setShowLoading(false);

            if (type === "photo") {
              vidFleetVideoRetryTimer = setTimeout(() => {
                setUrl(null);
                setShowLoading(false);
                setShowPlayButton(true);
                setPlayingVideo(false);
                setMessage('');
              }, 40000);
            }
          }
          else if (response?.status === "uploading") {
            setMessage(messages ? messages['vidFleet_uploading'] : "Loading");
            setPlayingVideo(false);
            if (type === "photo") {
              vidFleetVideoRetryTimer = setTimeout(() => {
                setUrl(null);
                setShowLoading(false);
                setShowPlayButton(true);
                setPlayingVideo(false);
                setMessage('');
              }, 61000);
            }
          }
          //We need to wait until the camera wake up
          else if (response?.status === 'waitForPublish' || response?.status === 'waitForAwake') {
            setMessage(messages ? messages['vidFleet_wakingCamera'] : "Loading");
            setPlayingVideo(false);
            //setOpenImg(false);
            vidFleetVideoRetryTimer = setTimeout(() => {
              if (type === "photo") {
                dispatch(getLivePhotoStatus({ esn: id, unitId, transactionId: response?.transactionId }));
              } else {
                dispatch(getStreamingStatus({ esn: id, unitId }));
              }
            }, 4000);
          }
          //We need to wait until the camera stops to stream
          else if (response?.status === 'waitForStop') {
            setMessage(messages ? messages['vidFleet_stopingCamera'] : "Loading");
            setUrl(null);
            setPlayingVideo(false);
            vidFleetVideoRetryTimer = setTimeout(() => {
              dispatch(getStreamingStatus({ esn: id, unitId }));
            }, 4000);
          }
          //The streaming is off
          else if (response?.status === 'stopped' || response?.status === 'timeout') {
            setUrl(null);
            setShowLoading(false);
            setShowPlayButton(true);
            setPlayingVideo(false);
            setMessage('');
            setOpenImg(false);
          }
          //The camera is offline
          else if (response?.status === 'offline') {
            setMessage(messages ? messages['vidFleet_cameraOffline'] : "Loading");
            setShowLoading(false);
            setPlayingVideo(false);
            setShowPlayButton(true);
            setOpenImg(false);
          }
          else if (response?.status === 'firmwareNotSupport') {
            setMessage(messages ? messages['vidFleet_firmwareNotSupport'] : "Loading");
            setShowLoading(false);
            setPlayingVideo(false);
            setShowPlayButton(true);
            setOpenImg(false);
          }
        }
        //The video playback has not states
        else if (type === "playback") {
          setMessage('');
          setUrl(response?.mp4Url);
          setThumbnail(response?.thumbnailUrl);

          if (autoplay) {
            setPlayingVideo(true);
            setShowPlayButton(false);
          } else {
            setShowPlayButton(true);
          }

          setShowLoading(false);
        }
      }
    }
  }, [response, autoplay]);

  const startStopLiveStreaming = useCallback((action) => {
    if (action === "start") {
      if (type === "photo") {
        dispatch(startLivePhoto({ esn: id, unitId }));
      } else {
        dispatch(startStreaming({ esn: id, unitId }));
      }
      
    } else if (action === "stop") {
      if (type === "photo") {

      } else {
        dispatch(stopStreaming({ esn: id, unitId }));
      }
    }
  });

  let isUpsidedown = false;
  if (response?.rotate === "upsidedown" || pictureResponse?.rotate === "upsidedown") {
    isUpsidedown = true;
  }

  const renderThumbnail = useMemo(() => {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {
          deviceModel === "360" && dewrapImage
            ?
            <Image360
              imageUrl={thumbnail}
              id={id || pictureId}
              isUpsidedown={isUpsidedown}
            />
            :
            thumbnail && <img src={thumbnail} style={{ width: '100%', height: '100%' }} />
        }
      </div>
    )
  });

  let options = {
    autoplay: true,
    controls: true,
    url: url,
    is360: (deviceModel === '360' ? true : false),
    downloadable: (type === "streaming" ? false : true),
    videoType: type,
    notice360Message: messages ? messages['vidFleet_360Message'] : "Error",
    errorMessage: messages ? messages['vidFleet_retryMessage'] : "Error",
    timeoutMessage: messages ? messages['vidFleet_timeout'] : "Error",
    id: id,
    bigPlayButton: false,
    name: name || id
  };

  if (type === "streaming") {
    options.stopCallback = () => {
      dispatch(stopStreaming({ esn: id, unitId }));
      if (videoPlayerRef) videoPlayerRef?.current?.clearVideo();
    };
    options.retryCallback = () => {
      setUrl(null);
      setPlayingVideo(false);
      if (videoPlayerRef) videoPlayerRef?.current?.clearVideo();
      let res = {};
      res[`${id}`] = null;
      dispatch(getStreamingStatusResponse(res));
      dispatch(startStreaming({ esn: id, simProviderName, unitId }));
    };
  }

  const setLoading = (newLoading) => {
    let ld = { ...loadings };
    ld[id] = newLoading || false;
    dispatch(clearRedux('VIDFLEET_VIDEO', { loading: ld }));
  }

  //functions
  const downloadVideo = useCallback(() => {
    if (url) {
      setLoading(true);
      download(url, `${name || id}.mp4`, setLoading);
    }
  });

  const downloadPicture = async () => {
    if (thumbnail) {
      try {
        await fetch(thumbnail)
          .then(resp => resp.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${name || id}.jpg`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
      } catch (e) {
        return window.open(thumbnail, '_blank')
      }
      //download(thumbnail);
      //download(thumbnail, `${name || id}.jpg`, setLoading);
    }
  };

  return (
    <div className="vidFleet-container" style={{ backgroundColor: disabled ? 'white' : 'black' }}>
      {
        mode === "picture" && <>{renderThumbnail}</>
      }

      {
        mode === "video" &&
        <>
          {
            playingVideo
            &&
            <>
              {
                type === "streaming" || type === "playback" ?
                <VideoPlayer isUpsidedown={isUpsidedown} ref={videoPlayerRef} {...options} /> :
                <div style={{ width: '100%', height: '100%', position: "relative" }} >
                  <img src={url} style={{ width: '100%', height: '100%' }} />
                  {
                    response?.time &&
                    <div style={{ position: 'absolute', bottom: 8, right: 8 }}>
                      <span style={{ color: "white", fontSize: 14 }}>
                        {getFormattedDateByTimeZone(response?.time, timeZone, messages, "HH:mm:ss")}
                      </span>
                    </div>
                  }
                </div>
              }
            </>
          }

          {
            (thumbnail && !playingVideo) && <>{renderThumbnail}</>
          }
        </>
      }

      <div className="vidFleet-actions-container">
        {
          (loading || showLoading || loadingPicture || loadingCameraStatus) &&
          <LinearProgress />
        }

        <div className="vidFleet-message">
          {message}
        </div>

        {
          (showPlayButton && !loading && !showLoading && !loadingPicture && !loadingCameraStatus) ?
            <div className="vidFleet-play-button">
              <IconButton
                aria-label="play"
                style={{ color: (thumbnail ? 'black' : 'white') }}
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  if (type === "streaming" || type === "photo") {
                    startStopLiveStreaming('start');
                  } else if (type === "playback") {
                    setPlayingVideo(true);
                    setShowPlayButton(false);
                  }
                }}
                size="large">
                <Icon className={'icon-vidFleet-play-replay'} style={{ fontSize: 50, color: (disabled ? 'rgba(255, 255, 255, 0.3)' : 'white') }}>
                  {(response && response && response.error) ? 'replay' : 'play_arrow'}
                </Icon>
              </IconButton>
            </div>
            :
            ((mode === 'picture' || type === "photo") && (!loading && !showLoading && !loadingPicture && !loadingCameraStatus)) &&
            <div className="vidFleet-play-button">
              <IconButton
                aria-label="search"
                style={{ color: (thumbnail ? 'black' : 'white') }}
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenImg(true)
                }}
                size="large">
                <Icon className={'icon-vidFleet-play-replay'} style={{ fontSize: 50, color: (disabled ? 'rgba(255, 255, 255, 0.3)' : 'white') }}>
                  search
                </Icon>
              </IconButton>
            </div>
        }
      </div>
      <ModalImg setOpenImg={setOpenImg} openImg={openImg} img={thumbnailUrl || thumbnail || url} />
    </div>
  );
});

export default VidFleetVideoComponent;

VidFleetVideoComponent.propTypes = {
  id: PropTypes.string, //This could be a ESN or Clip Id
  autoplay: PropTypes.bool, //If is true the video will be played automatically
  type: PropTypes.string.isRequired, //Depends of the playing type, the url VidFleet API changes
  type: PropTypes.oneOf(['streaming', 'playback', 'photo']),
  deviceModel: PropTypes.string.isRequired, //4k|360
  deviceModel: PropTypes.oneOf(['4K', '4k', '360']),
  mode: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['video', 'picture']), // This component could be use just for show images and dewrapping 360 images.
  thumbnailUrl: PropTypes.string, //An url for the thumbnail image of the video
  pictureId: PropTypes.string,
  disabled: PropTypes.bool, //If is true the user can not play the video
};