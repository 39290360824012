import React from 'react'
import { injectIntl } from 'react-intl';
/*********************************************************
 * Controls
 *********************************************************/
import { ToggleSwitch } from 'Components';

/*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    8 March 2021
 * Module:  Realtime Maps
 * 
 * Class associated to the Map Settings button check control into the 
 * realtime maps
 ********************************************************/
const MapSettingsButton = (props) => {
  const { intl } = props;
  const { messages } = intl;

  const { id, label, checked, optionLabels, mediuml, medium } = props

  /*********************************************************
  * Event when the user click on the button check
  *********************************************************/
  const onOptionChange = (id, checked) => {
    if(props.onChange){
      props.onChange(id, checked)
    }
  }

  return(
    <div>
      <div>
        <ToggleSwitch
            id={id}
            medium={!medium && !mediuml ? true : medium || false}
            mediuml={mediuml || false}
            mapToggle
            optionLabels={optionLabels || [messages['show'], messages['hide']]}
            checked={checked}
            onChange={(checked) => onOptionChange(id, checked)} />
        <label className={'labelMapSettings'}>
          {label}
        </label>
      </div>    
    </div>
  )

}

export default injectIntl(MapSettingsButton);