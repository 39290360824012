import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Grid} from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useSelector } from 'react-redux'

// controls
import InfiniteScrollTable from 'Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable'

//helpers
import {
  formatDateFromFilter,
  formatDatetime,
  getGroupId,
  getNameUnitDriverTag,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';
//hooks
import {useReportsSelector, useSidePanelMap} from 'Core/hooks';
import {GetFormatForMoment} from 'Core/data/Helpers';

//actions
import {reportsMainGenerateReportUpdate} from 'Redux/actions'

// styles
import './driverFormsReportV2.scss';

// International
import { useIntl } from "react-intl";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

const ROW_PER_PAGE = 25;

const parseJSON = (text) => {
  try {
    return JSON.parse(text);
  } catch (error) {
    return text;
  }
}

const buildTableHeader = (element, messages) => {
  const { user } = useSelector(state => state.securityRedux)
  const momentFormat = GetFormatForMoment(user.dateformat)
  const { timeZone } = user
  return (

    <Grid   container  direction={"column"} style={{marginBottom: "10px"}}>
      <Grid  container item  direction={"row"} className={""}>
        <Grid item className={"report-ctn-text"} sm={3}>
          <span>{messages['generatedReport_formName']}: {element.formName}</span>
        </Grid>
        <Grid item className={"report-ctn-text"} sm={5} style={{textAlign: "center"}}>
          <span>{messages['generatedReport_location']}: {element.address}</span>
        </Grid>
        <Grid item className={"report-ctn-text"} sm={4}>
          {messages['generatedReport_updated']}: {element.unitTime && formatDatetime(element.unitTime, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone)}
        </Grid>
      </Grid>
      <Grid  container item  direction={"row"} className={"table-scroll-reports-title-details"}>
        <Grid item sm={4} className={'reports-cut-text'}>
          {messages['generatedReport_question']}
        </Grid>
        <Grid item sm={8} className={'reports-cut-text'}>
          {messages["generatedReport_answer"]}
        </Grid>
      </Grid>
    </Grid>
  )
}
export const TableViewDetail = ({index, style, content, id, previousItem}) => {
  const { messages } = useIntl();
  const element = content?.recordDetail;

  return (
    <div
      className="listItem detailItem"
      style={style}
      id={id}
      key={element.unitTime}
    >
      {buildTableHeader(element, messages)}
      <Grid  key={new Date().getTime()} container direction={"column"} style={{marginBottom: "10px"}}>
        {
          element.noData ?
              <Grid item className={"report-ctn-text"} sm={12}>
                {messages["reportsMainRT_noDataEvent"]}
              </Grid>
              :
              renderAnswer(element, messages)
        }
      </Grid>
      <br/>
      <div className="table-scroll-reports-separator"></div>
      <br/>
      <br/>
    </div>
  )
}

const renderOptionsAnswer = (form, messages) => {
  const { user } = useSelector(state => state.securityRedux)
  const momentFormat = GetFormatForMoment(user.dateformat)
  const { timeZone } = user

  switch (form.type) {
    case 'Picture':
    case 'Signature':
      return (
          <>
            <div className="img-report-answer">
              <p id={'altText_' + form.value} style={{display: 'none'}}>Not Available</p>
              { form.status === 'error' ?
                  (<>
                    <p id={'altText_' + form.value}>Not Available</p>
                  </>) : (
                      <img id={form.value} src={form.value} alt="Not Found" className="img-report-answer"
                           onError={function mostrarAlt() {
                             form.status = 'error';
                             const img = document.getElementById(form.value)
                             if (img) {
                               img.style.display = 'none';
                               img.style.height = '0px';
                             }
                             const text = document.getElementById('altText_' + form.value)
                             if (text) {
                               text.style.display = 'block';
                             }
                           }}
                      />
                  )
              }
            </div>
          </>
      )
    case 'Date':
    case 'DateTime':
    case 'Time':
      return <span>{form.value && formatDatetime(form.value, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone)}</span>
    case 'Checkbox':
      return <span>{parseJSON(form.value)}</span>
    case 'Switch':
      return <span>{Boolean(form.value) ? messages['reportsMainRT_yes'] : messages['reportsMainRT_no']}</span>
    default:
      return <span>{form.value}</span>
  }
};

const renderAnswer = (element, messages) => {
  return element.formValues.map((data, indx) => (
          <>
            <Grid container item  direction={"row"} style={{paddingTop: "1px", paddingBottom: "1px"}} key={element.unitTime + data.type + indx}>
              <Grid item className={"report-ctn-text"} sm={4} key={new Date().getTime() + 'form_label_' + data.type + indx}>
                <span>{data.type}</span>
              </Grid>
              <Grid item className={"report-ctn-text"} sm={8} key={new Date().getTime() + 'form_value_ ' + data.type + indx}>
                {renderOptionsAnswer(data, messages)}
              </Grid>
            </Grid>
          </>
    ))
};
const DriverFormsReportV2 = (props) => {

  const { messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive } = props;
  const dispatch = useDispatch()
  const { loading, asyncLoading, data, filters, timeZone } = report
  const { filterEntity, filterArray, filterType } = filters;
  const exportHandler = useHandleExportReport(report)
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const [lastEntity, setLastEntity] = useState({});


  const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
  const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)
  const reportRef = useRef();
  const entitiesArrayRef = useRef();
  const detailReportTableRef = useRef();
  const lastEntityRef = useRef({});
  const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)
  const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity: null;
  const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected: null;
  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if(isActive){
        reportTableSelect.onChangeResetCache();
    }
  }, [isActive])

  const buildTableHeader = (element) => {
    return (

      <Grid   container  direction={"column"} style={{marginBottom: "10px"}}>
        <Grid  container item  direction={"row"} className={""}>
          <Grid item className={"report-ctn-text"} sm={3}>
            <span>{messages['generatedReport_formName']}: {element.formName}</span>
          </Grid>
          <Grid item className={"report-ctn-text"} sm={5} style={{textAlign: "center"}}>
            <span>{messages['generatedReport_location']}: {element.address}</span>
          </Grid>
          <Grid item className={"report-ctn-text"} sm={4}>
            {messages['generatedReport_updated']}: {element.unitTime && formatDatetime(element.unitTime, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone)}
          </Grid>
        </Grid>
        <Grid  container item  direction={"row"} className={"table-scroll-reports-title-details"}>
          <Grid item sm={4} className={'reports-cut-text'}>
            {messages['generatedReport_question']}
          </Grid>
          <Grid item sm={8} className={'reports-cut-text'}>
            {messages["generatedReport_answer"]}
          </Grid>
        </Grid>
      </Grid>
    )
  }

  const TableViewGroup = ({index, style, content, id, previousItem}) => {
    const element = content?.recordInfo;
    const recordInfo = content?.recordInfo;


    return (
      <div
        className="listItem groupItem"
        id={id}
        key={id}
        style={style}
      >

        <Grid container  direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group'>
            <Grid container item className='table-scroll-reports-header'>
                <Grid item sm={3}>

                    {
                        element?.tagId > 0 ?
                            (<LocalOfferIcon
                                style={{fontSize: "16.8px", color: `#${element.groupTagColor && element.groupTagColor != "ffffff" ? element.groupTagColor : "000"}`, position: 'absolute'}}/>)
                            : ""
                    }
                    <span className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding':''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)}`}</span>
                    {
                        report?.filters?.filterArray.length !== 1 && (<span  className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)
                    }
                </Grid>
                <Grid item sm={9}>
                  <span className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} ({getAbbvTimezone(timeZone)})</span>
                </Grid>
            </Grid>
          <div className='table-scroll-reports-separator'></div>
          <Grid item>
            <p className='table-scroll-reports-label'>{element?.entityLabel}</p>
          </Grid>
        </Grid>
      </div>
    )
  }





  const renderItems = (parameters) => {
    const {content} = parameters;
    const type = content.recordInfo?.recordType;
    if(type === "GROUP"){
      const groupId = getGroupId(content)
      const entitiesArray = entitiesArrayRef.current || {};
      if(!entitiesArray[groupId]){
          const newEntityArray = {...entitiesArray};
          newEntityArray[groupId] = content;
          entitiesArrayRef.current = newEntityArray
      }
      // skip the first group beacuse the first group is replaced with the sticky header
      if(parameters.index === 0){
        return;
      }

      return (
        <TableViewGroup {...parameters}></TableViewGroup>
      )
    } else if(type === "DETAIL"){
      return <TableViewDetail {...parameters}></TableViewDetail>;
    }

    if(parameters.index === 0){
      return;
    }

    return (
      <div>
        unknown type
      </div>
    )
  }

  const onStartIndex = (index) => {
    const item = data?.items[index];

    if(item) {
      if(item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId || item.recordInfo?.tagId !== lastEntityRef.current.recordInfo?.tagId){
        setLastEntity(item)
      }
      lastEntityRef.current = item
    }
  }

  const renderHeader = () => {
    const groupId = getGroupId(lastEntity);
    const entitiesArray = entitiesArrayRef.current || {};
    const itemHeader = entitiesArray[groupId];

    if(itemHeader){
      return (
        <TableViewGroup
          style={{
            backgroundColor: '#fff',
            position: 'sticky',
            top: 0,
            zIndex: 9

          }}
          content={itemHeader}
          id={itemHeader?.recordInfo?.entityId}
        ></TableViewGroup>
      );
    }
    return;

  };

  const findTag = filterArray?.findIndex((item) => item.name == "Tag")
  const hasTag = findTag >= 0;
  const type = hasTag ? messages['generatedReport_tag'] :
                  ( report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver'])
  return (
    <>
      <InfiniteScrollTable
        refContainer={detailReportTableRef}
        renderItems={renderItems}
        loading={asyncLoading || loading}
        data={data.items || []}
        total={data.total}
        reportTitle={messages['reportsMainRT_driverFormsReportV2']}
        editReport={()=> {
          copyReportToSetup({...report, data: []})
        }}
        onSchedule={() => {
          scheduleReport(report)
        }}
        onCreate={openReportSetup}
        tableContainerClass={"detailed-report-reports-container"}
        type={type}
        handleNewPageLoad={(page) => {
          let currentReport = {...report};
          currentReport.exportType = "json";
          currentReport.filters = {
            ...currentReport.filters,
            unitsSelected: unitDriverSelected?.id != -99 ? unitDriverSelected: null,
            accumulateData: true,
            dateSelected: dateSelected?.id != -99 ? unitDriverSelected: null,
          };
          dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
        }}
        renderHeader={renderHeader}
        onStartIndex={onStartIndex}
        isActive={isActive}
        {...reportTableSelect}
        {...exportHandler}
      />
      <SideMapComponent hiddenButton hiddenSearchMap  overlayClass={"overlay-class-reports"}/>
    </>
  );
};

export default DriverFormsReportV2;
