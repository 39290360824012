export default {
    exportVehicles_title: "Exportación Próximos mantenimientos",
    exportVehicles_upcoming: "Próximos mantenimientos",
    exportVehicles_fileFormat: "Formato de archivo",
    exportVehicles_dueIn: "Vencimiento En:",
    exportVehicles_exportPeriod: "Período de exportación",
    exportVehicles_days: "días",
    exportVehicles_ahead: "de adelanto",
    exportVehicles_left: "pendientes",
    exportVehicles_vehicledueexport: "Exportación de vehículos pendientes",
}