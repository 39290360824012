import React, { useState, useEffect } from "react";
import { Box, Slider, styled, Tooltip } from "@mui/material";
import moment from "moment";
import { useIntl } from "react-intl";
import { getRailEvents, getTimeZoneUnit } from "./helpers/createRailsEvents";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useSelector } from "react-redux";

const SliderStyled = styled(Slider)(({ railbg }) => ({
  color: "black",
  height: 0,
  padding: "8px 0",
  "& .MuiSlider-thumb": {
    display: "none", //size review
    height: 0,
    width: 0,
    background: "black",
    border: "1px solid currentColor",
    marginLeft: "2px",
    top: "-34px",
    "& .airbnb-bar": {
      height: 9,
      width: 1,
      marginLeft: 1,
      marginRight: 1,
    },
  },

  "& .MuiSlider-track": {
    border: "none",
    top: "-11px",
    height: "53px",
    borderTop: "4px solid black",
    borderBottom: "4px solid black",
    borderRight: "15px solid black",
    borderLeft: "15px solid black",
    background: 'transparent',
    margin: '0px 0px 0px -15px'
    // // backgroundColor: "#c6f3ffa8",
    // background: "url('/assets/images/selector.png')",
    // backgroundRepeat: "no-repeat", //size review
  },

  "& .MuiSlider-rail": {
    width: "100%",
    color: "#ababab",
    borderRadius: 0,
    opacity: 1,
    height: "53px",
    bottom: "0px",
    background: `linear-gradient(90deg, ${railbg})`,
    top: "-70%"
  },

  "& .MuiSlider-markLabel": {
    color: "#000000",
    fontSize: "12px",
    fontFamily: "Time - Outfit",
    fontWeight: 500,
    top: "25px"
  },

  "& .MuiSlider-markActive": {
    opacity: 0.8,
    backgroundColor: "transparent",
  },

  "& .MuiSlider-mark": {
    height: "4px",
    width: "2px",
    background: "black",
    top: "12px",
  },
}));

const ButtonRigth = styled(Box)(({ disabled }) => ({
  background: disabled ? "#777272" : "#3F4046",
  cursor: disabled ? "no-drop" : "pointer",
  height: "53px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  margin: "-33px 2px 0px -1px",
  borderTopRightRadius: "5px",
  borderBottomRightRadius: "5px",
  color: "white",
  "&:hover": {
    background: disabled ? "#777272" : "#000000",
  },
}));

const ButtonLeft = styled(Box)(({ disabled }) => ({
  background: disabled ? "#777272" : "#3F4046",
  cursor: disabled ? "no-drop" : "pointer",
  height: "53px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  margin: "-33px -1px 0px 2px",
  borderTopLeftRadius: "5px",
  borderBottomLeftRadius: "5px",
  color: "white",
  "&:hover": {
    background: disabled ? "#777272" : "#000000",
  },
}));

const SliderRange = ({
  dateSelect,
  setSelectedTime,
  setStartDate,
  setEndDate,
  maxRange,
  minRange,
  intervalue,
  minDistance,
  maxDistance,
  loading,
  events = [],
  clearEvents,
  unit
}) => {
  const [value, setValue] = useState([0, 20]);
  const [marks, setMarks] = useState([]);
  const [disabled, setDisabled] = useState({ left: false, rigth: false });
  const date = moment(dateSelect);
  const max = maxRange;
  const min = minRange;
  const { messages } = useIntl();
  const unitsList = useSelector(state => state.catalogsRedux.unitsList);
  let timezoneUnit = getTimeZoneUnit(unitsList, unit)

  const railEvent = getRailEvents(events, date, timezoneUnit?.timeZone?.name);

  const valuetext = (value) => {
    return `${value}s`;
  };

  const handleChange = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    let leftValue = newValue[0];

    let rightValue = newValue[1];

    const prevLeft = value[0];

    const range = rightValue - leftValue;

    const active = leftValue !== prevLeft ? 0 : 1;

    if (active === 0) {
      if (range >= maxDistance) {
        rightValue = leftValue + maxDistance;
      }

      if (range <= minDistance) {
        leftValue = rightValue - minDistance;
      }
    } else {
      if (range >= maxDistance) {
        leftValue = rightValue - maxDistance;
      }

      if (range <= minDistance) {
        rightValue = leftValue + minDistance;
      }
    }

    const start = moment(dateSelect).add(leftValue, "seconds");

    const end = moment(dateSelect).add(rightValue, "seconds");

    setStartDate(start.format());

    setEndDate(end.format());

    return setValue([leftValue, rightValue]);
  };

  const getMarksByDate = (max, intervalue, dt) => {
    const newMarks = [];
    const formatMomentDate = moment(dt);
    newMarks.push({
      id: formatMomentDate.format(),
      value: 0,
      label: String(formatMomentDate.format("hh:mm:ss")),
    });
    for (let i = intervalue; i <= max; i = i + intervalue) {
      const val = i;
      const newDate = formatMomentDate
        .add(intervalue, "seconds")
        .format("hh:mm:ss");
      newMarks.push({
        id: newDate,
        value: val,
        label: String(newDate),
      });
    }

    return newMarks;
  };

  useEffect(() => {
    if (loading) {
      return setDisabled({ rigth: true, left: true });
    }
    setDisabled({ ...disabled, rigth: false, left: false });
  }, [loading]);

  useEffect(() => {
    const newMark = getMarksByDate(max, intervalue, date);

    setMarks(newMark);

    handleChange(undefined, [0, 60]);
  }, [dateSelect]);

  const disabledMoveBtns = (initDate, affectedDate, operation) => {
    let diffExists = false;
    if (moment(initDate).day() !== moment(affectedDate).day()) {
      diffExists = true;
      if (operation === "subtract") {
        setDisabled({ ...disabled, left: true });
      } else {
        setDisabled({ ...disabled, rigth: true });
      }
    } else {
      setDisabled({ ...disabled, rigth: false, left: false });
    }
    return diffExists;
  };

  const onChangeDate = (operation) => {
    let dateOpe;
    if (operation === "subtract") {
      dateOpe = moment(date).subtract(5, "m");
    } else {
      dateOpe = moment(date).add(5, "m");
    }
    if (disabledMoveBtns(date, dateOpe, operation)) return;
    clearEvents([])
    setSelectedTime(new Date(dateOpe));
  };


  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        borderTop: "1px solid #f8f8f8",
        borderRadius: "10px",
      }}
    >
      {date ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ButtonLeft
            disabled={disabled.left}
            onClick={() => onChangeDate("subtract")}
          >
            <ArrowLeftIcon />
          </ButtonLeft>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            
            <Box sx={{ width: "100%", minWidth: "100%", marginTop: '30px'}}>
              <SliderStyled
                // aria-label="Custom marks"
                // ThumbComponent={ThumbComponent} //size review
                min={min}
                max={max}
                step={1}
                onChange={handleChange}
                value={value}
                valueLabelDisplay="off"
                getAriaValueText={valuetext}
                marks={marks}
                railbg={railEvent?.rail}
              />
              <Box style={{position: 'relative', top: '-60px', height: 0}}>
                {railEvent &&
                  railEvent?.eventData &&
                  railEvent.eventData?.map((itemIcon) => {
                    let pos = `${itemIcon.position}%`;
                    
                    return (
                      <Tooltip title={itemIcon.dataEvent.name} key={itemIcon.id}>
                        <span
                          style={{ 
                            left: pos, 
                            position: 'absolute', 
                            zIndex: 2,
                            Bottom: '-25px' 
                          }}
                        >
                          <img
                            src={itemIcon.dataEvent.icon}
                            alt="name"
                            style={{ width: 24, height: 24 }}
                          />
                        </span>
                      </Tooltip>
                    );
                })}
              </Box>
            </Box>
          </Box>
          <ButtonRigth
            disabled={disabled.rigth}
            onClick={() => onChangeDate("add")}
          >
            <ArrowRightIcon />
          </ButtonRigth>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          {messages["videoClipRequest_selectedDate"]}
        </div>
      )}
    </Box>
  );
};

export default SliderRange;
