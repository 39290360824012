import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// controls
import Table, { Column } from '../../table'
import {
  formatDatetime,
  getCategoryFilterType,
  useHandleExportReport,
  getEntityType
} from 'Modules/reports/views/main/components/reportsTab/helper';
import { useSidePanelMap, useTableReport } from 'Core/hooks';
import {
  reportsMainGenerateReportProgress,
  reportsMainGenerateEvents
} from 'Redux/actions'
import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark'

const InputReport = (props) => {
  const { messages, report } = props;
  const { data, filters, summary, loadingSummary, timeZone } = report;
  const { filterEntity, startRange, endRange } = filters;
  const [currentId, setCurrentId] = useState(null);
  const reportRef = useRef();
  const dispatch = useDispatch()
  const exportHandler = useHandleExportReport(report);
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const secondsToString = (seconds, formatText) => {
    let hour = Math.floor(seconds / 3600);
    hour = (hour < 10) ? hour : hour;
    let minute = Math.floor((seconds / 60) % 60);
    minute = (minute < 10) ? minute : minute;
    let second = seconds % 60;
    second = (second < 10) ? second : second;
    return formatText ?
      hour ?
        `${hour} Hour${hour > 1 ? 's' : ''}, ${minute} Minute${minute > 9 ? 's' : ''}, ${second} Second${second > 9 ? 's' : ''}` :
      minute ?
        `${minute} Minute${minute > 9 ? 's' : ''}, ${second} Second${second > 9 ? 's' : ''}` :
        `${second} Second${second > 9 ? 's' : ''}` :
      `${minute}.${second}`
  }

  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  const renderExpandableRowSubEvents = (row) => {
    return (
      <div style={{ marginBottom: 10 }}>
        <Table
          loading={loadingSummary}
          data={summary?.items || []}
          totalItems={summary?.total}
          onChangedPage={(page) => {
            let events = {
              filters: {
                filterType: getCategoryFilterType(filterEntity),
                filterArray: { id: currentId },
                page: page,
                rowsPerPage: 10,
                otherFilters: {
                  event: row?.event
                }
              },
            };
            dispatch(reportsMainGenerateEvents(report, events));
          }}
          noDataMessage={messages["reportsMainRT_noDataEvent"]}
          hideNumberOfRows={true}
          classNameColumns={"report-sub-events-columns"}
          centerPagination
          serverSideTable={true}
        >
         <Column label={messages['generatedReport_on']}>
            {(element) => <span>{formatDatetime(element.onDatetime, "h:mm A MM/DD/YYYY", timeZone)}</span>}
          </Column>
          <Column label={''} noSorting>
            {(element) => {
              return (
                <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{ address: element?.onAddress, latitude: element?.onLatitude, longitude: element?.onLongitude }}/>
              )
            }}
          </Column>
          <Column label={messages['generatedReport_off']}>
          {(element) => <span>{formatDatetime(element.offDatetime, "h:mm A MM/DD/YYYY", timeZone)}</span>}
          </Column>
          <Column label={''} noSorting>
            {(element) => {
              return (
                <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{ address: element?.offAddress, latitude: element?.offLatitude, longitude: element?.offLongitude }}/>
              )
            }}
          </Column>
          <Column label={messages['generatedReport_totalTime']}>
            {(element) => <span>{secondsToString(element.duration, true)}</span>}
          </Column>
        </Table>
      </div>
    );
  };

  let parametersTableSummary = useTableReport({
    data: [],
    report,
    messages,
    hideHeader: true,
    hiddenPagination: true,
    renderExpandableRow: renderExpandableRowSubEvents,
    showExpandable: true
  });

  const renderExpandableRow = (row) => {
    let parametersSummaryTable = {
      ...parametersTableSummary,
      data: row?.eventList || [],
      onChangeSelector: null,
      placeholderSelector: null,
      hideSelectorUnitsDriver: true,
      classNameColumns:"table-report-main-sub-table",
      noData: row.noData,
      idRenderExpandableRow: (event) => {
        let currentReport = report;
        delete currentReport.summary;
        dispatch(reportsMainGenerateReportProgress(currentReport));
        let events = {
          filters: {
            filterType: getCategoryFilterType(filterEntity),
            filterArray: { id: currentId },
            page: 1,
            rowsPerPage: 10,
            sortField: "eventDate",
            otherFilters: {
              event: event?.event
            }
          },
        };
        dispatch(reportsMainGenerateEvents(report, events));
      },
    };
    return (
      <Table {...parametersSummaryTable}>
        <Column label="unit" field="label">
          {(element) => {
            return (
              <span>
                <strong>{`${element.event} Count: ${element.count}`}</strong>
              </span>
            );
          }}
        </Column>
        <Column
          label={''}
          field="label"
        ></Column>
      </Table>
    )
  }

  const onChangeExpandable = (selected) => {
    if(!selected.noData){
      let selectedId = (filterEntity === "Unit" ? selected.deviceId : selected.driverId) || selected.id;
      setCurrentId(selectedId);
    }

  };

  let parametersTable = useTableReport({
    data: data.items,
    report,
    messages,
    hideHeader: true,
    renderExpandableRow,
    onChangeExpandable,
    propertyToShowExpandable: 'id',
    classNameColumns: 'start-stop-idle-main-table'
  });

  return (
    <Fragment>
      <Table
        {...parametersTable}
        {...exportHandler}
      >
        <Column label="" field="label">
          {(element) => {
            const type = getEntityType(report, messages)
            return <span><strong>{type}:  </strong>{`${element.idLabel || ''}`}</span>

          }}
        </Column>
        <Column label="" field="label">
          {(element) => {
            const localTimeStart = moment.utc(startRange).local().format();
            const localTimeEnd = moment.utc(endRange).local().format();
            return (
              <div>
                <span>
                  <span>
                    <strong>{messages['generatedReport_range']}:</strong> {` ${formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", timeZone)} - ${formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", timeZone)}`}
                  </span>
                </span>
              </div>
            );
          }}
        </Column>
      </Table>
      <SideMapComponent hiddenButton hiddenSearchMap />
    </Fragment>
  );
}

export default InputReport
