import crewTab from "Modules/settings/views/crew/lan/en";
import driverGroupsTab from "Modules/settings/views/driverGroups/lan/en";
import mainView from "Modules/settings/views/main/lan/en";
import inputLabelsView from "Modules/settings/views/inputLabels/lan/en";
import vehicleStatusView from "Modules/settings/views/vehicleStatus/lan/en";
import vehicleEquipmentView from "Modules/settings/views/vehicleEquipment/lan/en";
import locationsView from "Modules/settings/views/locations/lan/en";
import DriverView from "Modules/settings/views/driver/lan/en";
import KeyFobView from "Modules/settings/views/keyFob/lan/en";
import TemperatureView from "Modules/settings/views/temperature/lan/en";
import unitView from "Modules/settings/views/unitSettings/lan/en";
import fuelCardView from "Modules/settings/views/fuelCard/lan/en";
import CameraManage from "Modules/settings/views/cameraManage/lan/en";
import CameraManageEdit from "Modules/settings/views/cameraManage/components/edit/lan/en";
import editUnitSettings from "Modules/settings/views/unitSettings/components/editUnitSettings/lan/en";
import driverStatus from "Modules/settings/views/driverStatus/lan/en";
import driverStatusDelete from "Modules/settings/views/driverStatus/components/delete/lan/en";
import driverStatusCreateEdit from "Modules/settings/views/driverStatus/components/createEdit/lan/en";
import driverStatusLogList from "Modules/settings/views/driver/components/timeClock/lan/en";
import driverStatusLogCreateEdit from "Modules/settings/views/driver/components/timeClock/components/createEdit/lan/en";
import driverStatusLogDelete from "Modules/settings/views/driver/components/timeClock/components/delete/lan/en";

const locales = {
    ...crewTab,
    ...mainView,
    ...driverGroupsTab,
    ...inputLabelsView,
    ...vehicleEquipmentView,
    ...vehicleStatusView,
    ...locationsView,
    ...DriverView,
    ...KeyFobView,
    ...TemperatureView,
    ...unitView,
    ...fuelCardView,
    ...CameraManage,
    ...CameraManageEdit,
    ...editUnitSettings,
    ...driverStatus,
    ...driverStatusDelete,
    ...driverStatusCreateEdit,
    ...driverStatusLogList,
    ...driverStatusLogCreateEdit,
    ...driverStatusLogDelete
};
  
export default locales;