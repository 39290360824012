import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useIntl } from "react-intl";
import Form from "Components/Form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import {
  clipRequestGetUnits,
  createClipRequest,
  clearRedux,
  getShowTimeline,
  getSliderVehicleTrails,
  clipRequestOpenForm,
  setRequestClipData
} from "Redux/actions";
import SliderRange from "./SliderRange";
import SliderDatePicker from "./SliderDatePicker";
import { SubTitle, Title } from "./Titles";
import SliderUnit from "./SliderUnit";
import RangeLabelTime from "./RangeLabelTime";
import SliderStockRecords from "./SliderStockRecords";
import SliderPanelActions from "./SliderPanelActions";
import { NotificationManager } from "Components/Notifications";
import { getPairFromRange, getTimeZoneUnit } from "./helpers/createRailsEvents";
import "./style.scss";
import SliderRangeTime from "./SliderRangeTime";
import { GetFormatForMoment } from "Core/data/Helpers";
import { Loading } from 'Components';
import { HasPermission } from "Core/security"


import {
  dateTimeZoneToUTC
} from 'Core/data/Helpers';
import { calculateTimeDifference } from "./helpers/toolsDates";
const SliderRangePanel = () => {
  const initialState = {
    deviceId: "",
    dateRange: { start: "", end: "" },
    gpsId: ''
  };

  const [currentClip, setCurrentClip] = useState(initialState);
  const [selectedTime, setSelectedTime] = useState(new Date());
  const [unit, setUnit] = useState();
  const [exitDate, setExistDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [trailsEvents, setTrailsEvents] = useState([]);
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const clipRequestForm = useRef();
  const {
    units,
    requestClipResponse,
    loadingUnits,
    requestClipData,
    availableClips,
  } = useSelector((state) => state.clipsRedux);
  const { vehicleTrails, loadVehicleTrails } = useSelector(
    (state) => state.SliderRangeVideoRedux
  );
  const { user } = useSelector((state) => state.securityRedux);

  const { dateformat, timeZone } = user;
  const format = GetFormatForMoment(dateformat);
  const unitsList = useSelector(state => state.catalogsRedux.unitsList);

  const { currentTab } = useSelector((state) => state.videoMainRedux);

  useEffect(() => {

    if (requestClipData) {
      const { dateRange, deviceId, label, dateTime } = requestClipData;
      if (dateTime) {
        setSelectedTime(new Date(dateTime));
      }
      setUnit({ deviceName: label });
      setCurrentClip({ deviceId, deviceName: label });
      setStartDate(dateRange.start);
      setEndDate(dateRange.end);
      dispatchVehicleTrails({
        initialTime: dateTime,
        deviceId
      })

    }
  }, []);

  const getFormatedRange = (timeZoneUnit) => {
    const formatedRange = {
      start: dateTimeZoneToUTC(startDate, timeZoneUnit),
      end: dateTimeZoneToUTC(endDate, timeZoneUnit)
    };
    return formatedRange;
  };

  const validateRanges = (avClips, ranges, timeZoneUnit) => {
    const startClip = moment.tz(ranges.start, timeZoneUnit);
    const endClip = moment.tz(ranges.end, timeZoneUnit);
    let result = false;

    avClips.find((item) => {
      const formatItem = JSON.parse(item);
      const startAvClip = moment.tz(formatItem[0], timeZoneUnit);
      const endAvClip = moment.tz(formatItem[1], timeZoneUnit);
      if (
        startClip.isBetween(startAvClip, endAvClip) ||
        endClip.isBetween(startAvClip, endAvClip) ||
        startAvClip.isBetween(startClip, endClip) ||
        endAvClip.isBetween(startClip, endClip)
      ) {
        return (result = true);
      }
    });
    return Boolean(result);
  };

  const handleOnValidSubmit = () => {
    if (!availableClips[0]?.validTimeRangeSeq || !availableClips[0]) return;
    const { deviceId, gpsId } = requestClipData;
    let timezoneUnit = getTimeZoneUnit(unitsList, deviceId)
    const formattedDate = getFormatedRange(timezoneUnit?.linkedUnit?.timeZone?.name);

    const dataToSave = {
      //deviceId: currentClip.deviceId,
      deviceId: gpsId,
      dateRange: formattedDate,
    };

    const res = validateRanges(
      availableClips[0].validTimeRangeSeq,
      formattedDate,
      timezoneUnit?.linkedUnit?.timeZone?.name
    );

    if (res) {
      dispatch(
        createClipRequest({
          ...dataToSave,
          hideTableLoading: true,
          resetSearch: true,
          hideNotification: true,
        })
      );

      dispatch(clipRequestOpenForm(false));

      if (unit) {
        dispatch(getShowTimeline(true));
      }
    } else {
      NotificationManager.warning(
        messages["videoMainView_selectorWarning"],
        messages["videoClipRequest_Alert"],
        4000,
        null,
        null,
        "info",
        null
      );
    }
  };

  //timezone method
  useEffect(() => {
    if (vehicleTrails?.items?.length > 0 && !loadVehicleTrails && availableClips?.length > 0 && availableClips[0]?.validTimeRangeSeq.length > 0) {
      const { deviceId } = currentClip;
      let timezoneUnit = getTimeZoneUnit(unitsList, deviceId)
      const initialTime = moment(selectedTime)
      const avClips = getPairFromRange(
        availableClips[0]?.validTimeRangeSeq,
        initialTime,
        timezoneUnit?.linkedUnit?.timeZone?.name
      );


      const formatEvents = [];
      if (avClips.length > 0) {
        const orderedItems = vehicleTrails.items.reverse();
        avClips.forEach(({ startClip, endClip }) => {

          const timezoneStart = new Date(startClip).toLocaleString('en', { timeZone: timezoneUnit?.linkedUnit?.timeZone?.name })
          const timezoneEnd = new Date(endClip).toLocaleString('en', { timeZone: timezoneUnit?.linkedUnit?.timeZone?.name })

          const mStart = moment(timezoneStart);
          const mEnd = moment(timezoneEnd);

          orderedItems.forEach(({ unitTime, eventCode, eventName }) => {
            const itemDateUtc = new Date(unitTime).toLocaleString('en', { timeZone: timezoneUnit?.linkedUnit?.timeZone?.name}) 
            const itemDate =  moment(itemDateUtc)
            if (itemDate.isBefore(initialTime)) {
              formatEvents.push(
                {
                  unitTime: mStart,
                  eventCode: "NO_DATA",
                  eventName: "NO_DATA"
                }, {
                unitTime: mEnd,
                eventCode: eventCode,
                eventName: eventName
              }
              );
            } else if (itemDate.isBetween(mStart, mEnd)) {
              if (avClips.length !== 1) {
                formatEvents.push({
                  unitTime: mStart,
                  eventCode: "NO_DATA",
                  eventName: "NO_DATA"
                });
              }

              formatEvents.push({
                unitTime: itemDate,
                eventCode: eventCode,
                eventName: eventName

              });
            }
          });
          formatEvents.push({
            unitTime: mEnd,
            eventCode: "NO_DATA",
            eventName: "NO_DATA",
          });
        });


        setTrailsEvents(formatEvents);
      } else {
        setTrailsEvents([]);
      }
    } else {
      setTrailsEvents([]);
    }
  }, [vehicleTrails]);

  const getAvClipsRange = useCallback(
    (initialTime) => {
      if (
        currentClip?.deviceId &&
        availableClips &&
        Array.isArray(availableClips) &&
        availableClips[0]?.validTimeRangeSeq
      ) {
        const deviceId = currentClip?.deviceId
        dispatchVehicleTrails({
          initialTime,
          deviceId
        })
      }
    },
    [currentClip, availableClips]
  );

  const handleOnChangeTime = useCallback((time) => {
    setSelectedTime(time);
    const initialTime = moment(time);
    getAvClipsRange(initialTime);
  }, [getAvClipsRange]);


  //VehicleTrails with unit timezone
  const dispatchVehicleTrails = ({
    initialTime,
    deviceId
  }) => {
    let timezoneUnit = getTimeZoneUnit(unitsList, deviceId)
    const diffUtcHours = calculateTimeDifference(timezoneUnit?.linkedUnit?.timeZone?.name)
    const formatDate = moment(initialTime).add(diffUtcHours, 'h')
    const endDate = moment(formatDate).add(5, "minutes").format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    const paramToGetTrails = {
      unitId: deviceId,
      event: "0",
      startDate: moment(formatDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      endDate: endDate,
      limit: 100,
      offset: 0,
      withRecentEvent: true,
    };
    dispatch(getSliderVehicleTrails(paramToGetTrails));
  }

  return (
    <Grid>
      <Title />
      <SubTitle />
      <Form ref={clipRequestForm}>
        <Box
          style={{
            display: "flex",
            width: "100%",
            marginTop: "30px",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              width: "95%",
              display: "flex",
              alignItems: "baseline",
              justifyContent:
                availableClips.length > 0 ? "space-between" : "flex-start",
              paddingBottom: "34px",
            }}
          >
            <Box display="flex" style={{ cursor: "pointer" }}>
              <SliderUnit
                setCurrentClip={setCurrentClip}
                currentClip={currentClip}
                unitsList={unitsList}
                units={units}
              />
            </Box>

            {availableClips.length > 0 ? (
              <>
                <Box>
                  <SliderDatePicker
                    date={moment(selectedTime).format(format)}
                    onApply={(item) => { handleOnChangeTime(item) }}
                    selectedTime={selectedTime}
                    setSelectedTime={(item) => { handleOnChangeTime(item) }}
                    availableClips={availableClips}
                    requestClipData={requestClipData}
                  />
                </Box>

                <Box>
                  <SliderRangeTime
                    date={moment(selectedTime).format("hh:mm:ss a")}
                    onApply={(item) => { handleOnChangeTime(item) }}
                    selectedTime={selectedTime}
                  />
                </Box>

                <Box>
                  <RangeLabelTime dateTo={endDate} dateFrom={startDate} />
                </Box>
              </>
            ) : (
              <Box color="red" ml="20px">
                {messages["videoClipRequest_noClips"]}
              </Box>
            )}
          </Box>

          {currentClip.deviceId ? (
            availableClips.length > 0 && (
              <SliderRange
                loading={loadVehicleTrails}
                dateSelect={selectedTime}
                setSelectedTime={(item) => { handleOnChangeTime(item) }}
                events={trailsEvents}
                clearEvents={setTrailsEvents}
                unit={currentClip.deviceId}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                minDistance={60}
                maxDistance={HasPermission(user, "extvidclip") ? 300 : 60}
                intervalue={30}
                maxRange={300}
                minRange={0}
              />
            )
          ) : (
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "30px 0 35px 0",
              }}
            >
              {messages["videoClipRequest_noUnitSelected"]}
            </Box>
          )}

          <Box
            style={{
              marginTop: "70px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "60%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <SliderStockRecords />
            </Box>
            <SliderPanelActions
              actionCancel={() => {
                dispatch(clipRequestOpenForm(false))
                dispatch(setRequestClipData(null));
              }}
              actionOk={() => handleOnValidSubmit()}
              isDisable={loadingUnits || loadVehicleTrails}
              avClips={availableClips.length === 0}
              actionLabel={messages["videoClipRequest_getClip"]}
              cancelLabel={messages["cancel"]}
            />

            {loadVehicleTrails && <Loading overlay show />}
          </Box>
        </Box>
      </Form>
    </Grid>
  );
};

export default SliderRangePanel;
