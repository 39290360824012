//imports
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

//Action types
/* import { MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP_COLOR } from 'Redux/actionTypes'; */

//actions
import { updateUnitGroupColorConfirmResult } from 'Redux/actions';


function* watchForgotUpdateUnitGroupColor() {
	/* yield takeEvery(MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP_COLOR, updateUnitGroupColor); */
}

//merge sagas
export default function* rootSaga() {
	yield all([fork(watchForgotUpdateUnitGroupColor)]);
}
