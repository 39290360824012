import React, { useState, useRef } from 'react';
import { injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { Tooltip } from 'Components';
/*********************************************************
 * Controls
 *********************************************************/
import Popover from '@mui/material/Popover';

/*********************************************************
 * Components
 *********************************************************/
import RadioGroup, { RadioCheck } from 'Components/RadioGroup'
import Preferences from 'Core/containers/Preferences'

/*********************************************************
 * Icons
 *********************************************************/
import MapIcon from '@mui/icons-material/Map';

/*********************************************************
 * Style
 *********************************************************/
import './style.scss';
import { useIntl } from 'react-intl';




/*********************************************************
 * Class associated to the legends markers in realtime maps
 ********************************************************/
const LayerToggle = (props) =>  {

    /********************************************************
     * Define const
     ********************************************************/
    const { authenticatedId } = props
    const { messages } = useIntl()
    const keyPreferences = "mapSettings"
    const preferencesRef = useRef()
    const [isLegendsOpened, setIsLegendsOpened] = useState() 
    const [mapType, setMapType] = useState('roadmap')
    const [preferences, setPreferences] = useState(null)

    const Layers = [
      { name: messages['Hybrid_layer'], id:'G_HYBRID_MAP', key: 'hybrid' },
      { name: messages['Map_layer'], id:'G_NORMAL_MAP', key: 'roadmap'},
      { name: messages['Satellite_layer'], id:'G_SATELLITE_MAP', key: 'satellite'},
      { name: messages['Terrain_layer'], id:'G_TERRAIN_MAP', key: 'terrain'},
    ];

    /********************************************************
     * Event to close legend component
     ********************************************************/
    const handleClose = () => {
        setIsLegendsOpened(null)
    };

    /********************************************************
     * Event to open legend component
     ********************************************************/
    const handleOpenLegend = (event) => {
        setIsLegendsOpened(event.currentTarget)
    }

    /********************************************************
     * Configure the maptype preferences by default
     ********************************************************/
    const loadPreferencesDefault = (preferences) => {
      if(preferences){
        let value = null
        Object.keys(preferences)?.forEach((key) => {
          if(key == 'mapType'){
            value = preferences[key];
          }
        })
        if(value){
           const layer = Layers.find((item) => item.id == value || item.key == value)
           value = layer ? layer.key : 'roadmap'
        }else{
          value =  'roadmap'
        }
        if(props.changeMapType){
          props.changeMapType(value)
        }
        setMapType(value)
      }
    }
    /********************************************************
    * Save the preferences
    ********************************************************/
    const savePreferences = (paramMapType) => {
      if (authenticatedId) {
        let preferencesToSave = {...preferences, mapType: paramMapType}
        if(preferencesToSave){
          setPreferences(preferencesToSave)
          preferencesRef?.current?.savePreference("User", keyPreferences, preferencesToSave)
        }
      } 
    }
    
    /********************************************************
     * Return the legend component
     ********************************************************/

    const open = Boolean(isLegendsOpened);
    const id = open ? 'simple-popover' : undefined;
    const styleButton = open ? { color: '#0098DC' } : {}
    return (
        <Preferences
            ref={preferencesRef}
            preferencesKey={keyPreferences}
            onInitialPreferences={(preferences) => {
              let valuePreference = preferences
              if(typeof preferences == 'string'){
                valuePreference = JSON.parse(preferences)
              }
              loadPreferencesDefault(valuePreference)
              setPreferences(valuePreference)
            }}
          >
            <Tooltip placement="left" title={messages['maputilities_layer']} >
              <button
                onClick={handleOpenLegend}
              >
                  <MapIcon style={styleButton}></MapIcon>
              </button>
            </Tooltip>

            <Popover
                id={id}
                open={open}
                anchorEl={isLegendsOpened}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <div className={'mapType-menu'}>
                    <RadioGroup 
                        id="rgCheck"
                        value={mapType}
                        onChange={(event) => 
                        {
                            setMapType(event) 
                            if(props.changeMapType){
                                props.changeMapType(event)
                            }
                            savePreferences(event)
                        }}
                        vertical>
                        {
                            Layers.map((item, key) => {
                                return(
                                    <RadioCheck key={key} className="blueCheck" id={item.id} value={item.key} label={item.name} size='md' />
                                )
                            })
                        }
                    </RadioGroup>    
                </div>
            </Popover>
        </Preferences>
    )
}

const mapStateToProps = ({ securityRedux }) => {
  const { authenticatedId } = securityRedux;
  return {
      authenticatedId
  };
}


export default injectIntl(
  connect(mapStateToProps, {})(LayerToggle),
);