import {
  //TYPES
  MAP_UTILITIES_VEHICLE_LIST_SET,
  MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS,
  MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS_RESPONSE,
  MAP_UTILITIES_VEHICLE_LIST_REFRESH,
  MAP_UTILITIES_VEHICLE_LIST_GET_UNIT,
  MAP_UTILITIES_VEHICLE_LIST_GET_UNIT_RESPONSE,
  MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT,
  MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT_RESPONSE
} from 'Redux/actionTypes';


export const setHiddenGroups = (hiddenGroupIds, value) => {
  return {
      type: MAP_UTILITIES_VEHICLE_LIST_SET,
      payload: { hiddenGroupIds: hiddenGroupIds, visible: value }
  };
}

export const getUnitsVehicleList = (value = '') => {
    return {
        type: MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS,
        payload: value
    }
}
    
export const getUnitsVehicleListResponse = (data, error = null) => {
    return {
        type: MAP_UTILITIES_VEHICLE_LIST_SEARCH_UNITS_RESPONSE,
        payload: { data, error }
    }
}

export const refreshDataVehicleList = (refresh = false) => {
    return {
        type: MAP_UTILITIES_VEHICLE_LIST_REFRESH,
        payload: { refresh }
    }
}

export const getUnitVehicleList = (idVehicle) => {
    return {
        type: MAP_UTILITIES_VEHICLE_LIST_GET_UNIT,
        payload: { idVehicle }
    }
}

export const getUnitVehicleListResponse = (data, error = null) => {
    return {
        type: MAP_UTILITIES_VEHICLE_LIST_GET_UNIT_RESPONSE,
        payload: { data, error }
    }
}

export const getTagsVehicleList = () => {
    return {
        type: MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT,
        payload: { entityName: "Unit" }
    }
}

export const getTagsVehicleListResponse = (data, error = null) => {
    return {
        type: MAP_UTILITIES_VEHICLE_LIST_GET_TAG_UNIT_RESPONSE,
        payload: { data, error }
    }
}