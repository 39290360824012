// Action Types
import {
  VIDFLEET_VIDEO_START_STREAMING,
  VIDFLEET_VIDEO_START_STREAMING_RESPONSE,
  VIDFLEET_VIDEO_START_LIVE_PHOTO,
  VIDFLEET_VIDEO_START_LIVE_PHOTO_RESPONSE,
  VIDFLEET_VIDEO_STOP_STREAMING,
  VIDFLEET_VIDEO_STOP_STREAMING_RESPONSE,
  VIDFLEET_VIDEO_GET_STREAMING_STATUS,
  VIDFLEET_VIDEO_GET_STREAMING_STATUS_RESPONSE,
  VIDFLEET_VIDEO_GET_CLIP_BY_ID,
  VIDFLEET_VIDEO_GET_CLIP_BY_ID_RESPONSE,
  VIDFLEET_VIDEO_GET_PICTURE_BY_ID,
  VIDFLEET_VIDEO_GET_PICTURE_BY_ID_RESPONSE,
  VIDFLEET_VIDEO_GET_ONLINE_STATUS,
  VIDFLEET_VIDEO_GET_ONLINE_STATUS_RESPONSE,
  VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS,
  VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS_RESPONSE,
  VIDFLEET_VIDEO_LIVE_PHOTO_RESPONSE,
  CLEAR_REDUX
} from 'Redux/actionTypes';

const INIT_STATE = {
  loading: [],
  response: [],
  pictureResponse: [],
  loadingPicture: [],
  loadingCameraStatus: [],
  cameraStatusResponse: []
};

export default (state = INIT_STATE, action) => {
  let ld = {};
  switch (action.type) {
    case VIDFLEET_VIDEO_START_STREAMING:
    case VIDFLEET_VIDEO_START_LIVE_PHOTO:
    case VIDFLEET_VIDEO_STOP_STREAMING:
    case VIDFLEET_VIDEO_GET_STREAMING_STATUS:
    case VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS:
    case VIDFLEET_VIDEO_GET_CLIP_BY_ID:
      ld[action.payload.data] = true;
      return { ...state, payload: action.payload.data, loading: ld };

    case VIDFLEET_VIDEO_START_STREAMING_RESPONSE:
    case VIDFLEET_VIDEO_START_LIVE_PHOTO_RESPONSE:
    case VIDFLEET_VIDEO_STOP_STREAMING_RESPONSE:
    case VIDFLEET_VIDEO_GET_STREAMING_STATUS_RESPONSE:
    case VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS_RESPONSE:
    case VIDFLEET_VIDEO_GET_CLIP_BY_ID_RESPONSE:
      ld[action.payload.data] = false;
      return { ...state, payload: {}, response: { ...state.response, ...action.payload.data }, loading: ld };

    case VIDFLEET_VIDEO_LIVE_PHOTO_RESPONSE:
      ld[action.payload.data] = false;
      return { ...state, payload: {}, response: { ...state.response, ...action.payload.data }, loading: ld };

    case VIDFLEET_VIDEO_GET_PICTURE_BY_ID:
      ld[action.payload.data] = true;
      return { ...state, payload: action.payload.data, loadingPicture: ld };

    case VIDFLEET_VIDEO_GET_PICTURE_BY_ID_RESPONSE:
      ld[action.payload.id] = false;
      return { ...state, payload: {}, pictureResponse: { ...state.pictureResponse, ...action.payload.data }, loadingPicture: ld };

    case VIDFLEET_VIDEO_GET_ONLINE_STATUS:
      ld[action.payload.data] = true;
      return { ...state, payload: action.payload.data, loadingCameraStatus: ld };

    case VIDFLEET_VIDEO_GET_ONLINE_STATUS_RESPONSE:
      ld[action.payload.id] = false;
      return { ...state, payload: {}, cameraStatusResponse: { ...state.cameraStatusResponse, ...action.payload.data }, loadingCameraStatus: ld };
  

    case CLEAR_REDUX:
      return ["", "VIDFLEET_VIDEO"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
}
