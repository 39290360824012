import { put, call } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';
// Actions
import {
  reportsMainGenerateReportProgress
} from 'Redux/actions';

import {
  getTimeZone
} from 'Modules/reports/views/main/components/reportsTab/helper';

function* getUnitInfoSummaryReport(report, event) {
  const { timeZone, name, reportTimeRanges } = report;
  const { startRange, endRange } = report.filters;
  const {
    filterType,
    filterArray,
    page,
    rowsPerPage,
    sortField,
    sortOrder,
    otherFilters
  } = event.filters;
  try {
      const newReport = JSON.parse(JSON.stringify(report))
      let data = yield call(
        clientQuery,
        `query getUnitInfoDetailsReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
          getUnitInfoDetailsReport (conditions: $conditions, limit: $limit, offset: $offset) {
              items {
                  id
                  type
                  serial
                  garminSerialNumber
                  driverName
                  equipment
                  timezone
                  vin
                  unitTime
                  odometer
                  engineHours
                  eventName
                  label
              }
              total
              limit
              offset
              category
          }
      }`,
        {
          "conditions": {
            reportName: name,
            category: {
              field: filterType,
              entities: [
                {
                  id: filterArray?.groupId,
                  type: "Tag",
                  parentId: null
                }
              ]
            },
            timeZone: getTimeZone(timeZone),
            dateRange: {
              fields: [
                "unitTime"
              ],
              GTE: startRange,
              LTE: endRange,
              reportTimeRanges
            },
            sortField: {
              field: sortField || "deviceId",
              order: sortOrder === 'desc' ? 'DESC' : 'ASC'
            },
            stringValues: {
              values: report?.filters.filterArray.map((filter) => `${filter.id}-${filter.name}`) || []
            },
          },
          limit: rowsPerPage,
          offset: page
        },
        endpoints.GRAPHQL_PX_REPORTING
      );

      if (data?.getUnitInfoDetailsReport) {
        newReport.summary = data.getUnitInfoDetailsReport
        newReport.loadingSummary = false
        yield put(reportsMainGenerateReportProgress(newReport))
      } else {
        newReport.loadingSummary = false
        yield put(reportsMainGenerateReportProgress(newReport))
      }
    } catch (exc) {
      report.loadingSummary = false
      ExceptionManager(exc, 'src/modules/reports/views/main/components/reportsTab/.components/reports/stopReport', 'getUnitInfoSummaryReport');
      yield put(reportsMainGenerateReportProgress(report))
    }
  }


export default getUnitInfoSummaryReport;
