import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    MAPS_ETA_LINK_GET
} from 'Redux/actionTypes';

import {
    mapsEtaLinkPublicGetResponse
} from 'Redux/actions';

function* searchPublicEtaLink({ payload }) {
    try {
        const { code } = payload;
        const response = yield call(
            clientQuery,
            `
            query getPublicEtaLink ($code: String!) {
                etaLinkData: getPublicEtaLink (code: $code) {
                    id
                    code
                    unit {
                        label
                        driverName
                        lastReading {
                            latitude
                            longitude
                        }
                    }
                    unitId
                    status
                    address
                    latitude
                    longitude
                    isKph
                }
            }`,
            {code: code},
            endpoints.GRAPHQL_GENERAL,
            {
                'x-api-key': endpoints.PUBLIC_API_KEY
            }
        );

        /********************************************* 
         * Return types of error in eta
         * *******************************************
            OK: No error
            NO_DATA: No find information
            LOAD_FAILED: failed to send petition
        **********************************************/

        if (response && response.etaLinkData) {
            
            if(response?.etaLinkData?.unit?.lastReading)
                yield put(mapsEtaLinkPublicGetResponse(response.etaLinkData, "OK"));
        } else {
            yield put(mapsEtaLinkPublicGetResponse(null, "NO_DATA"));
            //throw 'Error while getting public eta link';
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, 'Core/views/eta/redux', 'searchPublicEtaLink');
        yield put(mapsEtaLinkPublicGetResponse(null, "LOAD_FAILED"));
    }
}

function* watchMaspEtaLinkRequests() {
    yield takeEvery(MAPS_ETA_LINK_GET, searchPublicEtaLink);
}

export default function* rootSaga() {
    yield all([
        fork(watchMaspEtaLinkRequests)
    ]);
}