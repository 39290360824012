//imports
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams, Routes, Route, Navigate } from "react-router-dom";

//controls
import { Loading } from "Components";
import { importExternalScript } from "Core/hooks";
import { ValidateVersion, GerVersionFromServer } from "Core/security";
import { ErrorHandlerLogin } from 'Core/data/Helpers';
import SalesForceApi from 'Core/salesForceApi';
//sass  432
import "./login.scss";

//actions
import {
  loginSM,
  logout,
  clearRedux,
  getResellerInfo,
  getLoginPage,
  termsAndCondition,
} from "Redux/actions";

import ContentLogin from "./components/ContentLogin";
import FormLogin from "./components/FormLogin";
import TermsAndCondition from "./components/TermsAndCondition";

const LoginView = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [newLoginPage, setNewLoginPage] = useState(false);
  const [credentials, setCredentials] = useState({});
  const [termsAndConditionPage, setTermsAndConditionPage] = useState(false);

  importExternalScript("https://gjw3kv7rh8bd.statuspage.io/embed/script.js");
  const lang = useSelector(state => state.commomRedux.language);

  const { loadingLoginPage, loginPage, error, termAndConditionData } = useSelector(
    (state) => state.loginRedux
  );
  // reseller config
  const { current_reseller, reseller_was_validated } = useSelector(
    (state) => state.resellerRedux
  );
  // if reseller is comming by parameter
  let { reseller } = useParams();

  useEffect(() => {
    return () => {
      // clear login redux
      dispatch(clearRedux("LOGIN"));
      setNewLoginPage(false);
      GerVersionFromServer(() => {
        ValidateVersion("login");
      });
    };
  }, []);

  useEffect(() => {
    if (error && ErrorHandlerLogin(error) == 'login_invalid_terms_condition') {
      setTermsAndConditionPage(true)
    }
  }, [error]);

  useEffect(() => {
    if (termAndConditionData?.username) {
      setCredentials({ username: termAndConditionData?.username, password: termAndConditionData?.password })
    }
  }, [termAndConditionData]);

  useEffect(() => {
    let language = lang === 'en' ? 'EN' : 'ES'
    setNewLoginPage(true)
    if (!current_reseller && !loginPage && !newLoginPage) {
      dispatch(getLoginPage(`${language}-gpstrackit.htm`, false, language, navigate));
    }
    if (newLoginPage && !current_reseller) {
      dispatch(getLoginPage(`${language}-gpstrackit.htm`, false, language, navigate));
    }
  }, [lang]);

  useEffect(() => {
    if (reseller) {
      //// checking reseller by key and by name in the url
      dispatch(getResellerInfo("key", reseller));
    } else if (!current_reseller) {
      // check if the current domain corresponds to a reseller
      const hostname = window.location.hostname;
      dispatch(getResellerInfo("domain", hostname));
    }
  }, [reseller]);

  const callback = (username, password) => {
    setCredentials({ username, password })
  }

  const sendTermsAndCondition = () => {
    dispatch(termsAndCondition(credentials?.username, credentials.password, navigate));
  }

  const handleClose = () => {
    navigate('/')
    setTermsAndConditionPage(false)
  }

  return (
    <>
      {loadingLoginPage ? (
        <div className="loading-area" style={{ height: "100%" }}>
          <Loading full />
        </div>
      ) : (
        <ContentLogin
          loginPage={loginPage}
          current_reseller={current_reseller}
          reseller_was_validated={reseller_was_validated}
        >
          {termsAndConditionPage &&
            <TermsAndCondition sendTermsAndCondition={sendTermsAndCondition} callback={handleClose} />
          }

          {!termsAndConditionPage &&
            <FormLogin
              navigate={navigate}
              current_reseller={current_reseller}
              reseller_was_validated={reseller_was_validated}
              callback={callback}
            />
          }
          {
            !current_reseller && reseller_was_validated &&
            <SalesForceApi/>
          }
        </ContentLogin>
      )}
    </>
  );
};

const LoginSM = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch();
  let { id } = useParams();


  useEffect(() => {
    dispatch(loginSM(id, navigate));
  }, [])

  return (
    <div className="loading-area" style={{ height: "100%" }}>
      <Loading full />
    </div>
  )
}

const LoginPreview = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const lang = useSelector(state => state.commomRedux.language);
  let { id } = useParams();

  let language = lang === 'en' ? 'EN' : 'ES'

  useEffect(() => {
    dispatch(getLoginPage(`${id}.htm`, true, language, navigate));
  }, [])

  return (
    <div className="loading-area" style={{ height: "100%" }}>
      <Loading full />
    </div>
  )
}

const LoginRouter = () => {

  return (
    <Routes>
      <Route path='sm/:id' element={<LoginSM />} />
      <Route path='preview/:id' element={<LoginPreview />} />
      <Route path="/" element={<LoginView />} />
      <Route path="*" element={<Navigate to="/error" />} />
    </Routes>
  )
}

export default LoginRouter;
