//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
/* import { NotificationManager } from 'Components/Notifications'; */
import axios from 'axios';

//Action types
import { REALTIME_MAP_GET_KMLS, REALTIME_MAP_UPDATE_KMLS, REALTIME_MAP_GET_OFFLINE_DEVICES } from 'Redux/actionTypes';

//actions
import { getKmlsConfirmResult, 
    getMapOfflineDevicesResult, 
    updateKmlsConfirmResult, 
    getKmls,
    getJsonLandmarkConfirmResult,
    getJsonGeofenceConfirmResult
 } from 'Redux/actions';

//Function

function* getJsonRequest() {
	try {
		let data = yield call(
			clientQuery,
			`query getJsonMap{
                getJsonMap {
                    entityName
                    jsonPath {
                        urls
                    }
                    generatedAt
                    changedAt
                }
            }`,
			{ },
			endpoints.GRAPHQL_GENERAL,
		);
		if (data && data.getJsonMap){
            //remove progress bar.
            if(data.getJsonMap.length > 0){
                if(!data.getJsonMap.find((item) => item.type == 'Geofence'))
                    yield put(getJsonGeofenceConfirmResult([], null))

                if(!data.getJsonMap.find((item) => item.type == 'Landmark'))
                    yield put(getJsonLandmarkConfirmResult([], null))

                yield* yield all(
                    data.getJsonMap.map(function*(item) {
                        const nameFile = item.jsonPath.urls[0];
                        
                        const pathFile = `https://s3.amazonaws.com/${endpoints.REPOSITORY_CODE}`
                        const url = `${pathFile}/${nameFile}`;
                        
                        let dataRead = yield call(async () => {
                            const res = axios.get(url).then(dataMap => {
                                if(dataMap.data)
                                    return { type: item.entityName, data: dataMap?.data };
                                return null
                            });
                            return res;
                        });

                        if(dataRead){
                            if(dataRead.type == "Geofence")
                                yield put(getJsonGeofenceConfirmResult(dataRead.data, null))
                
                            if(dataRead.type == "Landmark")
                                yield put(getJsonLandmarkConfirmResult(dataRead.data, null))
                        }
                    })
                )
                yield put(getKmlsConfirmResult(data.getJsonMap));
            }else{
                yield put(getJsonGeofenceConfirmResult([], null))
                yield put(getJsonLandmarkConfirmResult([], null))
            }
        }
		else {
            yield put(getJsonGeofenceConfirmResult([], null))
            yield put(getJsonLandmarkConfirmResult([], null))
            throw 'Not Data JSonFiles';
        }
	} catch (e) {
		ExceptionManager(e, 'modules/realtimeMaps/views/redux/saga', 'getKmlsConfirmResult');
        yield put(getJsonGeofenceConfirmResult([], null))
        yield put(getJsonLandmarkConfirmResult([], null))
		yield put(getKmlsConfirmResult(null));
		//NotificationManager.error(`Error Fetching the kmls items`, 'Error', 3000, null, null, 'error');
	}
}


//Function
function* updateJsonFilesRequest({ payload }) {
	try {
		let data = yield call(
			clientMutation,
			`mutation updateJsonMapFiles ($id: Int, $generatedFile: Int!, $entityName: JsonMapEntityName) {
                updateJsonMapFiles (id: $id, generatedFile: $generatedFile, entityName: $entityName) {
                    id
                    companyId
                    entityName
                    fileIndex
                    jsonPath
                    generatedFile
                    generatedAt
                    changedAt
                }
            }`,
			{ 
        generatedFile: 0
      },
			endpoints.GRAPHQL_GENERAL,
		);
		if (data && data.updateJsonMapFiles) {
			yield put(updateKmlsConfirmResult(data.updateJsonMapFiles));
            yield put(getKmls())
		} else {
			throw "Error clearing Landmark Group";
		}
	} catch (exc) {
		// ALWAYS RETURN ACTION
		ExceptionManager(exc, 'modules/realtimeMaps/views/redux/saga', 'updateJsonFilesRequest');
		yield put(updateKmlsConfirmResult(false, exc.errorData));
    //NotificationManager.error('Error', `Error Delete Landmark Group`, 3000, null, null, 'error');
	}
}

function* getOfflineDevicesRequest(){
  try {
		let data = yield call(
			clientQuery,
			`query OfflineDevices ($deviceCategory: enumDeviceCategory, $thresholdMinutes: Int) {
        getUnitsOffline (deviceCategory: $deviceCategory, thresholdMinutes: $thresholdMinutes ) {
              id
              label
              type
              driverId
              driverName
              deviceCategory
              lastReading {
                LastMessageTime
                serverTime
                unitTime
                address{
                  address
                }
              }
            }
        }`,
			{ },
			endpoints.GRAPHQL_GENERAL,
		);
		if (data && data.getUnitsOffline)
			yield put(getMapOfflineDevicesResult(data.getUnitsOffline));
		else throw 'Not Units Offline';
	} catch (e) {
		ExceptionManager(e, 'modules/realtimeMaps/views/redux/saga', 'getKmlsConfirmResult');
		yield put(getMapOfflineDevicesResult(null));
		//NotificationManager.error(`Error Fetching the getOfflineDevicesRequest`, 'Error', 3000, null, null, 'error');
	}
}

function* watchGetKmlsTasksRequest() {
	yield takeEvery(REALTIME_MAP_GET_KMLS, getJsonRequest);
  yield takeEvery(REALTIME_MAP_UPDATE_KMLS, updateJsonFilesRequest);
}

function* watchGetUnitsOfflineTasksRequest(){
  yield takeEvery(REALTIME_MAP_GET_OFFLINE_DEVICES, getOfflineDevicesRequest);
}

//Saga Export
export default function* rootSaga() {
	yield all([
		fork(watchGetKmlsTasksRequest),
    fork(watchGetUnitsOfflineTasksRequest)
	]);
}

