export default {
    exportVehicles_title: "Export Upcoming Maintenance",
    exportVehicles_upcoming: "Upcoming Maintenance",
    exportVehicles_fileFormat: "File Format",
    exportVehicles_dueIn: "Due In",
    exportVehicles_exportPeriod: "Export Period",
    exportVehicles_days: "days",
    exportVehicles_ahead: "ahead",
    exportVehicles_left: "left",
    exportVehicles_vehicledueexport: "Vehicles Due Export",
}