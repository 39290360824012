// Merge Views
import mainAlertsView from 'Modules/alerts/views/main/lan/en';
import alertsTabView from 'Modules/alerts/views/main/components//alertsTab/lan/en';
import wizardAlert from 'Modules/alerts/components/wizardAlert/lan/en';
import inboxTab from 'Modules/alerts/views/main/components/inboxTab/lan/en';

let locales = {};

locales = {
  ...locales,
  ...mainAlertsView,
  ...alertsTabView,
  ...wizardAlert,
  ...inboxTab
};

export default locales;
