import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';

import {
    MAPS_ETA_SHARE
} from 'Redux/actionTypes';

import {
    mapsEtaLinkShareResponse
} from 'Redux/actions';

function* createEtaLinkRequest({ payload }) {
    try {
        const { data } = payload;

        const response = yield call(
            clientMutation,
            `
            mutation createEtaLink (
                $unitId: Int!,
                $landmarkId: Int,
                $address: String!,
                $latitude: Float!,
                $longitude: Float!,
                $sharedWith: SharedWithInput!
                $eta: String
            ) {
                etaLink: createEtaLink(
                    unitId: $unitId, 
                    landmarkId: $landmarkId,
                    address: $address,
                    latitude: $latitude,
                    longitude: $longitude,
                    sharedWith: $sharedWith
                    eta: $eta
                ) {
                    id
                    code
                }
            }`,
            data,
            endpoints.GRAPHQL_GENERAL,
        );

        if (response && response.etaLink) {
            yield put(mapsEtaLinkShareResponse(response.etaLink, false));
        } else {
            throw 'Error while creating shared media';
        }
    } catch (exc) {
        // ALWAYS RETURN ACTION
        ExceptionManager(exc, 'modules/realtimeMaps/main/components/eta/redux/saga', 'createEtaLinkRequest');
        yield put(mapsEtaLinkShareResponse(null, true));
    }
}

function* watchMaspEtaRequests() {
    yield takeEvery(MAPS_ETA_SHARE, createEtaLinkRequest);
}

export default function* rootSaga() {
    yield all([
        fork(watchMaspEtaRequests)
    ]);
}