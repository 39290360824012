import { Button, Icons, Pill, Select as SelectGtc } from "@gpstrackit/gtc-react-controls";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Grid } from "@mui/material";
import colors from "Assets/sass/core/vars.scss";
import { useDispatch, useSelector } from "react-redux";
import { catalogLoad } from "Redux/actions";
import { Checkbox, Input, Select } from "Components";
import AlertsList from "./alertsList";
import SubscribersTypes from "./subscribersTypes";
import countries from './countries.json';

const Step2 = ({
  formData,
  setFormData,
  phoneContacts,
  setPhoneContacts,
  emailContacts,
  setEmailContacts,
}) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const [selectedSuscriber, setSelectedSuscriber] = useState("1");
  const [emailPhone, setEmailPhone] = useState("");
  const [errorEmailPhone, setErrorEmailPhone] = useState("");
  const [prefix, setPrefix] = useState(null)

  const { contacts, loadingContacts } = useSelector(
    (state) => state.catalogsRedux
  );

  useEffect(() => {
    dispatch(catalogLoad(["contacts"]));
  }, []);

  const savePhone = () => {
    const exist = phoneContacts?.find((item) => item?.title === `${prefix?.id}${emailPhone}`);
    if (exist) {
      return setErrorEmailPhone("incident_exist_phone");
    }
    if(!prefix){
      return setErrorEmailPhone("incident_carriet_obligatory");
    }

    const tempArr = phoneContacts;
    tempArr.push({
      type: 'sms',
      address: `${prefix?.id}${emailPhone}`,
    });
    setEmailPhone("");
    setErrorEmailPhone()
    setPrefix(null)
    setPhoneContacts(tempArr);
  };

  const saveEmail = () => {
    const exist = emailContacts?.find((item) => item?.title === emailPhone);
    if (exist) {
      return setErrorEmailPhone("incident_exist_phone");
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!emailRegex.test(emailPhone)){
      return setErrorEmailPhone("incident_wrong_email");
    }
    const tempArr = emailContacts;
    tempArr.push({
      type: 'email',
      address: emailPhone,
    });
    setEmailPhone("");
    setErrorEmailPhone()
    setEmailContacts(tempArr);
  };

  return (
    <div style={{ padding: "15px", textAlign: "start"}}>
      <AlertsList formData={formData} setFormData={setFormData} />
      <SubscribersTypes
        selectedSuscriber={selectedSuscriber}
        setSelectedSuscriber={setSelectedSuscriber}
        setEmailPhone={setEmailPhone}
        setErrorEmailPhone={setErrorEmailPhone}
      />
      {(selectedSuscriber === "1" || selectedSuscriber === "2") && (
        <div style={{ marginTop: "20px" }}>
          {selectedSuscriber === "1" ? (
            <Input
              id="address"
              type="email"
              label={messages["incident_email"]}
              placeholder={messages["incident_email"]}
              value={emailPhone}
              onChange={(e) => setEmailPhone(e)}
              email
              emailMessage={messages["accountContacts_emailMessage"]}
              maxLength={50}
              requiredMessage={messages["required_field"]}
            />
          ) : (
            <div style={{display: 'flex'}}>
              <div style={{width: '215px', marginRight: '5px'}}>
                <Select
                  id="type"
                  label={messages['incident_phone']}
                  placeholder="CO(+57)"
                  valueField="id"
                  textField="code"
                  getOptionLabel={(opt) => `${opt?.code} (+${opt?.id})`}
                  options={countries}
                  value={prefix}
                  onChange={(data) => {
                    setPrefix(data);
                  }}
                  isClearable
                />
              </div>
              <div style={{width: '400px', marginTop: '20px'}}>
                <Input
                  id="cellphone"
                  type="text"
                  placeholder={messages["incident_phone"]}
                  value={emailPhone}
                  onChange={(e) => setEmailPhone(e)}
                  regex={/^\d*$/}
                  maxLength={10}
                  maxLengthMessage={`${messages["accountContacts_maxLengthMessageCellPhone"]} 10`}
                  minLength={10}
                  minLengthMessage={`${messages["accountContacts_minLengthMessageCellPhone"]} 10`}
                  requiredMessage={messages["required_field"]}
                />
              </div>
            </div>
          )}
          {errorEmailPhone?.length > 0 && (
            <p style={{ color: "red", margin: 0 }}>{messages[errorEmailPhone]}</p>
          )}
          <Button
            style={{ width: "100%", marginTop: "20px", marginBottom: "5px" }}
            size="medium"
            isDisabled={
              selectedSuscriber === "1"
                ? emailPhone?.length > 10 && emailPhone?.length < 50
                  ? false
                  : true
                : emailPhone?.length === 10
                ? false
                : true
            }
            onClick={() => {
              selectedSuscriber === "1" ? saveEmail() : savePhone();
            }}
          >
            {messages["incident_add_recipient"]}
          </Button>
        </div>
      )}
      {selectedSuscriber === "3" && (
        <div style={{ marginTop: "20px" }}>
          <SelectGtc
            id="incidentsSuscribers"
            value={
              contacts.filter((contact) =>
                formData?.subscribers?.find((x) => x.contactId === contact.id)
              ) || []
            }
            onChange={(items = []) => {
              setFormData({
                ...formData,
                subscribers: items?.map((item) => {
                  return {
                    alert: true,
                    alertAfter: false,
                    contactId: item.id,
                    title: item?.title,
                  };
                }),
              });
            }}
            isSearchable
            options={contacts}
            textField="title"
            valueField="id"
            placeholder={messages["alertsNewAlert_selectPlaceholder"]}
            isClearable
            selectedOptionsBelow
            isMulti
            loading={loadingContacts}
            showSelection={false}
            isDisabled={formData?.subscribers?.find((x) => {
              if (x.contactId === -1) return true;
            })}
          />
          <div style={{ marginTop: "10px" }}>
            <Checkbox
              value={
                formData.subscribers?.find((x) => x.contactId === -1)
                  ? true
                  : false
              }
              onChange={(event) => {
                if (
                  formData?.subscribers?.find((item) => item?.contactId === -2)
                ) {
                  setFormData({
                    ...formData,
                    subscribers: event
                      ? [
                          {
                            alert: true,
                            alertAfter: false,
                            contactId: -1,
                            title: messages["incidents_all_contacts"],
                          },
                          {
                            alert: true,
                            alertAfter: false,
                            contactId: -2,
                            title: messages["incidents_current_driver"],
                          },
                        ]
                      : formData?.subscribers?.filter(
                          (x) => x.contactId !== -1
                        ),
                  });
                } else {
                  setFormData({
                    ...formData,
                    subscribers: event
                      ? [
                          {
                            alert: true,
                            alertAfter: false,
                            contactId: -1,
                            title: messages["incidents_all_contacts"],
                          },
                        ]
                      : formData?.subscribers?.filter(
                          (x) => x.contactId !== -1
                        ),
                  });
                }
              }}
              size="md"
              className="checkReschedule"
              id="allcontact"
              label={messages["alertsNewAlert_selectAllContactsLabel"]}
            />
            <div item style={{ marginBottom: 0 }}>
              <Checkbox
                value={
                  formData.subscribers?.find((x) => x.contactId === -2)
                    ? true
                    : false
                }
                onChange={(event) =>
                  setFormData({
                    ...formData,
                    subscribers: event
                      ? [
                          ...(formData?.subscribers || []),
                          {
                            alert: true,
                            alertAfter: false,
                            contactId: -2,
                            title: messages["incidents_current_driver"],
                          },
                        ]
                      : formData?.subscribers?.filter((x) => x.contactId != -2),
                  })
                }
                size="md"
                className="checkReschedule"
                id="currentDriver"
                label={messages["alertsNewAlert_currentDriver"]}
              />
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          height: "370px",
          overflowY: "auto",
        }}
      >
        <div style={{ marginTop: "10px" }}>
          {formData?.subscribers && formData?.subscribers?.length > 0 && (
            <p>{messages["incident_contacts"]}</p>
          )}
          {formData?.subscribers &&
            formData?.subscribers?.map((item) => {
              return (
                <Grid item sm={3} style={{ padding: 2 }} key={item?.id}>
                  <Pill
                    size="full"
                    item={{
                      label: item?.title,
                      color: colors["green"],
                      id: item?.contactId,
                    }}
                    outlined={false}
                    icon={<Icons name="close"></Icons>}
                    onClickIcon={(e) => {
                      const newValues = formData?.subscribers?.filter(
                        (item) => item?.contactId !== e?.id
                      );
                      setFormData({ ...formData, subscribers: newValues });
                    }}
                  />
                </Grid>
              );
            })}
        </div>
        <div style={{ marginTop: "10px" }}>
          {phoneContacts && phoneContacts?.length > 0 && (
            <p>{messages["incident_phone"]}</p>
          )}
          {phoneContacts &&
            phoneContacts?.map((item) => {
              return (
                <Grid item sm={3} style={{ padding: 2 }} key={item?.id}>
                  <Pill
                    size="full"
                    item={{
                      label: item?.address,
                      color: colors["green"],
                      id: item?.contactId,
                    }}
                    outlined={false}
                    icon={<Icons name="close"></Icons>}
                    onClickIcon={(e) => {
                      const newValues = phoneContacts?.filter(
                        (item) => item?.address !== e?.label
                      );
                      setPhoneContacts(newValues);
                    }}
                  />
                </Grid>
              );
            })}
        </div>
        <div style={{ marginTop: "10px" }}>
          {emailContacts && emailContacts?.length > 0 && (
            <p>{messages["incident_email"]}</p>
          )}
          {emailContacts &&
            emailContacts?.map((item) => {
              return (
                <Grid item sm={3} style={{ padding: 2 }} key={item?.id}>
                  <Pill
                    size="full"
                    item={{
                      label: item?.address,
                      color: colors["green"],
                      id: item?.contactId,
                    }}
                    outlined={false}
                    icon={<Icons name="close"></Icons>}
                    onClickIcon={(e) => {
                      const newValues = emailContacts?.filter(
                        (item) => item?.address !== e?.label
                      );
                      setEmailContacts(newValues);
                    }}
                  />
                </Grid>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Step2;
