//View Keys
export default {
  eta_subtitle: 'Generate a link that will allow others to see the ETA of a vehicle so they know when to expect the driver to arrive at their location.',
  eta_cancel: 'Cancel',
  eta_share: 'Share',
  eta_send: 'Send',
  eta_vehicle: "Vehicle",
  eta_choose_vehicle: "Choose Vehicle:",
  eta_destination: "Destination",
  eta_choose_destination: "Select Destination Using:",
  eta_recipient: "Recipients",
  eta_choose_contact: "Choose Contact Method:",
  eta_link_expire: "** Link will expire when the vehicle reaches the destination or after 8 hours",
  eta_link_error: "An error occurred, please try again.",
  eta_link_success: "ETA sent and link copied!",
  eta_link_error_destination: "Please select a destination",
  eta_link_error_recipient: "Please select a least on recipient",
  eta_type_to_search: "Type to search"
};