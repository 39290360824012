import {
  TOGGLE_SIDEBAR,
  HIDE_TOGGLE_SIDEBAR,
  CLEAR_REDUX,
  DOWNLOAD_ATTACHMENT_URL,
  DOWNLOAD_ATTACHMENT_URL_RESULT,
  UPLOAD_ATTACHMENT,
  UPLOAD_ATTACHMENT_RESULT,
  UPLOAD_ATTACHMENT_LOCAL,
  UPLOAD_ATTACHMENT_LOCAL_RESULT,
  SET_DEVELOPER,
  TOGGLE_FULL_SCREEN,
  GENERIC_EXPORT_SEND,
  GENERIC_EXPORT_SEND_RESULT,
  SET_CALENDAR_SCROLL_PARENT
} from 'Redux/actionTypes';
import { CHANGE_SELECT_LANGUAGE, GET_USER_LANGUAGE } from './actionTypes';

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
  //payload: { username, password, navigate }
});

export const hideToggleSidebar = (value) => ({
  type: HIDE_TOGGLE_SIDEBAR,
  payload: value
})

export const clearRedux = (option, parameters) => ({
  type: CLEAR_REDUX,
  payload: { option: option || "", parameters }
});

export const downloadAttachmentUrl = (data) => ({
  type: DOWNLOAD_ATTACHMENT_URL,
  payload: data
})

export const downloadAttachmentUrlResult = (data) => ({
  type: DOWNLOAD_ATTACHMENT_URL_RESULT,
  payload: { data }
})

export const uploadAttachment = (data) => ({
  type: UPLOAD_ATTACHMENT,
  payload: data
})

export const uploadAttachmentResult = (data) => ({
  type: UPLOAD_ATTACHMENT_RESULT,
  payload: { data }
})

export const uploadAttachmentLocal = () => ({
  type: UPLOAD_ATTACHMENT_LOCAL
})

export const uploadAttachmentLocalResult = (data) => ({
  type: UPLOAD_ATTACHMENT_LOCAL_RESULT,
  payload: { data }
})

export const setIsDeveloper = (val) => ({
  type: SET_DEVELOPER,
  payload: val || false
})

export const setToggleFullScreen = (val) => ({
  type: TOGGLE_FULL_SCREEN,
  payload: val
})

export const getUserLanguage = () => {
  return {
    type: GET_USER_LANGUAGE,
  }
};


export const setChangeSelectLanguage = (val) => ({
  type: CHANGE_SELECT_LANGUAGE,
  payload: val
})

export const sendGenericExport = (name, type, graphqlQuery, graphqlVariables, columns, exportFormat, header, isDynamo, general,columnStyles) => ({
  type: GENERIC_EXPORT_SEND,
  payload: { name, type, graphqlQuery, graphqlVariables, columns, exportFormat, header, isDynamo, general, columnStyles }
})


export const sendGenericExportResult = (result) => ({
  type: GENERIC_EXPORT_SEND_RESULT,
  payload: result
})

export const setCalendarScrollParent = (result) => ({
  type: SET_CALENDAR_SCROLL_PARENT,
  payload: result
})


