export default {
    name_incident_history: 'Name',
    vehicle_incident_history: 'Vehicle',
    stared_incident_history: 'Stared',
    ended_incident_history: 'Ended',
    duration_incident_history: 'Duration',
    reportedBy_incident_history: 'Reported By',
    endedBy_incident_history: 'Ended By',
    note_incident_history: 'Note',
    reactive_incident_history: 'Reactivate Incident',
    delete_incident_history: 'Delete Incident',
    delete_message_incident_history: 'Are you sure you want to delete the following incidents? This action is irreversible',
    noInformation_incident_history: 'There are no incidents in the selected range...',
    reactivate_message_incident_history: 'The incident will be reactivated with one hour of activity from its confirmation.',
    incidentName_incident_history: 'Name:',
    incidentVehicle_incident_history: 'Vehicle:',
    incidentStart_incident_history: 'Start Date:',
    reactiveConfirm_incident_history: 'Reactivate',
    deleteMessage_incident_history: 'Incidents were successfully deleted',
    deleteMessageTitle_incident_history: 'Delete Incidents',
    reactivateMessageTitle_incident_history: 'Reactivate Incident',
    reactivateMessage_incident_history: 'The Incident was reactivated and its duration is one hour from this moment',
    reactivateMessageError_incident_history: 'The incident could not be activated again since the vehicle has an active Incident',


    


    
}