/*********************************************************
 * Imports
 ********************************************************/
 import React , { useEffect, useMemo, useState } from 'react';
 import { useDispatch, useSelector } from 'react-redux';
 import { injectIntl } from 'react-intl';
 /*********************************************************
  * Controls
  ********************************************************/
 import Autocomplete from '@mui/material/Autocomplete';
 //import Grid from '@mui/material/Grid';
 import Typography from '@mui/material/Typography';
 import parse from 'autosuggest-highlight/parse';
 import throttle from 'lodash/throttle';
 import { TypeLayer } from 'Components/Map/providers/enums';
 import { OrderData } from 'Core/data/Helpers';

 import {
    Box,
	Icon,
	Grid,
} from '@mui/material';

import {
    formatAddress
  } from 'Core/data/Helpers';
 
/*********************************************************
  * Css
********************************************************/
import './style.scss'

/*********************************************************
 * Import Hooks
*********************************************************/
import { useGeocoder } from 'Core/hooks'

 
const autocompleteService = { current: null };

/*********************************************************
 * Actions
*********************************************************/
import { addPointers, zoomPointOnMap } from "Redux/actions";
 


 /*********************************************************
  * Class to new control to search items and show on map
  * @param {Object} props 
 *********************************************************/
 const MapSearchBox = (props) => {

    const { units, modalPopupEvent, showInfoWindow, mapRef } = props
    const { messages } = props.intl;

    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [findAddress, setfindAddress] = useState([]);
    const [findUnits, setFindUnits] = useState([]);
    const [findLatLng, setFindLatLng] = useState([]);
    const [findLandmark, setFindLandmark] = useState([]);
    const [findGeofence, setFindGeofence] = useState([]);
    
    const [activeLat, setActiveLat] = useState(0)

    const [group, setGroup] = useState();
    const [geofenceTagsArray, setGeofenceTagsArray] = useState();
    const [dataLandmarks, setDataLandmarks] = useState([]);
    const [dataGeofences, setDataGeofences] = useState([]);

    const [onGetGeocoder, address] = useGeocoder()
    const dispatch = useDispatch();
 

    /*********************************************************
     * Use selector
    *********************************************************/
    /* const { landmarkGroupsPreferences } = useSelector((state) => state.realtimeMapLandmarkModuleRedux); */
    const { landmarkTags } = useSelector((state) => state.realtimeMapLandmarkListModuleRedux);
    const { geofenceTags } = useSelector((state) => state.realtimeMapNewGeofenceModuleRedux);
    const { jsonLandmark, jsonGeofence } = useSelector((state) => state.realtimeMapRedux);
    
    const tags = useSelector(state => state.catalogsRedux.tags);
    const haveUntagged = tags?.find(t => t?.id == -1) ? true : false;

    /*********************************************************
     * Set the groups preferences
    *********************************************************/
    useEffect(() => {
        if (landmarkTags && landmarkTags.length > 0 && !group) {
            setGroup(landmarkTags)
        }
    }, [landmarkTags])

    /*********************************************************
     * Set the tags for Geofences
    *********************************************************/
     useEffect(() => {
        if (geofenceTags && geofenceTags.length > 0 && !geofenceTagsArray) {
            setGeofenceTagsArray(geofenceTags)
        }
    }, [geofenceTags])

    const getTagsIdsArray = (tagsId) => {
        if(tagsId){
            const tagsIds = tagsId.split(',')
            return tagsIds || []
        }
        return []
    }

    const setValueGropsTags = (tagGroups, LandmarkOrGeofenceData) => {
        let arrayDataResult = []
            tagGroups.forEach((tag) => {
                let filteredLandmarks = LandmarkOrGeofenceData.filter((item) => getTagsIdsArray(item.tagsId).includes(tag.id.toString()) || (haveUntagged && !item.tagsId)); 
                arrayDataResult = [...arrayDataResult, ...filteredLandmarks];
            })
            //Remove Duplicates
            if(arrayDataResult && arrayDataResult.length > 0){
                const removeDuplicates = [...new Map(arrayDataResult.map((m) => [m.id, m])).values()];
                arrayDataResult = [...removeDuplicates]
            }
            arrayDataResult = OrderData(arrayDataResult, 'name');
            return arrayDataResult;
            
    }
    /*********************************************************
     * Set the landmarks by rows per page in start data
    *********************************************************/
    useEffect(() => {
        if (group && group.length > 0 && jsonLandmark && jsonLandmark.length > 0) {
            const arrayDataResult = setValueGropsTags(group, jsonLandmark)
            setDataLandmarks(arrayDataResult);
        }
    }, [group, jsonLandmark])

    /*********************************************************
     * Set the landmarks by rows per page in start data
    *********************************************************/
     useEffect(() => {
        if (geofenceTagsArray && geofenceTagsArray.length > 0 && jsonGeofence && jsonGeofence.length > 0) {
            const arrayDataResult = setValueGropsTags(geofenceTagsArray, jsonGeofence)
            setDataGeofences(arrayDataResult);
        }
    }, [geofenceTagsArray, jsonGeofence])

    /*********************************************************
        * Use memo to memorize elements
    ********************************************************/
    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    /********************************************************
     * Validate dirección value from geocoder
     * @param {string} value as direction
     * @returns 
     ********************************************************/
    const validateDirection = (value) => {
        value = value.toString().replace(',','')
        value = value.toString().replace(/\s/g, "")
        if(value) return true
        return false
    }

    /********************************************************
     * Validate is object is JSON
     * @param {JSON} json 
     * @returns true is valid JSON
     ********************************************************/
    const isValidJson = (json) => {
        try {
            JSON.parse(json);
            return true;
        } catch (e) {
            return false;
        }
    }

   /********************************************************
     * Using geocoder to get lat lng to the point
     ********************************************************/
    const getAddressByPlaceId = (placeId) => {
        onGetGeocoder({
            from: 'placeId', //latlng, address
            placeId: placeId
        })
    }

    /*********************************************************
     * Get the address from lat lng params
     * @param {Float} lat 
     * @param {Float} lng 
     ********************************************************/
    const getAddressLatLng = (lat, lng) => {
        onGetGeocoder({
            from: 'latlng',
            lat: lat,
            lng: lng
        });  
    }

   /********************************************************
   * Create the marker into the map with the lat long geocoder
   ********************************************************/
    useEffect(() => {
        if (address && address.result == true) {
            const { data } = address
            const label = validateDirection(data[0].fulladdress) ? data[0].fulladdress: '';

            //get lat lng option
            if(activeLat){
                const latLngValue = `${data[0].lat}, ${data[0].lng}`;
                const newArray = []
                newArray.push({
                    id: 1,
                    index: 'l01',
                    place_id: data[0]?.placeId || '',
                    description: latLngValue,
                    main_text: latLngValue,
                    secondary_text: label,
                    value: "latLng",
                    type: TypeLayer.MapSearch,
                    startSearch: latLngValue.toLocaleLowerCase().indexOf(latLngValue.toLocaleLowerCase()),
                    latitude: data[0].lat,
                    longitude: data[0].lng
                })
                setFindLatLng(newArray)
                return
            }

            //show the element into the map
            const pointer = {
                id: '01',
                lat: data[0].lat,
                lng: data[0].lng,
                label: label,
                showLabel: false,
                heading: 0,
                color: '000',
                eventName: 'no event',
                draggable: props.draggable || false
            }
            
            mapRef.current.hideMarkerModal();

            setTimeout(function () {
                dispatch(addPointers([pointer]));
                onSelectedAddress({ 
                    id: 0, 
                    label: data[0]?.address1 || label, 
                    address: label,
                    type: TypeLayer.MapSearch, 
                    latitude: data[0].lat, 
                    longitude: data[0].lng });
            },50); //to close modal in window map
        }
    }, [address, activeLat])
 
   /*********************************************************
    * split array by char space and comma
    * @param {string} word to find lat lng
    *********************************************************/
    const validateLatLng = (param) => {
        setFindLatLng([])
        let isDirectionLatLng = false;        
        let arrayLatLng = splitArrayLatLng(param, ' ');
        if(arrayLatLng){
            isDirectionLatLng = true
        }else{
            arrayLatLng = splitArrayLatLng(param, ',')
            if(arrayLatLng){
                isDirectionLatLng = true
            }
        }

        if(isDirectionLatLng && arrayLatLng && arrayLatLng.length > 1){
            setActiveLat(1)
            getAddressLatLng(arrayLatLng[0], arrayLatLng[1])
        }
    }
 
   /*********************************************************
    * split string by param and evalate if value is lat and lng 
    * @param {string} valueArray element to split value
    * @param {char} valueSplit value to split
    * @returns {array} array lat lng position
    *********************************************************/
    const splitArrayLatLng = (valueArray, valueSplit = ' ') => {
        let arrayLatLng = valueArray.split(valueSplit)
        if(arrayLatLng && arrayLatLng.length > 0){
            arrayLatLng = arrayLatLng.filter((item) => item != '');
            if(arrayLatLng.length < 2) return null;

            arrayLatLng = arrayLatLng.map((item) => {
                return item.toString().replace(',', '');
            })

            if(arrayLatLng.length == 2){
                if(!isNaN(arrayLatLng[0]) && !isNaN(arrayLatLng[1]))
                {
                    return arrayLatLng
                }   
            }
        }
        return null
    }

    /*********************************************************
     * filter the landmarks by name
     * @param {String} param data to search
     *********************************************************/
     const getLandmarksByName = (param) => {
        if(dataLandmarks && dataLandmarks.length <= 0 || !dataLandmarks) return

        let filterlandmark = dataLandmarks.filter((item) => 
            //filter by name
            item?.name?.toLocaleLowerCase().includes(param.toLocaleLowerCase())
        );

        let elements = []
        if(filterlandmark && filterlandmark.length > 0){

            if (filterlandmark && filterlandmark.length > 3){
                filterlandmark = filterlandmark.slice(0, 3);
            }

            filterlandmark.map((item, index) => {
                elements.push({
                    id: item.id,
                    index: index,
                    place_id: 0,
                    description: item?.name,
                    main_text: item?.name,
                    secondary_text: item?.address,
                    type: TypeLayer.Landmark,
                    searchByType: true,
                    startSearch: item?.name.toLocaleLowerCase().indexOf(param.toLocaleLowerCase()),
                    latitude: item.latitude,
                    longitude: item.longitude
                })
            })

        }
        setFindLandmark(elements)
    }

    /*********************************************************
     * filter the geofence by name
     * @param {String} param data to search
     *********************************************************/
     const getGeofenceByName = (param) => {
        let elements = []
        try{
            if(dataGeofences && dataGeofences.length <= 0 || !dataGeofences) return
            
            let filter = dataGeofences.filter((item) => 
                //filter by name
                item?.name?.toLocaleLowerCase().includes(param.toLocaleLowerCase())
            );

            
            if(filter && filter.length > 0){

                if (filter && filter.length > 3){
                    filter = filter.slice(0, 3);
                }
                filter.map((item, index) => {
                    const { coordinates } = item
                    const radius = item.radius > 0 ? item.radius : 0
                    const coordinatesCenter = radius > 0 ? [{ lat: coordinates[0].latitude, lng: coordinates[0].longitude }] : coordinates
                    let lat = coordinatesCenter[0]?.lat || coordinatesCenter[0]?.latitude
                    let lng = coordinatesCenter[0]?.lng || coordinatesCenter[0]?.longitude
                    let secondValue = `${parseFloat(lat).toFixed(6)}, ${parseFloat(lng).toFixed(6)}`

                    elements.push({
                        id: item.id,
                        index: index,
                        place_id: 0,
                        description: item?.name,
                        main_text: item?.name,
                        secondary_text: secondValue,
                        type: TypeLayer.Geofence,
                        searchByType: true,
                        startSearch: item?.name.toLocaleLowerCase().indexOf(param.toLocaleLowerCase()),
                        latitude: lat,
                        longitude: lng
                    })  
                })
            }
        }catch(ex){
            console.log('getGeofenceByName', ex)
        }
        setFindGeofence(elements)
    }


    /*********************************************************
     * filter the units from Label, Esn, Driver, vehicleInformation
     * @param {String} param data to search
     *********************************************************/
    const getUnitsVinNumber = (param) => {
        if(units && units.length <= 0 || !units) return

        let filterUnits = units.filter((item) => 

            //filter by label
            item?.label.toLocaleLowerCase().includes(param.toLocaleLowerCase())

            //filter by vehicleInformation
            || item?.vehicleInformation && 
            item?.vehicleInformation?.vin &&
            item.vehicleInformation?.vin.toLocaleLowerCase().includes(param.toLocaleLowerCase())

            //filter by esn
            || item?.esn.toLocaleLowerCase().includes(param.toLocaleLowerCase())

            //driver Name
            || item?.driverName?.toLocaleLowerCase().includes(param.toLocaleLowerCase())
        );

        let elements = []
        if(filterUnits && filterUnits.length > 0){

            filterUnits.sort((a, b) => {
                const aMatches = a.label.toLocaleLowerCase() == param?.toLocaleLowerCase() ;
                const bMatches = b.label.toLocaleLowerCase() == param?.toLocaleLowerCase() ;
              
                if (aMatches && !bMatches) {
                  return -1; 
                }
                if (!aMatches && bMatches) {
                  return 1; 
                }
                return 0;
              });

            if (filterUnits && filterUnits.length > 3){
                filterUnits = filterUnits.slice(0, 3);
            }

            filterUnits.map((item, index) => {
                let typeFilter = item.label
                let searchByType = true

                //Comment the filters to add the category into the names after the search
                //filter by vin
                if(item?.vehicleInformation && 
                    item?.vehicleInformation?.vin &&
                    item.vehicleInformation?.vin.toLocaleLowerCase().includes(param.toLocaleLowerCase())){
                    typeFilter = `${item?.vehicleInformation?.vin} - ${item.label}`
                    //searchByType = false
                }
        
                //filter by esn
                if(item?.esn.toLocaleLowerCase().includes(param.toLocaleLowerCase())){
                    typeFilter = `${item?.esn} - ${item.label}`
                    //searchByType = false
                }

                //filter by esn
                if(item?.driverName?.toLocaleLowerCase().includes(param.toLocaleLowerCase())){
                    typeFilter = `${item?.driverName} - ${item.label}`
                    //searchByType = false
                }

                //filter by label
                /* if(item?.label.toLocaleLowerCase().includes(param.toLocaleLowerCase())){
                    typeFilter = item.label
                } */
                
                elements.push({
                    id: item.id,
                    index: index,
                    place_id: 0,
                    description: item.label,
                    main_text: typeFilter,
                    secondary_text: formatAddress(item),
                    type: TypeLayer.Units,
                    searchByType: searchByType,
                    startSearch: typeFilter.toLocaleLowerCase().indexOf(param.toLocaleLowerCase()),
                    latitude: item.lat,
                    longitude: item.lng
                })
            })
        }
        setFindUnits(elements)
    }
 
   /*********************************************************
    * Get the direction using AutocompleteService
    ********************************************************/
    useEffect(() => {
        let active = true;
    
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
    
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
    
        fetch({ input: inputValue }, (results) => {
            
            const elements = []
            if(results && results.length > 0){
                results.map((item, index) => {
                    elements.push({
                        index: index,
                        id: index,
                        place_id: item.place_id,
                        description: item.description,
                        main_text: item.structured_formatting.main_text,
                        secondary_text: item.structured_formatting.secondary_text,
                        searchByType: true,
                        startSearch: item.structured_formatting.main_text.toLocaleLowerCase().indexOf(inputValue.toLocaleLowerCase()),
                        type: TypeLayer.MapSearch,
                        startSearch: 0
                    })
                })
            }

            if (active) {
                let newOptions = [];
        
                if (value) {
                    newOptions = [value];
                }
        
                if (elements) {
                    newOptions = [...newOptions, ...elements];
                }

                if (newOptions && newOptions.length > 3){
                    newOptions = newOptions.slice(0, 3);
                }  
        
                setfindAddress(newOptions);
            }
        });
        
        //validate latitude or Longitude positions
        validateLatLng(inputValue);
        //Find by vin
        getUnitsVinNumber(inputValue);
        //find by landmark
        getLandmarksByName(inputValue)
        //Get geofence
        getGeofenceByName(inputValue)

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    /*********************************************************
     * Show the options to the user in this sort
     *********************************************************/
    useEffect(()=> {
        setOptions([...findUnits, ...findAddress, ...findLatLng, ...findLandmark, ...findGeofence]);
    }, [findAddress, findUnits, findLatLng, findLandmark, findGeofence])
 
    /*********************************************************
    * Use efect to clear elements
    ********************************************************/
    useEffect(()=>{
        if(props.clear){
            clearObject()
        }
    },[props.clear])

   /*********************************************************
    * Clear the elements into the view
    *********************************************************/
    const clearObject = () => {
        setInputValue('')
        setValue(null)
        setOptions([])
        setFindUnits([])
        setFindLatLng([])
        setfindAddress([])
        setFindLandmark([])
        setFindGeofence([])
        mapRef.current.hideMarkerModal();
        dispatch(addPointers([]));
    }

    /*********************************************************
     * Show the address object into realtime map
     * @param {*} item 
     *********************************************************/
    const onSelectedAddress = (item) => {
        const coordinates = new google.maps.LatLng(item.latitude, item.longitude); // eslint-disable-line no-undef
        const mapObject = {
            id: item?.id,
            latitude: item.latitude,
            longitude: item.longitude,
            position: coordinates,
            label: item?.label,
            address: item?.address,
            icon: {
                rotation: 0
            },
            layerName: item.type
        }
        modalPopupEvent(mapObject, mapObject);
        
    }

    /*********************************************************
     * Return the correct icon from the view
     * @param {TypeLayer} iconName 
     * @returns 
     *********************************************************/
    const selectIconByType = (iconName) => {
        let nameIcon = "room";
        switch(iconName){
            case TypeLayer.MapSearch:
                nameIcon = "room";
                break;

            case TypeLayer.Units:
                nameIcon = "directions_car";
                break;

            case TypeLayer.Landmark:
                nameIcon = "flag";
                break;

            case TypeLayer.Geofence:
                nameIcon = "format_shapes";
                break;
        }
        return nameIcon;
    }
 
   return (
        <Autocomplete
            id="google-map-autocomplete_mapSearchBox"
            style={{ width: '100%', heigth: 30 }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            freeSolo={true}
            options={options}

            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            disabled={props.disabled}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);

                if(!newValue) return
                
                if(newValue?.type == TypeLayer.MapSearch && !newValue?.value) {
                    setActiveLat(0)
                    getAddressByPlaceId(newValue.place_id)
                }else if(newValue?.type == TypeLayer.MapSearch && newValue?.value == "latLng") {
                    setActiveLat(0) 
                    getAddressLatLng(newValue.latitude, newValue.longitude)
                }

                if(newValue?.type == TypeLayer.Units) {
                    showInfoWindow(newValue.id);
                }
                if(newValue?.type == TypeLayer.Landmark){
                    onSelectedAddress(newValue)
                }

                if(newValue?.type == TypeLayer.Geofence){
                    onSelectedAddress(newValue)
                }
         
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue, reason) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <div className={inputValue ? 'classDivSearchMapShadow' : 'classDivSearchMap'} >
                    <div id={'mapAutoComplete-realtime-maps'} ref={params.InputProps.ref}>
                        <Grid  
                            alignItems="center"
                            justifyContent="center"
                            container 
                            spacing={0} 
                            ref={params.InputProps.ref}>
                            <Grid item xs={10}>
                                    <input 
                                        placeholder={messages['search']}
                                        style={{ color: '#797A7C' }} 
                                        type="text" 
                                        {...params.inputProps} />  
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={1}>
                                <Box
                                    display="flex"
                                    justifyContent="left"
                                    alignItems="center"
                                >
                                    {
                                        !value
                                        ?
                                        <Icon style={{ fontSize: '1.5rem', color: inputValue ? '#333333' : '#797A7C' }}>
                                            search
                                        </Icon>
                                        :
                                        <Icon style={{ fontSize: '1.5rem', color: '#333333' }}
                                            onClick={() => {clearObject()}}>
                                            cancel
                                        </Icon>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )}
            renderOption={(props, option, state) => {
                if(!option) return (<div></div>)
                const parts = parse(option.main_text, [[option.startSearch, (option?.searchByType ? option?.startSearch : 0) + ( option?.searchByType ? inputValue?.length || 0 : 0)]])
                return (
                    <div key={`${option.main_text}_${option.id}`} {...props}>
                        <Grid wrap="nowrap" container alignItems="center"
                            >
                            <Grid item>
                                <Icon className={'MapSearchBox-icon'} >
                                        { selectIconByType(option.type) }
                                </Icon>
                            </Grid>
                            <Grid item xs>

                                {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                    </span>
                                ))}
                
                                <Typography variant="body2" color="textSecondary">
                                    {option.secondary_text}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                );
            }}
        />
   );
 }
 
 
 export default injectIntl(MapSearchBox)
