import React, { Fragment, useRef, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import Modal, { ModalBody, ModalFooter } from 'Components/Modal';
import { Button, Form, Select, Input, ToggleSwitch } from 'Components';
import IconPicker from 'Modules/.common/components/IconPicker';
import TagPicker from 'Modules/.common/components/NewTagPicker';
import { Grid } from '@mui/material';
import Map from 'Components/Map';
import MapObject from 'Components/Map/providers/mapObject';
import { TypeObjectMap, TypeLayer } from 'Components/Map/providers/enums';
import { radiusFeetToMeters } from 'Components/Map/utils'
import { HasPermission } from 'Core/security';
import {
    createEditFleetLandmark,
    openFleetLandmarkModal,
    clearRedux
} from 'Redux/actions';

import { NotificationManager } from "Components/Notifications";
import { useGeocoder } from 'Core/hooks'
import { LandmarkCircleRadiusValues } from 'Components/Map/providers/enums';

let idLandmark = 'mapObject_fleet';

const ModalLandMark = (props) => {
    const mapRef = useRef();
    const fleetLandmarkForm = useRef();
    const { messages } = useIntl();

    const dispatch = useDispatch();

    const {
        open,
        lodingCreateEdit: loading,
        createEditResponse: response,
        landmark,
        vehicle
    } = useSelector((state) => state.vehicleLandMarkRedux);

    const tags = useSelector((state) => state.catalogsRedux.tags);
    const { user } = useSelector(state => state.securityRedux);

    const radiusValues = [...LandmarkCircleRadiusValues];
    const shapes = [
        { type: "circle", label: messages["fleetLandmark_circle"] },
        { type: "polygon", label: messages["fleetLandmark_polygon"] }
    ];
    const defaultFormData = { shape: "circle", color: '8677D9', iconName: `kmls/flagicon/8677D9.png`, radius: 150 };

    const [formData, setFormData] = useState({ ...defaultFormData });
    const [errorMessage, setErrorMessage] = useState();
    const [onGetGeocoder, address, setAddress] = useGeocoder();
    

    useEffect(() => {
        return () => {
            dispatch(clearRedux("FLEET_LANDMARK"));
        };
    }, []);

    useEffect(() => {
        if (response && open) {
            if (response?.error) {
                let message = null
                if(response?.message == 'Landmark duplicated name'){
                    message = messages['fleetLandmark_error_name']
                }
                NotificationManager.error(
                    message || messages['errorMessage'],
                    messages['error'],
                    3000,
                    null,
                    null,
                    'warning',
                );

                dispatch(clearRedux("FLEET_LANDMARK", { lodingCreateEdit: false, createEditResponse: null }));
            } else {
                NotificationManager.success(
                    landmark ? messages['fleetLandmark_successEdit'] : messages['fleetLandmark_successCreate'],
                    messages['success'],
                    3000,
                    null,
                    null,
                    'warning',
                    null
                );
                handleClose();
            }
        }

    }, [open, response])

    useEffect(() => {
        if (open && vehicle) {
            idLandmark = `${idLandmark}_${vehicle?.id}`;
            evaluateAddress(vehicle);
            let newLandmark = {};
            debugger
            if (landmark) {
                newLandmark = { 
                    ...landmark,
                    color: landmark?.color || '8677D9', 
                    iconName: landmark?.iconName || `kmls/flagicon/8677D9.png`,
                    coordinates: landmark?.radius ? landmark?.coordinates : landmark?.coordinates.map(c => {
                        return { lat: c.latitude, lng: c.longitude };
                    })
                };

                if (landmark?.tagsId) {
                    let tagIds = landmark?.tagsId?.split(",");
                    let landmarkTags = [];
                    if (tagIds && tagIds?.length) {
                        landmarkTags = tags?.filter(t => tagIds?.includes(`${t?.id}`));
                    }

                    newLandmark = { ...newLandmark, tags: landmarkTags, tagsIds: tagIds };
                }
            }

            let coordinates = landmark ? newLandmark?.coordinates : [{ latitude: vehicle?.latitude, longitude: vehicle?.longitude }];
            const newFormData = {
                ...formData,
                ...newLandmark,
                longitude: vehicle?.longitude || "",
                latitude: vehicle?.latitude || "",
                coordinates: coordinates,
                address: vehicle?.address?.address || vehicle?.address || "",
                city: vehicle?.address?.city || vehicle?.city || "",
                zip: vehicle?.address?.zip || vehicle?.zip || "",
                state: vehicle?.address?.state || vehicle?.state || "",
                country: vehicle?.address?.country || vehicle?.country || '',
                name: landmark?.name || "",
                note: landmark?.note || "",
                landmarkIcon: landmark?.iconName ? true : false
            };

            setFormData(newFormData);
            updateLandmarkCircle(newFormData.radius, newFormData.color, newFormData.iconName, coordinates);
        }

    }, [vehicle, landmark, open]);

    useEffect(() => {
        if (address && open) {
            const { data } = address;
            if (data) {
                setFormData({
                    ...formData,
                    address: data[0]?.address1,
                    city: data[0]?.city,
                    zip: data[0]?.postalcode,
                    state: data[0]?.state,
                    country: data[0]?.country
                });
            }
        }
    }, [address, open]);

    const updateLandmarkCircle = useCallback((radius, color, iconName, coordinates) => {
        mapRef?.current?.removeMapObject(idLandmark, TypeObjectMap.Landmark);
        let position = { lat: vehicle?.latitude, lng: vehicle?.longitude };
        if (landmark) position = { lat: landmark.latitude, lng: landmark.longitude };

        const mapObject = new MapObject().buildLandmark({
            id: idLandmark,
            index: idLandmark,
            position: position,
            url: iconName,
            radius: radius ? radiusFeetToMeters(radius) : 0,
            layerName: TypeLayer.LandmarkMarker,
            showLabel: false,
            hasChild: true,
            childType: radius ? TypeObjectMap.Circle : TypeObjectMap.Polygon,
            coordinates: coordinates,
            color: `#${color}`
        });

        mapRef?.current?.addMapObject(mapObject, true);
        mapRef.current.centerByFitBounds(radius ? [position] : coordinates, radiusFeetToMeters(radius || 0));
    });

    const evaluateAddress = (vehicle) => {
        const { latitude, longitude, address, city } = vehicle;
        if (!address && latitude && longitude || !city && latitude && longitude) {
            onGetGeocoder({
                from: 'latlng',
                lat: latitude,
                lng: longitude
            })
        }
    }

    const onSubmit = () => {
        setErrorMessage(null);
        if (!formData?.tagsIds?.length) {
            setErrorMessage(messages['required_field']);
            return;
        }

        let coordinates = formData?.coordinates;
        if (landmark) coordinates = landmark?.coordinates;
        debugger

        dispatch(createEditFleetLandmark({ ...formData, coordinates: coordinates }, true));
    }

    const handleClose = () => {
        setFormData({ ...defaultFormData });
        setAddress(null);
        mapRef?.current?.removeMapObject(idLandmark, TypeObjectMap.Landmark);
        setErrorMessage(null);
        dispatch(openFleetLandmarkModal(false));
        dispatch(clearRedux("FLEET_LANDMARK"));
    }

    const title = landmark ? `${messages['fleetLandmark_edit']} "${landmark?.name}"` : messages['fleetLandmark_create'];

    return (
        <Modal
            open={open}
            handleClose={handleClose}
            title={title}
            loading={loading}
            position="right"
            size="small"
            style={{ overflow: 'hidden' }}
            className="overflow-hidden"
        >
            <ModalBody
                style={{
                    overflow: 'hidden',
                    marginBottom: 0
                }}
            >
                <div
                    style={{
                        padding: "0px 24px 70px 24px",
                        height: "calc(100vh - 225px)",
                        overflowX: 'auto'
                    }}
                >
                    <Form
                        ref={fleetLandmarkForm}
                        onValidSubmit={onSubmit}
                    >
                        <div>
                            <Map
                                ref={mapRef}
                                noRealtime={true}
                                hiddenButton
                                hiddenWeather={true}
                                hiddenLayerToggle={true}
                                hiddenLegends={true}
                                hiddenTraffic={true}
                                hiddenSearchMap
                                height="40vh"
                            />
                        </div>

                        <div className="unitForm">
                            <Grid
                                container
                                wrap="nowrap"
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Grid item style={{ height: 0 }}>
                                    <div className="form-item">
                                        <IconPicker
                                            defaultIcon
                                            color={formData.color}
                                            icon={formData?.landmarkIcon ? formData.iconName : `kmls/flagicon/${formData.color}.png`}
                                            options={formData?.landmarkIcon ? ["color", "icon"] : ["color"]}
                                            onChange={(color, icon) => {
                                                const newIcon = formData.landmarkIcon ? icon : `kmls/flagicon/${color}.png`;
                                                setFormData({ ...formData, color: color, iconName: newIcon });
                                                updateLandmarkCircle(formData?.radius, color, newIcon, formData?.coordinates);
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid zeroMinWidth item xs>
                                    <Input
                                        id="name"
                                        type={landmark ? "inline" : "text"}
                                        placeholder={messages['mapLandmarkCreateEditLandmark_name']}
                                        value={formData.name || ""}
                                        onChange={(value) => setFormData({ ...formData, name: value })}
                                        required
                                        requiredMessage={messages['required_field']}
                                        maxLength={50}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <div style={{ padding: '18px 0px 10px 0px' }}>
                            <label style={{ marginRight: 10, fontWeight: 'bold' }}>
                                {messages['mapLandmarkCreateEditLandmark_landmarkIcon']}
                            </label>

                            <ToggleSwitch
                                id="landmarkIcon"
                                name="landmarkIcon"
                                medium
                                blueToggle
                                optionLabels={[messages['yes'], messages['no']]}
                                checked={formData.landmarkIcon || false}
                                onChange={(value) => {
                                    const newColor = "8677D9";
                                    const newIcon = `kmls/flagicon/8677D9.png`;
                                    setFormData({ ...formData, landmarkIcon: value, color: newColor, iconName: newIcon });
                                    updateLandmarkCircle(formData?.radius, newColor, newIcon, formData?.coordinates);
                                }}
                            />
                        </div>

                        <div className="unitForm" >
                            <Select
                                id="shape"
                                label={messages['fleetLandmark_shape']}
                                placeholder={messages['select']}
                                options={shapes}
                                textField="label"
                                valueField="type"
                                isClearable={true}
                                required
                                onChange={(value) => {
                                    setFormData({ ...formData, shape: value?.type })
                                }}
                                value={shapes.filter(data => data.type.toString() === formData.shape?.toString())[0]}
                                disabled={true}
                            />
                        </div>


                        {
                            formData.shape === "circle" &&
                            <div className="unitForm" style={{ width: "100%" }}>
                                <Select
                                    id="radius"
                                    label={messages['fleetLandmark_radius']}
                                    placeholder={messages['select']}
                                    options={radiusValues}
                                    textField="text"
                                    valueField="value"
                                    onChange={(radius) => {
                                        setFormData({ ...formData, radius: radius?.value });
                                        updateLandmarkCircle(radius?.value, formData.color, formData.iconName, formData?.coordinates);
                                    }}
                                    value={radiusValues.filter(data => data.value.toString() === formData.radius?.toString())[0]}
                                    required
                                />
                            </div>
                        }

                        <div className="unitForm" style={{ marginTop: 15 }}>
                            <TagPicker
                                id="tags"
                                showCreateOption={false}
                                label={messages["fleetLandmark_tags"]}
                                placeholder={messages["fleetLandmark_tags"]}
                                values={formData?.tags || []}
                                multiple={true}
                                required={true}
                                position="absolute"
                                onChange={(values) => {
                                    setErrorMessage(null);
                                    setFormData({ ...formData, tags: values, tagsIds: values?.map((item) => item.id) || [] });
                                }}
                            />

                            {
                                errorMessage && <small className='text-danger'>{errorMessage}</small>
                            }
                        </div>

                        <div className="unitForm" style={{ width: "100%" }} >
                            <Input
                                id="note"
                                label={messages['fleetLandmark_notes']}
                                placeholder={messages['fleetLandmark_notes']}
                                type="textarea"
                                maxLength={2000}
                                onChange={(value) => setFormData({ ...formData, note: value })}
                                value={formData.note}
                            />
                        </div>
                    </Form>
                </div>
            </ModalBody>
            <ModalFooter className="modalShadow">
                <Fragment>
                    <Button
                        type="button"
                        onClick={handleClose}
                        className="btn-cancel"
                    >
                        {messages['cancel']}
                    </Button>

                    
                    { 
                        HasPermission(user, '', [3]) //edit landmark
                        &&
                        <Button
                            className="btn-blue"
                            onClick={(e) => {
                                if (!loading) {
                                    e.preventDefault();
                                    fleetLandmarkForm.current.submit();
                                }
                            }}
                        >
                            {messages['save']}
                        </Button>
                    }
                </Fragment>
            </ModalFooter>
        </Modal>
    );
};

export default ModalLandMark;