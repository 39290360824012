import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useParams } from "react-router-dom";

import { Loading } from 'Components';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { useWindowDimensions } from 'Core/hooks';

import Map from 'Components/Map';

import {
    mapsEtaLinkPublicGet,
    addPointers,
    drawGetDirections,
    setMapRoutesOptions
} from "Redux/actions";

import './styles.scss';

let intervalNewData;

const EtaLink = (props) => {
    const { messages } = useIntl();
    const mapRef = useRef();
    const dispatch = useDispatch();

    const { code } = useParams();
    const [formData, setFormData] = useState({});

    const { height, width } = useWindowDimensions();

    //define the default values of the routes.... this values can be changed
    const routeProperties = {
        optimizeWaypoints: true,
        avoidTolls: false,
        avoidHighways: false,
    }

    //Get the information at the points saved 
    const {
        pointers,
        directions
    } = useSelector((state) => state.realtimeMapRedux);

    const response = useSelector(state => state.etaLinkRedux.response);
    const error = useSelector(state => state.etaLinkRedux.error);
    const loading = useSelector(state => state.etaLinkRedux.loading);

    useEffect(() => {
        //temporally define the points into the view
        dispatch(mapsEtaLinkPublicGet(code, true))

        //create interval to get information from API
        if (!intervalNewData) {
            intervalNewData = setInterval((codeParam) => {
                dispatch(mapsEtaLinkPublicGet(codeParam))
            }, 15000, code);
        }
    }, [])

    useEffect(() => {
        if (response) {
            const startPoint = {
                latitude: response?.unit?.lastReading?.latitude,
                longitude: response?.unit?.lastReading?.longitude,
                driverName: response?.unit?.driverName,
                label: response?.unit?.label
            };

            const endPoint = {
                latitude: response?.latitude,
                longitude: response?.longitude,
                label: response?.address
            };
            setFormData({ startPoint, endPoint, isKph: response.isKph });
        }
    }, [response]);


    useEffect(() => {
        /* Types error ETA
            OK: 0,
            NO_DATA: 1,
            LOAD_FAILED: 2
        */
        if (error == "NO_DATA") {
            //end the get information
            clearInterval(intervalNewData);
        }
        if(error == "LOAD_FAILED"){
            if (!intervalNewData) {
                dispatch(mapsEtaLinkPublicGet(code))
                intervalNewData = setInterval((codeParam) => {
                    dispatch(mapsEtaLinkPublicGet(codeParam))
                }, 15000, code);
            }
        }
    }, [error]);

    useEffect(() => {
        if (
            formData?.startPoint?.latitude &&
            formData?.startPoint?.longitude &&
            formData?.endPoint?.latitude &&
            formData?.endPoint?.longitude
        ) {
            createRouteOnView(formData?.startPoint, formData?.endPoint);
        }
    }, [
        formData?.startPoint?.latitude,
        formData?.startPoint?.longitude,
        formData?.endPoint?.latitude,
        formData?.endPoint?.longitude,
    ]);

    const createRouteOnView = (startPoint, endPoint) => {
        const tempPointers = [
            {
                id: '1',
                lat: parseFloat(startPoint?.latitude || 0),
                lng: parseFloat(startPoint?.longitude || 0),
                label: startPoint?.driverName && startPoint?.driverName != "true" && startPoint?.driverName != "null"
                    ?
                    `${startPoint?.label} (${startPoint?.driverName})` : startPoint?.label,
                heading: 0,
                color: '000',
                eventName: 'no event',
                draggable: false,
                updatePointers: true,
                hideMarker: true
            },
            {
                id: '2',
                lat: parseFloat(endPoint?.latitude || 0),
                lng: parseFloat(endPoint?.longitude || 0),
                label: endPoint.label || '',
                heading: 0,
                color: '000',
                eventName: 'no event',
                draggable: false,
                updatePointers: true,
                hideMarker: true
            }
        ];
        //save pointers and shows into map
        dispatch(addPointers([...tempPointers]));
        dispatch(setMapRoutesOptions(routeProperties));
    }

    /********************************************************
     * recreate the route in map when have 2 points
     ********************************************************/
    useEffect(() => {
        if (pointers && pointers.length > 1) {
            //delete the old rounte on map
            mapRef?.current?.deleteRouteInMap()
            //create the new pointers of the view
            dispatch(drawGetDirections(true))
        }
    }, [pointers])

    let messagesToMap = new Array();
    if(messages){
        messagesToMap['kilometers'] = messages['kilometers']
        messagesToMap['miles'] = messages['miles']
        messagesToMap['Day'] = messages['Day']
        messagesToMap['Days'] = messages['Days']
        messagesToMap['hour'] = messages['hour']
        messagesToMap['hours'] = messages['hours']
        messagesToMap['min'] = messages['min']
        messagesToMap['mins'] = messages['mins']
    } 

    return (
        <div
            style={{
                width: '100%',
                height: '100%'
            }}
        >
            {
                loading ?
                    <Loading full /> :
                    <Fragment>
                        {
                            error == "NO_DATA" ?
                                <div className='eta-error-container'>
                                    <div className='eta-error-message'>
                                        This ETA link is no longer available. The device has reached the destination or it
                                        has been more than 8 hours since it was generated.
                                    </div>
                                </div> :
                                <Map
                                    ref={mapRef}
                                    pointers={pointers || []}
                                    directions={directions}
                                    noRealtime={true}
                                    hiddenButton
                                    hiddenLayerToggle={true}
                                    hiddenLegends={true}
                                    hiddenTraffic={true}
                                    hiddenSearchMap
                                    height="100vh"
                                    showEtaLink={true}
                                    isMobile={width <= 767}
                                    messages={messagesToMap}
                                    isKph={formData?.isKph || false}
                                />
                        }
                    </Fragment>
            }
        </div>
    )

}

export default EtaLink;