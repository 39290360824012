import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const CustomTooltip = (props) => {
	const {
		placement,
		title,
		children,
		type,
		open
	} = props;

	return type === 'report' ? (
		<Tooltip
			placement={placement}
			title={title}
			open={open}
			arrow
			componentsProps={{
				tooltip: {
					sx: {
						backgroundColor: '#ffffff',
						color: 'rgba(0, 0, 0, 0.87)',
						maxWidth: "500px",
						fontSize: "14px",
						padding: "12px",
						border: '0 solid #ddd',
						boxShadow: '0px 0px 15px 1px rgba(0,0,0,0.2)',
					}
				},
				arrow: {
					sx: {
						color: '#ffffff !important',
					}
				}
			}}
		>
			{children}
		</Tooltip>
	) : (

		<Tooltip
			placement={placement}
			title={title}
			componentsProps={{
				tooltip: {
					sx: {
						backgroundColor: '#ffffff',
						color: 'rgba(0, 0, 0, 0.87)',
						maxWidth: "500px",
						fontSize: "14px",
						padding: "12px",
						border: '1px solid #ddd',
						boxShadow: '0px 0px 15px 1px rgba(0,0,0,0.2)',
					}
				}
			}}
		>
			{children}
		</Tooltip>
	);
}

export default CustomTooltip;
