import {
  VIDFLEET_VIDEO_START_STREAMING,
  VIDFLEET_VIDEO_START_STREAMING_RESPONSE,
  VIDFLEET_VIDEO_START_LIVE_PHOTO,
  VIDFLEET_VIDEO_START_LIVE_PHOTO_RESPONSE,
  VIDFLEET_VIDEO_STOP_STREAMING,
  VIDFLEET_VIDEO_STOP_STREAMING_RESPONSE,
  VIDFLEET_VIDEO_GET_STREAMING_STATUS,
  VIDFLEET_VIDEO_GET_STREAMING_STATUS_RESPONSE,
  VIDFLEET_VIDEO_GET_CLIP_BY_ID,
  VIDFLEET_VIDEO_GET_CLIP_BY_ID_RESPONSE,
  VIDFLEET_VIDEO_GET_PICTURE_BY_ID,
  VIDFLEET_VIDEO_GET_PICTURE_BY_ID_RESPONSE,
  VIDFLEET_VIDEO_GET_ONLINE_STATUS,
  VIDFLEET_VIDEO_GET_ONLINE_STATUS_RESPONSE,
  VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS,
  VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS_RESPONSE,
  VIDFLEET_VIDEO_LIVE_PHOTO_RESPONSE
} from 'Redux/actionTypes';

export const startStreaming = (data) => ({
  type: VIDFLEET_VIDEO_START_STREAMING,
  payload: { data },
});

export const startStreamingResponse = (data) => ({
  type: VIDFLEET_VIDEO_START_STREAMING_RESPONSE,
  payload: { data },
});

export const startLivePhoto = (data) => ({
  type: VIDFLEET_VIDEO_START_LIVE_PHOTO,
  payload: { data },
});

export const startLivePhotoResponse = (data) => ({
  type: VIDFLEET_VIDEO_START_LIVE_PHOTO_RESPONSE,
  payload: { data },
});

export const stopStreaming = (data) => ({
  type: VIDFLEET_VIDEO_STOP_STREAMING,
  payload: { data },
});

export const stopStreamingResponse = (data) => ({
  type: VIDFLEET_VIDEO_STOP_STREAMING_RESPONSE,
  payload: { data },
});

export const getStreamingStatus = (data) => ({
  type: VIDFLEET_VIDEO_GET_STREAMING_STATUS,
  payload: { data },
});

export const getStreamingStatusResponse = (data) => ({
  type: VIDFLEET_VIDEO_GET_STREAMING_STATUS_RESPONSE,
  payload: { data },
});

export const getLivePhotoStatus = (data) => ({
  type: VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS,
  payload: { data },
});

export const getLivePhotoStatusResponse = (data) => ({
  type: VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS_RESPONSE,
  payload: { data },
});

export const getClipById = (data) => ({
  type: VIDFLEET_VIDEO_GET_CLIP_BY_ID,
  payload: { data },
});

export const getClipByIdResponse = (data) => ({
  type: VIDFLEET_VIDEO_GET_CLIP_BY_ID_RESPONSE,
  payload: { data },
});

export const getPictureById = (data) => ({
  type: VIDFLEET_VIDEO_GET_PICTURE_BY_ID,
  payload: { data },
});

export const getPictureByIdResponse = (id, data) => ({
  type: VIDFLEET_VIDEO_GET_PICTURE_BY_ID_RESPONSE,
  payload: { data, id },
});

export const getCameraOnlineStatus = (data) => ({
  type: VIDFLEET_VIDEO_GET_ONLINE_STATUS,
  payload: { data },
});

export const getCameraOnlineStatusResponse = (data) => ({
  type: VIDFLEET_VIDEO_GET_ONLINE_STATUS_RESPONSE,
  payload: { data },
});

export const getLivePhotoResponse = (data) => ({
  type: VIDFLEET_VIDEO_LIVE_PHOTO_RESPONSE,
  payload: { data },
});