//action types
export const LOGIN = 'LOGIN';
export const LOGIN_SM = 'LOGIN_SM';
export const LOGIN_RESULT = 'LOGIN_RESULT';

export const LOGIN_CONFIRM_CLOSE = 'LOGIN_CONFIRM_CLOSE';
export const LOGIN_CONFIRM = 'LOGIN_CONFIRM';
export const LOGIN_CONFIRM_RESULT = 'LOGIN_CONFIRM_RESULT';

export const CHECK_LOGIN_USER_BY_CODE = 'CHECK_LOGIN_USER_BY_CODE';
export const CHECK_MIGRATE_USER_BY_TOKEN_RESULT = 'CHECK_MIGRATE_USER_BY_TOKEN_RESULT';

export const CHECK_MIGRATE_USER = 'CHECK_MIGRATE_USER'; 
export const CHECK_MIGRATE_USER_RESULT = 'CHECK_MIGRATE_USER_RESULT'; 

export const GET_LOGIN_PAGE = 'GET_LOGIN_PAGE'; 
export const GET_LOGIN_PAGE_RESULT = 'GET_LOGIN_PAGE_RESULT'; 

export const NEW_PASSWORD_REQUIRED_RESULT = "NEW_PASSWORD_REQUIRED_RESULT";

export const TERMS_AND_CONDITION = 'TERMS_AND_CONDITION';

export const UPDATE_TERMS_AND_CONDITION = 'UPDATE_TERMS_AND_CONDITION';