// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";

// Action Types
import {
  ACCOUNT_SETTINGS_SAVE,
} from "Redux/actionTypes";

// Actions
import {
  saveAccountSettingsResponse,
  getUserInfo
} from "Redux/actions";

// Helpers
import { clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";

function* saveAccountSettings({ payload }) {
  const { defaultTab, isKph, timezone, dateFormat, eldUser, durationFormat } = payload;
  const mutation = `
    mutation saveAccountSettings ($defaultTab: String!, $isKph: Boolean!, $timezone: String!, $dateFormat: String!, $eldUser: EldUserInput,$durationFormat: enumDurationFormat!) {
      saveAccountSettings (defaultTab: $defaultTab, isKph: $isKph, timezone: $timezone, dateFormat: $dateFormat, eldUser: $eldUser, durationFormat: $durationFormat)
  }`;

  const variables = {
    defaultTab,
    isKph,
    timezone,
    dateFormat,
    eldUser,
    durationFormat
  };
  try {
    const data = yield call(
      clientMutation,
      mutation,
      variables,
      endpoints.GRAPHQL_GENERAL
    );
    if (data) {
      const { saveAccountSettings: result } = data;
      yield put(saveAccountSettingsResponse({
        updatedAccount: result,
        error: false
      }));

      yield put(getUserInfo())
    }
  } catch (exc) {
    yield put(saveAccountSettingsResponse({ updatedAccount: false, error: true }));
    ExceptionManager(
      exc,
      "modules/.common/components/AccountSettings/redux/sagas",
      "saveAccountSettings"
    );
  }
}

function* watchMethods() {
  yield takeEvery(ACCOUNT_SETTINGS_SAVE, saveAccountSettings);
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}
