export default {
  "videoDetail_availableMedia": "Available Media",
  "videoDetail_event": "Event: ",
  "videoDetail_datetime": "Date/Time: ",
  "videoDetail_address": "Address: ",
  "videoDetail_vehicle": "Vehicle: ",
  "videoDetail_driver": "Driver: ",
  "videoDetail_speed": "Speed: ",
  "videoDetail_clipRequests": "Clip Request",
  "videoDetail_error": "An error occurred, please try again.",
  "videoDetail_notesTitle": "Admin Comments",
  "videoDetail_addNote": "Add a note...",
  "videoDetail_adminComments": "Add a comment...",
  "videoDetail_saveNote": "Add",
  "videoDetail_notNotes": "No notes yet.",
  "videoDetail_download": "Download",
  "videoDetail_delete": "Delete",
  "videoDetail_share": "Share",
  "videoDetail_editNote": "Edit",
  "videoDetail_cancel": "Cancel",
  "videoDetail_deleteMedia": "Delete",
  "videoDetail_deleteMediaWarning": "Are you sure you want delete the following items?",
  "videoDetail_deleteClipRequest": "the clips",
  "videoDetail_deleteEvent": "the events",
  "videoDetail_deleteSuccess": "has been deleted",
  "videoDetail_deleteEvents": "Delete events",
  "videoDetail_deleteClips": "Delete clips",
  "videoDetail_deleteEventNotes": "Delete notes",
  "videoDetail_deletedNotes": "Notes deleted successfully",
  "videoDetail_newAlert": "New alert",
  "videoDetail_reviewStatus": "Review Status",
  "videoDetail_driverNotes": "Driver Notes",
  "videoDetail_statusRequired": "Please select a status for the review",
  "videoDetail_success": "Data saved successfully",
  "videoDetail_notes": "Notes",
  "videoDetail_visibleToDriver": "Visible to driver",
}