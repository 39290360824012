import colors from 'Assets/sass/core/vars.scss';
export const urlIcons = "/assets/icons";
export const urlImages = "/assets/images";

export const vidFleetVideoEvents = {
    "DEFAULT": { name: "Default", lanKey: null, color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    //"CameraEvent.HARD_ACCEL": { name: "Hard Acceleration", lanKey: "videoMainView_hardAccel", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_acc.png` },
    "CameraEvent.HARD_ACCEL": { name: "Rapid Acceleration", lanKey: "videoMainView_hardAccel", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_acc.png` },
    //"CameraEvent.SEVERE_ACCEL": { name: "Hard Acceleration", lanKey: "videoMainView_severeAccel", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_acc.png` },
    "CameraEvent.SEVERE_ACCEL": { name: "Rapid Acceleration", lanKey: "videoMainView_severeAccel", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_acc.png` },
    //"CameraEvent.HARSH_ACCEL": { name: "Hard Acceleration", lanKey: "videoMainView_harshAccel", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_acc.png` },
    "CameraEvent.HARSH_ACCEL": { name: "Rapid Acceleration", lanKey: "videoMainView_harshAccel", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_acc.png` },

    "CameraEvent.HARD_BRAKE": { name: "Hard Brake", lanKey: "videoMainView_hardBrake", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_brake.png` },
    "CameraEvent.SEVERE_BRAKE": { name: "Hard Brake", lanKey: "videoMainView_severeBrake", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_brake.png` },
    "CameraEvent.HARSH_BRAKE": { name: "Hard Brake", lanKey: "videoMainView_harshBrake", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_brake.png` },

    "CameraEvent.DRIVING_HIT": { name: "Potential Incident", lanKey: "videoMainView_drivingHit", color: '#ddaea6',  icon: `${urlIcons}/markervideo/crash.png` },
    "CameraEvent.DRIVING_HEAVY_HIT": { name: "Potential Incident", lanKey: "videoMainView_drivingheavyHit", color: '#ddaea6',  icon: `${urlIcons}/markervideo/crash.png` },

    "CameraEvent.PARKING_MOTION": { name: "Parking", lanKey: "", color: '#16aaf2', icon: `${urlIcons}/markervideo/parking.png` },
    "CameraEvent.PARKING_HIT": { name: "Parking", lanKey: "", color: '#16aaf2',  icon: `${urlIcons}/markervideo/parking.png` },
    "CameraEvent.PARKING_HEAVY_HIT": { name: "Parking", lanKey: "", color: '#16aaf2',  icon: `${urlIcons}/markervideo/parking.png` },

    "CameraEvent.SHARP_TURN": { name: "Hard Turn", lanKey: "videoMainView_sharpTurn", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_turn.png` },
    "CameraEvent.HARSH_TURN": { name: "Hard Turn", lanKey: "videoMainView_harshTurn", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_turn.png` },
    "CameraEvent.SEVERE_TURN": { name: "Hard Turn", lanKey: "videoMainView_severeTurn", color: '#b763bb',  icon: `${urlIcons}/markervideo/hard_turn.png` },

    "AutoSnapshot": { name: "AutoSnapshot", lanKey: "", color: '#16aaf2',  icon: `${urlIcons}/markervideo/auto_snapshot.png` },

    
    "CameraEvent.ASLEEP": { name: "Asleep", lanKey: 'videoMainView_asleep', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.DROWSINESS": { name: "Drowsy", lanKey: 'videoMainView_drowsy', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.YAWN": { name: "Yawn", lanKey: 'videoMainView_yawn', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.DAYDREAMING": { name: "Day Dreaming", lanKey: 'videoMainView_dayDreaming', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.MANUAL": { name: "Manual", lanKey: 'videoMainView_manual', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.USING_PHONE": { name: "Using Phone", lanKey: 'videoMainView_usingPhone', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.DISTRACTED": { name: "Distracted", lanKey: 'videoMainView_distracted', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.SMOKING": { name: "Smoking", lanKey: 'videoMainView_smoking', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.NO_SEATBELT": { name: "No Seatbelt", lanKey: 'videoMainView_noSeatBelt', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.HEADWAY_MONITORING_WARNING": { name: "Headway Monitoring Warning", lanKey: 'videoMainView_closeFollowing', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.HEADWAY_MONITORING_EMERGENCY": { name: "Tailgating", lanKey: 'videoMainView_tailgating', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.FORWARD_COLLISION_WARNING": { name: "Collision Warning", lanKey: 'videoMainView_collisionWarning', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.STOP_SIGN_VIOLATION": { name: "Stop Sign Violation", lanKey: 'videoMainView_stopSignViolation', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.LANE_DEPARTURE": { name: "Lane Departure", lanKey: 'videoMainView_laneDeparture', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.LANE_DEPARTURE_WARNING": { name: "Lane Departure", lanKey: 'videoMainView_laneDeparture', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },

    "CameraEvent.NO_DRIVER": { name: "Camera Obstructed", lanKey: 'videoMainView_noDriver', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.IDLE_NO_DRIVER": { name: "Idle No Driver", lanKey: 'videoMainView_idleNoDriver', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.POTENTIAL_INCIDENT_LOW": { name: "Potential Incident", lanKey: 'videoMainView_potentialIncident', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.POTENTIAL_INCIDENT_MEDIUM": { name: "Potential Incident", lanKey: 'videoMainView_potentialIncident', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.POTENTIAL_INCIDENT_HIGH": { name: "Potential Incident", lanKey: 'videoMainView_potentialIncident', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` }
};

export const getVidFleetEvent = (code) => {
    let event = vidFleetVideoEvents[code];
    if (!event) event = vidFleetVideoEvents["DEFAULT"];

    return event;
};


export const vidFleetClipStatus = {
    "requestAccepted": { lanKey: 'videoClipRequest_inProgress', color: colors.black, icon: `${urlIcons}/markervideo/ico_inprogress.svg`, image: `${urlImages}/clipRequestInprogress.png` },
    "startUpload": { lanKey: 'videoClipRequest_inProgress', color: colors.black, icon: `${urlIcons}/markervideo/ico_inprogress.svg`, image: `${urlImages}/clipRequestInprogress.png` },
    "uploading": { lanKey: 'videoClipRequest_inProgress', color: colors.black, icon: `${urlIcons}/markervideo/ico_inprogress.svg`, image: `${urlImages}/clipRequestInprogress.png` },

    "requestDecline": { lanKey: 'videoClipRequest_failed', color: colors.red, icon: `${urlIcons}/markervideo/ico_failed.svg`, image: `${urlImages}/clipRequestFailed.png` },
    "failed": { lanKey: 'videoClipRequest_failed', color: colors.red, icon: `${urlIcons}/markervideo/ico_failed.svg`, image: `${urlImages}/clipRequestFailed.png` },
    "timeout": { lanKey: 'videoClipRequest_failed', color: colors.red, icon: `${urlIcons}/markervideo/ico_failed.svg`, image: `${urlImages}/clipRequestFailed.png` },
    "interrupted": { lanKey: 'videoClipRequest_failed', color: colors.red, icon: `${urlIcons}/markervideo/ico_failed.svg`, image: `${urlImages}/clipRequestFailed.png` },

    "requestPending": { lanKey: 'videoClipRequest_pending', color: colors.black, icon: `${urlIcons}/markervideo/ico_pending.svg`, image: `${urlImages}/clipRequestPending.png` },
    
    "finished": { lanKey: 'videoClipRequest_inProgress', color: colors.black, icon: `${urlIcons}/markervideo/ico_inprogress.svg`, image: `${urlImages}/clipRequestInprogress.png` },
    "mp4RemuxFinished": { lanKey: 'videoClipRequest_completed', color: colors.green, icon: `${urlIcons}/markervideo/ico_completed.svg`, image: "" },


    "mp4Uploaded": { name: "AutoSnapshot", lanKey: "", color: '#16aaf2',  icon: `${urlIcons}/markervideo/auto_snapshot.png` },

    "unknown": { lanKey: 'videoClipRequest_unknown', color: colors.blue, icon: `${urlIcons}/markervideo/ico_generic.svg`, image: `${urlImages}/clipRequestFailed.png` }
}

export const getVidFleetClipStatus = (status) => {
    let clipStatus = vidFleetClipStatus[status];
    if (!clipStatus) event = vidFleetClipStatus["unknown"];

    return clipStatus;
};