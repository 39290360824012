import {
  REPORTS_HANDLE_MODAL_EXTRA_TIME,
  CLEAR_REDUX
} from "Redux/actionTypes";

//STATE INITIAL
const INIT_STATE = {
  open: false,
  option: null,
  loading: false,
  response: null,
  //Report factor for 1 months
  reportFactor : {
    driverFormsReportV2 : 80,
    driverStatusReportV2 : 80,
    actReport : 80,
    hourOfUseReport : 80,
    simpleReport : 80,
    speedReport: 80,
    detailedReport: 80,
    stateMileageReport: 122,
    mileageReport: 110,
    mileageSummaryReport: 90,
    driverMileageSummaryReport: 90,
    idleReport: 122,
    startStopIdleReport: 122,
    ignitionReport: 122,
    stopReport: 122,
    landmarkReport: 122,
    driverSafetyReport: 122,
    summaryReport: 122,
    advancedSummaryReport: 122,
    utilizationReport: 122,
    latitudeLongitudeReport: 122,
    eventReport: 122,
    timesheetReport: 122,
    pingReport: 122,
    landmarkBasedReport: 122,
    odometerReport: 122,
    geofenceBasedReport: 122,
    landmarkEngineHoursReport: 122,
    nonReportingReport: 122,
    driverTimesheetReport: 122,
    movingReport: 122,
    UnitInfoReport: 122,
    landmarkSummaryReport: 122,
    landmarkCrewReport: 122,
    locationReport: 122,
    geofenceReport: 122,
    jbDiagnosticsReport: 122,
    diagnosticParametersReport: 122,
    diagnosticIndicatorsReport: 122,
    jbSummaryReport: 122,
    alertLogReport: 122,
    temperatureReport: 122,
    jbdtcReport: 122,
    chatHistoryReport: 122,
    engineHoursReport: 122,
    stopHistoryReport: 122,
    fsmTimeCardReport: 122,
    timeCardReportV2: 122,
    stopSummaryReport:122,
    formStopSummaryReport:122,
    formStopSummaryReportV2:122,
    driverFormsReport:122,
    driverStatusReport:122,
    smartOneCUtilizationReport:122,
    fuelCardReport:122,
    deviceSummaryInfoReport:122,
    milesPerGallonReport:122,
    vehicleMaintenanceItemsReport:122,
    maintenanceItemsReport:122,
    vehServiceItemsReport:122,
    serviceItemsReport:122,
    inputReport:122,
    chatHistoryReportV2: 122,
    stateMileageCrossingReport:122,
    stateMileageSummariesReport:122,
    // 31 because this is the combination of 4 reports
    fsmCombinedReport: 31,
    behaviorReport:122
  }
};

export default (state = INIT_STATE, action) => {
  let reportIndex
  switch (action.type) {
    case REPORTS_HANDLE_MODAL_EXTRA_TIME:
      return { ...state, open: action.payload.open, option: action.payload.option };
    case CLEAR_REDUX:
      return ["", "REPORTS_MODAL_EXTRA_TIME"].includes(action.payload.option)
        ? action.payload.parameters
          ? { ...state, ...action.payload.parameters }
          : { ...INIT_STATE }
        : { ...state }

    default:
      return state;
  }
};
