import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIconAttributes, validateEventColor } from "../utils";
import { Area, AreaMenu, IconButton, Menu, Radio } from "@gpstrackit/gtc-react-controls";
import { useIntl } from "react-intl";
import { sliceHistoryTrails } from "Redux/actions";
import '../styles.scss'
import MarkerOverlay from "Components/Map/providers/google/MarkerOverlay";
import { orderTrailPolilyne } from "../../../utils";
import { getVidFleetEvent } from 'Core/data/VideoEvents';

export const Map = ({
  map,
  setMap,
  setReadingSelected,
  setNumberSelected
}) => {
  const mapRef = useRef(null);
  const dispatch = useDispatch()
  const [mapType, setMapType] = useState('roadmap');
  const { messages } = useIntl();
  const areaMenuLayerRef = useRef();
  const polylinesRef = useRef([]);
  const markersRef = useRef([]);
  const labelRef = useRef([]);

  const { 
    isOpenHistoryOverViewIncident,
    loadingTrailsIncident,
    errorTrailsIncident,
    trailsIncidentTotal,
    trailsIncident,
    sliceTrails
  } = useSelector((state) => state.incidentsRedux);

  useEffect(() => {
    const mapOptions = {
      zoom: 12,
      center: { lat: -34.397, lng: 150.644 },
      mapTypeControl: false,
      mapTypeId: mapType
    };

    const newMap = new google.maps.Map(mapRef.current, mapOptions);
    setMap(newMap);
  }, [mapType, setMap]);

  useEffect(() => {
    if (!google) {
      return;
    }

    if (!map) {
      const mapOptions = {
        zoom: 12,
        center: { lat: -34.397, lng: 150.644 },
        mapTypeControl: false,
      };

      const newMap = new google.maps.Map(mapRef.current, mapOptions);
      setMap(newMap);
    } else {
      if (!loadingTrailsIncident && sliceTrails?.length > 0) {

        clearPolilyne()
        clearMarkers();
        clearLabels()

        const getOrderTrail = orderTrailPolilyne(sliceTrails)

        getOrderTrail.forEach((markerData, index) => {
          const pathCoordinates = getOrderTrail.slice(index, index + 2).map(data => ({
            lat: data.lat,
            lng: data.lon
          }));

          if (pathCoordinates.length > 1) {

            var dashedLine = {
              //path: 'M 0,-1 0,1',
              path: 'M -2,0 2,0 0,-4 z',
              strokeOpacity: 0.8,
              scale: 3,
              //fillColor: mapObject.color,
              fillOpacity: 0.8,
            };

            const color = validateEventColor(markerData); 
            const polyline = new google.maps.Polyline({
              path: pathCoordinates,
              geodesic: false,
              strokeColor: color,
              strokeOpacity: 0.6,
              strokeWeight: 8,
              clickable: false,
              icons: [{
                icon: dashedLine,
                offset: '0',
                repeat: '200px' // Define la distancia entre cada segmento punteado
              }],
            });

            

            polyline.setMap(map);
            polylinesRef.current.push(polyline);
          }
        });

        sliceTrails.forEach((markerData, index) => {
          const color = validateEventColor(markerData);
          const numberItem = sliceTrails?.length - index;
          // let iconMarker = getIconAttributes(color, markerData?.heading, null, null, {...markerData, hasVideo: true });

          let url = null;
          let imageStyle = {};
          if (markerData?.eventCode && markerData?.eventCode?.includes("CameraEvent")) {
            let event = getVidFleetEvent(markerData?.eventCode);
            url = event.icon;
            imageStyle = {
              anchor: new google.maps.Point(15, 10)  // eslint-disable-line no-undef
            };
          }

          const iconMarker = getIconAttributes(color, markerData.heading, url, imageStyle, markerData)

          const marker = new google.maps.Marker({
            position: { lat: markerData.latitude, lng: markerData.longitude },
            map: map,
            icon: iconMarker,
            info: markerData,
          });


          marker.addListener('click', () => {
            setReadingSelected(markerData);
            filterTrail(index)
            setNumberSelected(numberItem)
          });

          labelRef.current.push(addLabel(marker, numberItem))

          markersRef.current.push(marker);
        });

        const lastEvent = sliceTrails[0];

        map.setCenter({ lat: lastEvent.latitude, lng: lastEvent.longitude });
        setReadingSelected(lastEvent);
      }
    }
  }, [loadingTrailsIncident, sliceTrails, map]);

  const clearMarkers = () => {
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];
  }

  const clearLabels = () => {
    labelRef.current.forEach(labels => labels.setMap(null));
    labelRef.current = [];
  }

  const clearPolilyne = () => {
    polylinesRef.current.forEach(polyline => polyline.setMap(null));
    polylinesRef.current = [];
  }

  const filterTrail = (item = 0) => {
    const newSlice = sliceTrails?.slice(item)
    if(newSlice?.length > 0){
      dispatch(sliceHistoryTrails(newSlice))
    }
  }

  const addLabel = useCallback((mapObject, index) => {
    if (!map) return
  
    let googlePosition = null;

    googlePosition = new google.maps.LatLng(mapObject.position.lat(), mapObject.position.lng()) // eslint-disable-line no-undef

    const bubbleAnchor = document.createElement("div");
    bubbleAnchor.innerHTML = index;
    bubbleAnchor.setAttribute("id", `${index}`);


    const customOverlayLabels = new MarkerOverlay(googlePosition, bubbleAnchor);

    customOverlayLabels.setMap(map);
    return customOverlayLabels;
  });

  return (
    <div style={{ width: '100%', marginLeft: 0 }}>
      <div
        style={{
          position: 'absolute',
          zIndex: 1,
          right: '30px',
          top: '180px'
        }}
      >
        <AreaMenu
          id="areaUser"
          noHideOnBlur={true}
          ref={areaMenuLayerRef}
          noCloseAway={true}
          menuPlacement="left"
        >
          <Area>
            <IconButton
              icon="map"
              style={{
                color: 'black',
                borderRadius: 0,
                background: 'white'
              }}
              tooltip={messages['incident_map_layer']}
              placement="bottom"
            />
          </Area>
          <Menu >
            <div style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Radio
                value="hybrid"
                checked={mapType === 'hybrid'}
                label={messages['incident_map_hybrid']}
                onChange={(e) => setMapType(e)}
              />
              <Radio
                value="roadmap"
                checked={mapType === 'roadmap'}
                label={messages['incident_map_map']}
                onChange={(e) => setMapType(e)}
              />
              <Radio
                value="satellite"
                checked={mapType === 'satellite'}
                label={messages['incident_map_satelite']}
                onChange={(e) => setMapType(e)}
              />
              <Radio
                value="terrain"
                checked={mapType === 'terrain'}
                label={messages['incident_map_terrain']}
                onChange={(e) => setMapType(e)}
              />
            </div>
          </Menu>
        </AreaMenu>
      </div>
      <div className="incidents_history_map" style={{ width: "100%", height: "300px" }} ref={mapRef}></div>
    </div>
  );
};
