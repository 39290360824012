import maintenanceVehiclesTabRedux from '../../views/main/components/vehiclesTab/redux/slice';
import maintenanceServicesTabRedux from '../../views/main/components/servicesTab/redux/slice';
import maintenanceDriversTabRedux from '../../views/main/components/driversTab/redux/slice';
import maintenanceCreateEditMaintenanceServiceRedux from '../../modals/createEditMaintenanceService/redux/slice';
import maintenanceScheduledServicesRedux from '../../views/vehicleDetail/components/scheduledServices/redux/slice';
import maintenancePerformedServicesRedux from '../../views/vehicleDetail/components/performedServices/redux/slice';
import maintenanceEntityDetailsRedux from '../../modals/entityDetails/redux/slice';
import maintenanceManageServiceTasksRedux from '../../modals/manageServiceTasks/redux/slice';
import maintenanceDeleteMaintenanceServiceRedux from '../../modals/deleteMaintenanceService/redux/slice';
import maintenanceScheduleDriverTasksRedux from '../../modals/scheduleDriverTask/redux/slice';
import maintenanceCreateDriverTasksRedux from '../../modals/createEditDriverTask/redux/slice';
import maintenanceManageTasksRedux from '../../modals/manageTasks/redux/slice';
import maintenanceScheduleTasksTabRedux from '../../views/driverDetail/.components/scheduleTasks/redux/slice';
import maintenanceScheduleTasksLogsTabRedux from '../../views/driverDetail/.components/scheduleTasksLog/redux/slice';
import maintenanceDeleteDriverScheduledTaskRedux from '../../modals/deleteDriverScheduledTask/redux/slice';
import maintenanceCompleteDriverScheduledTaskRedux from '../../modals/completeDriverScheduledTask/redux/slice';
import maintenanceCompleteMaintenanceServiceRedux from '../../modals/completeMaintenanceService/redux/slice';
import maintenanceServiceDetailRedux from '../../views/serviceDetail/redux/slice';
import maintenanceExportVehiclesRedux from '../../views/main/components/vehiclesTab/components/exportVehicles/redux/slice';
import maintenanceDeleteMaintenanceServiceUnitRedux from '../../modals/deleteMaintenanceServiceUnit/redux/slice';
import maintenanceScheduleMaintenanceServiceRedux from '../../modals/scheduleMaintenanceService/redux/slice';
import maintenanceManageDriverTasksScheduledRedux from '../../modals/manageDriverTasksScheduled/redux/slice';

export default {
    maintenanceVehiclesTabRedux,
    maintenanceServicesTabRedux,
    maintenanceDriversTabRedux,
    maintenanceCreateEditMaintenanceServiceRedux,
    maintenanceScheduledServicesRedux,
    maintenancePerformedServicesRedux,
    maintenanceEntityDetailsRedux,
    maintenanceManageServiceTasksRedux,
    maintenanceDeleteMaintenanceServiceRedux,
    maintenanceScheduleDriverTasksRedux,
    maintenanceCreateDriverTasksRedux,
    maintenanceManageTasksRedux,
    maintenanceScheduleTasksTabRedux,
    maintenanceScheduleTasksLogsTabRedux,
    maintenanceDeleteDriverScheduledTaskRedux,
    maintenanceCompleteDriverScheduledTaskRedux,
    maintenanceCompleteMaintenanceServiceRedux,
    maintenanceServiceDetailRedux,
    maintenanceExportVehiclesRedux,
    maintenanceDeleteMaintenanceServiceUnitRedux,
    maintenanceScheduleMaintenanceServiceRedux,
    maintenanceManageDriverTasksScheduledRedux,
};