import {
    //TYPES
    MAP_UTILITIES_VISIBLE_TAGS_SHOW_MODAL,
    MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP,
    MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP_CONFIRM
} from 'Redux/actionTypes';

export const modalVehicleGroups = (display) => {
    return {
        type: MAP_UTILITIES_VISIBLE_TAGS_SHOW_MODAL,
        payload: { display }
    };
}

export const updateUnitGroupModal = (loading = false) =>{
  return {
    type: MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP,
    payload: loading
  };
} 

export const updateUnitGroupColorConfirmResult = (result, error) => ({
	type: MAP_UTILITIES_VISIBLE_UPDATE_UNIT_GROUP_CONFIRM,
	payload: { result, error },
});