export const REALTIME_MAP_CENTER_UNIT = "REALTIME_MAP_CENTER_UNIT";
export const REALTIME_MAP_ADD_POINTERS = "REALTIME_MAP_ADD_POINTERS";
export const REALTIME_MAP_ADD_NOT_CENTER_POINTERS = "REALTIME_MAP_ADD_NOT_CENTER_POINTERS";
export const REALTIME_MAP_ADD_POINTERS_MORE_ITEMS = "REALTIME_MAP_ADD_POINTERS_MORE_ITEMS";
export const REALTIME_MAP_ADD_POINTERS_RESPONSE = "REALTIME_MAP_ADD_POINTERS_RESPONSE";
export const REALTIME_MAP_CLICK_LATLNG = "REALTIME_MAP_CLICK_LATLNG";
export const REALTIME_MAP_CLICK_LATLNG_RESTART = "REALTIME_MAP_CLICK_LATLNG_RESTART";
export const REALTIME_MAP_GET_KMLS = "REALTIME_MAP_GET_KMLS";
export const REALTIME_MAP_GET_JSON_CONFIRM = "REALTIME_MAP_GET_JSON_CONFIRM";
export const REALTIME_MAP_UPDATE_KMLS = "REALTIME_MAP_UPDATE_KMLS";
export const REALTIME_MAP_UPDATE_KMLS_COMFIRM = "REALTIME_MAP_UPDATE_KMLS_COMFIRM";
export const REALTIME_MAP_SAVE_KMLS = "REALTIME_MAP_SAVE_KMLS";
export const REALTIME_MAP_LISTEN_ON_CLICK_MAP = "REALTIME_MAP_LISTEN_ON_CLICK_MAP";
export const REALTIME_MAP_SET_ON_CLICK_MAP_POSITION = "REALTIME_MAP_SET_ON_CLICK_MAP_POSITION";
export const REALTIME_MAP_SAVE_LOADING = "REALTIME_MAP_SAVE_LOADING";
export const REALTIME_MAP_ZOOM_POINT = "REALTIME_MAP_ZOOM_POINT";
export const REALTIME_MAP_ADD_RADIUS = "REALTIME_MAP_ADD_RADIUS";
export const REALTIME_MAP_GET_DIRECTIONS = "REALTIME_MAP_GET_DIRECTIONS";
export const REALTIME_MAP_GET_DIRECTIONS_OPTIONS = "REALTIME_MAP_GET_DIRECTIONS_OPTIONS";
export const REALTIME_MAP_GET_DIRECTIONS_OPTIONS_RESTART = "REALTIME_MAP_GET_DIRECTIONS_OPTIONS_RESTART";
export const REALTIME_MAP_GET_DIRECTIONS_ERROR = "REALTIME_MAP_GET_DIRECTIONS_ERROR";
export const REALTIME_MAP_RESTART = "REALTIME_MAP_RESTART";
export const REALTIME_MAP_GET_OFFLINE_DEVICES = "REALTIME_MAP_GET_OFFLINE_DEVICES";
export const REALTIME_MAP_GET_OFFLINE_DEVICES_RESPONSE = "REALTIME_MAP_GET_OFFLINE_DEVICES_RESPONSE";
export const REALTIME_MAP_FOLLOW_UNIT = "REALTIME_MAP_FOLLOW_UNIT";
export const REALTIME_MAP_PRINT_VIEW = "REALTIME_MAP_PRINT_VIEW";
export const REALTIME_MAP_GET_LANDMARK_CONFIRM = "REALTIME_MAP_GET_LANDMARK_CONFIRM";
export const REALTIME_MAP_GET_GEOFENCE_CONFIRM = "REALTIME_MAP_GET_GEOFENCE_CONFIRM";
export const REALTIME_MAP_LOAD_OBJECT_LANDMARK = "REALTIME_MAP_LOAD_OBJECT_LANDMARK";
export const REALTIME_MAP_LOAD_OBJECT_GEOFENCE = "REALTIME_MAP_LOAD_OBJECT_GEOFENCE";
export const REALTIME_MAP_UPDATE_ADD_LANDMARK = "REALTIME_MAP_UPDATE_ADD_LANDMARK";
export const REALTIME_MAP_UPDATE_ADD_GEOFENCE = "REALTIME_MAP_UPDATE_ADD_GEOFENCE";
