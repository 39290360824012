// Reducers
import videoMainRedux from 'Modules/video/views/main/redux/reducers';
import videoAdvancedFiltersRedux from 'Modules/video/views/main/.components/advancedFilters/redux/reducers';
import eventsRedux from 'Modules/video/views/main/.components/events/redux/reducer';
import clipsRedux from 'Modules/video/views/main/.components/clips/redux/reducer';
import manageCamerasRedux from 'Modules/video/views/manageCamerasTab/redux/reducer';
import videoDetailRedux from 'Modules/video/views/videoDetail/redux/reducer';

export default {
  videoMainRedux,
  videoAdvancedFiltersRedux,
  eventsRedux,
  clipsRedux,
  manageCamerasRedux,
  videoDetailRedux
};