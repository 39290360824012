export default {
  unit_settings_tab: "Settings",
  unit_settings_groups: "Tags",
  unit_settings_label_and_icon: "Unit Label and Icon Type",
  unit_settings_unit: "Unit",
  unit_settings_speed: "Speed",
  unit_settings_calculated_by_ecu: "Calculated by ECU",
  unit_settings_calculated_by_gps: "Calculated by GPS",
  unit_settings_odometer: "Odometer",
  unit_settings_odometer_dash_reading: "Dash Reading",
  unit_settings_last_updated: "Last update",
  unit_settings_engine_hours: "Engine Hours",
  unit_settings_actual: "Actual",
  unit_settings_consistent_speed: "Consistent Speed",
  unit_settings_driver: "Driver",
  unit_settings_location: "Location",
  unit_settings_time_zone: "Timezone",
  unit_settings_data: "Data",
  unit_settings_input_labels: "Input Labels",
  unit_settings_output_labels: "Output Labels",
  unit_settings_notes: "Notes",
  unit_settings_make: "Make",
  unit_settings_model: "Model",
  unit_settings_year: "Year",
  unit_settings_date_validation_message: "Please enter a valid 4-digit year,from 1950 to",
  unit_settings_engine_liters:"Engine Liters",
  unit_settings_engine_liters_validation_message: "you can add up to 10000 engine liters",
  unit_settings_input_label: "Input Label",
  unit_settings_output_label: "Output Label",
  unit_settings_type: "Type",
  unit_settings_highway: "Highway",
  unit_settings_city: "City",
  unit_settings_groups_units_description: "The selected units will belong to the following groups:",
  unit_settings_tags_units_description: "The selected units will belong to the following tags:",
  unit_settings_edit_multiple_units: "Bulk settings",
  unit_settings_units: "Units",
  unit_settings_add_or_delete_groups_units: "Add or delete groups",
  unit_settings_update_groups: "Groups updated successfully.",
  unit_settings_tags: "Add a Tag",
  unit_settings_tags_search: "Search available tags",
  unit_settings_tags_shape: "Landmark Shape",
  unit_settings_tags_label: "Landmark Label",
  unit_settings_tags_type: "Create Using",
  unit_settings_tags_simple: "Tags",
  unit_settings_tags_required: "You must select a least one tag",
  unit_settings_custom: "Custom",
  unit_settings_alert_contacts_required: "You must select a least one contact",
  unit_settings_alert_tags_required: "You must select a Tag",
  unit_settings_modal_icon_type: "Select an icon type for this unit:",
}