import {
    SETTINGS_CAMERA_MANAGE_EDIT_OPEN,
    SETTINGS_CAMERA_MANAGE_EDIT_OPEN_RESULT,
    SETTINGS_CAMERA_MANAGE_EDIT_CLOSE,
    SETTINGS_CAMERA_MANAGE_EDIT_SAVE,
    SETTINGS_CAMERA_MANAGE_EDIT_RESULT
} from "Redux/actionTypes";

export const searchSettingsCameraManageEditOpen = (id) => {
    return {
        type: SETTINGS_CAMERA_MANAGE_EDIT_OPEN,
        payload: { id }
    };
};

export const searchSettingsCameraManageEditOpenResponse = (data) => {
    return {
        type: SETTINGS_CAMERA_MANAGE_EDIT_OPEN_RESULT,
        payload: data
    };
};

export const searchSettingsCameraManageEditClose = () => {
    return {
        type: SETTINGS_CAMERA_MANAGE_EDIT_CLOSE,
        //payload: { id }
    };
};


export const searchSettingsCameraManageEditSave = (id, unitId) => {
    return {
        type: SETTINGS_CAMERA_MANAGE_EDIT_SAVE,
        payload: { id, unitId }
    };
};

export const searchSettingsCameraManageEditSaveResponse = (data) => {
    return {
        type: SETTINGS_CAMERA_MANAGE_EDIT_RESULT,
        payload: data
    };
};
