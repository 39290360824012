//View Keys
export default {
  eta_subtitle: 'Genere un enlace que permita a otros ver el tiempo estimado de llegada de un vehículo para que sepan cuándo esperar que el conductor llegue a su ubicación.',
  eta_cancel: 'Cancelar',
  eta_share: 'Compartir',
  eta_send: 'Enviar',
  eta_vehicle: "Vehículo",
  eta_choose_vehicle: "Seleccionar Vehículo:",
  eta_destination: "Destino",
  eta_choose_destination: "Seleccionar Destino Usando:",
  eta_recipient: "Destinatarios",
  eta_choose_contact: "Seleccionar Método de Contacto:",
  eta_link_expire: "** El enlace caducará cuando el vehículo llegue al destino o después de 8 horas",
  eta_link_error: "Ha ocurrido un error, por favor intente de nuevo",
  eta_link_success: "Link enviado y copiado al portapapeles",
  eta_link_error_destination: "Por favor seleccione el destino",
  eta_link_error_recipient: "Por favor seleccione un contacto",
  eta_type_to_search: "Escriba para buscar"
};