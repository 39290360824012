//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientPOST, clientGET } from 'Core/data/RestApi';
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { isValidJson } from 'Core/data/Helpers'
import moment from 'moment';

//Action types
import {
  VIDFLEET_VIDEO_START_STREAMING,
  VIDFLEET_VIDEO_START_LIVE_PHOTO,
  VIDFLEET_VIDEO_STOP_STREAMING,
  VIDFLEET_VIDEO_GET_STREAMING_STATUS,
  VIDFLEET_VIDEO_GET_CLIP_BY_ID,
  VIDFLEET_VIDEO_GET_PICTURE_BY_ID,
  VIDFLEET_VIDEO_GET_ONLINE_STATUS,
  VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS
} from 'Redux/actionTypes';

//actions
import {
  startStreamingResponse,
  startLivePhotoResponse,
  stopStreamingResponse,
  getStreamingStatusResponse,
  getClipByIdResponse,
  getPictureByIdResponse,
  getCameraOnlineStatusResponse,
  getLivePhotoStatusResponse
} from 'Redux/actions';

/* FUNTIONS ASYNC */
function* startLivePhotoRequest({ payload }) {
  const { data } = payload;
  const { esn, unitId } = data;

  try {
    const response = yield call(
      clientMutation,
      `
      mutation startVidFleetLivePhoto ($unitId: Int!) {
        response: startVidFleetLivePhoto (unitId: $unitId) {
          transactionId
          status
          rotate
          playUrl
        }
      }
      `,
      {
        unitId: parseInt(unitId)
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response?.response) {
      throw 'Error while starting VidFleet live photo streaming: ' + unitId;
    }

    let res = {};
    res[esn] = response?.response;

    yield put(startLivePhotoResponse(res));
  } catch (exc) {
    // catch throw
    let res = {};
    res[esn] = { error: true, message: 'vidFleet_errorAccessDenied' };
    yield put(startLivePhotoResponse(res));
    ExceptionManager(exc, 'modules/.common/components/VidFleetVideo/redux', 'Error while starting VidFleet live photo streaming');
  }
}

function* startStreamingRequest({ payload }) {
  const { data } = payload;
  const { esn, unitId } = data;

  try {
    const response = yield call(
      clientMutation,
      `
      mutation startVidFleetLiveStreaming ($unitId: Int!) {
        response: startVidFleetLiveStreaming (unitId: $unitId) {
          transactionId
          status
          rotate
          playUrl
        }
      }
      `,
      {
        unitId: parseInt(unitId)
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response?.response) {
      throw 'Error while starting VidFleet live streaming: ' + unitId;
    }

    let res = {};
    res[esn] = response?.response;

    yield put(startStreamingResponse(res));
  } catch (exc) {
    // catch throw
    let res = {};
    res[esn] = { error: true, message: 'vidFleet_errorAccessDenied' };
    yield put(startStreamingResponse(res));
    ExceptionManager(exc, 'modules/.common/components/VidFleetVideo/redux', 'Error while starting VidFleet camera streaming');
  }
}

function* stopStreamingRequest({ payload }) {
  const { data } = payload;
  const { esn, unitId } = data;

  try {
    const response = yield call(
      clientMutation,
      `
      mutation stopVidFleetLiveStreaming ($unitId: Int!) {
        response: stopVidFleetLiveStreaming (unitId: $unitId) {
          transactionId
          status
          rotate
          playUrl
        }
      }
      `,
      {
        unitId: parseInt(unitId)
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response?.response) {
      throw 'Error while stoping VidFleet live streaming: ' + unitId;
    }

    let res = {};
    res[esn] = response?.response;

    yield put(stopStreamingResponse(res));
  } catch (exc) {
    // catch throw
    ExceptionManager(exc, 'modules/.common/components/VidFleetVideo/redux', 'Error while stoping VidFleet camera streaming');
  }
}

function* getStreamingStatusRequest({ payload }) {
  const { data } = payload;
  const { esn, unitId } = data;

  try {
    const response = yield call(
      clientQuery,
      `
      query getVidFleetLiveStreamingStatus ($unitId: Int!) {
        response: getVidFleetLiveStreamingStatus (unitId: $unitId) {
          transactionId
          status
          rotate
          playUrl
        }
      }
      `,
      {
        unitId: parseInt(unitId)
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response?.response) {
      throw 'Error while getting VidFleet streaming status: ' + unitId;
    }

    let res = {};
    res[esn] = { ...response?.response, id: Math.random() * 1000 };

    yield put(getStreamingStatusResponse(res));
  } catch (exc) {
    // catch throw
    ExceptionManager(exc, 'modules/.common/components/VidFleetVideo/redux', 'Error while getting VidFleet streaming status');
  }
}

function* getLivePhotoStatusRequest({ payload }) {
  const { data } = payload;
  const { esn, unitId, transactionId } = data;

  try {
    const response = yield call(
      clientQuery,
      `
      query getVidFleetLivePhotoStatus ($unitId: Int!, $transactionId: String!) {
        response: getVidFleetLivePhotoStatus (unitId: $unitId, transactionId: $transactionId)
      }
      `,
      {
        unitId: parseInt(unitId),
        transactionId: transactionId
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response?.response) {
      throw 'Error while getting VidFleet live photo status: ' + unitId;
    }

    if (!isValidJson(response?.response)) {
      throw 'Error while getting VidFleet live photo status: ' + unitId;
    }

    const result = JSON.parse(response?.response);

    let res = {};
    res[esn] = { ...result, id: Math.random() * 1000 };

    yield put(getLivePhotoStatusResponse(res));
  } catch (exc) {
    // catch throw
    ExceptionManager(exc, 'modules/.common/components/VidFleetVideo/redux', 'Error while getting VidFleet live photo status');
  }
}

function* getClipByIdRequest({ payload }) {
  const { data: clipId } = payload;

  let res = {};
  res[clipId] = {};

  try {
    const response = yield call(
      clientQuery,
      `
      query getVidFleetClipById ($clipId: String!) {
        response: getVidFleetClipById (clipId: $clipId)
      }
      `,
      {
        clipId: clipId + ""
      },
      endpoints.GRAPHQL_GENERAL,
      {
        'x-api-key': endpoints.PUBLIC_API_KEY
      }
    );

    if (!response?.response) {
      throw 'Error while getting clip url video VidFleet camera: ' + clipId;
    }

    if (!isValidJson(response?.response)) {
      throw 'Error while getting clip url video VidFleet camera: ' + clipId;
    }

    const result = JSON.parse(response?.response);

    if (result) {
      res[clipId] = result;

      yield put(getClipByIdResponse(res));
    }
  } catch (exc) {
    res[clipId] = {error: true};
    yield put(getClipByIdResponse(res));
    ExceptionManager(exc, 'modules/.common/components/VidFleetVideo/redux', 'Error while getting clip url video VidFleet camera');
  }
}

function* getPictureByIdRequest({ payload }) {
  const { data: pictureId } = payload;

  let res = {};
  res[pictureId] = {};

  try {
    const response = yield call(
      clientQuery,
      `
      query getVidFleetPictureById ($pictureId: String!) {
        response: getVidFleetPictureById (pictureId: $pictureId)
      }
      `,
      {
        pictureId: pictureId + ""
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response?.response) {
      throw 'Error while getting picture url by id VidFleet camera: ' + pictureId;
    }

    if (!isValidJson(response?.response)) {
      throw 'Error while getting picture url by id VidFleet camera: ' + pictureId;
    }

    const result = JSON.parse(response?.response);

    if (result) {
      res[pictureId] = result;
      res[pictureId].expiredTime = moment().add(1, 'h').unix();

      yield put(getPictureByIdResponse(pictureId, res));
    }
  } catch (exc) {
    res[pictureId] = {error: true};
    yield put(getPictureByIdResponse(pictureId, res));
    ExceptionManager(exc, 'modules/.common/components/VidFleetVideo/redux', 'Error while getting picture url by id VidFleet camera');
  }
}

function* getCameraOnlineStatusRequest({ payload }) {
  const { data } = payload;
  const { esn, unitId } = data;

  try {
    const response = yield call(
      clientQuery,
      `
      query getVidFleetOnlineStatus ($unitId: Int!) {
        response: getVidFleetOnlineStatus (unitId: $unitId)
      }
      `,
      {
        unitId: parseInt(unitId)
      },
      endpoints.GRAPHQL_GENERAL
    );

    if (!response?.response) {
      throw 'Error while getting VidFleet camera online status: ' + unitId;
    }

    if (!isValidJson(response?.response)) {
      throw 'Error while getting VidFleet camera online status: ' + unitId;
    }

    const result = JSON.parse(response?.response);

    if (result) {
      let signal = "unknown";
      if (result?.RSRP >= -84) {
        signal = "excellent";
      } else if (result?.RSRP < -84 && result?.RSRP >= -102) {
        signal = "good";
      } else if (result?.RSRP < -102 && result?.RSRP >= -111) {
        signal = "fair";
      } else if (result?.RSRP < -111) {
        signal = "poor";
      }

      let res = {};
      res[esn] = { ...result, signal };

      yield put(getCameraOnlineStatusResponse(res));
    }
  } catch (exc) {
    // catch throw
    let res = {};
    res[esn] = { error: true, message: 'vidFleet_errorAccessDenied' };
    yield put(getCameraOnlineStatusResponse(res));
    ExceptionManager(exc, 'modules/.common/components/VidFleetVideo/redux', 'Error while getting VidFleet camera online status');
  }
}


function* watchStartLivePhotoRequest() {
  yield takeEvery(VIDFLEET_VIDEO_START_LIVE_PHOTO, startLivePhotoRequest);
}

function* watchStartStreamingRequest() {
  yield takeEvery(VIDFLEET_VIDEO_START_STREAMING, startStreamingRequest);
}

function* watchStopStreamingRequest() {
  yield takeEvery(VIDFLEET_VIDEO_STOP_STREAMING, stopStreamingRequest);
}

function* watchGetStreamingStatusRequest() {
  yield takeEvery(VIDFLEET_VIDEO_GET_STREAMING_STATUS, getStreamingStatusRequest);
}

function* watchGetClipByIdRequest() {
  yield takeEvery(VIDFLEET_VIDEO_GET_CLIP_BY_ID, getClipByIdRequest);
}

function* watchGetPictureByIdRequest() {
  yield takeEvery(VIDFLEET_VIDEO_GET_PICTURE_BY_ID, getPictureByIdRequest);
}

function* watchGetCameraOnlineStatusRequest() {
  yield takeEvery(VIDFLEET_VIDEO_GET_ONLINE_STATUS, getCameraOnlineStatusRequest);
}

function* watchGetLivePhotoStatusRequest() {
  yield takeEvery(VIDFLEET_VIDEO_GET_LIVE_PHOTO_STATUS, getLivePhotoStatusRequest);
}

//merge sagas
export default function* rootSaga() {
  yield all([
    fork(watchStartLivePhotoRequest),
    fork(watchStartStreamingRequest),
    fork(watchStopStreamingRequest),
    fork(watchGetStreamingStatusRequest),
    fork(watchGetClipByIdRequest),
    fork(watchGetPictureByIdRequest),
    fork(watchGetCameraOnlineStatusRequest),
    fork(watchGetLivePhotoStatusRequest),
  ]);
}
