import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _, { isNumber } from 'lodash';
import { Icon, Grid } from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { Button, LinearProgress, Select, Tooltip } from 'Components';

// controls
import InfiniteScrollTable from 'Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable'

//helpers
import {
  formatDatetime,
  formatTimeDelta,
  formatGeneralNumber,
  postedSpeedColor,
  handleSignalIcon,
  isJson,
  getPlaceholderSelector,
  getNameUnitDriverForSubtables,
  getNameUnitDriverTag,
  useHandleExportReport,
  getFilterNameByEntity,
  isKPH,
  formatDateFromFilter,
  getGroupId, isKPHAcronymSpeed
} from 'Modules/reports/views/main/components/reportsTab/helper';

//hooks
import { useSidePanelMap, useReportsSelector } from 'Core/hooks';
import { GetFormatForMoment } from 'Core/data/Helpers';

//actions
import {
  reportsMainGenerateReportUpdate,
  openFleetLandmarkModal,
  clearRedux
} from 'Redux/actions'

import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark'

// styles
import './driverSafetyReport.scss';
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";
import { isKPHAcronym } from '../../../helper';

const ROW_PER_PAGE = 25;
const DriverSafetyReport = (props) => {

  const { messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive } = props;
  const { loading, asyncLoading, data, filters, timeZone } = report
  const { filterEntity, filterArray, filterType } = filters;
  const dispatch = useDispatch()
  const [lastEntity, setLastEntity] = useState({});

  const exportHandler = useHandleExportReport(report)

  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();

  const momentFormat = GetFormatForMoment(user.dateformat)

  const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
  const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)



  const reportRef = useRef();
  const mileageReportTableRef = useRef();
  const entitiesArrayRef = useRef();
  const lastEntityRef = useRef({});
  const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)
  const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity: null;
  const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected: null;

  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if(isActive){
      reportTableSelect.onChangeResetCache();
    }
  }, [isActive])

  const buildTableHeader = () => {
    return (
      <Grid  container item  direction={"row"} className={"table-scroll-reports-title-details"} style={{margin: '10px 0'}}>
        <Grid item sm={2} className={'reports-cut-text'}>
          {filters.filterType !== 'deviceId' ? messages['generatedReport_unit'] : messages['generatedReport_driver'] }
        </Grid>
        <Grid item sm={2} className={'reports-cut-text'}>
          {messages['reportsMainRT_TimeDate']}
        </Grid>
        <Grid item sm={3} className={'reports-cut-text'}>
          {messages["generatedReport_address"]}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text'}>
          {messages['generatedReport_speed']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text'}>
          {messages['generatedReport_direction']}
        </Grid>
        <Grid item sm={2} className={'reports-cut-text'}>
          {messages['generatedReport_eventName']}
        </Grid>
        <Grid item sm={1} className={'reports-cut-text'}>
          {messages['generatedReport_signal']}
        </Grid>

      </Grid>
    )
  }

  const TableViewGroup = ({index, style, content, id, previousItem}) => {
    const element = content?.recordInfo;
    const recordInfo = content?.recordInfo;

    return (
      <div
        className="listItem groupItem"
        style={style}
        id={id}
        key={id}
      >

        <Grid container  direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group'>
          <Grid container item className='table-scroll-reports-header'>
            <Grid item sm={3}>

              {
                element?.tagId > 0 ?
                  (<LocalOfferIcon
                    style={{fontSize: "16.8px", color: `#${element.groupTagColor && element.groupTagColor != "ffffff" ? element.groupTagColor : "000"}`, position: 'absolute'}}/>)
                  : ""
              }
              <span className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding':''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)}`}</span>
              {
                report?.filters?.filterArray.length !== 1 && (<span  className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)
              }</Grid>
            <Grid item sm={9}>
              <span className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} ({getAbbvTimezone(timeZone)})</span>
            </Grid>
          </Grid>
          <div className='table-scroll-reports-separator'></div>
          <Grid item>
            <p className='table-scroll-reports-label'>{element?.entityLabel}</p>
          </Grid>
        </Grid>
      </div>
    )
  }

  const TableViewSummary = ({ index, style, content, id, previousItem }) => {

    const element = content?.recordDetail;

    return (
      <div
        className="listItem groupItemSummary"
        style={style}
        id={id}
      >
        <Grid container direction={"column"} alignItems={"flex-start"} style={{ margin: "5px 0" }}>
          <Grid container item className='table-scroll-reports-body'>
            <Grid item className={ "report-ctn-text" } sm={ 12 }>
              <span style={ { fontWeight: '600', marginRight: '6rem' } }>{ formatDatetime( element.earliestDate, "dddd, LL", timeZone) }</span>
              <span style={ { fontWeight: '600' } }>
                {`${messages["reportsMainRT_timeFrame"]}:           ${formatDatetime( element.earliestDate, "h:mm A", timeZone )} - ${formatDatetime( element.latestDate, "h:mm A", timeZone )}`}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const TableViewDetail = ({index, style, content, id, previousItem}) => {
    const element = content?.recordDetail;

    return (
      <div
        className="listItem"
        style={style}
        id={id}
        key={id}
      >
        {previousItem?.recordInfo.recordType !== "DETAIL" ? buildTableHeader() : null}
        <Grid   container  direction={"column"} style={{marginBottom: "15px"}}>
          <Grid container item  direction={"row"} style={{paddingTop: "1px", paddingBottom: "1px"}}>
            {
              element.noData ?
                <Grid item className={"report-ctn-text"} sm={12}>
                  {messages["reportsMainRT_noDataEvent"]}
                </Grid>
                :
                <>
                  <Grid item className={"report-ctn-text"} sm={2}>
                    {filterEntity === "Unit" ? element?.driverName || '' : element?.deviceLabel || ''}
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={2}>
                    {element.unitTime && formatDatetime(element.unitTime, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone)}
                  </Grid>
                  <Grid item className={"report-ctn-text"} sm={3}>
                    <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={element} iconStyle={{fontSize: "18px"}} classNameButtonAddress={"report-ctn-text-address-left"}/>
                  </Grid>

                  <Grid item className={ "report-ctn-text" } sm={ 1 }>
                    <div className={ `detailed-report-speed` } style={ { color: postedSpeedColor( element.speed, element.speedLimit ) } }>
                      <b><span>{ `${Math.round( element.speed )} ` || "0 " }</span>
                        { isKPHAcronymSpeed( element.isKPH ) } (e)
                      </b>
                    </div>
                  </Grid>
                  <Grid item className={ "report-ctn-text" } sm={ 1 }>
                    <span>
                      { element.direction }
                    </span>
                  </Grid>
                  <Grid item className={ "report-ctn-text" } sm={ 2 }>
                    <EventReports element={ { event: element.event || '' } }/>
                  </Grid>
                  <Grid item className={ "report-ctn-text"} sm={1}>
                    <Tooltip
                      title={element.signal || ''}
                      placement="top"
                    >
                      {handleSignalIcon(element.signal || '')}
                    </Tooltip>
                  </Grid>

                </>
            }
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderItems = (parameters) => {
    const {content} = parameters;
    const type = content.recordInfo?.recordType;
    if (type === "GROUP") {
      const groupId = getGroupId(content)
      const entitiesArray = entitiesArrayRef.current || {};
      if(!entitiesArray[groupId]){
        const newEntityArray = {...entitiesArray};
        newEntityArray[groupId] = content;
        entitiesArrayRef.current = newEntityArray
      }

      // skip the first group beacuse the first group is replaced with the sticky header
      if(parameters.index === 0){
        return <></>;
      }
      return (
        <TableViewGroup {...parameters}></TableViewGroup>
      )
    }
    else if (type === "UNIT_SUMMARY") {
      return <TableViewSummary {...parameters}></TableViewSummary>;
    }
    else if (type === "DETAIL") {
      return <TableViewDetail {...parameters}></TableViewDetail>;
    }
    ;

    return (
      <div>
        unknown type
      </div>
    )
  }

  const renderHeader = () => {
    const groupId = getGroupId(lastEntity);
    const entitiesArray = entitiesArrayRef.current || {};
    const itemHeader = entitiesArray[groupId];

    if(itemHeader){
      return (
        <TableViewGroup
          style={{
            backgroundColor: '#fff',
            position: 'sticky',
            top: 0,
            zIndex: 9

          }}
          content={itemHeader}
          id={itemHeader?.recordInfo?.entityId}
        ></TableViewGroup>
      );
    }
    return;
  };

  const onStartIndex = (index) => {
    const item = data?.items[index];
    if(item) {
      if(item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId || item.recordInfo?.tagId !== lastEntityRef.current.recordInfo?.tagId){
        setLastEntity(item)
      }
      lastEntityRef.current = item
    }
  }
  const findTag = filterArray?.findIndex((item) => item.name == "Tag")
  const hasTag = findTag >= 0;
  const type = hasTag ? messages['generatedReport_tag'] :
    (report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver']);
  return (
    <>
      <InfiniteScrollTable
        refContainer={mileageReportTableRef}
        renderItems={renderItems}
        loading={asyncLoading || loading || !isActive}
        data={data.items || []}
        total={data.total}
        reportTitle={messages['reportsMainRT_driverSafetyReport']}
        editReport={()=> {
          copyReportToSetup({...report, data: []})
        }}
        onSchedule={() => {
          scheduleReport(report)
        }}
        onCreate={openReportSetup}
        tableContainerClass={"detailed-report-reports-container"}
        type={type}
        handleNewPageLoad={(page) => {
          let currentReport = {...report};
          currentReport.exportType = "json";
          currentReport.filters = {
            ...currentReport.filters,
            unitsSelected: unitDriverSelected?.id != -99 ? unitDriverSelected: null,
            accumulateData: true,
            dateSelected: dateSelected?.id != -99 ? unitDriverSelected: null,
          };
          dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
        }}
        renderHeader={renderHeader}
        onStartIndex={onStartIndex}
        {...reportTableSelect}
        {...exportHandler}
      />
      <SideMapComponent hiddenButton hiddenSearchMap overlayClass={"overlay-class-reports"}/>
    </>
  );
};

export default DriverSafetyReport;
