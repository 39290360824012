import React, { useState, useEffect, forwardRef, useCallback, cloneElement, useImperativeHandle } from 'react';
// Utils
import PropTypes from 'prop-types';
import _ from 'lodash';
import InfiniteScroll from "react-infinite-scroll-component";
// Material UI Components
// TableView Components
import CardView from './Views/Card';
import ListView from './Views/List';
import Pagination from './Pagination';
import ToolbarComponent from './Toolbar';
export const Toolbar = ToolbarComponent;
export * from './Toolbar';

// Components
import { LinearProgress } from 'Components';

//Styles
import './tableView.scss';
import { Grid } from '@mui/material';

/**
 * The TableView is a data grid that supports multiple ways of display data, this could be cards, a table, etc.
 * This is a core component of the Cloud React Project, please be sure of the changes that you made in this component,
 * the idea is be very organice and do not affect the performance and the structure of the component.
 */
const TableView = forwardRef((props, ref) => {
  // The variable data is necesary for to have a copy of the initial data, for to know when the data changes from the parent.
  const [data, setData] = useState(props.data || []);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(props.rowsPerPage || 50);
  const [rowsCount, setRowsCount] = useState(props.total || (rows ? rows.length : 0));
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  useImperativeHandle(ref, () => ({
    setNewPage: setNewPage,
    setNewRowsPerPage: setNewRowsPerPage
  }));

  useEffect(() => {
    if (props.page !== undefined && props.page !== null) {
      setPage(props.page);
    }
  }, [props.page]);

  useEffect(() => {
    if (!props.serverSide) {
      processData({});
    }
  }, [props.serverSide]);

  useEffect(() => {
    if (props.total != undefined && props.total != null) {
      setRowsCount(props.total);
    }
  }, [props.total])

  // When data changes we need to reset the grid
  useEffect(() => {
    if (!_.isEqual(data, props.data)) {
      setData(props.data);
      // When server side is true, the table view relegates the pagination to the parent.
      // The parent must sent the onChangePage, onChangeSearch, onChangeRowsPerPage, data, and total props
      if (props.serverSide) {
        setRows(props.data);
        setRowsCount(props.total);
      } else {
        setRows([]);
        processData({});
      }
    }
  }, [props.data, props.serverSide, props.total]);

  useEffect(() => {
    if (props.infiniteScroll === false) {
      // When the infinite scroll change to false, we need to reset the page to 1
      if (props.serverSide) {
        setPage(1);
        props.onChangePage(1);
      } else {
        processData({ newPage: 1 });
      }

      setSelectedRows([]);
    } else if (props.infiniteScroll === true) {
      // When the infinite scroll change to true, we need to reset the page to 1, reset the data and set rows per page to 25, we 
      // need enough data to have a good height and to show the scroll.
      if (props.serverSide) {
        setPage(1);
        setRowsPerPage(25);
        props.onChangePage(1);
        if (props.onChangeRowsPerPage) props.onChangeRowsPerPage(25);
      } else {
        processData({ newPage: 1, newRowsPerPage: 25, resetRows: true });
      }
      setSelectedRows([]);
    }
  }, [props.infiniteScroll, props.serverSide]);

  // When loading is true we need to reset the selected rows
  useEffect(() => {
    if (props.loading) {
      setSelectedRows([]);
    }
  }, [props.loading]);

  useEffect(() => {
    props.setSelectedRows && props.setSelectedRows(selectedRows);
  }, [selectedRows])

  const setNewPage = useCallback((newPage) => {
    setPage(newPage);
  });

  const setNewRowsPerPage = useCallback((rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
  });

  // Main function to calculate pagination, filtering, etc
  const processData = useCallback(({
    newSearchText,
    newPage,
    newRowsPerPage,
    resetRows
  }) => {
    let currentSearchText = newSearchText != undefined ? newSearchText : searchText;
    let newRows = [];
    let rowsCount = 0;

    // Searching in all the data by search text string, the problem is the multidimensional objects
    // the performance search could be affected
    if (!currentSearchText || (currentSearchText && currentSearchText.length <= 0)) {
      newRows = [...props.data];
    } else {
      props.data.forEach((row) => {
        let values = Object.values(row);

        let match = false;
        values.forEach((value) => {
          if (typeof value !== 'object') {
            let stack = value ? value.toString() : '';
            let needle = currentSearchText ? currentSearchText.toString() : '';
            needle = needle.toLowerCase();
            stack = stack.toLowerCase();

            if (stack.indexOf(needle) >= 0) {
              match = true;
            }
          }
        });

        if (match) {
          newRows.push(row);
        }
      });
    }

    rowsCount = newRows.length;

    //Pagination
    let currentPage = newPage != undefined ? newPage : page;
    let currentRowsPerPage = newRowsPerPage != undefined ? newRowsPerPage : rowsPerPage;
    newRows = newRows.slice(
      (currentPage - 1) * currentRowsPerPage,
      (currentPage - 1) * currentRowsPerPage + currentRowsPerPage,
    );

    if (currentRowsPerPage != rowsPerPage) {
      newRows = newRows.slice(0 * currentRowsPerPage, 0 * currentRowsPerPage + currentRowsPerPage);
    }

    // When infiniteScroll is false or undefined the grid only will display the rows per page defined.
    if (!props.infiniteScroll) {
      setRows(newRows);
    } else {
      // When infinite scroll is true the grid will be concatenate the data until no more is available.
      if (resetRows === true) {
        setRows(newRows);
      } else {
        const currentRows = [...rows];
        const concatenatedRows = currentRows.concat(newRows);
        setRows(concatenatedRows);
      }
    }

    setRowsCount(rowsCount);
    setPage(currentPage);
    setRowsPerPage(currentRowsPerPage);
  });

  // When the page is changed, we need to selecte the rows of the selected page or notify the parent if is handling the pagination
  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
    if (props.onChangePage) {
      props.onChangePage(newPage);
    } else {
      processData({ newPage: newPage });
    }
  });

  // When rows per page is changed, we need to rebuild the grid or notify the parent if is handling the pagination
  const handleChangeRowsPerPage = useCallback((newRowsPerPage) => {
    setPage(1);
    setRowsPerPage(newRowsPerPage);
    if (props.onChangeRowsPerPage) {
      props.onChangeRowsPerPage(newRowsPerPage, 1);
    } else {
      processData({ newPage: 1, newRowsPerPage: newRowsPerPage });
    }
  });

  // When the user types into the input search we need to find the matching rows or notify the parent if is handling the pagination
  const handleSearch = useCallback((searchText) => {
    if (window.typingTime) {
      clearTimeout(window.typingTime)
    }

    window.typingTime = setTimeout(() => {
      setPage(1);
      setSearchText(searchText);
      setSelectedRows([]);
      if (props.onChangePage && props.onSearch) {
        //props.onChangePage(1);
        props.onSearch(searchText);
      } else {
        processData({ newPage: 1, newSearchText: searchText, resetRows: true });
      }

      window.typingTime = null
    }, 500);
  });

  // When a row is selected we need to add the selected row to the selectedRows array
  const handleSelectRow = useCallback((row) => {
    const selectedIndex = selectedRows.indexOf(row);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, row);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1),
      );
    }

    setSelectedRows(newSelected);
    if (props.onSelectRow) {
      props.onSelectRow(newSelected);
    }
  });

  // This function will return the right view, depending of the viewType props
  const renderView = useCallback(() => {
    if (props.viewType === 'card') {
      return (
        <CardView
          rows={rows}
          renderRow={props.renderRow}
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          infiniteScroll={props.infiniteScroll}
          itemSelector={props.itemSelector}
          showCheck={props.showCheck}
        />
      );
    } else if (props.viewType === 'list') {
      return (
        <ListView
          rows={rows}
          renderRow={props.renderRow}
          selectedRows={selectedRows}
          onSelectRow={handleSelectRow}
          infiniteScroll={props.infiniteScroll}
          itemSelector={props.itemSelector}
        />
      );
    }

    return <span></span>;
  });

  let toolbar;
  if (Array.isArray(props.children)) {
    toolbar = props.children.find((col) => col.type === Toolbar);
  } else {
    if (props.children && props.children.type === Toolbar) {
      toolbar = props.children;
    }
  }

  // This important to calculate the height of the grid. by default is all the heigh less 200px that is the size of the toolbar and pagination divs.
  // this is very important for the height responsive of the table.
  const height = props.height ? props.height : `calc(100vh - ${props.topSpaceSize || 200}px)`;

  return (
    <div className="table-view-container">
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
      >
        <Grid item xs className={props.toolbarContainerClassName}>
          {
            toolbar &&
            cloneElement(toolbar, {
              onSearch: handleSearch,
              loading: props.loading,
              inputSearch: props.inputSearch,
              sizeInput: props.sizeInput
            })
          }
        </Grid>

        {
          (props.loading && !props.infiniteScroll) && <LinearProgress color="primary" />
        }

        {
          <Grid item style={{ height: height, width: '100%', maxHeight: props.maxHeight || '' }}>
            {props.renderHeader && props.renderHeader()}
            {
              props.infiniteScroll ?
                <div style={{ height: height, width: '100%', maxHeight: props.maxHeight || '' }}>
                  {
                    (props.loading && page === 1) && <LinearProgress color="primary" />
                  }
                  <InfiniteScroll
                    dataLength={rows.length}
                    height={height}
                    style={props.maxHeight ? { maxHeight: props.maxHeight } : {}}
                    next={() => {
                      handleChangePage(null, page + 1);
                    }}
                    hasMore={props.moreRowsAvailable != undefined ? props.moreRowsAvailable : (rows.length < rowsCount)}
                    loader={<span />}
                    endMessage={
                      <div style={{ padding: !props?.noDataMessage && 30, textAlign: !props?.noDataMessage && "center" }}>
                        {
                          !props.loading &&
                          <span style={{ fontSize: 13, color: 'grey' }}>
                            {`${rows.length ? (props?.messages['noMoreItems'] || 'There are no more items.') : (props?.noDataMessage || props.messages['noItemsFound'] || 'No matches found.')}`}
                          </span>
                        }
                      </div>
                    }
                  >
                    {renderView()}
                    {
                      (props.loading && page !== 1) && <LinearProgress color="primary" />
                    }
                  </InfiniteScroll>
                </div>
                :
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    height: props.containerHeight || '100%',
                    width: '100%'
                  }}
                >
                  {
                    (!props.loading && !props.data?.length)
                      ?
                      <div style={{ padding: 25 }}>
                        {props.messages['noItemsFound'] || 'No matches found.'}
                      </div>
                      :
                      <React.Fragment>
                        {renderView()}
                      </React.Fragment>
                  }

                </div>
            }
          </Grid>
        }

        {
          (props.pagination !== false && !props.infiniteScroll)
          &&
          <Grid item xs>
            <div
              style={{
                padding: '15px 0px 0px 0px'
              }}
            >
              <Pagination
                count={rowsCount}
                page={page}
                rowsPerPage={rowsPerPage}
                messages={props.messages}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                onChangePage={handleChangePage}
                hideAllInPagination={props.hideAllInPagination}
              />
            </div>
          </Grid>
        }
      </Grid>
    </div>
  );
});

export default TableView;

TableView.propTypes = {
  data: PropTypes.array.isRequired, //The data for to display in the grid. This is mandatory server side|local pagination
  viewType: PropTypes.string.isRequired, //The view type to render in the grid
  viewType: PropTypes.oneOf(['card', 'list']),
  name: PropTypes.string.isRequired, //The name for identify the table
  loading: PropTypes.bool, //If is true the loading will be show in the top of the grid
  infiniteScroll: PropTypes.bool, //The pagination will be hidden and the data will be displayed like an infinite scroll
  moreRowsAvailable: PropTypes.bool, //If is false the infinite scroll data will stop requesting data
  rowsPerPage: PropTypes.number, //Quantity of rows displayed per page
  topSpaceSize: PropTypes.number, //This very important for fit the grid correctly in the available space in vertical.
  renderRow: PropTypes.func.isRequired, //Each row will be render with component returned by this function
  total: PropTypes.number, //This prop is required on server side pagination
  endMessage: PropTypes.string, //Message when there are no more items to show on infinite scroll mode
  itemSelector: PropTypes.bool //If is false hides the checkbox for item selector 
};