export default {
    incidents_title: 'Incidents',
    new_incident: 'New Incident',
    search_incident: 'Search Incident',
    incident_items_no_found: 'Incidents No Found',
    active_incident: 'Active',
    history_incident: 'History',
    incident_end: 'End Incident',
    incident_stared: 'Stared:',
    incident_last_report: 'Last Report:',
    incident_vehicle: 'Vehicle:',
    incident_reported_by: 'Reported by:',
    incident_ended: 'Ended:',
    incident_duration: 'Duration:',
    incident_ended_by: 'Ended by:',
    create_incident: 'Create Incident',
    edit_incident: 'Edit Incident',
    incidents_next: 'Next',
    incidents_prev: 'Previous',
    incidents_close: 'Close',
    incidents_message: "New Incident helps you track and recover your stolen vehicle in real time. Simply select your vehicle, choose who to notify about updates, and start tracking. A live map shows all updates, camera-enabled vehicles will display live and historical footage, you can share a link for real-time updates. Focus on recovery, we'll provide the details",
    incident_name: 'Incident Name:',
    incident_estimate_start: 'Estimated Start:',
    incident_vehicle_info: 'Vehicle',
    incident_choose_vehicle: 'Choose Vehicle:',
    incident_last_event: 'Last Event:',
    incident_speed: 'Speed:',
    incident_address: 'Address:',
    incident_address_export: 'Address',
    incident_alert: 'Alert',
    incident_alert_start: 'Incident Start',
    incident_alert_vehicle_stopped: 'Vehicle Stopped',
    incident_alert_incident_end: 'Incident End',
    incident_suscribers: 'Suscribers',
    incident_suscribers_helper: 'Who should receive this alerts?',
    incident_email: 'Email',
    incident_phone: 'Phone',
    incident_contacts: 'Contacts',
    incident_add_recipient: 'Add recipient',
    incident_all_suscribers: 'Select All Contacts',
    incident_current_driver: 'Current driver',
    incident_exist_phone: 'Phone already exists',
    incidents_all_contacts: 'All Contacts',
    incidents_current_driver: 'Current Driver',
    incidents_name_rules_error: 'The name of the incident cannot be less than 3 and a maximum of 30 characters',
    incidents_unitId_rules_error: 'Selecting a vehicle is mandatory',
    incidents_date_rules_error: "The date can't be greater than the current date",
    incidents_success_create: "The Incident was created succesfully",
    incidents_success_update: "The Incident was updated succesfully",
    incidents_error_create: "This vehicle already has an open incident",
    incidents_error_update: "An error occurred updating the incident",
    incidents_delete_title: "End Incident",
    incident_delete_body_1: "Please confirm if this incident",
    incident_delete_body_2: "has already finished and adds a note",
    incident_delete_note: "add note...",
    incidents_end_confirm: "End",
    incidents_cancel: "Cancel",
    incident_note: "Note:",
    incident_note_success: "The incident ended successfully.",
    incident_note_error: "There was an error here",
    incidents_noUnitLabel: "This Unit not has name",
    incidents_overview_title: "Unit:",
    incident_event_title: 'Events',
    incident_event_title_events_speed: 'Speed:',
    incident_event_title_events_driver: 'Driver:',
    incident_event_trails: 'Trails',
    incident_event_deviceInfo: 'Last Location',
    incident_event_deviceInfo_lastEvent: 'Last Event:',
    incident_event_deviceInfo_currentTime: 'Current Time:',
    incident_event_deviceInfo_unitTime: 'Unit Time:',
    incident_event_deviceInfo_address: 'Address:',
    incident_event_deviceInfo_event: 'Event:',
    incident_event_deviceInfo_speed: 'Speed:',
    incident_event_deviceInfo_lat_long: 'Lat/Long:',
    incidents_no_data_events: 'This unit has no events for the selected date range',
    incidents_no_data_trails: 'This unit has no trails for the selected date range',
    incident_event_incident_started: 'Started:',
    incident_event_incident_reportedBy: 'Reported By:',
    incident_event_incident_scheduled_end: 'Scheduled End:',
    incident_event_incident_ended: 'Ended:',
    incident_event_incident_duration: 'Duration:',
    incident_event_incident_endedBy: 'Ended By:',
    incident_event_incident_resolution: 'Resolution:',
    incident_events_export: 'export events information',
    incident_trails_export: 'export trails information',
    incident_edit_name_title: "Edit incident name",
    incident_active_button_title: "ACTIVE INCIDENT",
    incident_active_newName_incorrect: "The incident name cannot be less than 3 and greater than 50",
    incident_active_newName_success: "Incident Name was updated successfully",
    event_from_start: "From Start",
    event_last_24h: "Last 24h",
    incident_active_title: "Incident",
    incendent_active_extend_button: "Extend",
    incendent_active_end_button: "End",
    incendent_active_alerts_title: "Alerts Subscribers",
    incendent_active_alerts_extend_notification: "The Alert was extended for one hour",
    incident_live_share: "Share Live Incident",
    incident_map_layer: "Map Layer",
    incident_history_title: 'History',
    incident_wrong_email: "The email is incorrect",
    incident_carriet_obligatory: 'Prefix is mandatory',
    incident_alert_after_created: 'Since there is an alert for this incident, you need to enter some information here',
    incident_alert_no_selected: 'If you selected a contact, you need to choose an alert',
    incident_contact_no_selected: 'If you selected a alert, you need to choose an contact',
    incident_phone_prefix: 'Prefix',
    incident_message_end_by_cron: 'The following incidents ended because their end time was exceeded by the current time',
    incident_map_hybrid: 'Hybrid',
    incident_map_map: 'Map',
    incident_map_satelite: 'Satelite',
    incident_map_terrain: 'Terrain',
    incident_clip_completed: 'Completed',
    incident_clip_request: 'Clip Request',
    incident_live_request: 'Live Request',
    incident_view_trails: 'View Trails',
    incident_hide_trails: 'Hide Trails'

}