import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { ExceptionManager } from '../../../../../../../core/logManager';

import { resetALL } from '../../../../../../../.globals/redux/actions';

import moment from "moment";

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    
    loadingVehicles: false,
    filters: {
        status: [],
        groups: [],
        tags: [],
        services: [],
        vehicles: [],
    },

    loadingServicesList: false,
    servicesList: [],

    loadingVehicles: false,
    vehicles: [],

    error: null
};

const route = 'modules/maintenance/views/main/components/vehiclesTab/redux/slice'

export const maintenanceVTGetServicesList = createAsyncThunk('maintenance/vehiclesTab/maintenanceVTGetServicesList', async ({ }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
                query {
                    data: searchMaintenanceServices {
                        id
                        title
                    }
                }
            `,
            {
            },
            endpoints.GRAPHQL_GENERAL
        );

        return response?.data || [];
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceVTGetServicesList');
        return rejectWithValue(exc)
    }
});

export const maintenanceVTGetVehiclesData = createAsyncThunk('maintenance/vehiclesTab/maintenanceVTGetVehiclesData', async ({ }, { rejectWithValue, dispatch }) => {
    try {
        const response = await clientQuery(
            `
                query {
                    data: searchUnits {
                            id
                            nextMaintenanceServiceUnit {
                                id
                                status
                                serviceId
                            }
                            lastMaintenanceServiceUnitLog {
                                id
                                maintenanceDate
                                serviceUnit {
                                    service {
                                        title
                                    }
                                }
                            }
                            dtcCount {
                                history
                                active
                            }
                    }
                }
            `,
            {
            },
            endpoints.GRAPHQL_GENERAL
        );

        return response?.data || [];
    } catch (exc) {
        ExceptionManager(exc, route, 'maintenanceVTGetVehiclesData');
        return rejectWithValue(exc)
    }
});


//Slice
const maintenanceVehiclesTabRedux = createSlice({
    name: 'maintenance/vehiclesTab',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        changeFilters(state, action) {
            state.filters = action.payload
        },
        changeVehicles(state, action) {
            state.vehicles = action.payload.vehicles
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //maintenanceVTGetServicesList
        builder.addCase(maintenanceVTGetServicesList.pending, (state, action) => {
            state.loadingServicesList = true
        });
        builder.addCase(maintenanceVTGetServicesList.fulfilled, (state, action) => {
            state.loadingServicesList = false;
            state.servicesList = action.payload
        });
        builder.addCase(maintenanceVTGetServicesList.rejected, (state, action) => {
            state.loadingServicesList = false;
            state.error = action.payload
        });

        //maintenanceVTGetVehiclesData
        builder.addCase(maintenanceVTGetVehiclesData.pending, (state, action) => {
            state.loadingVehicles = true
        });
        builder.addCase(maintenanceVTGetVehiclesData.fulfilled, (state, action) => {
            state.loadingVehicles = false;
            state.vehicles = action.payload
        });
        builder.addCase(maintenanceVTGetVehiclesData.rejected, (state, action) => {
            state.loadingVehicles = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = maintenanceVehiclesTabRedux;

// Extract and export each action creator by name
export const { reset, changeFilters, changeVehicles } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;