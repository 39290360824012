import {
    DASHBOARD_PANEL_RECENT_ALERTS,
    DASHBOARD_PANEL_RECENT_ALERTS_RESPONSE,
    CLEAR_REDUX
} from "Redux/actionTypes";

const INITIAL_STATE = {
    recentAlerts: {},
    loadingRecentAlerts: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
     
        case DASHBOARD_PANEL_RECENT_ALERTS:
            return {
                ...state,
                recentAlerts: {},
                loadingRecentAlerts: true
            };

        case DASHBOARD_PANEL_RECENT_ALERTS_RESPONSE:
            return {
                ...state,
                recentAlerts: action.payload,
                loadingRecentAlerts: false
            };

   
        case CLEAR_REDUX:
            return ["", "DASHBOARD_PANEL"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INITIAL_STATE }
                : { ...state };

        default:
            return { ...state };
    }
};