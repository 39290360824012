import React from "react";
import VidFleetVideo from "Modules/.common/components/VidFleetVideo";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

export const LiveVideo = () => {

  const {messages} = useIntl()
  const { isOpenOverViewUnit } = useSelector((state) => state.incidentsRedux);

  const unit = isOpenOverViewUnit?.data?.unit
  const lastReading = isOpenOverViewUnit?.data?.reading

  return (
    <div className="incident_liveVideo_map_liveVideo">
      <VidFleetVideo
        messages={messages}
        mode="video"
        type={
          unit?.liveMode
            ? unit?.liveMode?.toLowerCase()
            : "streaming"
        }
        id={unit?.esn}
        unitId={unit?.id}
        autoplay={false}
        deviceModel={
          unit?.deviceModelName === "360"
            ? "360"
            : "4k"
        }
        simProviderName={unit?.simProviderName}
        vehicleEvent={lastReading?.eventName}
        timeZone={unit?.timeZone?.name}
      />
    </div>
  );
};
