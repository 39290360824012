//imports
import { all } from 'redux-saga/effects';

//Views
import realtimeMapsMapSagas from 'Modules/realtimeMaps/main/redux/saga';
import realtimeMapsMapUtilitiesSagas from 'Modules/realtimeMaps/main/components/MapUtilitiesMenu/redux/saga';
import realtimeMapsMapVehiclesListSagas from 'Modules/realtimeMaps/main/components/VehicleList/redux/saga';
import realtimeMapsMapVisibleGroupsTagsSagas from 'Modules/realtimeMaps/main/components/modalVisibleGroupTags/redux/saga';
import realtimeMapsMapVehiclesTrailSagas from 'Modules/realtimeMaps/main/components/VehicleTrails/redux/saga';
import realtimeMapsLandmarkSagas from 'Modules/realtimeMaps/main/components/LandmarksListMain/redux/saga'; //remove
import realtimeMapsLandmarksSagas from 'Modules/realtimeMaps/main/components/landmarks/redux/saga';
import realtimeMapsGeofenceSagas from 'Modules/realtimeMaps/main/components/GeofenceListMain/redux/saga';
import realtimeMapsNewGeofenceSagas from 'Modules/realtimeMaps/main/components/geofences/redux/saga';
import realtimeMapsSendMessageSagas from 'Modules/realtimeMaps/main/components/SendMessage/redux/saga';
import realtimeMapsRoutesSagas from 'Modules/realtimeMaps/main/components/routes/redux/saga';
import realtimeLayerMapSagas from 'Modules/realtimeMaps/main/components/LayerMap/redux/saga';
import realtimeMapsFindNearestSagas from 'Modules/realtimeMaps/main/components/findNearest/redux/saga';
import realtimeMapsEtaSagas from 'Modules/realtimeMaps/main/components/eta/redux/saga';
import realtimeMapsSpeedOverrideSagas from 'Modules/realtimeMaps/main/components/speedOverride/redux/sagas';

//merge sagas
export default function* rootSaga() {
    yield all([
        realtimeMapsMapSagas(),
        realtimeMapsMapUtilitiesSagas(),
        realtimeMapsMapVehiclesListSagas(),
        realtimeMapsMapVisibleGroupsTagsSagas(),
        realtimeMapsMapVehiclesTrailSagas(),
        realtimeMapsLandmarkSagas(),
        realtimeMapsLandmarksSagas(),
        realtimeMapsGeofenceSagas(),
        realtimeMapsNewGeofenceSagas(),
        realtimeMapsSendMessageSagas(),
        realtimeMapsRoutesSagas(),
        realtimeLayerMapSagas(),
        realtimeMapsFindNearestSagas(),
        realtimeMapsEtaSagas(),
        realtimeMapsSpeedOverrideSagas()
    ]);
}
