//imports
import React, {Fragment, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import cx from 'classnames';
import {v4 as uuidv4} from 'uuid';
import {Grid} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {TimeFrame, useTimeFrameComponent} from '@gpstrackit/gtc-react-controls'
//controls
// import TimeFrame from '../timeFrame';
import {Button, Checkbox, Form, Select, Switch} from 'Components';
import Pill from "Components/Pill";
import SidePanel, {SidePanelBody, SidePanelFooter, SidePanelHeader} from 'Components/SidePanel';

import ModalExtraTime from 'Modules/reports/views/main/components/reportsTab/.components/modalExtraTime'

//ownControls
//Hooks
import {useNewEntityTag, useReportCustomFields, useReportTypeOptions} from 'Core/hooks'

import {formatDatetime} from 'Modules/reports/views/main/components/reportsTab/helper';

// actions
import {
  catalogLoad,
  getServiceItemsLegacy,
  reportsMainGenerateReport,
  reportsMainHideReportSetup,
  reportsMainSetFormDataReportSetup,
  reportsMainShowReportSetup,
  reportsMainUpdateReport,
  reportsOpenModalExtraTime,
  reportsOpenModalReportType,
} from 'Redux/actions';

import {dateTimeZoneToUTC} from 'Core/data/Helpers';
//styles
import './reportSetup.scss';
import colors from 'Assets/sass/core/vars.scss';

import EntityTagPicker from 'Modules/.common/components/NewEntityTagPicker';
import ReportCustomFields from '../../../reportCustomFields';
import {validateReportTypePermissions, validCustomFilters} from '../../../../../../.globals/helpers';
import {getDataForEntityTags, getUnitsNaturalOrder} from '../../../../../../../../core/data/Helpers';

import ReportTypes from '../reportTypes';
import CustomTimeRange from '../../../../../../../../components/uiControls/CustomTimeRange';
import {useCustomTimeRange} from '../../../../../../../../core/hooks';
import {searchDynamicForms} from "Modules/dynamicForms/views/main/components/forms/redux/slice";
import TagPicker from "Modules/.common/components/NewTagPicker";

const ReportSetup = (props) => {
  const { messages, refClearReportSetup } = props;
  const dispatch = useDispatch()
  const formSetup = useRef()


  //FILTER TO BE USED IN THE TABLE to know if we are showing Drivers or Units
  const [itemsError, setItemsError] = useState(false)
  const [errorCustomFilters, setErrorCustomFilters] = useState(false)
  const [reportType, setReportType] = useState()
  const [errorDates, setErrorDates] = useState(false)
  const [advancedFilter, setAdvancedFilter] = useState(false)
  const [excludeEmptyTables, setExcludeEmptyTables] = useState(true)
  const [includeInactiveDrivers, setIncludeInactiveDrivers] = useState(false)
  const [edit, setEdit] = useState(false)

  // is used to Custom time range state
  const {dataCustomTimeRange,validateCustomTimeRanges,setStateCustomTimeRange} = useCustomTimeRange();
  const [reportTimeRanges, setReportTimeRanges] = useState(dataCustomTimeRange)

  const showReportSetup = useSelector(state => state.reportsMainRTRedux.showReportSetup)
  const formDataReportSetup = useSelector(state => state.reportsMainRTRedux.formDataReportSetup)
  const serviceItems = useSelector(state => state.reportsMainRTRedux.serviceItems)
  const services = useSelector(state => state.catalogsRedux.maintenanceServicesItems);

  const user = useSelector(state => state.securityRedux.user);
  const reportFactor = useSelector(state => state.reportsModalExtraTime.reportFactor);
  const { durationFormat } = user;
  const drivers = useSelector(state => state.catalogsRedux.drivers);
  const driversIgnoreStatus = useSelector(state => state.catalogsRedux.driversIgnoreStatus);
  const units = useSelector(state => getUnitsNaturalOrder(state.catalogsRedux.unitListToShow));

  const [formData, setFormData] = useState(null);

  const tags = useSelector(state => state.newEntityTagPickerRedux.tags);
  const haveUntagged = tags?.find(t => t?.id == -1) ? true : false;

  const lang = useSelector(state => state.commomRedux.language);
  const statusUpdateReport = useSelector(state => state.reportsMainRTRedux.statusUpdateReport);
  const [dataSubmit, setDataSubmit] = useState([])
  const customFieldsReports = useReportCustomFields(haveUntagged)

  const filters = useSelector(state => state.dynamicFormsRedux.filters);
  const dynamicForms = useSelector(state => state.dynamicFormsRedux.data);

  const reportTypeOptions = useReportTypeOptions(user)?.reportTypeOptions;
  const validatePermissionsReportType = validateReportTypePermissions(reportTypeOptions);
  // <---- Entity Selector Setup ---->

  const timeFrameOptions = [
    { id: "custom", label: messages['date_custom'] },
    { id: "today", label: messages['date_today'] },
    { id: "yesterday", label: messages['date_yesterday'] },
    { id: "week", label: messages['date_this_week'] },
    { id: "lastWeek", label: messages['date_last_week'] },
    { id: "thisMonth", label: messages['date_this_month'] },
    { id: "lastMonth", label: messages['date_last_month'] },
    { id: "quarter", label: messages['date_this_quarter'] },
    { id: "lastQuarter", label: messages['date_last_quarter'] },
  ]

  const entityTags = useNewEntityTag(
      ["Unit", "Driver"],
      messages['reportsMainRT_viewReportBy'],
      "calc(100vh - 320px)",
      messages['reportsMainRT_runReportFor'],
      'hideTags',
      true
  )

  // <---- VIEW OPTIONS PILLS ---->
  const VIEW_OPTIONS = [
    { id: "json", label: "Web" },
    { id: "csv", label: "CSV" },
    { id: "pdf", label: "PDF" }
  ];

  const [viewOption, setViewOption] = useState(VIEW_OPTIONS[0]);
  // const timeFrame = useTimeFrameComponent(timeFrameOptions, null, true)
  const timeFrame = useTimeFrameComponent(['odometerReport'].includes(reportType?.id) ? [{ id: "today", label: messages['date_current'] }]: timeFrameOptions, 'today')

  const getReportInitial = (allEntities, isAll, timeZone, filterEntity, reportType, filterType, filterArray, startRange, endRange, otherFilters, isExport, exportType, sendByEmail, emailsTo, name, categoryName, timeframeName, delayed, excludeEmptyTables, includeInactiveDrivers) => {
    const id = formDataReportSetup?.edit?.id;
    const createdOn = formDataReportSetup?.edit?.createdOn;
    let reportInitial = {
      lang: lang,
      id: uuidv4(),
      type: reportType,
      loading: true,
      data: [],
      timeZone,
      filters: {filterEntity, filterType, filterArray, startRange, endRange, otherFilters, includeInactiveDrivers},
      export: isExport,
      exportType,
      asyncLoading: true,
      loadSync: false,
      sendByEmail,
      emailsTo,
      name,
      categoryName,
      timeframeName,
      delayed,
      excludeEmptyTables,
      dataResultPath: null,
      createdOn: null,
      userId: null,
      isAll,
      allEntities: isAll && allEntities
    }

    const UIParams = JSON.stringify(reportInitial)
    const exportOptions = JSON.stringify({
      format: exportType,
      emailsTo,
      sendByEmail
    });

    dispatch(reportsMainUpdateReport({ id, createdOn, UIParams, exportOptions }))
  }

    //State Time Range
    const [openTimeRange, setOpenTimeRange] = useState(false)
    const [oldStateTimeRange, setOldStateTimeRange] = useState(false)
    const [actDays, setActDays] = useState([])

  useEffect(()=>{
    if(formDataReportSetup){
      formDataReportSetup.lang = lang;

      if(formDataReportSetup?.edit){
        setEdit(true);
      }else{
        setEdit(false);
      }

      if(formDataReportSetup?.type){
        let reports = [];
        validatePermissionsReportType.map(element=>{
          reports.push(...element.options)
        })
        let report = reports.find(element=> element.id == formDataReportSetup.type)
        if(report){
          selectReportType(report);
          if(
            (
              report?.advancedFilter &&
              formDataReportSetup?.filters?.otherFilters?.advancedFilter
            ) ||
            (
              report?.advancedFilter && (
                !(formDataReportSetup?.filters?.otherFilters?.hasOwnProperty("advancedFilter"))
              )
            )
          ) setAdvancedFilter(true);
        }
      }

      if(formDataReportSetup?.timeframeName){
        const localTimeStart = moment.utc(formDataReportSetup?.filters?.startRange || null).local().format();
        const localTimeEnd = moment.utc(formDataReportSetup?.filters?.endRange || null).local().format();
        let startDate = new Date(formatDatetime(localTimeStart, "MMMM D, YYYY h:mm A", formDataReportSetup.timeZone))
        let endDate = new Date(formatDatetime(localTimeEnd, "MMMM D, YYYY h:mm A", formDataReportSetup.timeZone))
        let option = timeFrame.options.find(element=>element.label == formDataReportSetup.timeframeName)
        timeFrame.onChangeSelect(option,startDate, endDate)
        timeFrame.onChangeDateRange(moment(startDate),moment(endDate))

        if(formDataReportSetup.timeframeName == 'Custom' && formDataReportSetup?.reportTimeRanges){
          setActDays(formDataReportSetup.reportTimeRanges)

          timeFrame.setAdvancedFilter( formDataReportSetup?.reportTimeRanges.length > 0)
        }
      }

      if (formDataReportSetup?.filters?.filterEntity === 'Driver'){
        entityTags.onOptionChangeStatus(!!formDataReportSetup.filters.includeInactiveDrivers)
        setIncludeInactiveDrivers(entityTags.includeInactiveDrivers);
      }
      else setIncludeInactiveDrivers(false);

      if(formDataReportSetup?.filters){
        if (formDataReportSetup?.filters?.filterEntity) entityTags.onChangeEntity({ id: formDataReportSetup.filters.filterEntity })
        if(formDataReportSetup?.filters?.filterArray){
          let entity = formDataReportSetup.filters?.filterArray[0];
          const labelEntity = (formDataReportSetup.allEntities && formDataReportSetup.allEntities.length) ? formDataReportSetup.allEntities[0]?.name : entity.name;

          let isTag = labelEntity ? labelEntity : 'Tag';
          if(formDataReportSetup.isAll && isTag !== 'Tag') entity = { id: "all" }
          else if(isTag === 'Tag') entity = { id: "hideTags" }
          else entity = { id: "entities" }
          entityTags.onOptionChange(entity)

          if (!['all', 'hideTags'].includes(entity?.id)) entityTags.onChange([...formDataReportSetup.filters.filterArray])
          else if (!['all'].includes(entity?.id)) {
            const defaultAllTags = formDataReportSetup?.isAll ? [
              {
                "id": -99,
                "type": "Tag"
              }
            ] : formDataReportSetup.filters.filterArray;
            entityTags.onChange([...defaultAllTags])
            setFormData({...formData, tags: [...defaultAllTags]})
          }
        }
      }

      if(formDataReportSetup?.exportType){
        let exportType = VIEW_OPTIONS.find(element=>element.id == formDataReportSetup.exportType);
        if(exportType) setViewOption(exportType);
      }

      if(formDataReportSetup?.excludeEmptyTables) setExcludeEmptyTables(true);
      else setExcludeEmptyTables(false);

      if(formDataReportSetup?.filters?.otherFilters){
        customFieldsReports.onChangeOtherFilters({
          ...customFieldsReports.otherFilters,
          ...formDataReportSetup.filters.otherFilters
        })
      }
    }
  },[formDataReportSetup])
  useEffect(()=>{
    if(statusUpdateReport?.complete && statusUpdateReport?.data?.id){
      dispatch(reportsMainGenerateReport(
        ...dataSubmit, lang
      ));
      setDataSubmit({});
    }
  }, [statusUpdateReport])


  //Clear range time if timeFrame is not advanced
  useEffect(() => {
    if(!timeFrame.advancedFilter){
      setReportTimeRanges([])
    }
  }, [timeFrame.advancedFilter])

  const onClose = (stateSidePanel) => {
    //validate some changes to show messages confirm close
    if (!stateSidePanel) {

      //clear all data
      // clearModal()
      dispatch(reportsMainHideReportSetup())
      dispatch(reportsMainSetFormDataReportSetup(null))
    } else {
      dispatch(reportsMainShowReportSetup())
    }
  }
  /***
   * get the correct padding in the bottom to the modal to be displayed correctly
   */


  const getClassPaddingBottom = () => {
    let padding = 200;
    if (timeFrame?.option?.id === 'custom') {
      padding = 450;
    }
    return padding;
  }

  const clearModal = () => {
    setReportType()
    timeFrame.reset();
    setViewOption(VIEW_OPTIONS[0]);
    setItemsError(false)
    entityTags.onOptionChange({id: 'hideTags'});
    setFormData({})
    entityTags.onChange([]);
    entityTags.onOptionChangeStatus(false);
    entityTags.onChangeEntity({ id: "Unit" });
    customFieldsReports.clear()
    customFieldsReports.restartOtherFilters()
    setErrorCustomFilters(false)
    setAdvancedFilter(false)
    setExcludeEmptyTables(true)
    setIncludeInactiveDrivers(false)
    setEdit(false)
    dispatch(reportsMainSetFormDataReportSetup(null))
  }
  //The ref is used to elevate the function so that it can be executed from the parent due to the need to clear the useState
  refClearReportSetup.current = clearModal;

  const getCountEntities = (entities, typeEntity) => {
    // total unit or driver count by items selected
    let unitsCount = entities.reduce((previousValue, currentValue) => {
      if (currentValue.name == 'Tag') {
        if(entityTags.entity?.id === 'Unit'){
          return previousValue + currentValue?.tag?.unitsCount || currentValue?.unitsCount || 0;
        }else {
          return previousValue + currentValue?.tag?.driversCount || currentValue?.driversCount || 0;
        }

      }
      return previousValue + 1;
    }, 0)
    return unitsCount;
  }

  const calculateFactor = (type) => {
    // 30 Days
    const baseDays = 30;
    // 30 Seconds
    const baseTime = 30;
    // number of units depending on the report
    const baseNumberOfUnits = reportFactor[reportType.id];
    const startDate = moment(timeFrame.startDate)
    const endDate = moment(timeFrame.endDate)
    const diffInDays = endDate.diff(startDate, 'days')
    let value = entityTags.values;
    if(entityTags.option?.id == "all"){
      if(entityTags.entity?.id == "Unit"){
        value = [...units]
      } else  {
        value = includeInactiveDrivers ? [...driversIgnoreStatus] : [...drivers]
      }

    }
    const countEntities = getCountEntities(value, entityTags.entity?.id);
    const factor = (countEntities / baseNumberOfUnits) * (diffInDays / baseDays) * baseTime;
    if (factor >= 20) {
      return true
    }
    return false;
  }

  const submitExtraTimeReport = (sendOption, sendByEmail, emails) => {
    submitReport(sendOption, sendByEmail, emails, new Date().toISOString());
    dispatch(reportsOpenModalExtraTime(false, viewOption?.id));
    onClose(!showReportSetup)
  }

  const submitReport = (sendOption, sendByEmail, emails, delayed) => {
    const emailTo = emails?.split(',') || [''];
    let entityValues = entityTags?.values;
    //const selectItemsJustIdLabel = entityTags.values?.map(item => ({id: item.id, label: item.label, name: item.name, parentId: item.parentTagId}));
    const field = entityTags?.entity?.id === 'Driver' ? 'driverId' : 'deviceId';
    const validateTAllTags = (formData?.tags?.length && formData.tags[0]?.id === -99) || (entityTags?.values.length && entityTags?.values[0]?.id === '-99')
    const includeAllUnits = (entityTags.option?.id === 'all');
    const includeAllTags = (entityTags.option?.id === 'hideTags' && validateTAllTags);
    let allEntities = []
    if(includeAllUnits || includeAllTags){
      const allDrivers = includeInactiveDrivers ? driversIgnoreStatus : drivers;
      allEntities = entityTags.option?.id === 'hideTags' ? tags : getDataForEntityTags(entityTags.entity?.id, allDrivers, units)
    }

    if (entityTags?.values.length && entityTags?.values[0]?.id === '-99'){
      entityTags.values = [{id: -99, type: 'Tag'}]
      // entityTags.onChange([{id: -99, type: 'Tag'}])
    }

    if (!entityTags?.includeInactiveDrivers && entityTags?.entity?.id === 'Driver' && entityTags?.option?.id === 'entities'){

      const newEntityValues = entityTags?.values.map(item => {
        const driver = driversIgnoreStatus.find(elem => elem.driverId === item.id);
        return {
          id: item.id,
          name: item.name,
          label: driver?.name,
          status: driver?.status
        };
      });
      entityTags?.onChange(entityValues);
      entityValues = newEntityValues.filter(driver => !driver.status === entityTags?.includeInactiveDrivers)
    }

    const newDataSubmit = JSON.parse(
      JSON.stringify(
        [
          allEntities,
          (includeAllUnits || includeAllTags),
          user.timeZone,
          entityTags?.entity?.id,
          reportType.id, //reportType
          field, //entity to filter
          includeAllUnits ? [{id: -99, type: entityTags.entity?.id}] : entityValues, //items to filter
          dateTimeZoneToUTC(timeFrame.startDate, user.timeZone), // start date
          dateTimeZoneToUTC(timeFrame.endDate.seconds( 59), user.timeZone), // end date
          customFieldsReports.otherFilters,
          true,
          sendOption,
          sendByEmail,
          emailTo,
          reportType.name || '',
          field || '', // CategoryName
          timeFrame?.option?.label || '',
          delayed,
          excludeEmptyTables,
          null,
          null,
          null,
          reportTimeRanges,
          durationFormat,
          entityTags?.includeInactiveDrivers
        ]
      )
    );
    if(edit){
      getReportInitial(...newDataSubmit)
    }
    else{
      dispatch(reportsMainGenerateReport(...newDataSubmit, lang));
    }

    setDataSubmit(newDataSubmit)
  }

  const formatGroupLabel = (data) => (
    <>
      <div style={{
          borderTop: `${data?.label != messages['reportsMainRT_basic'] ? `1px solid ${colors['color_evnt_other']}`: ''}`,
          width: '100%'
        }}>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px',
        paddingTop: `${data?.label == messages['reportsMainRT_basic'] ? `0px`: '10px'}`
      }}>
        <span style={{
          fontWeight: 'bold',
          color: colors['color_black'],
          fontSize: '12px'
        }}>{data?.label}</span>
      </div>
    </>
  );

  const handleModalReportTypes = () =>{
    dispatch(reportsOpenModalReportType(true))
  }
  const selectReportType  = (type) => {
    timeFrame.onChangeSelect({ id: "today", label: messages['date_today'] })
    setReportType(type)
    if(type?.hideUnits){
      entityTags.hideUnits()
    } else {
      entityTags.showAll()
    }

    if (type?.id === 'driverFormsReportV2' || type?.id === 'formStopSummaryReportV2'){
      dispatch(searchDynamicForms({...filters, limit: 0}));
    }
    if (type?.id === 'vehServiceItemsReport'){
      dispatch(getServiceItemsLegacy(''));
    }
    if (type?.id === 'serviceItemsReport'){
      dispatch(catalogLoad(["maintenanceServicesItems"]));
    }
    if (type?.id === 'odometerReport'){
      timeFrame.onChangeSelect({ id: "today", label: messages['date_current'] })
    }
    entityTags.onChange([... formData?.tags || []])
    customFieldsReports.restartOtherFilters()
  }

  const handlerDataCustomTimeRange = (dataCustomTimeRange) => {
    setReportTimeRanges(dataCustomTimeRange)
    setOldStateTimeRange(dataCustomTimeRange)
    setOpenTimeRange(false)
  }

  const difference = moment(timeFrame.endDate).diff(moment(timeFrame.startDate),'days')

  const startDateDay = moment(timeFrame.startDate);
  const endDateDay = moment(timeFrame.endDate);

  const weekDays = [];

  useEffect(() => {

      if(difference < 7){

        while (startDateDay.isSameOrBefore(endDateDay)) {
          weekDays.push(
            {
              day: startDateDay.locale('en').format('dddd').toLocaleLowerCase(),
              endTime: "23:59",
              starTime: "00:00"
            }

            );
          startDateDay.add(1, 'day');
        }
        setOldStateTimeRange({days: weekDays,isDataInitial: true});
      }else{
        setOldStateTimeRange(
          {
            days:[
            {
              day: 'monday',
              endTime: "23:59",
              starTime: "00:00"
            },
            {
              day: 'tuesday',
              endTime: "23:59",
              starTime: "00:00"
            },
            {
              day: 'wednesday',
              endTime: "23:59",
              starTime: "00:00"
            },
            {
              day: 'thursday',
              endTime: "23:59",
              starTime: "00:00"
            },
            {
              day: 'friday',
              endTime: "23:59",
              starTime: "00:00"
            },
            {
              day: 'saturday',
              endTime: "23:59",
              starTime: "00:00"
            },
            {
              day: 'sunday',
              endTime: "23:59",
              starTime: "00:00"
            }
          ]
          ,isDataInitial: true
          }
        );
      }

  }, [JSON.stringify([moment(timeFrame.startDate).format('DD/MM/YYYY'), moment(timeFrame.endDate).format('DD/MM/YYYY')])])

  const onOptionChangeStatus = (status) =>{
    entityTags.onChange([]);
    setFormData({ ...formData, tags: [] });
    entityTags.onOptionChangeStatus(status)
    setIncludeInactiveDrivers(status)
  }

  return (
    <>
      <SidePanel style={{ zIndex: 2 }} sidebarShow={true} isOpen={showReportSetup} toggle={() => onClose(!showReportSetup)} classSidePanel={showReportSetup && 'report-side-panel'}>
        <SidePanelHeader>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item sm={12}>
              <h1 style={{ margin: 0, fontSize: '18px' }}>{messages['reportsMainRT_reportSetup']}</h1>
            </Grid>
            <Grid item>
              <p className="report-setup-clear-all" onClick={clearModal}>
                {messages['reportsMainRT_clearAll']}
              </p>
            </Grid>
          </Grid>
        </SidePanelHeader>
        <SidePanelBody
          className={
            cx(
              'contentModal-reportSetup'
            )
          }
        >
          <div
            style={{ paddingBottom: getClassPaddingBottom() }}
          >
            <Form
              ref={formSetup}
              onSubmit={() => {

                if(timeFrame?.advancedFilter && (validateCustomTimeRanges() && timeFrame?.option?.id === 'custom' )){
                  setOpenTimeRange(true)
                  validateCustomTimeRanges(true)
                  return false
                }

                if(timeFrame.isInvalid()){
                  return false
                }

                const isValidCustomFilters = validCustomFilters(reportType?.id, customFieldsReports.otherFilters);
                if(!isValidCustomFilters){
                  setErrorCustomFilters(true)
                  return false;
                }

                if (entityTags.values?.length === 0 && !['all'].includes(entityTags.option?.id)) {
                  setItemsError(true)
                  return false
                }

                let start = new Date(timeFrame.startDate);
                let end = new Date(timeFrame.endDate);
                if (start > end) {
                  setErrorDates(true)
                  return false
                }

                return true;
              }}
              onValidSubmit={(type) => {
                //call report
                const factor = calculateFactor(type);
                setErrorDates(false)
                setItemsError(false)
                setErrorCustomFilters(false)
                if (factor) {
                  dispatch(reportsOpenModalExtraTime(true, viewOption?.id));
                } else {
                  submitReport(viewOption.id, false, null, null)
                  // onClose(!showReportSetup)
                }

              }}>
              <div>
                <div style={{ display: 'grid', width: '100%' }}>
                  <div style={{ marginTop: "32px" }}>
                    <h1 className="input-report-setup">{messages['reportsMainRT_reportOptions']}</h1>
                  </div>
                  <div style={{ width: "100%", marginTop: "8px" }}>

                    <Select
                      id="reportType"
                      iconRight="info"
                      onClickIcon={handleModalReportTypes}
                      label={messages['reportsMainRT_reportType']}
                      value={reportType}
                      onChange={selectReportType}
                      options={validatePermissionsReportType}
                      textField="name"
                      valueField="id"
                      placeholder={messages['reportsMainRT_reportType']}
                      isClearable
                      required
                      requiredMessage={messages["generatedReport_selectAReportOption"]}
                      isOptionDisabled={(option) => {
                        return !option || option.disabled
                      }}
                      disabled={ edit }
                      className={'selected-report-options'}
                      formatGroupLabel={formatGroupLabel}
                      titleStyles={'report-setup-type-title'}
                      styleIcon={{color: colors['color_black']}}
                      messageInfoIcon={messages["generatedReport_infoReports"]}
                    />
                  </div>
                  {
                    reportType?.advancedFilter && (
                      <>
                        <Switch
                          label={messages['vehicleView_advancedFilters']}
                          onChange={(event) => {
                            customFieldsReports.onChangeOtherFilters(
                              {
                                ...customFieldsReports.otherFilters,
                                advancedFilter: event.target.checked
                              }
                            );

                            setAdvancedFilter(event.target.checked)
                          }}
                          checked={advancedFilter}
                        />

                        {
                          advancedFilter && (
                            <ReportCustomFields
                              reportType={reportType}
                              messages={messages}
                              otherFilters={customFieldsReports.otherFilters}
                              setOtherFilters={customFieldsReports.onChangeOtherFilters}
                              selectEventTypeOptions={customFieldsReports.selectEventTypeOptions}
                              landmarkTags={customFieldsReports.tags}
                              getAllLandmarks={customFieldsReports.getAllLandmarks}
                              landmarkViewOptions={customFieldsReports.landmarkViewOptions}
                              landmarks={customFieldsReports.landmarks}
                              landmarkTagsLoading={customFieldsReports.landmarkTagsLoading}
                              getGeofencesTags={customFieldsReports.getGeofencesTags}
                              geofenceViewOptions={customFieldsReports.geofenceViewOptions}
                              geofences={customFieldsReports.geofences}
                              geofenceTagsLoading={customFieldsReports.geofenceTagsLoading}
                              driverViewOptions={customFieldsReports.driverViewOptions}
                              forms={customFieldsReports.allForms}
                              dynamicForms={dynamicForms.items}
                              serviceItems={serviceItems}
                              services={services}
                            />
                          )
                        }


                        {errorCustomFilters && <small className="select-error text-danger">{messages["reportsMainRT_itemsError"]}</small>}
                      </>
                    )
                  }
                </div>
              </div>
              <div className="reports-margin-top-26">
                <h2 className="input-report-setup" style={{marginBottom: 8}}>{messages['reportsMainRT_timeFrame']}</h2>
                <TimeFrame
                  isRequired
                  {...timeFrame}
                  label={messages['reportsMainRT_selectTimeFrameRange']}
                  placeholder={messages['reportsMainRT_timeFrameLabel']}
                  requiredMessage={messages["required_field"]}
                  customRangeDate={92}
                  maxDate={moment().endOf('day').toDate()}
                  dateFormat={user.dateformat || 'Y/m/d'}
                  onAdvancedFilter={()=>{
                    setOpenTimeRange(true)
                  }}
                />
                <div style={{ width: '100%' }}>
                  {errorDates && <small className="select-error text-danger">{messages["reportsMainRT_errorDate"]}</small>}
                </div>
              </div>
              <div className="reports-margin-top-26">
                <div style={{ width: "100%" }}>
                  <div className="report-margin-bottom-18">
                    <h2 className="report-input-normal-label">{entityTags?.entity?.id === ('Unit') ? messages['reportsMainRT_unitDriverSelection'] : messages['reportsMainRT_driverSelection']}</h2>
                  </div>
                  <div>
                      <EntityTagPicker
                          {...entityTags}
                          module={'report-module'}
                          onChangeEntity={(newEntity)=> {
                            setFormData({})
                            entityTags.onChangeEntity(newEntity)
                            setItemsError(false)
                          }}
                          onOptionChange={(newOption) => {
                            if (newOption?.id === 'hideTags') {
                              entityTags.onOptionChange({id: 'hideTags', label: 'All Units'});
                            } else {
                              entityTags.onOptionChange(newOption)
                              setItemsError(false)
                            }
                          }}
                          options={[
                            { id: "all", label: messages[`entityTagPicker_all${entityTags.entity?.id || ""}`] },
                            { id: "entities", label: messages[`entityTagPicker_single${entityTags.entity?.id || ""}`] },
                            { id: "hideTags", label: messages['entityTagPicker_tags'] },
                          ]}
                          onOptionChangeStatus={onOptionChangeStatus}
                          entityLabelClass='report-setup-option-label'
                          entityOptionLabelClass='report-setup-type-title'
                          entityTagPickerContainerClass='report-setup-container-tag-picker'
                          ignoreUnitStatus={true}
                          includeInactiveDrivers={entityTags?.includeInactiveDrivers}
                          useIncludesInactive={`${entityTags?.entity?.id}-${entityTags?.option?.id}`}
                      />
                      <Grid item sm={12}>
                        <TagPicker
                            id="tags"
                            showCreateOption={false}
                            label={''}
                            placeholder={messages["entityTagPicker_searchTag"]}
                            values={formData?.tags || []}
                            multiple={true}
                            hideLoader={true}
                            hideComponent={entityTags.option?.id !== 'hideTags'}
                            required={true}
                            onChange={(items) => {
                              if (items.length && items[0].id === -99){
                                entityTags.onChange([{id: -99, type: 'Tag'}])
                              }else {
                                entityTags.onChange(items)
                              }
                              setFormData({ ...formData, tags: items });
                              setItemsError(false);
                            }}
                            position="absolute"
                        />
                      </Grid>
                  </div>
                  {
                    itemsError &&
                    <small className="select-error text-danger">{messages["reportsMainRT_itemsError"]}</small>
                  }
                </div>
              </div>
              <div className="reports-margin-top-26">
                <div className="report-margin-bottom-18">
                  <h2 className="report-input-normal-label">{messages['reportsMainST_deliveryOptions']}</h2>
                </div>
                <div style={{ width: "100%", marginTop: '10px' }}>
                  <div className="report-setup-option-label">
                    <label>{messages['reportsMainST_selectReportFormat']}</label>
                  </div>
                  <Grid container spacing={1}>
                    {
                      VIEW_OPTIONS.map((option, key) => {
                        const { id } = option;
                        return (
                          <Grid item sm={4} key={key}>
                            <Pill
                              textField={'label'}
                              item={option}
                              outlined={id !== viewOption?.id}
                              onClick={(entity) => {
                                setViewOption(entity);
                              }}
                              style={{ border: '0', margin: '2px 0', width: '100%' }}
                              label="pill-label-text"
                              colorField="default"
                            />
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                </div>
                <div style={{ width: "100%", marginTop: '27px' }}>
                  <Checkbox
                    value={excludeEmptyTables}
                    onChange={(event)=>{
                      setExcludeEmptyTables(event)
                    }}
                    size="small"
                    id={"checkbox-table-with-no=data"}
                    label={messages["reportsMainRT_excludeTables"]}
                    classNameLabel={'label-exclude-data'}
                    style={{marginRight: 0}}
                  />
                </div>
              </div>
            </Form>
          </div>
        </SidePanelBody>
        <SidePanelFooter className={showReportSetup && 'reports-setup-side-panel'} >
          <Grid container spacing={1}>
              <Grid item sm={12}>
                <Button
                  onClick={() => {
                    formSetup.current.submit()
                  }}
                  className="btn-blue report-setup-get-report-btn"
                >
                  {
                    !edit ?
                    messages["reportSetup_getReport"]
                    :
                    `${messages["save"]} & ${messages["reportSetup_getReport"]}`
                  }
                </Button>
              </Grid>
          </Grid>
        </SidePanelFooter>
      </SidePanel>
      <ModalExtraTime submitExtraTimeReport={submitExtraTimeReport} />
      <ReportTypes onValidSubmit={(reportTypeSelected)=> {
        let reportType;
        validatePermissionsReportType.map(reportT=> {
          reportT.options?.map(reportOption => {
            if(reportOption.id === reportTypeSelected){
              reportType = reportOption;
            }
          })
        });

        selectReportType(reportType)
      }}/>
      {
        openTimeRange &&
        <SidePanel
          sidebarShow={false}
          isOpen={openTimeRange}
          toggle={()=>{
            handlerDataCustomTimeRange(dataCustomTimeRange)
          }}
          classSidePanel={openTimeRange && 'report-side-panel'}
          hiddenButton={true} style={{zIndex: 3,
            display: (openTimeRange ? "initial": "none")
          }}>
          <SidePanelHeader>
            <div className='header__customTimeRange'>
              <div>
                <h2>{messages['reportsAdvanceFilterOptions']}</h2>
                <CloseIcon onClick={()=>{
                  handlerDataCustomTimeRange(dataCustomTimeRange)
                }}/>
              </div>
              <div className='line'></div>
            </div>
          </SidePanelHeader>
          <SidePanelBody>

            <CustomTimeRange
              setStateCustomTimeRange={setStateCustomTimeRange}
              scheduleTimeRanges={oldStateTimeRange}
              validateCustomTimeRanges={validateCustomTimeRanges}
              errorMessage={messages["reportsMainST_selectAtLeastOneDay"]}
              activeDays={{actDays, setActDays}}
              isSimple
              isDisabled
            />

          </SidePanelBody>
          <SidePanelFooter className={'reports-setup-item-type-footer'} >
              <Button
                  className="btn-cancel"
                onClick={() => {
                  setOpenTimeRange(false)
                }}
              >
                {messages['reportSetup_cancel']}
              </Button>
              <Button
                className="btn-blue"
                onClick={() => {
                  handlerDataCustomTimeRange(dataCustomTimeRange)
                }}
              >
                {messages['Apply']}
              </Button>
          </SidePanelFooter>
        </SidePanel>
      }
    </>
  )
}

export default ReportSetup;
