import {
    //TYPES
    REALTIME_MAP_CENTER_UNIT,
    REALTIME_MAP_ADD_POINTERS,
    REALTIME_MAP_ADD_NOT_CENTER_POINTERS,
    REALTIME_MAP_ADD_POINTERS_MORE_ITEMS,
    REALTIME_MAP_ADD_POINTERS_RESPONSE,
    REALTIME_MAP_CLICK_LATLNG,
    REALTIME_MAP_CLICK_LATLNG_RESTART,
    REALTIME_MAP_GET_KMLS,
    REALTIME_MAP_GET_JSON_CONFIRM,
    REALTIME_MAP_UPDATE_KMLS,
    REALTIME_MAP_UPDATE_KMLS_COMFIRM,
    REALTIME_MAP_SAVE_KMLS,
    REALTIME_MAP_LISTEN_ON_CLICK_MAP,
    REALTIME_MAP_SET_ON_CLICK_MAP_POSITION,
    REALTIME_MAP_SAVE_LOADING,
    REALTIME_MAP_ZOOM_POINT,
    REALTIME_MAP_ADD_RADIUS,
    REALTIME_MAP_GET_DIRECTIONS,
    REALTIME_MAP_GET_DIRECTIONS_OPTIONS,
    REALTIME_MAP_GET_DIRECTIONS_OPTIONS_RESTART,
    REALTIME_MAP_GET_DIRECTIONS_ERROR,
    CLEAR_REDUX,
    REALTIME_MAP_RESTART,
    REALTIME_MAP_GET_OFFLINE_DEVICES,
    REALTIME_MAP_GET_OFFLINE_DEVICES_RESPONSE,
    REALTIME_MAP_FOLLOW_UNIT,
    REALTIME_MAP_PRINT_VIEW,
    REALTIME_MAP_GET_LANDMARK_CONFIRM,
    REALTIME_MAP_GET_GEOFENCE_CONFIRM,
    REALTIME_MAP_LOAD_OBJECT_LANDMARK,
    REALTIME_MAP_LOAD_OBJECT_GEOFENCE,
    REALTIME_MAP_UPDATE_ADD_LANDMARK,
    REALTIME_MAP_UPDATE_ADD_GEOFENCE
  } from "Redux/actionTypes";
    
  const INIT_STATE = {
    centerUnitId: 0, 
    followUnit: false,
    pointers: [],
    noCenterPoints: false,
    pointersMoreAdded: false,
    pointersCreated: false,
    updateKmls: false,
    offlineDevices: [],
    jsonFiles: [],
    kmlsToShowMap : [],
    listenOnClickMap: false,
    clickMapPosition: null,
    loading: false,
    loadingOfflineDevices: false,
    generatePrint: false,
    pointOnMap: null,
    markerRadius: 0,
    clickLatLng: null,
    unitGroups: [],
    loadingUnitGroups: false,
    directions: { options: {}, getDirections: false, showDirectionsPanel: false},
    directionsError: 0,
    loadingLandmarkJson: false,
    loadingGeofenceJson: false,
    jsonLandmark: [],
    jsonGeofence: [],
    loadObjectLandmarkOnMap: false,
    loadObjectGeofenceOnMap: false
  };
  
  export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REALTIME_MAP_CENTER_UNIT:
            return { ...state, centerUnitId: action.payload.centerUnitId, followUnit: action.payload.followUnit, currentZoom: action.payload.currentZoom };

        case REALTIME_MAP_FOLLOW_UNIT:
            return { ...state, followUnit: action.payload  };
        
        case REALTIME_MAP_ZOOM_POINT:
            return { ...state, pointOnMap: action.payload };
    
        case REALTIME_MAP_ADD_POINTERS:
            if(state.pointersMoreAdded && action.payload && action.payload.length > 0 && state.pointers.length > 0){
              action.payload[0] = { ...action.payload[0], id: state.pointers.length + 1 }
            }
            return { ...state, 
              pointers: state.pointersMoreAdded && action.payload?.length > 0 ? [...state.pointers, ...action.payload ] : action.payload, 
              pointersCreated: !(action.payload?.length == 0),
              directions: { ...state.directions, getDirections: action.payload?.length == 0 ? false : state.directions.getDirections }
            };
          
        case REALTIME_MAP_ADD_NOT_CENTER_POINTERS:
            return { ...state, noCenterPoints: action.payload };

        case REALTIME_MAP_ADD_POINTERS_MORE_ITEMS:
            return { ...state, pointersMoreAdded: action.payload };
  
        case REALTIME_MAP_ADD_POINTERS_RESPONSE:
            return { ...state, pointersCreated: action.payload };

        case REALTIME_MAP_GET_KMLS: 
            return { ...state, loadingLandmarkJson: true, loadingGeofenceJson: true, updateKmls: action.payload };
        
        case REALTIME_MAP_GET_JSON_CONFIRM:
            return { ...state, updateKmls: false, jsonFiles: action.payload.result };
        
        case REALTIME_MAP_UPDATE_KMLS:
            return { ...state, loadingLandmarkJson: true, loadingGeofenceJson: true };

        case REALTIME_MAP_UPDATE_KMLS_COMFIRM:
            return { ...state, loadingLandmarkJson: false, loadingGeofenceJson: false };
        
        case REALTIME_MAP_SAVE_KMLS:
            return { ...state, kmlsToShowMap: action.payload };

        case REALTIME_MAP_LISTEN_ON_CLICK_MAP:
            return { ...state, listenOnClickMap: action.payload };

        case REALTIME_MAP_SET_ON_CLICK_MAP_POSITION:
            return { ...state, clickMapPosition: action.payload };
            
        case REALTIME_MAP_SAVE_LOADING:
            return { ...state, loading: action.payload };

        case REALTIME_MAP_ADD_RADIUS:
          return { ...state, markerRadius: action.payload };
        
        case REALTIME_MAP_CLICK_LATLNG: 
          return { ...state, clickLatLng: action.payload };

        case REALTIME_MAP_CLICK_LATLNG_RESTART: 
          return { ...state, clickLatLng: null };

        case REALTIME_MAP_GET_DIRECTIONS:
          if(state?.pointers?.length > 0){
            if(action.payload){
              return { ...state, directions: { ...state.directions, getDirections: action.payload } }
            }else if(/* state.directions.getDirections &&  */!action.payload){
              return { ...state, directions: { ...state.directions, getDirections: action.payload }, pointers: [] }
            }
          }
          return { ...state, directions: { ...state.directions, getDirections: false } }
          
        case REALTIME_MAP_GET_DIRECTIONS_OPTIONS_RESTART:
            return { ...state, directions: { options: {}, getDirections: false}}

        case REALTIME_MAP_GET_DIRECTIONS_OPTIONS:
          return { ...state, directions: { ...state.directions, options: { ...state.directions.options, ...action.payload } }}

        case REALTIME_MAP_GET_DIRECTIONS_ERROR:
          return { ...state, directionsError: state.directionsError + 1 }

        case REALTIME_MAP_GET_OFFLINE_DEVICES: 
            return { ...state, loadingOfflineDevices: true };
        
        case REALTIME_MAP_GET_OFFLINE_DEVICES_RESPONSE:
            return { ...state, loadingOfflineDevices: false, offlineDevices: action.payload.result };

        case REALTIME_MAP_PRINT_VIEW:
            return { ...state, generatePrint: action.payload };

        case REALTIME_MAP_GET_LANDMARK_CONFIRM:
            return { ...state,  loadingLandmarkJson: false, jsonLandmark: action.payload.result };

        case REALTIME_MAP_UPDATE_ADD_LANDMARK:
            let newJsonLandmark = [ ...state.jsonLandmark ];
            const { payload: { landmark, action: a} } = action;
            if (a === "add") {
                newJsonLandmark.push(landmark);
            } else if (a === "update") {
                newJsonLandmark = state.jsonLandmark.map(currentLandmark => {
                    if (currentLandmark.id == landmark.id) {
                        return {
                            ...currentLandmark,
                            ...landmark
                        }
                    }
                    return currentLandmark;
                });
            } else if (a === "remove") {
                let ids = landmark.map(l => {
                    return l.id;
                });

                newJsonLandmark = state.jsonLandmark.filter(currentLandmark => !ids.includes(currentLandmark.id));
            }

            return { ...state,  loadingLandmarkJson: false, jsonLandmark: [...newJsonLandmark] };

        case REALTIME_MAP_UPDATE_ADD_GEOFENCE:
                let newJsonGeofence = [ ...state.jsonGeofence ];
                const { payload: { geofence, action: act} } = action;
                if (act === "add") {
                    newJsonGeofence.push(geofence);
                } else if (act === "update") {
                    newJsonGeofence = state.jsonGeofence.map(currentGeofence => {
                        if (currentGeofence.id == geofence.id) {
                            return {
                                ...currentGeofence,
                                ...geofence
                            }
                        }
                        return currentGeofence;
                    });
                } else if (act === "remove") {
                    let ids = geofence.map(l => {
                        return l.id;
                    });
    
                    newJsonGeofence = state.jsonGeofence.filter(currentGeofence => !ids.includes(currentGeofence.id));
                }
    
                return { ...state,  loadingGeofenceJson: false, jsonGeofence: [...newJsonGeofence] };

        case REALTIME_MAP_GET_GEOFENCE_CONFIRM:
            return { ...state,  loadingGeofenceJson: false, jsonGeofence: action.payload.result };
    
        case REALTIME_MAP_LOAD_OBJECT_LANDMARK:
            return { ...state, loadObjectLandmarkOnMap: action.payload };

        case REALTIME_MAP_LOAD_OBJECT_GEOFENCE:
            return { ...state, loadObjectGeofenceOnMap: action.payload };
            

        case CLEAR_REDUX:
          return ["", "REALTIMEMAPS"].includes(action.payload.option)
              ? action.payload.parameters
                  ? { ...state, ...action.payload.parameters }
                  : { ...INIT_STATE }
              : { ...state }

        case REALTIME_MAP_RESTART:
          return { ...INIT_STATE }
          
        default:
      return state;
    }
  };