export default {
  accountInformation_title: "Account Information",
  accountInformation_preferences: "Preferences",
  accountInformation_defaultTab: "Default Tab",
  accountInformation_unitOfMeasure: "Unit of Measure",
  accountInformation_timezone: "Timezone",
  accountInformation_dateFormat: "Date Format",
  accountInformation_durationFormat: "Duration Format",
  accountInformation_durationFormatHH: "Hours with Decimal",
  accountInformation_durationFormatDaysHoursMinSec: "Days, Hours, Mins, Seconds, (1 Day 2 hr 16 min 47 sec)",
  accountInformation_select: "Select",
  accountInformation_cancel: "Cancel",
  accountInformation_save: "Save",
  accountInformation_success: "Account edited, login again to see the changes.",
  accountInformation_vehicleStatus: "Status",
  accountInformation_maps: "Maps",
  accountInformation_reports: "Reports",
  accountInformation_dashboard: "Dashboard",
  accountInformation_mph: "MPH/Miles",
  accountInformation_kph: "KPH/Kilometers",
  accountInformation_language: "Language",
  accountInformation_apollo_eld_account: "Apollo ELD Account",
  accountInformation_username: "Username",
  accountInformation_password: "Password",
  accountInformation_change_password: "Change Password",
  accountInformation_confirm_password: "Confirm password",
  accountInformation_minMessageUsername: "The username must contain minimun chars:",
  accountInformation_maxMessageUsername: "The username must contain maximum chars:",
  accountInformation_minMessagePassword: "The password must contain minimun chars:",
  accountInformation_maxMessagePassword: "The password must contain maximum chars:",
  accountInformation_valueToCompareMessage: "Confirm password does not match New Password",
};

