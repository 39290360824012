/* eslint-disable */
import { ApolloClient, ApolloLink, InMemoryCache, HttpLink, defaultDataIdFromObject } from '@apollo/client';
import { LogManager, ExceptionManager } from 'Core/logManager';
import gql from 'graphql-tag';
import { onError } from "@apollo/client/link/error";
import { GerVersionFromServer } from 'Core/security';
import { getLegacyUrl } from 'Core/data/Helpers';
import { endpoints } from 'Core/defaultValues';
import packageJSON from '../../../package.json'

import {
	setCookie
} from 'Core/views/login/helper';

export const clientMutation = async (query, variables, endpoint, headers) => {
	let token = localStorage.getItem('IdToken');
	let refreshTokenValue = localStorage.getItem('RefreshToken');

	let attempts = 0;
	let refreshToken = false;
	let errorData = [];
	let returnCall = false;
	let returnData = null;
	let returnError = null;

	while (!returnCall) {
		returnError = null;

		const authLink = new ApolloLink((operation, forward) => {
			operation.setContext({
				headers: headers || {
					authorization: token,
					"app-id": "px-cloud",
					"app-version": packageJSON.version,
					"package-name": packageJSON.name
				},
			});

			// Call the next link in the middleware chain.
			return forward(operation);
		});

		const client = new ApolloClient({
			link: authLink.concat(
				ApolloLink.from([
					onError(({ response, graphQLErrors, networkError, operation, forward }) => {
						console.log('graphQLErrors', graphQLErrors, response);
						errorData = response;
						graphQLErrors &&
							graphQLErrors.map((error) => {
								if (error.errorType === 'UnauthorizedException') refreshToken = true;
							});
					}),
					new HttpLink({
						uri: endpoint,
					}),
				]),
			),
			cache: new InMemoryCache(),
			defaultOptions: {
				query: {
					errorPolicy: 'all',
				},
			},
		});

		await client
			.mutate({
				mutation: gql(query),
				variables: variables,
				context: {
					fetchOptions: {
						onUploadProgress: (progress) => { },
					},
				},
			})
			.then((res) => {
				returnCall = true;
				returnData = res.data;
			})
			.catch((error) => {
				if (refreshToken) {
					attempts = attempts + 1;
					returnError = error;

					if (attempts === 3) returnCall = true;
				} else {
					returnCall = true;
					returnError = error;
				}
			});

		if (!returnCall) {
			console.log('recovery');
			await clientGraphQLAuthMutation(`
				mutation refreshToken($refreshToken: String!) {
					authenticationResult: refreshToken(refreshToken: $refreshToken) {
						AccessToken
						ExpiresIn
						RefreshToken
						IdToken
						ChallengeName
						Session
					}
				}
      			`,
				{
					refreshToken: refreshTokenValue
				}
			).then(data => {
				if (data && data.authenticationResult) {
					const { authenticationResult } = data
					token = authenticationResult.IdToken;
					localStorage.setItem('IdToken', authenticationResult.IdToken);
					setCookie("GPSTSharedOAUTHToken", authenticationResult.IdToken, 1)
					localStorage.setItem('AccessToken', authenticationResult.AccessToken);
					authenticationResult.RefreshToken = localStorage.getItem("RefreshToken")

					//login PHP
					try {
						let username = localStorage.getItem("username")
						let sm = localStorage.getItem("snm")

						let valuesLegacy = { action: "login", username: username, cognito: authenticationResult, sm: sm }
						let frame = document.getElementById('legacyIframe');
						frame.contentWindow.postMessage(valuesLegacy, getLegacyUrl(endpoints.LEGACY_URL));
						LogManager('login legacy');

					} catch (ex) {
						ExceptionManager(ex, 'GraphQLApi', 'Calling legacy set_login')
					}
				}
			}).catch(error => {
				console.log("clientGraphQLQuery in  error")
				if (error.message === 'GraphQL error: Refresh Token has expired')
					return new Promise((resolve, reject) => {
						return reject({ error: error, logout: true });
					});
			})
		}
	}

	return new Promise((resolve, reject) => {
		if (!returnError) return resolve(returnData);
		else {
			return reject({
				error: returnError.message,
				innerError: returnError,
				errorData: errorData,
				logout: returnCall && returnError && refreshToken,
			});
		}
	});
};

export const clientQuery = async (query, variables, endpoint, headers) => {
	let token = localStorage.getItem('IdToken');
	let refreshTokenValue = localStorage.getItem('RefreshToken');

	let attempts = 0;
	let refreshToken = false;
	let errorData = [];
	let returnCall = false;
	let returnData = null;
	let returnError = null;

	GerVersionFromServer()

	while (!returnCall) {
		returnError = null;

		const authLink = new ApolloLink((operation, forward) => {
			operation.setContext({
				headers: headers || {
					authorization: token,
					"app-id": "px-cloud",
					"app-version": packageJSON.version,
					"package-name": packageJSON.name
				},
			});

			// Call the next link in the middleware chain.
			return forward(operation);
		});

		const client = new ApolloClient({
			link: authLink.concat(
				ApolloLink.from([
					onError(({ response, graphQLErrors, networkError, operation, forward }) => {
						console.log('graphQLErrors', graphQLErrors, response);
						errorData = response;
						graphQLErrors &&
							graphQLErrors.map((error) => {
								if (error.errorType === 'UnauthorizedException') refreshToken = true;
							});
					}),
					new HttpLink({
						uri: endpoint,
					}),
				]),
			),
			cache: new InMemoryCache({
				dataIdFromObject(responseObject) {
					switch (responseObject.__typename) {
						// this is because the reportLog id can be repeated between multiple records
						case 'EntityTag': return `EntityTag:${responseObject.tagId}${responseObject.unitId}${Math.random()}`;
						case 'ReportLog': return `ReportLog:${responseObject.id}${responseObject.createdOn}`;
						case 'ReportActEntry': return `ReportActEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportDriverFormsReportV2Entry': return `ReportDriverFormsReportV2Entry:${responseObject.unitTime}${Math.random()}${new Date().getTime()}`;
						case 'ReportDriverStatusReportV2Entry': return `ReportDriverStatusReportV2Entry:${responseObject.unitTime}${Math.random()}${new Date().getTime()}`;
						case 'ReportHourOfUseEntry': return `ReportHourOfUseEntry:${responseObject.unitTime}${Math.random()}${new Date().getTime()}`;
						case 'ReportStopSummary': return `ReportStopSummary:${responseObject.id}${responseObject.startDate}`;
						case 'LandmarkSummaryItem': return `LandmarkSummaryItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportIgnitionEntry': return `ReportIgnitionEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportEventEntry': return `ReportEventEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportTimesheetDetailItem': return `ReportTimesheetDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportOdometerEntry': return `ReportOdometerEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportLandmarkEngineHoursDetailItem': return `ReportLandmarkEngineHoursDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportLatLongDetailItem': return `ReportLatLongDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportLandmarkBasedDetailList': return `ReportLandmarkBasedDetailList:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'DriverTimesheetDetailItem': return `DriverTimesheetDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'GeofenceBasedSummaryEntry': return `GeofenceBasedSummaryEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'MovingDetailItem': return `MovingDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'GeofenceDetailItem': return `GeofenceDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'AlertLogDetailItem': return `AlertLogDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'FuelCardDetailItem': return `FuelCardDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'DeviceSummaryInfoDetailItem': return `DeviceSummaryInfoDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'InputDetailItem': return `InputDetailItem:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportMileageGroupedByDateEntry': return `ReportMileageGroupedByDateEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ItemUtilizationReport': return `ItemUtilizationReport:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportStartStopIdleCategorySummary': return `ReportStartStopIdleCategorySummary:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportStartStop': return `ReportStartStop:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportChatHistoryV2': return `ReportChatHistoryV2:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportStateMileageCrossing': return `ReportStateMileageCrossing:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportStateMileageSummariesEntry': return `ReportStateMileageSummariesEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportTimesheet': return `ReportTimesheet:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportSummary': return `ReportSummary:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'ReportSpeedEntry': return `ReportSpeedEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
						case 'IdleReportPerUnit': return `IdleReportPerUnit:${responseObject.id}${Math.random()}${new Date().getTime()}`;
            			case 'UtilizationReportUnit' : return `UtilizationReportUnit:${responseObject.id}${Math.random()}${new Date().getTime()}`;
            			case 'DriverMileageSummaryItem' : return `DriverMileageSummaryItem:${responseObject.deviceId}${Math.random()}${new Date().getTime()}`;
            			case 'ReportLandmarkEntry' : return `ReportLandmarkEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
            			case 'ReportLocationEntry' : return `ReportLocationEntry:${responseObject.id}${Math.random()}${new Date().getTime()}`;
                        case 'ReportLandmarkBased' : return `ReportLandmarkBased:${responseObject.id}${Math.random()}${new Date().getTime()}`;
                        case 'ReportStopEntry' : return `ReportStopEntry:${responseObject.deviceId}${Math.random()}${new Date().getTime()}`;
						default: return defaultDataIdFromObject(responseObject);
					}
				}
			}),
			defaultOptions: {
				query: {
					//errorPolicy: 'all'
				},
			},
		});

		await client
			.query({
				query: gql(query),
				variables: variables,
			})
			.then((res) => {
				returnCall = true;
				returnData = res.data;
			})
			.catch((error) => {
				if (refreshToken) {
					attempts = attempts + 1;
					returnError = error;

					if (attempts === 5) returnCall = true;
				} else {
					returnCall = true;
					returnError = error;
				}
			});

		if (!returnCall) {
			console.log('recovery');
			await clientGraphQLAuthMutation(`
				mutation refreshToken($refreshToken: String!) {
					authenticationResult: refreshToken(refreshToken: $refreshToken) {
						AccessToken
						ExpiresIn
						RefreshToken
						IdToken
						ChallengeName
						Session
					}
				}
      			`,
				{
					refreshToken: refreshTokenValue
				}
			).then(data => {
				console.log("recovery", data)
				if (data && data.authenticationResult) {
					const { authenticationResult } = data
					token = authenticationResult.IdToken;
					localStorage.setItem('IdToken', authenticationResult.IdToken);
					localStorage.setItem('AccessToken', authenticationResult.AccessToken);
					authenticationResult.RefreshToken = localStorage.getItem("RefreshToken")

					//login PHP
					try {
						let username = localStorage.getItem("username")

						let valuesLegacy = { action: "login", username: username, cognito: authenticationResult }
						let frame = document.getElementById('legacyIframe');
						frame.contentWindow.postMessage(valuesLegacy, getLegacyUrl(endpoints.LEGACY_URL));
						LogManager('login legacy');

					} catch (ex) {
						ExceptionManager(ex, 'GraphQLApi', 'Calling legacy set_login')
					}
				}
			}).catch(error => {
				console.log("recovery error", error)
				if (error.message === 'GraphQL error: Refresh Token has expired')
					return new Promise((resolve, reject) => {
						return reject({ error: error, logout: true });
					});
			})

		}
	}

	return new Promise((resolve, reject) => {
		if (!returnError) return resolve(returnData);
		else {
			return reject({
				error: returnError.message,
				innerError: returnError,
				errorData: errorData,
				logout: returnCall && returnError && refreshToken,
			});
		}
	});
};

export const clientGraphQLAuthMutation = async (query, variables) => {

	const authLink = new ApolloLink((operation, forward) => {
		// Use the setContext method to set the HTTP headers.
		operation.setContext({
			headers: {
				"x-api-key": endpoints.COGNITO_API_KEY,
			}
		});

		// Call the next link in the middleware chain.
		return forward(operation);
	});

	const client = new ApolloClient({
		link: authLink.concat(
			ApolloLink.from([
				new HttpLink({
					uri: endpoints.COGNITO_URL,
				}),
			]),
		),
		cache: new InMemoryCache()
	});

	return await client
		.mutate({
			mutation: gql(query),
			variables: variables,
		})
		.then(res => {
			return new Promise((resolve, reject) => {
				resolve(res.data)
			});
		})
		.catch(error => {
			return new Promise((resolve, reject) => {
				reject(error)
			});
		})
};
