//View Keys
export default {
  maputilities_menutitle: 'Utilidades Del Mapa',
  maputilities_vehicles: 'Vehículos',
  maputilities_realtimemaps: 'Mapas En Tiempo Real',
  maputilities_vehicletrails: 'Rastro de vehículos',
  maputilities_landmarks: 'Puntos de referencia',
  maputilities_geofences: 'Geocercas',
  maputilities_routemarker: 'Rutas',
  maputilities_vehicledirections: 'Direcciones del vehículo',
  maputilities_Vehicledirections: 'Indicaciones',
  maputilities_findnearest: 'Encontrar más cercano',
  maputilities_sendsms: 'Enviar Mensajes',
  maputilities_mapsettings: 'Opciones Del Mapa',
  maputilities_googleearth: 'Google Earth',
  maputilities_video: 'Video',
  maputilities_back: '< Atrás',
  maputilities_kml: 'Actualizar Kml',
  maputilities_fitbounds_vehicles: 'Centrar Vehículos',
  maputilities_kml_update: "Kmls Actualizados",
  maputilities_layer: 'Capas sobre el Mapa',
  maputilities_eta: 'Tiempo Estimado de Llegada',
  maputilities_speedOverride: 'Control de Velocidad',
  maputilities_vicinityTool: 'Herramienta de Proximidad',
  maputilities_vicinityToolUnits: 'Herramienta de Proximidad - Unidades',
  //----------------------------------------
  //Units
  mapsettings_unitlabels: 'Etiqueta de Unidades',
  mapsettings_unitlabelsOverlap: 'Evitar el solapamiento de etiquetas',
  mapsettings_arrowicon: 'Icono Apuntador',
  mapsettings_unitclusters: 'Agrupación de Unidades',
  mapsettings_followselectunits: 'Seguimiento a unidad Seleccionada',
  mapsettings_grouptagsonlabels: 'Etiqueta de Grupos en Label',
  //Landmarks
  mapsettings_landmarks: 'Puntos de Referencia',
  mapsettings_landmarkslabels: 'Etiquetas de Puntos de Referencias',
  mapsettings_singlelandmarkmode: 'Puntos de Referencia Modo Unico',
  mapsettings_ocuppiedlandmarkonly: 'Solo Puntos de Referencia Ocupados',
  //geofences
  mapsettings_geofences: 'Geocercas',
  mapsettings_geofenceslabels: 'Etiqueta de Geocercas',
  //Zoom
  mapsettings_vehiclezoom: 'Acercamiento de Vehículo',
  mapsettings_trailzoom: 'Acercamiento de Recorrido',
  mapsettings_geofencezoom: 'Acercamiento de Geocerca',
  mapsettings_geofencesvisibleatzoom: 'Visibilidad por Acercamiento de Geocercas',
  mapsettings_landmarkzoom: 'Acercamiento de Puntos de Referencia',
  mapsettings_landmarkmarkersvisibleatzoom: 'Acercamiento de Marcador de Puntos de Referencia',
  mapsettings_landmarkradiusvisibleatzoom: 'Visibilidad por Acercamiento al Area de los Puntos de Referencia',
  mapsettings_selectedUnits: 'Unidades seleccionadas',
  mapsettings_selectedUnits_map: 'Vehículos en el area',
  mapsettings_currentZoom: 'Zoom Actual',
};
