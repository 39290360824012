import crewRedux from "Modules/settings/views/crew/redux/reducer";
import driverGroupsRedux from "Modules/settings/views/driverGroups/redux/reducer";
import locationsRedux from "Modules/settings/views/locations/redux/reducers";
import inputLabelsRedux from "Modules/settings/views/inputLabels/redux/reducer";
import vehicleStatusRedux from "Modules/settings/views/vehicleStatus/redux/reducer";
import driverRedux from "Modules/settings/views/driver/redux/reducer";
import vehicleEquipmentRedux from "Modules/settings/views/vehicleEquipment/redux/reducer";
import keyFobsRedux from "Modules/settings/views/keyFob/redux/reducer";
import temperatureRedux from "Modules/settings/views/temperature/redux/reducer";
import unitSettingsRedux from "Modules/settings/views/unitSettings/redux/reducer";
import fuelCardRedux from "Modules/settings/views/fuelCard/redux/reducer";
import cameraManagedRedux from "Modules/settings/views/cameraManage/redux/reducer";
import cameraManagedEditRedux from "Modules/settings/views/cameraManage/components/edit/redux/reducer";
import editUnitRedux from "Modules/settings/views/unitSettings/components/editUnitSettings/redux/reducer";
import driverStatusRedux from '../../views/driverStatus/redux/slice';
import driverStatusDeleteRedux from '../../views/driverStatus/components/delete/redux/slice';
import driverStatusCreateEditRedux from '../../views/driverStatus/components/createEdit/redux/slice';
import driverStatusLogListRedux from '../../views/driver/components/timeClock/redux/slice';
import driverStatusLogCreateEditRedux from '../../views/driver/components/timeClock/components/createEdit/redux/slice';
import driverStatusLogDeleteRedux from '../../views/driver/components/timeClock/components/delete/redux/slice';
import driverFacesRedux from "../../views/driver/redux/slices";

export default {
    crewRedux,
    driverGroupsRedux,
    locationsRedux,
    inputLabelsRedux,
    vehicleStatusRedux,
    driverRedux,
    vehicleEquipmentRedux,
    keyFobsRedux,
    temperatureRedux,
    unitSettingsRedux,
    fuelCardRedux,
    cameraManagedRedux,
    cameraManagedEditRedux,
    editUnitRedux,
    driverStatusRedux,
    driverStatusDeleteRedux,
    driverStatusCreateEditRedux,
    driverStatusLogListRedux,
    driverStatusLogCreateEditRedux,
    driverStatusLogDeleteRedux,
    driverFacesRedux
};
