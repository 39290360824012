import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientQuery } from 'Core/data/GraphQLApi';
import { ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { NotificationManager } from 'Components/Notifications';
import { speedFormatMPH, deleteDupplyInArray } from 'Core/data/Helpers';
import { getEventColor, convertDateToSeconds, calcDistanceToPoints, calculateDistanceTraveled} from '../../../../../../components/Map/utils';

//Action types
import { REALTIME_MAP_VEHICLETRAILS_GET, REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET, REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET, GET_LAST_VEHICLETRAILS } from 'Redux/actionTypes';

//actions
import { getVehicleTrailsConfirmResult, searchUnitTypeEventsResult, searchUnitGetResult, getLastVehicleTrailResult } from 'Redux/actions';
import { isArray } from 'lodash';

//Function
function* getVehicleTrailsRequest({ payload }) {
  const { params } = payload
  const { unitId, event, startDate, endDate, openSearch, limit, offset, useEcuSpeed, unitType, kph, withRecentEvent } = params
  let countItems = offset == 0 ? offset : (offset - 1) * limit; //starting at the offset element
  let wayPoint = 0
  let queryElements = { deviceId: unitId, event, startDate, endDate, openSearch: openSearch ? openSearch : '', limit, offset: countItems, includeLinkedData: true, withRecentEvent }
  
  if (event == '0') {
    delete queryElements["event"]
  }
  if (!openSearch) {
    delete queryElements["openSearch"]
  }
  try {
    const response = yield call(
      clientQuery,
      `query searchVehicleTrails ($startDate: AWSDateTime!, $endDate: AWSDateTime!, $event: VehicleTrailEvent, $deviceId: String!, $openSearch: String, $limit: Int, $offset: Int, $includeLinkedData: Boolean, $withRecentEvent: Boolean) {
        Trails: searchVehicleTrails (startDate: $startDate, endDate: $endDate, event: $event, deviceId: $deviceId, openSearch: $openSearch, limit: $limit, offset: $offset, includeLinkedData: $includeLinkedData, withRecentEvent: $withRecentEvent) {
            items {
                id
                deviceId
                latitude
                longitude
                heading
                eventCode
                eventName
                address
                formattedAddress
                driverId
                driverName
                ignitionStatus
                satelliteCount
                signal
                ecuSpeed
                speed
                speedLimit
                unitTime
                landmark {
                  id
                  companyId
                  name
                  tags {
                    id
                    label
                    color
                    path
                  }
                  address
                  city
                  zip
                  state
                  country
                }
                trail{
                  lat
                  lon
                  timestamp
                }
            }
            total
        }
    }`,
      queryElements,
      endpoints.GRAPHQL_GENERAL,
    );

    let data = {}, trailRoute = [];
    if (response && response.Trails) {
      const vehicleTrailsData = response.Trails;

      if (vehicleTrailsData && vehicleTrailsData.items) {
        wayPoint = vehicleTrailsData.items.length + 1
        let dataTrails = [...vehicleTrailsData.items];

        dataTrails = dataTrails.sort((a, b) => {
          // Convertir to int
          const unitTimeA = parseInt(a.unitTime);
          const unitTimeB = parseInt(b.unitTime);

          // sort
          return unitTimeA - unitTimeB;
        });

        
        let newDataTrailsArray = [...vehicleTrailsData.items]
        newDataTrailsArray = newDataTrailsArray.sort((a, b) => {
          // sort
          return parseInt(a.id) - parseInt(b.id);
        });

        //get all trails
        newDataTrailsArray.map((item, indexTrail) => {
          let color =  getEventColor(item?.eventName)
          if(isArray(item.trail) && item.trail.length > 0){
            
            const timePrincipal = convertDateToSeconds(Date.parse(item.unitTime));
            const timeChild = convertDateToSeconds(item?.trail[0]?.timestamp);

            const diferencesInSeconds = Math.abs(timePrincipal - timeChild);
            const maxDistanceTraveled = diferencesInSeconds > 0 ? calculateDistanceTraveled(140, diferencesInSeconds) : 1000; //500 metters to 120 km per hour for speed units (every 30 seconds to come data)

            //if speed is mayor o equal to 120km per hour for TRUCKS
            //value of distance is in Metters
            const distanceValidationInMetters = calcDistanceToPoints(item?.latitude, item?.longitude, item?.trail[0]?.lat, item?.trail[0]?.lon);

            let haveError = false;
            let detailsTrailsElements;
            if(distanceValidationInMetters < maxDistanceTraveled){
              
              // made the validation when have error in trails
              // the trails come same in all rows
              if(indexTrail < newDataTrailsArray.length - 1 && isArray(newDataTrailsArray[indexTrail + 1]?.trail) &&  newDataTrailsArray[indexTrail + 1]?.trail?.length > 0 ){
                if(JSON.stringify(item?.trail) == JSON.stringify(newDataTrailsArray[indexTrail + 1]?.trail)){
                  haveError = true;
                }
              }

              if(!haveError){
                //orden elements
                //if have timestamp
                detailsTrailsElements = [...item.trail];

                if(item.trail[0].timestamp > 0){
                    detailsTrailsElements = detailsTrailsElements.sort((a, b) => {
                      const unitTimeA = parseInt(a.timestamp);
                      const unitTimeB = parseInt(b.timestamp);
                      // sort
                      return unitTimeA - unitTimeB;
                    });
                }else{
                  //if no have time data
                  let reversedArray = [];
                  for (let i = detailsTrailsElements.length - 1; i >= 0; i--) {
                      reversedArray.push(detailsTrailsElements[i]);
                  }
                  detailsTrailsElements = reversedArray;
                }

                detailsTrailsElements.forEach((trail) => {
                  trailRoute.push({
                    ...trail,
                    color:  color,
                    eventName: item.eventName,
                    timestamp: convertDateToSeconds(trail.timestamp),
                  })
                })
              }
            
            }else{
              //else for this validation if(distanceValidationInMetters < maxDistanceTraveled){
              //no have the correct distance
              haveError = true;
            }

            if(haveError){
              console.log('Error to trails data (speed validation)', 'Value Vehicle:', item, 'calc distance', distanceValidationInMetters)
            }
          } //end validation isArray(item.trail) && item.trail.length > 0

          trailRoute.push({
            timestamp:  convertDateToSeconds(Date.parse(item.unitTime)),
            lat: item.latitude,
            lng: item.longitude,
            lon: item.longitude,
            color: color,
            eventName: item.eventName
          })
        });


        let lastEvent = '', isChild = false;
        dataTrails = dataTrails.map((item) => {
          if(lastEvent != item.eventName){
            lastEvent = item.eventName
            isChild = false
          }else{
            isChild = true
          }
          return { ...item, hideMarker: isChild }
        })

        //order by events travel stop before to engine off -- order events by name
        dataTrails = dataTrails.sort((a, b) => {

          const unitTimeA = parseInt(a.unitTime);
          const unitTimeB = parseInt(b.unitTime);

          // sort
          return unitTimeB - unitTimeA;
        });

        lastEvent = '', isChild = false;
        dataTrails = dataTrails.map((item) => {
          countItems++;
          wayPoint--;
          let speed =  useEcuSpeed && item?.ecuSpeed > 0 ? item?.ecuSpeed : item.speed
          let speedFormat = 0
          if(unitType && kph){
            speedFormat = speedFormatMPH(speed, unitType, kph, true)
          }
          return { ...item, index: countItems, wayPoint: wayPoint, speedFormat: speedFormat, speed: speed, unitType: unitType }
        });

        data = { ...response.Trails, items: dataTrails, trailRoute: trailRoute };
      }

      yield put(getVehicleTrailsConfirmResult(data));
    } else {
      throw 'Not Vehicle Trails';
    }
  } catch (e) {
    ExceptionManager(e, 'modules/maintenance/views/redux/saga', 'getVehicleTrailsRequest');
    yield put(getVehicleTrailsConfirmResult(null));
  }
}

function* searchUnitTypeEvents({ payload }) {
  try {
    const response = yield call(
      clientQuery,
      `query searchUnitTypeEvents ($unitTypeId: Int) {
        result: searchUnitTypeEvents (unitTypeId: $unitTypeId) {
          eventName
          eventId
        }
      }`,
      {
        unitTypeId: payload
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (response?.result) {
      yield put(searchUnitTypeEventsResult(response?.result))
    } else yield put(searchUnitTypeEventsResult())
  } catch (e) {
    yield put(searchUnitTypeEventsResult())
    ExceptionManager(e, 'modules/realtimeMaps/main/components/VehicleTrails/redux/saga', 'searchUnitTypeEvents');
  }
}

function* searchUnits({ payload }) {
  try {
    const response = yield call(
      clientQuery,
      `query searchUnits ($openSearch: String) {
        result: searchUnits (openSearch: $openSearch) {
          id
          label
          typeId
          useEcuSpeed
          show
          timeZone {
            id
            name
          }
          lastReading {
            unitType
          }
        }
      }`,
      {
        openSearch: payload
      },
      endpoints.GRAPHQL_GENERAL,
    );
    if (response.result) {
      yield put(searchUnitGetResult(response.result));
    } else yield put(searchUnitGetResult())
  } catch (e) {
    yield put(searchUnitGetResult())
    ExceptionManager(e, 'modules/realtimeMaps/main/components/VehicleTrails/redux/saga', 'searchUnits');
  }
}

function* getLastVehicleTrailsRequest({ payload }) {
  const { params } = payload
  const { unitId, event, date, useEcuSpeed, comeBack,  unitType, kph } = params
  let wayPoint = 0
  let queryElements = { deviceId: unitId, date, comeBack, includeLinkedData: true }
  if (event == '0') {
    delete queryElements["event"]
  }
  try {
    const response = yield call(
      clientQuery,
      `query searchLastVehicleTrail ($date: AWSDateTime!, $deviceId: String!, $includeLinkedData: Boolean, $comeBack: Int) {
        Trails:searchLastVehicleTrail (date: $date, deviceId: $deviceId, includeLinkedData: $includeLinkedData, comeBack: $comeBack) {
            items {
                id
                deviceId
                latitude
                longitude
                heading
                eventCode
                eventName
                address
                formattedAddress
                driverId
                driverName
                ignitionStatus
                satelliteCount
                signal
                ecuSpeed
                speed
                speedLimit
                unitTime
                landmark {
                  id
                  companyId
                  name
                  tags {
                    id
                    label
                    color
                    path
                  }
                  address
                  city
                  zip
                  state
                  country
                }
            }
            total
        }
    }`,
      queryElements,
      endpoints.GRAPHQL_GENERAL,
    );

    let data = {};
    if (response && response.Trails) {
      const vehicleTrailsData = response.Trails;

      if (vehicleTrailsData && vehicleTrailsData.items) {
        wayPoint = vehicleTrailsData.items.length + 1
        const dataTrails = vehicleTrailsData.items.map((item) => {
          wayPoint--;
          let speed =  useEcuSpeed && item?.ecuSpeed > 0 ? item?.ecuSpeed : item.speed
          if(unitType && kph){
            speed = speedFormatMPH(speed, unitType, kph, true)
          }
          return { ...item, index: 1, wayPoint: wayPoint, speed: speed }
        });

        data = { ...response.Trails, items: dataTrails };
      }

      yield put(getLastVehicleTrailResult(data));
    } else {
      throw 'Not Vehicle Trails';
    }
  } catch (e) {
    ExceptionManager(e, 'modules/maintenance/views/redux/saga', 'getLastVehicleTrailRequest');
    yield put(getLastVehicleTrailResult(null));
  }
}

function* watchGetVehicleTrailsTasksRequest() {
  yield takeEvery(REALTIME_MAP_VEHICLETRAILS_GET, getVehicleTrailsRequest);
  yield takeEvery(REALTIME_MAP_VEHICLETRAILS_SEARCH_UNIT_TYPE_EVENTS_GET, searchUnitTypeEvents);
  yield takeEvery(REALTIME_MAP_VEHICLETRAILS_SEARCH_UNITS_GET, searchUnits)
  yield takeEvery(GET_LAST_VEHICLETRAILS, getLastVehicleTrailsRequest)
}
//Saga Export
export default function* rootSaga() {
  yield all([
    fork(watchGetVehicleTrailsTasksRequest)
  ]);
}