export default {
  "videoDetail_availableMedia": "Medios Disponibles",
  "videoDetail_event": "Evento: ",
  "videoDetail_datetime": "Fecha/Hora: ",
  "videoDetail_address": "Dirección: ",
  "videoDetail_vehicle": "Vehículo: ",
  "videoDetail_driver": "Conductor: ",
  "videoDetail_speed": "Velocidad: ",
  "videoDetail_clipRequests": "Solicitud de Clip",
  "videoDetail_error": "Ha ocurrido un error. Por favor intente de nuevo.",
  "videoDetail_notesTitle": "Comentarios del administrador",
  "videoDetail_addNote": "Agrega una nota...",
  "videoDetail_adminComments": "Agrega un comentario...",
  "videoDetail_saveNote": "Agregar",
  "videoDetail_notNotes": "Aún no hay notas.",
  "videoDetail_download": "Descargar",
  "videoDetail_delete": "Eliminar",
  "videoDetail_share": "Compartir",
  "videoDetail_editNote": "Editar",
  "videoDetail_cancel": "Cancelar",
  "videoDetail_deleteMedia": "Eliminar",
  "videoDetail_deleteMediaWarning": "¿Está seguro de que desea eliminar los siguientes elementos?",
  "videoDetail_deleteClipRequest": "clips",
  "videoDetail_deleteEvent": "eventos",
  "videoDetail_deleteSuccess": "han sido eliminados",
  "videoDetail_deleteEvents": "Eliminar eventos",
  "videoDetail_deleteClips": "Eliminar clips",
  "videoDetail_deleteEventNotes": "Eliminar notas",
  "videoDetail_deletedNotes": "Notas eliminadas exitosamente",
  "videoDetail_newAlert": "Nueva alerta",
  "videoDetail_reviewStatus": "Estado de revisión",
  "videoDetail_driverNotes": "Notas del conductor",
  "videoDetail_statusRequired": "Por favor seleccione un estado para la revisión",
  "videoDetail_success": "Datos guardados exitosamente",
  "videoDetail_notes": "Notas",
  "videoDetail_visibleToDriver": "Visible al conductor"
}