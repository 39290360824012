import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {isNumber} from 'lodash';
import {Grid} from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// controls
import InfiniteScrollTable from 'Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable'

//helpers
import {
  decimalHandler,
  formatDateFromFilter,
  formatDatetime,
  getGroupId,
  getNameUnitDriverTag, isKPH,
  useHandleExportReport,
  getMethodToFormatDuration,
  isKPHLower
} from 'Modules/reports/views/main/components/reportsTab/helper';
//hooks
import {useReportsSelector, useSidePanelMap} from 'Core/hooks';
import {GetFormatForMoment} from 'Core/data/Helpers';

//actions
import {reportsMainGenerateReportUpdate} from 'Redux/actions'

// styles
import './summaryReport.scss';
import { isKPHAcronym, isKPHAcronymSpeed } from '../../../helper';

const ROW_PER_PAGE = 25;
const SummaryReport = (props) => {

  const {messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive} = props;
  const dispatch = useDispatch()
  const {loading, asyncLoading, data, filters, timeZone} = report
  const {filterEntity, filterArray, filterType} = filters;
  const exportHandler = useHandleExportReport(report)
  const [lastEntity, setLastEntity] = useState({});

  const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
  const { durationFormat } = user;
  const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)
  const reportRef = useRef();
  const entitiesArrayRef = useRef();
  const detailReportTableRef = useRef();
  const lastEntityRef = useRef({});
  const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)
  const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity : null;
  const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected : null;
  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if (isActive) {
      reportTableSelect.onChangeResetCache();
    }
  }, [isActive])


  const buildTableHeader = () => {
    return (
        <Grid container item direction={"row"} className={"table-scroll-reports-title-details"}>

          <Grid item sm={6} className={'reports-cut-text'}>
            {messages["generatedReport_totals"]}
          </Grid>
          <Grid item sm={6} className={'reports-cut-text'}>
            {messages["permissions_utilization"]}
          </Grid>
        </Grid>
    )
  }

  const durationFormatTime = getMethodToFormatDuration(durationFormat);

  const TableViewGroup = ({index, style, content, id, previousItem}) => {
    const element = content?.recordInfo;
    const recordInfo = content?.recordInfo;

    return (
        <div
            className="listItem groupItem"
            id={id}
            key={id}
            style={style}
        >

          <Grid container direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group'>
            {
                element?.entityLabel !== 'Grand Total' && <Grid container item className='table-scroll-reports-header'>
                  <Grid item sm={3}>

                    {
                      element?.tagId > 0 ?
                          (<LocalOfferIcon
                              style={{
                                fontSize: "16.8px",
                                color: `#${element.groupTagColor && element.groupTagColor != "ffffff" ? element.groupTagColor : "000"}`,
                                position: 'absolute'
                              }}/>)
                          : ""
                    }
                    <span
                        className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding' : ''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)}`}</span>
                    {
                        report?.filters?.filterArray.length !== 1 && (<span  className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)
                    }
                  </Grid>
                  <Grid item sm={9}>
                <span
                    className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()}</span>
                  </Grid>
                </Grid>

            }
            <div className='table-scroll-reports-separator'></div>
            <Grid item>
              <p className='table-scroll-reports-label'>{element?.entityLabel === 'Grand Total' ? messages['generatedReport_grandTotals'] : element?.entityLabel}</p>
            </Grid>
          </Grid>
          {buildTableHeader()}
        </div>
    )
  }

  const TableViewDetail = ({index, style, content, id, previousItem}) => {

    const getValueNoDataFromRow = (value, noData, showMessage) => {
      if (noData) {
        return showMessage ? messages['reportsMainRT_noDataEvent'] : '';
      }
      return value;
    }

    const element = content?.recordDetail;

    let distance = `${decimalHandler(element.distance || 0, messages['reportsMainRT_notAvailable'])} ${isNumber(Number(element.distance)) ? messages[isKPHAcronym(element.isKPH)] : ''}`;
    distance = getValueNoDataFromRow(distance, element.noData);


    let input1 = 0, input2 = 0, input3 = 0, input4 = 0, input5 = 0, input6 = 0, input7 = 0

    element.inputs && element.inputs.map((input) => {
      switch (input.input) {
        case "Input 1 On":
          input1 = input.duration
          break;
        case "Input 2 On":
          input2 = input.duration
          break;
        case "Input 3 On":
          input3 = input.duration
          break;
        case "Input 4 On":
          input4 = input.duration
          break;
        case "Input 5 On":
          input5 = input.duration
          break;
        case "Input 6 On":
          input6 = input.duration
          break;
        case "Input 7 On":
          input7 = input.duration
          break;
        default:
          break;
      }
    })

    return (
        <div
            className="listItem detailItem"
            style={style}
            id={id}
        >
          <Grid container direction={"column"} style={{marginBottom: "10px"}}>
            {

              !element.noData ? (
                    <Grid container item direction={"row"}
                          style={{paddingTop: "1px", paddingBottom: "10px"}}>
                      <Grid item sm={6} className={'reports-cut-text'}>
                        <Grid container item direction={"column"}
                              style={{paddingTop: "1px", paddingBottom: "1px"}}>
                          <Grid item className={"report-ctn-text summary-report_column"}>
                            <span className='summary-report_label'>{messages['generatedReport_engineOn']}</span> {getValueNoDataFromRow(durationFormatTime(element.engineOn || 0, messages), element.noData, true)}
                          </Grid>
                          <Grid item className={"report-ctn-text summary-report_column"}>
                            <span className='summary-report_label'>{messages['generatedReport_engineOff']}</span>  {getValueNoDataFromRow(durationFormatTime(element.engineOff || 0, messages), element.noData, true)}
                          </Grid>
                          <Grid item className={"report-ctn-text summary-report_column"}>
                            <span className='summary-report_label'>{messages['generatedReport_travelTime']}</span>  {getValueNoDataFromRow(durationFormatTime(element.travelTime || 0, messages), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text summary-report_column"}>
                            <span className='summary-report_label'>{messages['generatedReport_distance']}</span>  {getValueNoDataFromRow(distance || 0, element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text summary-report_column"}>
                            <span className='summary-report_label'>{messages['generatedReport_idleTime']}</span>  {getValueNoDataFromRow(durationFormatTime(element.idleTime || 0, messages), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text summary-report_column"}>
                            <span className='summary-report_label'>{messages['generatedReport_input1']}</span>  {getValueNoDataFromRow(durationFormatTime(input1 || 0, messages), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text summary-report_column"}>
                            <span className='summary-report_label'>{messages['generatedReport_input2']}</span>
                            {getValueNoDataFromRow(durationFormatTime(input2 || 0, messages), element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text summary-report_column"}>
                            <span className='summary-report_label'>{messages['generatedReport_maxSpeed']}</span>  {getValueNoDataFromRow(`${element.maxSpeed || 0} ${isKPHAcronymSpeed(element.isKPH)}`, element.noData)}
                          </Grid>
                          <Grid item className={"report-ctn-text summary-report_column"}>
                            <span className='summary-report_label'>{messages['generatedReport_avgSpeed']}</span>  {getValueNoDataFromRow(`${element.averageSpeed || 0} ${isKPHAcronymSpeed(element.isKPH)}`, element.noData)}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={6} className={'reports-cut-text'}>
                          <Grid container item direction={"column"} style={{paddingTop: "1px", paddingBottom: "1px"}}>
                            <Grid item className={"report-ctn-text"}>
                              {getValueNoDataFromRow(`${element.utilization.engineOn}%` , element.noData)}
                            </Grid>
                            <Grid item className={"report-ctn-text"}>
                              {getValueNoDataFromRow(`${element.utilization.engineOff}%` , element.noData)}
                            </Grid>
                          </Grid>
                      </Grid>
                    </Grid>
              ) : (
                  <span>{messages['reportsMainRT_noDataEvent']}</span>
              )
            }
          </Grid>
        </div>
    )
  }

  const renderItems = (parameters) => {
    const {content} = parameters;
    const type = content.recordInfo?.recordType;
    if (type === "GROUP") {
      const groupId = getGroupId(content)
      const entitiesArray = entitiesArrayRef.current || {};
      if (!entitiesArray[groupId]) {
        const newEntityArray = {...entitiesArray};
        newEntityArray[groupId] = content;
        entitiesArrayRef.current = newEntityArray
      }
      // skip the first group beacuse the first group is replaced with the sticky header
      if (parameters.index === 0) {
        return;
      }

      return (
          <TableViewGroup {...parameters}></TableViewGroup>
      )
    } else if (type === "DETAIL") {
      return <TableViewDetail {...parameters}></TableViewDetail>;
    }

    if (parameters.index === 0) {
      return;
    }

    return (
        <div>
          unknown type
        </div>
    )
  }

  const onStartIndex = (index) => {
    const item = data?.items[index];

    if (item) {
      if (item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId) {
        setLastEntity(item)
      }
      lastEntityRef.current = item
    }
  }

  const renderHeader = () => {
    const groupId = getGroupId(lastEntity);
    const entitiesArray = entitiesArrayRef.current || {};
    const itemHeader = entitiesArray[groupId];

    if (itemHeader) {
      return (
          <TableViewGroup
              style={{
                backgroundColor: '#fff',
                position: 'sticky',
                top: 0,
                zIndex: 9

              }}
              content={itemHeader}
              id={itemHeader?.recordInfo?.entityId}
          ></TableViewGroup>
      );
    }
    return;

  };

  const findTag = filterArray?.findIndex((item) => item.name == "Tag")
  const hasTag = findTag >= 0;
  const type = hasTag ? messages['generatedReport_tag'] :
      (report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver'])
  return (
      <>
        <InfiniteScrollTable
            refContainer={detailReportTableRef}
            renderItems={renderItems}
            loading={asyncLoading || loading}
            data={data.items || []}
            total={data.total}
            reportTitle={messages['reportsMainRT_summaryReport']}
            editReport={() => {
              copyReportToSetup({...report, data: []})
            }}
            onSchedule={() => {
              scheduleReport(report)
            }}
            onCreate={openReportSetup}
            tableContainerClass={"detailed-report-reports-container"}
            type={type}
            handleNewPageLoad={(page) => {
              let currentReport = {...report};
              currentReport.exportType = "json";
              currentReport.filters = {
                ...currentReport.filters,
                unitsSelected: unitDriverSelected?.id != -99 ? unitDriverSelected : null,
                accumulateData: true,
                dateSelected: dateSelected?.id != -99 ? unitDriverSelected : null,
              };
              dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
            }}
            renderHeader={renderHeader}
            onStartIndex={onStartIndex}
            isActive={isActive}
            {...reportTableSelect}
            {...exportHandler}
        />
      </>
  );
};

export default SummaryReport;
